import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { toast } from "react-toastify";
import moment from "moment";
import "moment/locale/pt-br";
import { Autocomplete, debounce, TextField } from "@mui/material";
import DataTable, { TableColumn } from "react-data-table-component";
import { DatePicker } from "antd";
import locale from "antd/es/date-picker/locale/pt_BR";
import dayjs from "dayjs";
import en from "../i18n/en-us.json";
import pt from "../i18n/pt-br.json";
import { tableHelper } from "common/helpers/table/table-helper";
import { itlabMediumTableStyle } from "common/helpers/table/table-style";
import { CorbansService } from "app/corbans/services/corbans.service";
import { ConvenioService } from "app/convenios/services/convenio.service";
import { CorbanViewModel } from "app/lotes-contratacao/model/lotes-contratacao.model";
import { ConvenioViewModel } from "app/convenios/model/convenio.model";
import { ITSVG } from "common/helpers/ITSVG";
import {
  MasterLayout,
  MainLayout,
  ToolbarLayout,
  ToolbarLayoutLeft,
  ToolbarLayoutRight,
} from "common/imports/content-layout";
import searchIcon from "assets/images/icons/search.svg";
import { AmortizacaoService } from "../services/amortizacao.service";
import { AmortizacaoViewModel } from "../model/amortizacao.model";
import SaveButton from "common/partials/SaveButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import { GerarLoteContratacaoViewModel } from "../model/calculo-contratacao.model";
import { ExecucoesService } from "app/execucoes/services/execucoes.service";
import { GeneralSettingsService } from "app/general-settings/services/general-settings.service";
import CorbansSelecionadosModal from "./CorbansSelecionadosModal";
import CorbansEmAtrasoModal from "./child/CorbansEmAtrasoModal";
import { CalculoSettingsModel } from "app/general-settings/model/general-settings.model";
import { RolePermission } from "common/helpers/dictionaries/roles";

function CalculoContratacao() {
  i18next.addResourceBundle("us", "translation", en);
  i18next.addResourceBundle("br", "translation", pt);
  let initialParametro: CalculoSettingsModel = {
    roboSimulacaoModalidade: '',
    ticketMinimo: 0,
  };
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isCalculoSubmiting, setIsCalculoSubmiting] = useState(false);
  const { RangePicker } = DatePicker;
  const [dataInicial, setDataInicial] = useState("");
  const [dataFinal, setDataFinal] = useState("");
  const dateFormat = "DD/MM/YYYY";
  const [tableData, setTableData] = useState<CorbanViewModel[]>([]);
  const [corbanSearch, setCorbanSearch] = useState<string>("");
  const [convenios, setConvenios] = useState<ConvenioViewModel[]>([]);
  const [, setSelectedConvenioId] = useState<number | null>(null);
  const [amortizacao, setAmortizacao] = useState<AmortizacaoViewModel[]>([]);
  const [, setSelectedAmortizacaoId] = useState<number | null>(null);
  const [parametros, setParametros] = useState<CalculoSettingsModel>(initialParametro);
  
  const [page] = useState(0);

  const [selectedRows, setSelectedRows] = useState<CorbanViewModel[]>([]);
  const [selectedRowsIds, setSelectedRowsIds] = useState<number[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [toggleCleared] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [isCorbansSelecionadosModalOpen, setIsCorbansSelecionadosModalOpen] = useState(false);
  const [showModalCorbansEmAtraso, setShowModalCorbansEmAtraso] = useState(false);
  let initialData: GerarLoteContratacaoViewModel = {
    dataMutuo: new Date().toDateString(),
    diaVencimento: new Date().getDate(),
    modalidade: "0556",
    periodoFinal: "",
    periodoInicial: "",
    ticketMinimo: parametros.ticketMinimo,
    excluidos: [],
  };
  const [bodyLote, setbodyLote] = useState<GerarLoteContratacaoViewModel>(initialData);
  const handleOpenCorbansSelecionadosModal = () => {
    setIsCorbansSelecionadosModalOpen(true)
  };
  const handleCloseCorbansSelecionadosModal = () => {
    setIsCorbansSelecionadosModalOpen(false);
  };

  const handlePageChange = (page: number) => {
    fetchData(page - 1);
  };

  const handlePerRowsChange = async (newPerPage: number) => {
    setRowsPerPage(newPerPage);
  };

  const handleRowSelected = useCallback((state: any) => {
    setSelectedRows(state.selectedRows);
    let selectedIds: number[] = [];
    state.selectedRows.forEach((row: CorbanViewModel) => {
      selectedIds.push(row.id)
    })
    setSelectedRowsIds(selectedIds);
  }, []);

  const tableColumns: TableColumn<CorbanViewModel>[] = [
    {
      name: "IDLoja",
      selector: (row) => row.codigoOrigem || "",
      width: "90px"
    },
    {
      name: "CNPJ",
      selector: (row) => row?.cnpj?.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5") || "",
      width: "180px",
    },
    {
      name: "Razão Social",
      selector: (row) => row.razaoSocial || "",
      sortable: true,
    },
    {
      name: "Grupo",
      selector: (row) => row.grupoMutuo || "",
    },
    {
      name: "Data Elegível",
      selector: (row) =>
        moment(row.dataElegivel)
          .locale(`${t("dateFormat.locale")}`)
          .format("L"),
      center: true,
      width: "120px"
    },
  ];

  const conditionalRowStyles = [
    {
      when: (row: any) => row.parcelasEmAtraso,
      style: {
        backgroundColor: '#ff4d4d',
        color: 'white',
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
  ];
  const updateQuery = (e: ChangeEvent<HTMLInputElement>) => setCorbanSearch((e.target.value.trim()));

  const handleCorbanSearch = debounce(updateQuery, 800);


  const formSchema = Yup.object().shape({
    dataPagamento: Yup
      .string()
      .required(`${t("crud.validators.requiredField")}`)
      .nullable(),
    periodoInicial: Yup
      .string()
      .required(`${t("crud.validators.requiredField")}`),
    periodoFinal: Yup
      .string()
      .required(`${t("crud.validators.requiredField")}`),
    convenioId: Yup
      .string()
      .required(`${t("crud.validators.requiredField")}`),
    amortizacaoId: Yup
      .string()
      .required(`${t("crud.validators.requiredField")}`),
    modalidade: Yup
      .string()
      .required(`${t("crud.validators.requiredField")}`),
    diaVencimento: Yup
      .number()
      .required(`${t("crud.validators.requiredField")}`),
    ticketMinimo: Yup
      .number()
      .required(`${t("crud.validators.requiredField")}`),
  });

  const formik = useFormik<GerarLoteContratacaoViewModel>({
    enableReinitialize: true,
    initialValues: initialData,
    validationSchema: formSchema,
    onSubmit: async (values) => {

      const body: GerarLoteContratacaoViewModel = {
        dataMutuo: moment(new Date()).format("YYYY-MM-DD"),
        periodoInicial: dataInicial,
        periodoFinal: dataFinal,
        dataPagamento: values.dataPagamento,
        diaVencimento: values.diaVencimento,
        amortizacaoId: values.amortizacaoId,
        convenioId: values.convenioId,
        modalidade: values.modalidade,
        ticketMinimo: values.ticketMinimo,
        excluidos: selectedRowsIds
      };
      setbodyLote(body);
      var corbansEmAtraso = tableData.filter(f => f.parcelasEmAtraso && !selectedRowsIds.includes(f.id));
      if (corbansEmAtraso.length) {
        setShowModalCorbansEmAtraso(true);
      }
      else {
        calcular(body);
      }
    }
  });

  const calcular = async (body: GerarLoteContratacaoViewModel) => {
    setShowModalCorbansEmAtraso(false);
    setIsCalculoSubmiting(true);
    const response = await ExecucoesService.GerarLoteContratacao(body);
    if (response && !response?.hasErrors) {
      toast.success("Lote registrado e em processo de Cálculo. Acesse a lista de lotes para ver o status.")
    } else {
      toast.error(t(`${response?.message}`));
    }
    setIsCalculoSubmiting(false);
  };

  const calcularConfirm = async() => {
    calcular(bodyLote);
  };

  const handleCloseModal = () => {
    setShowModalCorbansEmAtraso(false);
  };

  const contextActions = useMemo(() => {
    return (
      <>
        <button
          type="button"
          className="btn btn-sm btn-primary me-2"
          onClick={() => handleOpenCorbansSelecionadosModal()}
        >
          Ver corbans removidos
        </button>
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows, toggleCleared]);

  const fetchAmortizacao = async () => {
    const response = await AmortizacaoService.getAllAmortizacao();
    if (response && !response.hasErrors) {
      setAmortizacao(response.data);
    }
  };

  const fetchConvenios = async () => {
    const response = await ConvenioService.getAllConvenio();
    if (response && !response.hasErrors) {
      setConvenios(response.data);
    }
  };

  const fetchParametros = async () => {
    const response = await GeneralSettingsService.getCalculoSettings();
    if (response && !response.hasErrors) {
      setParametros(response.data);
    }
  };

  const fetchData = async (page: number) => {
    setIsLoading(true);
    const response = await CorbansService.getCorbansElegiveisFiltered(page, rowsPerPage, corbanSearch);
    if (response && !response.hasErrors) {
      setTableData(response.data);
      setTotalRows(response.totalItems);
    } else {
      toast.error(t("crud.read.errorMessage"));
    }
    setIsLoading(false);
  };

  const handleFilterLotes = (dates: any) => {
    setDataInicial("");
    setDataFinal("");

    if (dates === null) {
      setDataInicial("");
      setDataFinal("");
      formik.setFieldValue("periodoInicial", undefined);
      formik.setFieldValue("periodoFinal", undefined);
    }

    if (dates) {
      const inicio = dayjs(dates[0]).format("YYYY-MM-DD");
      const fim = dayjs(dates[1]).format("YYYY-MM-DD");

      setDataInicial(inicio);
      setDataFinal(fim);
      formik.setFieldValue("periodoInicial", inicio);
      formik.setFieldValue("periodoFinal", fim);
    }
  };

  useEffect(() => {
    fetchConvenios();
    fetchAmortizacao();
    fetchParametros();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchData(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, corbanSearch]);

  return (
    <>
      <MasterLayout>
        <ToolbarLayout>
          <ToolbarLayoutLeft />
          <ToolbarLayoutRight />
        </ToolbarLayout>
        <MainLayout>
          <form onSubmit={formik.handleSubmit}>
            <div className="card mb-5 mb-xl-10">
              <div className="card-body">
                <div className="row">
                  <div className="col-6 col-sm-4 col-md-3 col-xxl-2 mb-10">
                    <label className="form-label">Data Consignado</label>
                    <DatePicker
                      className="form-control form-control-lg form-control-solid"
                      name="dataMutuo"
                      defaultValue={dayjs(formik.values.dataMutuo?.toString())}
                      locale={locale}
                      format={dateFormat}
                      disabled
                    />
                  </div>

                  <div className="col-6 col-sm-4 col-md-3 col-xxl-2 mb-10">
                    <label className="form-label required">Data Pagamento</label>
                    <DatePicker
                      className="form-control form-control-lg form-control-solid"
                      name="dataPagamento"
                      locale={locale}
                      format={dateFormat}
                      onChange={(e) => formik.setFieldValue("dataPagamento", e)}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.dataPagamento && formik.errors.dataPagamento && (
                      <div className="mt-3 text-danger fw-bold">{formik.errors.dataPagamento}</div>
                    )}
                  </div>

                  <div className="col-4 col-sm-4 col-md-2 mb-10">
                    <label className="form-label required">{t("Dia Venc.")}</label>
                    <input
                      type="number"
                      name="diaVencimento"
                      value={formik.values.diaVencimento}
                      className="form-control form-control-lg form-control-solid"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.diaVencimento && formik.errors.diaVencimento && (
                      <div className="mt-3 text-danger fw-bold">{formik.errors.diaVencimento}</div>
                    )}
                  </div>

                  <div className="col-8 col-sm-6 col-md-4 col-xxl-3 mb-10">
                    <label className="form-label required">Período</label>
                    <RangePicker
                      locale={locale}
                      format={dateFormat}
                      className="form-control form-control-lg form-control-solid d-flex"
                      onChange={(dates: any) => {
                        if (dates === null) {
                          formik.setTouched({ periodoInicial: true })
                        }
                        handleFilterLotes(dates);
                      }}
                      onBlur={() => formik.setTouched({ periodoInicial: true })}
                    />
                    {formik.touched.periodoInicial && formik.errors.periodoInicial && (
                      <div className="mt-3 text-danger fw-bold">{formik.errors.periodoInicial}</div>
                    )}
                  </div>

                  <div className="col-sm-6 col-md-3 col-xxl-2 mb-10">
                    <label className="form-label required">{t("Modalidade")}</label>
                    <input
                      type="string"
                      name="modalidade"
                      placeholder="Modalidade"
                      value={formik.values.modalidade}
                      className="form-control form-control-lg form-control-solid"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.modalidade && formik.errors.modalidade && (
                      <div className="mt-3 text-danger fw-bold">{formik.errors.modalidade}</div>
                    )}
                  </div>

                  <div className="col-sm-6 col-md-4 col-xxl-2 mb-10">
                    <label className="form-label required">Convênio</label>
                    <Autocomplete
                      options={convenios}
                      getOptionLabel={option => option.nome ? option.nome : ""}
                      onChange={(_, value) => {
                        setSelectedConvenioId(value?.id!)
                        formik.setFieldValue("convenioId", value?.id!)
                      }}
                      onInputChange={(_, value) => {
                        if (!value) {
                          setSelectedConvenioId(null)
                          formik.setFieldValue("convenioId", undefined)
                        }
                      }}
                      onBlur={() => formik.setTouched({ convenioId: true })}
                      renderInput={(params) =>
                        <TextField
                          {...params}
                          placeholder="Selecione"
                        />
                      }
                    />
                    {formik.touched.convenioId && formik.errors.convenioId && (
                      <div className="mt-3 text-danger fw-bold">{formik.errors.convenioId}</div>
                    )}
                  </div>

                  <div className="col-sm-6 col-md-5 col-xxl-3 mb-10">
                    <label className="form-label required">Amortização</label>
                    <Autocomplete
                      options={amortizacao}
                      getOptionLabel={option => option.descricao ? option.descricao : ""}
                      onChange={(_, value) => {
                        setSelectedAmortizacaoId(value?.id!)
                        formik.setFieldValue("amortizacaoId", value?.id!)
                      }}
                      onInputChange={(_, value) => {
                        if (!value) {
                          setSelectedAmortizacaoId(null)
                          formik.setFieldValue("amortizacaoId", undefined)
                        }
                      }}
                      onBlur={() => formik.setTouched({ amortizacaoId: true })}
                      renderInput={(params) =>
                        <TextField
                          {...params}
                          placeholder="Selecione"
                        />
                      }
                    />
                    {formik.touched.amortizacaoId && formik.errors.amortizacaoId && (
                      <div className="mt-3 text-danger fw-bold">{formik.errors.amortizacaoId}</div>
                    )}
                  </div>

                  <div className="col-sm-6 col-md-5 col-xxl-3 mb-10">
                    <label className="form-label required">{t("Ticket Mínimo")}</label>
                    <input
                      type="number"
                      name="ticketMinimo"
                      value={formik.values.ticketMinimo}
                      className="form-control form-control-lg form-control-solid"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.ticketMinimo && formik.errors.ticketMinimo && (
                      <div className="mt-3 text-danger fw-bold">{formik.errors.ticketMinimo}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="card-footer d-flex justify-content-end py-6 px-9">
                <SaveButton
                  type="submit"
                  label={`Calcular (${totalRows - selectedRows.length})`}
                  loading={isCalculoSubmiting}
                  disabled={!formik.isValid || !formik.dirty}
                  roles={[RolePermission.Update_CalculoContratacao]}
                />
              </div>
            </div>
          </form>

          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12 mb-5">
                  <label className="card-title fw-bolder text-muted fs-4">
                    Corbans Elegíveis
                  </label>
                </div>
                <div className="col-12 col-sm-4 col-lg-3 col-xxl-3 mb-8">
                  <div className="d-flex align-items-center position-relative">
                    <ITSVG path={searchIcon} className="svg-icon-1 position-absolute ms-6" />
                    <input
                      type="text"
                      className="form-control form-control-solid form-control-md ps-14"
                      placeholder="Buscar"
                      name="searchInput"
                      onChange={handleCorbanSearch}
                    />
                  </div>
                </div>
              </div>
              <DataTable
                title={selectedRows.length === 0 ? false : <></>}
                persistTableHead
                columns={tableColumns}
                data={tableData}
                progressPending={isLoading}
                progressComponent={tableHelper.getLoader()}
                noDataComponent={tableHelper.getNoDataComponent()}
                dense
                customStyles={itlabMediumTableStyle}
                striped
                highlightOnHover
                selectableRows
                selectableRowsHighlight
                contextActions={contextActions}
                contextMessage={{ singular: "corban removido do cálculo", plural: "corbans removidos do cálculo" }}
                onSelectedRowsChange={handleRowSelected}
                pagination
                paginationTotalRows={totalRows}
                paginationPerPage={rowsPerPage}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                paginationComponentOptions={tableHelper.getPaginationComponentOptions()}
                paginationServerOptions={{
                  persistSelectedOnPageChange: true
                }}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                conditionalRowStyles={conditionalRowStyles}
              />
            </div>
          </div>
        </MainLayout>
      </MasterLayout>

      <CorbansSelecionadosModal
        open={isCorbansSelecionadosModalOpen}
        onClose={handleCloseCorbansSelecionadosModal}
        corbansSelecionados={selectedRows}
        setCorbansSelecionados={setSelectedRows}
      />

      <CorbansEmAtrasoModal
            onConfirm={calcularConfirm}
            onClose={handleCloseModal}
            open={showModalCorbansEmAtraso}
            loading={isLoading}
          />
    </>
  )
}

export default CalculoContratacao