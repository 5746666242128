import { http } from 'common/helpers/axios/axios-helper';
import { ImportacaoViewModel } from '../models/importacao.model';
import { LogImportacaoViewModel } from '../models/log-importacao.model';

const basePath = '/importacao';

async function getImportacaoCompleteMessage() {
  return await http.get(`${basePath}/input`);
}

async function postCurvasFile(body: ImportacaoViewModel) {
  return await http.post<ImportacaoViewModel>(`${basePath}/curvas`, body, { headers: { 'Content-Type': 'multipart/form-data' } });
}

async function postInputsFile(body: ImportacaoViewModel) {
  return await http.post<ImportacaoViewModel>(`${basePath}/input`, body, { headers: { 'Content-Type': 'multipart/form-data' } });
}

async function postPocsFile(body: ImportacaoViewModel) {
  return await http.post<ImportacaoViewModel>(`${basePath}/poc`, body, { headers: { 'Content-Type': 'multipart/form-data' } });
}

async function postContasFile(body: ImportacaoViewModel) {
  return await http.post<ImportacaoViewModel>(`${basePath}/contas`, body, { headers: { 'Content-Type': 'multipart/form-data' } });
}

async function postParecerFile(body: ImportacaoViewModel) {
  return await http.post<ImportacaoViewModel>(`${basePath}/parecer`, body, { headers: { 'Content-Type': 'multipart/form-data' } });
}

async function postContratoCsvFile(body: ImportacaoViewModel) {
  return await http.post<ImportacaoViewModel>(`${basePath}/contrato-csv`, body, { headers: { 'Content-Type': 'multipart/form-data' } });
}

async function postTabelaServicosCsvFile(body: ImportacaoViewModel) {
  return await http.post<ImportacaoViewModel>(`${basePath}/tabela-servicos-csv`, body, { headers: { 'Content-Type': 'multipart/form-data' } });
}

async function getContratoCSVImportado() {
  return await http.get(`${basePath}/contrato-csv-importado`);
}

async function getTabelaServicosCSVImportado() {
  return await http.get(`${basePath}/tabela-servicos-csv-importado`);
}

async function processarContratosImportados() {
  return await http.get(`${basePath}/processar-contratos-importados`);
}
async function getLogsImportacao() {
  return await http.get<LogImportacaoViewModel[]>(`${basePath}/logs-importacao`);
}

export const ImportacaoService = {
  getImportacaoCompleteMessage,
  postCurvasFile,
  postInputsFile,
  postPocsFile,
  postContasFile,
  postParecerFile,
  postContratoCsvFile,
  postTabelaServicosCsvFile,
  getContratoCSVImportado,
  getTabelaServicosCSVImportado,
  processarContratosImportados,
  getLogsImportacao,
};
