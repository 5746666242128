import clsx from "clsx"
import appsettings from "appsettings.json";
import AppLogo from "common/partials/AppLogo";
import asideMobileToggle from "assets/images/icons/abs015.svg";
import { Link, useLocation } from "react-router-dom";
import { Topbar } from "./topbar/Topbar";
import { useEffect } from "react";
import { MenuComponent } from "assets/ts/components";
import { useTranslation } from "react-i18next";
import { useLayout } from "../../core/LayoutProvider";
import { ITSVG } from "common/helpers/ITSVG";

export function Header() {

  const { t } = useTranslation();

  const { pathname } = useLocation()
  const { config, classes, attributes } = useLayout()
  const { aside } = config

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [pathname])

  return (
    <div
      id="kt_header"
      className={clsx("header", classes.header.join(" "), "align-items-stretch")}
      {...attributes.headerMenu}
    >
      <div
        className={clsx(
          classes.headerContainer.join(" "),
          "d-flex align-items-stretch justify-content-between"
        )}
      >
        {aside.display && (
          <div className="d-flex align-items-center d-lg-none ms-n3 me-1" title="Show aside menu">
            <div
              className="btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px"
              id="kt_aside_mobile_toggle"
            >
              <ITSVG path={asideMobileToggle} className="svg-icon-2x" />
            </div>
          </div>
        )}
        {aside.display && (
          <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
            <Link to="/dashboard" className="d-lg-none">
              <AppLogo className="h-30px" />
            </Link>
          </div>
        )}

        <div className="d-flex align-items-center justify-content-between flex-lg-grow-1">
          <div className="d-none d-lg-block">
            <span className="fw-bolder text-grey fs-5">{appsettings["application-name"]}</span>
            <span className="text-muted fw-bolder fs-8 d-block">
              <span>{t("topbar.system.version")}</span>
              {appsettings["application-version"]}
            </span>
          </div>
          <div className="d-flex align-items-stretch flex-shrink-0">
            <Topbar />
          </div>
        </div>

      </div>
    </div>
  )
}