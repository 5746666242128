import i18next from 'i18next';
import DataTable, { TableColumn } from 'react-data-table-component';
import { toast } from 'react-toastify';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { tableHelper } from 'common/helpers/table/table-helper';
import {
  MasterLayout,
  MainLayout,
  ToolbarLayout,
  ToolbarLayoutLeft,
  ToolbarLayoutRight,
} from 'common/imports/content-layout';
import { itlabMediumTableStyle } from 'common/helpers/table/table-style';
import en from '../i18n/en-us.json';
import pt from '../i18n/pt-br.json';
import { APIClientsModel as Model } from '../model/api-clients.model';
import { APIClientsService as Service } from '../services/api-clients.service';
import { CreateEditAPIClient } from './CreateEditAPIClient';
import DeleteModal from 'common/partials/DeleteModal';
import ListActionsButtons from 'common/partials/ListActionsButtons';

export function APIClients() {
  i18next.addResourceBundle('us', 'translation', en);
  i18next.addResourceBundle('br', 'translation', pt);
  const { t } = useTranslation();
  const [tableData, setTableData] = useState<Model[]>([]);
  const [filteredTableData, setFilteredTableData] = useState<Model[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState<Model[]>([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [editId, setEditId] = useState(null);
  const [deleteId, setDeleteId] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleShowDeleteModal = (id: number) => {
    setShowDeleteModal(true);
    setDeleteId(id);
  };

  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  const [openCreateEditModal, setOpenCreateEditModal] = useState(false);
  const handleCreateEditModalOpen = (id: any) => {
    setEditId(id)
    setOpenCreateEditModal(true)
  };
  const handleCreateEditModalClose = () => {
    setOpenCreateEditModal(false);
  };

  const fetchData = () => {
    (async () => {
      try {
        setIsLoading(true);
        const response = await Service.getDataList();
        if (response && !response.hasErrors) {
          setTableData(response.data);
          setFilteredTableData(response.data);
        }
      } catch (err) {
        toast.error(t('crud.read.errorMessage'));
      } finally {
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  function handleFilterByName(textField: string) {
    if (textField === '') {
      setFilteredTableData(tableData);
      return;
    }

    const normalizedFilter = textField.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    setFilteredTableData(
      tableData.filter((item) =>
        item.clientId
          ?.normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()
          .includes(normalizedFilter.toLowerCase()),
      ),
    );
  }

  const columns: TableColumn<Model>[] = [
    {
      name: `${t('clientsApi.tableHeaders.id')}`,
      selector: (row) => row.id || '',
      sortable: true,
      width: '50px',
    },
    {
      name: `${t('clientsApi.tableHeaders.clientId')}`,
      selector: (row) => row.clientId || '',
    },
    {
      name: `${t('clientsApi.tableHeaders.description')}`,
      selector: (row) => row.description || '',
    },
    {
      name: `${t('clientsApi.tableHeaders.type')}`,
      cell: (row) =>
        row.type === 0 ? (
          <span className="badge badge-light-warning">{t('clientsApi.form.confidential')}</span>
        ) : (
          <span className="badge badge-light-primary">{t('clientsApi.form.public')}</span>
        ),
      center: true,
    },
    {
      name: `${t('clientsApi.tableHeaders.updateTokenTime')}`,
      selector: (row) => row.updateTokenTime || '-',
    },
    {
      name: `${t('clientsApi.tableHeaders.origin')}`,
      selector: (row) => row.origin || '-',
    },
    {
      name: `${t('clientsApi.tableHeaders.status')}`,
      cell: (row) =>
        row.isActive ? (
          <span className="badge badge-light-primary">{t('generalMessages.active')}</span>
        ) : (
          <span className="badge badge-light-danger">{t('generalMessages.inactive')}</span>
        ),
      center: true,
      width: '90px',
    },
    {
      name: `${t('clientsApi.tableHeaders.actions')}`,
      cell: (row) =>
        row.id && (
          <ListActionsButtons
            editButton
            deleteButton
            editAction={() => handleCreateEditModalOpen(row.id!)}
            deleteAction={() => handleShowDeleteModal(row.id!)}
          />
        ),
      center: true,
      width: '90px',
    },
  ];

  const handleRowSelected = useCallback((state: any) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const deleteDataById = async () => {
    try {
      setIsLoading(true);
      const result = await Service.deleteDataById(deleteId);
      if (result && !result.hasErrors) {
        toast.success(t("crud.deactivate.successMessage"));
      } else {
        toast.error(t("crud.deactivate.errorMessage"));
      }
    } catch (err) {
      toast.error(t("crud.read.errorMessage"));
    } finally {
      setToggleCleared(!toggleCleared);
      handleCloseDeleteModal();
      fetchData();
      setIsLoading(false);
    }
  };

  const contextActions = useMemo(() => {
    const deleteSelectedRows = () => {
      setShowDeleteModal(true);
      selectedRows.forEach((row) => {
        setDeleteId(row.id!);
      });
    }

    return (
      <button type="button" className="btn btn-sm btn-danger" onClick={deleteSelectedRows}>
        {t("crud.delete.buttonSave")}
      </button>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows, toggleCleared]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <CreateEditAPIClient editId={editId} open={openCreateEditModal} handleClose={handleCreateEditModalClose} fetchData={fetchData} />
      <MasterLayout>
        <ToolbarLayout>
          <ToolbarLayoutLeft />
          <ToolbarLayoutRight>
            <div className="d-flex justify-content-end">
              <button type="button" onClick={() => handleCreateEditModalOpen(0)} className="btn btn-sm btn-primary">
                {t('clientsApi.toolbar.addClient')}
              </button>
            </div>
          </ToolbarLayoutRight>
        </ToolbarLayout>
        <MainLayout>
          <div className="card card-flush">
            <div className="card-body">
              <div className="dataTables_wrapper dt-bootstrap4 no-footer">
                <DataTable
                  title={tableHelper.getTableHeader((e: any) => handleFilterByName(e.target.value))}
                  persistTableHead
                  columns={columns}
                  data={filteredTableData}
                  progressPending={isLoading}
                  progressComponent={tableHelper.getLoader()}
                  contextActions={contextActions}
                  contextMessage={tableHelper.getContextMessage()}
                  noDataComponent={tableHelper.getNoDataComponent()}
                  selectableRows
                  selectableRowsHighlight
                  selectableRowsVisibleOnly
                  onSelectedRowsChange={handleRowSelected}
                  clearSelectedRows={toggleCleared}
                  dense
                  striped
                  customStyles={itlabMediumTableStyle}
                  pagination
                  paginationComponentOptions={tableHelper.getPaginationComponentOptions()}
                  highlightOnHover
                  pointerOnHover
                />
              </div>
            </div>
          </div>

          <DeleteModal
            type="deactivate"
            onConfirm={deleteDataById}
            onClose={handleCloseDeleteModal}
            open={showDeleteModal}
            loading={isLoading}
          />
        </MainLayout>
      </MasterLayout>
    </>
  );
}
