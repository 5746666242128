import { http } from 'common/helpers/axios/axios-helper';
import { ICurvaPercentual } from '../model/curva-percentual.model';
import { TiposCurvaViewModel } from '../model/tipo-curva.model';

const basePath = '/tipoCurva';

async function getAllTipoCurva() {
  return await http.get<TiposCurvaViewModel[]>(`${basePath}`);
}

async function getTipoCurvaById(id: string) {
  return await http.get<TiposCurvaViewModel>(`${basePath}/${id}`);
}

async function getGetCurvasPercentuaisTipoCurva(id: number) {
  return await http.get<ICurvaPercentual[]>(`${basePath}/${id}/curvasPercentuais`);
}

async function postTipoCurva(data: TiposCurvaViewModel) {
  return await http.post<TiposCurvaViewModel>(`${basePath}`, data);
}

async function putTipoCurva(data: TiposCurvaViewModel) {
  return await http.put<TiposCurvaViewModel>(`${basePath}/${data.id}`, data);
}

async function deleteTipoCurvaById(id: string) {
  return await http.del(`${basePath}/${id}`);
}

export const TipoCurvaService = {
  getAllTipoCurva,
  getTipoCurvaById,
  getGetCurvasPercentuaisTipoCurva,
  postTipoCurva,
  putTipoCurva,
  deleteTipoCurvaById
};
