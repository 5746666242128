import { tableHelper } from 'common/helpers/table/table-helper';
import { itlabMediumTableStyle } from 'common/helpers/table/table-style';
import {
  MasterLayout,
  MainLayout,
  ToolbarLayout,
  ToolbarLayoutLeft,
  ToolbarLayoutRight,
} from 'common/imports/content-layout';
import i18next from 'i18next';
import 'moment/locale/pt-br';
import { useCallback, useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import en from '../../calculo/i18n/en-us.json';
import pt from '../../calculo/i18n/pt-br.json';
import { ParecerService } from '../services/parecer.service';
import { ParecerViewModel } from '../models/parecer.model';
import { ITSVG } from 'common/helpers/ITSVG';
import searchIcon from "assets/images/icons/search.svg";
import { debounce } from '@mui/material';

function Parecer() {
  i18next.addResourceBundle('us', 'translation', en);
  i18next.addResourceBundle('br', 'translation', pt);
  const { t } = useTranslation();
  const [tableData, setTableData] = useState<ParecerViewModel[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [corbanSearch, setCorbanSearch] = useState("");

  const [page] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalRows, setTotalRows] = useState(0);
  const [, setSelectedRows] = useState<ParecerViewModel[]>([]);
  const [toggleCleared] = useState(false);

  const handlePageChange = (page: number) => {
    fetchData(page - 1);
  };

  const handlePerRowsChange = async (newPerPage: number) => {
    setRowsPerPage(newPerPage);
  };

  const handleRowSelected = useCallback((state: any) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const tableColumns: TableColumn<ParecerViewModel>[] = [
    {
      name: "Código",
      cell: (row) => row.codigo || '',
      sortable: true,
      width: "80px"
    },
    {
      name: "Pendência",
      cell: (row) => row.pendencia || '',
      sortable: true,
    },
    {
      name: "Resolução",
      cell: (row) => row.resolucao || '',
      sortable: true,
    },
  ];

  const updateQuery = (e: React.ChangeEvent<HTMLInputElement>) => setCorbanSearch(e.target.value);

  const handleCorbanSearch = debounce(updateQuery, 800);


  const fetchData = async (page: number) => {
    try {
      setIsLoading(true);
      const response = await ParecerService.getParecerFiltered(page, rowsPerPage, corbanSearch);
      if (response && !response.hasErrors) {
        setTableData(response.data);
        setTotalRows(response.totalItems);
      }
    } catch (err) {
      toast.error(t('crud.read.errorMessage'));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, corbanSearch]);

  return (
    <MasterLayout>
      <ToolbarLayout>
        <ToolbarLayoutLeft />
        <ToolbarLayoutRight />
      </ToolbarLayout>
      <MainLayout>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-12 col-sm-6 col-lg-3 mb-8">
                <div className="d-flex align-items-center position-relative">
                  <ITSVG path={searchIcon} className={"svg-icon-1 position-absolute ms-6"} />
                  <input
                    type="text"
                    className="form-control form-control-solid ps-14"
                    placeholder="Buscar"
                    onChange={handleCorbanSearch}
                  />
                </div>
              </div>
              <DataTable
                persistTableHead
                columns={tableColumns}
                data={tableData}
                progressPending={isLoading}
                progressComponent={tableHelper.getLoader()}
                noDataComponent={tableHelper.getNoDataComponent()}
                dense
                customStyles={itlabMediumTableStyle}
                striped
                highlightOnHover
                contextMessage={tableHelper.getContextMessage()}
                onSelectedRowsChange={handleRowSelected}
                clearSelectedRows={toggleCleared}
                pagination
                paginationServer
                paginationPerPage={rowsPerPage}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}  
                paginationTotalRows={totalRows}
                paginationComponentOptions={tableHelper.getPaginationComponentOptions()}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
              />
            </div>
          </div>
        </div>
      </MainLayout>
    </MasterLayout>
  )
}

export default Parecer