import { http } from 'common/helpers/axios/axios-helper';
import { ExecucaoAcaoViewModel } from '../model/execucoes.model';
import { GerarLoteContratacaoViewModel } from 'app/calculo/model/calculo-contratacao.model';

const basePath = '/execucao';

async function ReprocessarInconsistencia(data: ExecucaoAcaoViewModel) {
  return await http.post<ExecucaoAcaoViewModel>(`${basePath}/reprocessar`, data);
}

async function PularInconsistencia(data: ExecucaoAcaoViewModel) {
  return await http.post<ExecucaoAcaoViewModel>(`${basePath}/pular`, data);
}

async function CancelarInconsistencia(data: ExecucaoAcaoViewModel) {
  return await http.post<ExecucaoAcaoViewModel>(`${basePath}/cancelar`, data);
}

async function GerarLoteContratacao(data: GerarLoteContratacaoViewModel) {
  return await http.post<GerarLoteContratacaoViewModel>(`${basePath}/gerarLoteContratacao`, data);
}

async function deleteDataById(id: any) {
  return await http.del(`${basePath}/${id}`);
}

export const ExecucoesService = {
  ReprocessarInconsistencia,
  PularInconsistencia,
  CancelarInconsistencia,
  GerarLoteContratacao,
  deleteDataById,
};
