import { useTranslation } from 'react-i18next';
import { ITSVG } from '../ITSVG';
import searchIcon from 'assets/images/icons/search.svg';

const Translate = () => {
    const { t } = useTranslation();
    return t
}

function getTableHeader(handleSearch: any) {
    return (
        <div className="card-title mb-8">
            <div className="d-flex align-items-center position-relative my-1">
                <ITSVG path={searchIcon} className={'svg-icon-1 position-absolute ms-6'} />
                <input
                    type="text"
                    className="form-control form-control-solid w-250px ps-14"
                    placeholder={`${Translate()('generalMessages.search')}`}
                    name="searchInput"
                    onChange={handleSearch}
                />
            </div>
        </div>
    )
}

function getPaginationComponentOptions() {
    const paginationComponentOptions = {
        rowsPerPageText: `${Translate()('datatable.pagination.title')}`,
        rangeSeparatorText: Translate()('datatable.pagination.of'),
        selectAllRowsItem: true,
        selectAllRowsItemText: Translate()('datatable.selectedItems.all'),
    };

    return paginationComponentOptions
}

function getContextMessage() {
    const contextMessage = {
        singular: `${Translate()('datatable.selectedItems.singular')}`,
        plural: `${Translate()('datatable.selectedItems.plural')}`,
    };

    return contextMessage
}

function getNoDataComponent() {
    return (
        <div className="text-gray-600 fw-bolder my-10">
            <div className="d-flex text-center w-100 align-content-center justify-content-center">
                {Translate()('datatable.noData')}
            </div>
        </div>
    )
}

function getLoader() {
    return (
        <div className="spinner-border text-primary mt-10" role="status">
            <span className="visually-hidden">Loading...</span>
        </div>
    );
}

export const tableHelper = {
    getTableHeader,
    getPaginationComponentOptions,
    getContextMessage,
    getNoDataComponent,
    getLoader
}