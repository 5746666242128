import axios, { AxiosRequestHeaders } from 'axios';
import { APP_ROUTES } from 'routes/routes';
import { AuthToken } from '../auth/auth-token';
import { AuthService } from '../auth/auth.service';
import { HttpCode } from './http-codes';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = AuthToken.getToken();
    config = {
      ...config,
      headers: { ...config?.headers, ...(token ? { Authorization: `Bearer ${token}` } : {}) } as AxiosRequestHeaders,
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (window.location.pathname === '/' + APP_ROUTES.login) {
      return Promise.reject(error);
    }
    if (error.response.status === HttpCode.Forbidden) {
      window.location.href = '/unauthorized';
      return;
    }
    if (error.response.status === HttpCode.Unauthorized) {
      AuthService.logout();
      window.location.href = '/';
      return;
    }
    return Promise.reject(error);
  },
);

export { axiosInstance };
