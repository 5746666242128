import { LotesContratacaoCorbanParcelaDetailViewModel } from "app/lotes-contratacao/model/lotes-contratacao.model";
import { LotesContratacaoService } from "app/lotes-contratacao/services/lotes-contratacao.service";
import { currencyMask } from "common/helpers/masks/CurrencyMask";
import { tableHelper } from "common/helpers/table/table-helper"
import { itlabMediumTableStyle } from "common/helpers/table/table-style";
import moment from "moment";
import "moment/locale/pt-br";
import { useEffect, useState } from "react"
import DataTable, { TableColumn } from "react-data-table-component"
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

function LoteCorbanPagamentosTable() {
  const { loteId } = useParams();
  const { corbanId } = useParams();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState<LotesContratacaoCorbanParcelaDetailViewModel[]>([]);
  const [page] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalRows, setTotalRows] = useState(0);

  const handlePageChange = (page: number) => {
    fetchData(page - 1);
  };

  const handlePerRowsChange = async (newPerPage: number) => {
    setRowsPerPage(newPerPage);
  };

  const tableColumns: TableColumn<LotesContratacaoCorbanParcelaDetailViewModel>[] = [
    {
      name: "Número da Parcela",
      selector: (row) => row.numeroParcela || "",
      sortable: true,
      width: '160px'
    },
    {
      name: "Data da Parcela",
      selector: (row) =>
        moment(row.dataVencimento)
          .locale(`${t("dateFormat.locale")}`)
          .format("L"),
      sortable: true,
      center: true,
      minWidth: '140px'
    },
    {
      name: "Saldo devedor Consignado",
      selector: (row) => currencyMask(row.saldoDevedor) || 0,
      sortable: true,
      right: true,
      minWidth: '180px'
    },
    {
      name: "Principal Amortizado Consignado",
      selector: (row) => currencyMask(row.valorAmortizado) || 0,
      sortable: true,
      right: true,
      minWidth: '250px'
    },
    {
      name: "Juros Consignado",
      selector: (row) => currencyMask(row.valorJuros) || 0,
      sortable: true,
      right: true,
      minWidth: '120px'
    },
    {
      name: "Valor da Parcela Consignado",
      selector: (row) => currencyMask(row.valorParcelaMutuo) || 0,
      sortable: true,
      right: true,
      minWidth: '200px'
    }
  ];

  const fetchData = async (page: number) => {
    setIsLoading(true);
    try {
      if (loteId && corbanId) {
        const response = await LotesContratacaoService.getParcelasLoteContratacao(page, rowsPerPage, loteId, corbanId);
        if (response && !response.hasErrors) {
          setTableData(response.data);
          setTotalRows(response.totalItems);
        }
      }
    } catch (err) {
      toast.error(t("crud.read.errorMessage"));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage]);

  return (
    <div className="mt-10">
      <DataTable
         persistTableHead
         columns={tableColumns}
         data={tableData}
         progressPending={isLoading}
         progressComponent={tableHelper.getLoader()}
         noDataComponent={tableHelper.getNoDataComponent()}
         dense
         customStyles={itlabMediumTableStyle}
         striped
         highlightOnHover
         contextMessage={tableHelper.getContextMessage()}
         onChangePage={handlePageChange}
         onChangeRowsPerPage={handlePerRowsChange}
         pagination
         paginationServer
         paginationTotalRows={totalRows}
         paginationPerPage={rowsPerPage}
         paginationRowsPerPageOptions={[10, 25, 50, 100]}
         paginationComponentOptions={tableHelper.getPaginationComponentOptions()}
      />
    </div>
  )
}

export default LoteCorbanPagamentosTable