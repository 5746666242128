import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  MasterLayout,
  MainLayout,
  ToolbarLayout,
  ToolbarLayoutLeft,
  ToolbarLayoutRight,
} from 'common/imports/content-layout';
// import en from '../i18n/en-us.json';
// import pt from '../i18n/pt-br.json';
import { TipoCurvaService as Service } from '../services/tipo-curva.service';
import moment from 'moment';
import { Autocomplete, TextField } from '@mui/material';
import { ConvenioService } from 'app/convenios/services/convenio.service';
import { TiposCurvaViewModel } from '../model/tipo-curva.model';
import { ConvenioViewModel } from 'app/convenios/model/convenio.model';

export function CreateEditTipoCurva() {
  // i18next.addResourceBundle('us', 'translation', en);
  // i18next.addResourceBundle('br', 'translation', pt);

  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const formSchema = Yup.object().shape({
    name: Yup.string(),
    description: Yup.string(),
    codLoja: Yup.number(),
    razaoSocialLoja: Yup.string(),
    nomeFantasia: Yup.string(),
    cidade: Yup.string(),
    uf: Yup.string(),
    cnpjCorban: Yup.string(),
    razaoSocialCorban: Yup.string(),
  });

  let initialData: TiposCurvaViewModel = {
    id: parseInt(id ?? '0'),
    convenioId: 0,
    inicioVigencia: "",
    finalVigencia: "",
    isActive: true,
    convenio: undefined,
    curvasPercentuais: []
  };

  const [data, setData] = useState<TiposCurvaViewModel>(initialData);
  const [convenios, setConvenios] = useState<ConvenioViewModel[]>([]);
  const [convenioDefaultValue, setConvenioDefaultValue] = useState<ConvenioViewModel | undefined | null>(null);

  const formik = useFormik<TiposCurvaViewModel>({
    enableReinitialize: true,
    initialValues: data,
    validationSchema: formSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const body: TiposCurvaViewModel = {
          id: data.id,
          convenioId: values.convenioId,
          inicioVigencia: values.inicioVigencia,
          finalVigencia: values.finalVigencia,
          isActive: values.isActive,
          convenio: undefined,
          curvasPercentuais: []
        };

        if (id) {
          const result = await Service.putTipoCurva(body);
          if (result && !result?.hasErrors) {
            toast.success(t('crud.update.successMessage'));
          }
        } else {
          const result = await Service.postTipoCurva(body);
          if (result && !result?.hasErrors) {
            toast.success(t('crud.create.successMessage'));
            navigate(`/cadastros/convenio`);
          }
        }
      } catch (error) {
        toast.error(t('crud.update.errorMessage'));
      } finally {
        setIsLoading(false);
      }
    },
  });

  const fetchData = async () => {
    try {
      setIsLoading(true);
      if (id) {
        const response = await Service.getTipoCurvaById(id);
        if (response && !response.hasErrors) {
          setData(response.data);
        }
      }
    } catch (err) {
      toast.error(t('crud.read.errorMessage'));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if ((data && data !== initialData) || (data && !id)) {
      const fetchConvenios = async () => {
        const response = await ConvenioService.getAllConvenio();
        if (response && !response.hasErrors) {
          setConvenios(response.data);
          setConvenioDefaultValue(response?.data.find(value => value.lotesContratacaos.convenioId === data.convenioId));
        }
      };
      fetchConvenios();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <MasterLayout>
      <ToolbarLayout>
        <ToolbarLayoutLeft />
        <ToolbarLayoutRight></ToolbarLayoutRight>
      </ToolbarLayout>
      <MainLayout>
        <form onSubmit={formik.handleSubmit}>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-3 mb-10">
                  <label className="required form-label">{t('form.label.account')}</label>
                  <Autocomplete
                    id="account"
                    disablePortal
                    options={convenios}
                    value={convenioDefaultValue ?? null}
                    getOptionLabel={option => option.lotesContratacaos.convenioNome ? option.lotesContratacaos.convenioNome : ""}
                    onChange={(_, value) => {
                      setConvenioDefaultValue(value)
                      formik.setFieldValue("convenioId", value?.lotesContratacaos.convenioId)
                    }}
                    onInputChange={(_, value) => {
                      if (!value) {
                        setConvenioDefaultValue(null)
                        formik.setFieldValue("convenioId", undefined)
                      }
                    }}
                    onBlur={formik.handleBlur}
                    renderInput={(params) =>
                      <TextField
                        {...params}
                        placeholder={`${t('generalMessages.select')}`}
                      />
                    }
                  />
                  {formik.touched.convenioId && formik.errors.convenioId && (
                    <div className="mt-3 text-danger fw-bold">
                      <div className="fv-help-block">{formik.errors.convenioId}</div>
                    </div>
                  )}
                </div>
                <div className="col-md-3 mb-10">
                  <label className="required form-label">{t('Início da Vigência')}</label>
                  <input
                    type="date"
                    name="inicioVigencia"
                    className="form-control form-control-lg form-control-solid"
                    value={moment(formik.values?.inicioVigencia).format('yyyy-MM-DD') ?? moment(new Date()).format('yyyy-MM-DD')}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.inicioVigencia && formik.errors.inicioVigencia && (
                    <div className="mt-3 text-danger fw-bold">
                      {/* <div className="fv-help-block">{formik.errors.inicioVigencia}</div> */}
                    </div>
                  )}
                </div>
                <div className="col-md-3 mb-10">
                  <label className="required form-label">{t('Final da Vigência')}</label>
                  <input
                    type="date"
                    name="finalVigencia"
                    className="form-control form-control-lg form-control-solid"
                    value={moment(formik.values?.finalVigencia).format('yyyy-MM-DD') ?? moment(new Date()).format('yyyy-MM-DD')}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.finalVigencia && formik.errors.finalVigencia && (
                    <div className="mt-3 text-danger fw-bold">
                      {/* <div className="fv-help-block">{formik.errors.finalVigencia}</div> */}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="card-footer d-flex justify-content-end py-6 px-9">
              <Link className="btn btn-light btn-active-light-primary me-2" to="/cadastros/convenio">
                {t('Voltar')}
              </Link>
              <button type="submit" className="btn btn-primary" disabled={isLoading}>
                {!isLoading && t('crud.update.buttonSave')}
                {isLoading && (
                  <span className="indicator-progress" style={{ display: 'block' }}>
                    {t('crud.commom.wait')}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </form>
      </MainLayout>
    </MasterLayout>
  );
}
