import i18next from "i18next";
import DataTable, { TableColumn } from "react-data-table-component";
import { toast } from "react-toastify";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { tableHelper } from "common/helpers/table/table-helper";
import {
  MasterLayout,
  MainLayout,
  ToolbarLayout,
  ToolbarLayoutLeft,
  ToolbarLayoutRight,
} from "common/imports/content-layout";
import { itlabMediumTableStyle } from "common/helpers/table/table-style";
import DeleteModal from "common/partials/DeleteModal";
import ListActionsButtons from "common/partials/ListActionsButtons";
import { RolePermission } from "common/helpers/dictionaries/roles";
import en from "../i18n/en-us.json";
import pt from "../i18n/pt-br.json";
import { MotivoBloqueioViewModel as Model } from "../model/motivos-bloqueio.model";
import { MotivosBloqueioService as Service } from "../services/motivos-bloqueio.service";

export function MotivosBloqueio() {
  i18next.addResourceBundle("us", "translation", en);
  i18next.addResourceBundle("br", "translation", pt);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [tableData, setTableData] = useState<Model[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState<Model[]>([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [deleteId, setDeleteId] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleShowDeleteModal = (id: number) => {
    setShowDeleteModal(true);
    setDeleteId(id);
  };

  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  const handlePerRowsChange = async (newPerPage: number) => {
    setRowsPerPage(newPerPage);
  };

  const columns: TableColumn<Model>[] = [
    {
      name: `${t("ID")}`,
      selector: (row) => row.id || "",
      sortable: true,
      width: "60px",
    },
    {
      name: `${t("Descrição")}`,
      selector: (row) => row.descricao || "",
    },
    {
      name: `${t("table.actions")}`,
      cell: (row) => (
        <ListActionsButtons
          editButton
          editAction={() => navigate(`/cadastros/motivosBloqueio/${row.id}`)}
          deleteButton
          deleteAction={() => handleShowDeleteModal(row.id!)}
          userDeleteRoles={[RolePermission.Update_MotivoBloqueio]}
        />
      ),
      center: true,
      width: "100px",
    },
  ];

  const handleRowSelected = useCallback((state: any) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const deleteDataById = async () => {
    try {
      setIsLoading(true);
      const result = await Service.deleteDataById(deleteId);
      if (result && !result.hasErrors) {
        toast.success(`${t("crud.delete.successMessage")}`);
      } else {
        toast.error(`${t("crud.delete.errorMessage")}`);
      }
    } catch (err) {
      toast.error(`${t("crud.read.errorMessage")}`);
    } finally {
      setToggleCleared(!toggleCleared);
      handleCloseDeleteModal();
      fetchData();
      setIsLoading(false);
    }
  };

  const contextActions = useMemo(() => {
    const deleteSelectedRows = () => {
      setShowDeleteModal(true);
      selectedRows.forEach((row) => {
        setDeleteId(row.id!);
      });
    }

    return (
      <button type="button" className="btn btn-sm btn-danger" onClick={deleteSelectedRows}>
        {t("crud.delete.buttonSave")}
      </button>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows, toggleCleared]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await Service.getDataList();
      if (response && !response.hasErrors) {
        setTableData(response.data);
      }
    } catch (err) {
      toast.error(t("crud.read.errorMessage"));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MasterLayout>
      <ToolbarLayout>
        <ToolbarLayoutLeft />
        <ToolbarLayoutRight>
          <div className="d-flex align-items-center">
            <Link to="/cadastros/motivosBloqueio/add" className="btn btn-sm btn-primary fw-bolder">
              {t("Adicionar Motivo")}
            </Link>
          </div>
        </ToolbarLayoutRight>
      </ToolbarLayout>
      <MainLayout>
        <div className="card card-flush">
          <div className="card-body">
            <div className="dataTables_wrapper dt-bootstrap4 no-footer">
              <DataTable
                title={selectedRows.length === 0 ? false : <></>}
                persistTableHead
                columns={columns}
                data={tableData}
                progressPending={isLoading}
                progressComponent={tableHelper.getLoader()}
                noDataComponent={tableHelper.getNoDataComponent()}
                contextActions={contextActions}
                contextMessage={tableHelper.getContextMessage()}
                selectableRows
                selectableRowsVisibleOnly={true}
                onSelectedRowsChange={handleRowSelected}
                clearSelectedRows={toggleCleared}
                dense
                striped
                highlightOnHover
                customStyles={itlabMediumTableStyle}
                pagination
                paginationPerPage={rowsPerPage}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                paginationComponentOptions={tableHelper.getPaginationComponentOptions()}
                onChangeRowsPerPage={handlePerRowsChange}
              />
            </div>
          </div>

          <DeleteModal
            type="delete"
            onConfirm={deleteDataById}
            onClose={handleCloseDeleteModal}
            open={showDeleteModal}
            loading={isLoading}
          />
        </div>
      </MainLayout>
    </MasterLayout>
  );
}
