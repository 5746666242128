export const APP_ROUTES = {
  blank: '',
  notfoundpage: '404',
  unauthorizedpage: 'unauthorized',
  login: 'login',
  auth: 'auth',
  dashboard: 'dashboard',
  security: 'security',
  settings: 'settings',
  userprofile: 'userprofile',
  healthcheck: 'healthcheck',
  generalsettings: 'generalsettings',
  parecer: 'parecer',
  parametros: 'parametros',
  importacao: 'importacao',
  importacaoContratos: 'importcontratos',
  operacao: 'operacao',
  cadastros: 'cadastros',
  relatorio: 'relatorio',
  calculo: {
    list: 'calculo',
  },
  lotes: {
    list: 'lotes',
    detalhes: 'lotes/:loteId',
    contratos: 'lotes/:loteId/corban/:corbanId',
    execucoes: 'lotes/:loteId/execucoes',
    execucoesControleFluxo: 'lotes/:loteId/execucoes/:execucaoId',
  },
  corban: {
    list: 'corban',
    add: 'corban/add',
    edit: 'corban/:id',
  },
  convenio: {
    list: 'convenio',
    add: 'convenio/add',
    edit: 'convenio/:id',
  },
  tipoCurva: {
    list: 'tipoCurva',
    add: 'tipoCurva/add',
    edit: 'tipoCurva/:id',
  },
  motivosBloqueio: {
    list: 'motivosBloqueio',
    add: 'motivosBloqueio/add',
    edit: 'motivosBloqueio/:id',
  },
  lojas: {
    list: 'lojas',
    add: 'lojas/add',
    edit: 'lojas/:id',
  },
  percentuais: {
    list: 'percentuais',
    add: 'percentuais/add',
    edit: 'percentuais/:id',
  },
  relatorios: {
    relatorioContratosConsignados: 'relatorio-contratos-consignados',
    relatorioBaseAnalitica: 'relatorio-base-analitica',
    relatorioCarteiraContratosMutuo: 'relatorio-carteira-contratos-mutuo',
    relatorioAnaliticoCalculoApurado: 'relatorio-analitico-calculo-apurado',
    relatorioContratosMutuoCorban: 'relatorio-contratos-mutuo-corban',
  },
  clients: {
    list: 'clients',
    add: 'clients/add',
    edit: 'clients/:id',
  },
  detailscreen: {
    list: 'detailscreen',
    edit: 'detailscreen/:id',
  },
  usermanagement: {
    list: 'usermanagement',
    add: 'usermanagement/add',
    edit: 'usermanagement/:id',
  },
  groupmanagement: {
    list: 'groupmanagement',
    add: 'groupmanagement/add',
    edit: 'groupmanagement/:id',
  },
  apiClients: {
    list: 'apiclients',
    add: 'apiclients/add',
    edit: 'apiclients/:id',
  },
};
