import i18next from 'i18next';
import avatarBlank from 'assets/images/svg/blank.svg';
import letterIcon from 'assets/images/icons/com011.svg';
import { ITSVG } from 'common/helpers/ITSVG';
import { TokenHelper } from 'common/helpers/auth/token-helper';
import { useLoading } from 'common/loading/loading.hook';
import { useAvatar } from 'common/helpers/avatar/context/avatar.hook';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ChangePassword } from 'app/user-management/components/child/ChangePassword';
import { MasterLayout, MainLayout, ToolbarLayout, ToolbarLayoutLeft, ToolbarLayoutRight } from 'common/imports/content-layout';
import { EditProfile } from './childs/EditProfile';
import en from '../i18n/en-us.json';
import pt from '../i18n/pt-br.json';
import { UserProfileService as Service } from '../service/user-profile.service';
import { UserProfileModel as Model } from '../model/user-profile.model';

export function UserProfile() {
  const { t } = useTranslation();
  i18next.addResourceBundle('us', 'translation', en);
  i18next.addResourceBundle('br', 'translation', pt);
  const { isLoading } = useLoading();
  const { setAvatar } = useAvatar();
  const helper = new TokenHelper();
  const userId = helper.GetId();

  let initialData: Model = {
    id: 0,
    name: '',
    email: '',
    language: '',
    userName: '',
    avatar: '',
  };

  const [data, setData] = useState(initialData);
  const [localAvatar, setLocalAvatar] = useState<any>('');
  const [imageUrl, setImageUrl] = useState<any>(null);
  const [openChangePassword, setOpenChangePassword] = useState(false);
  const handleChangePasswordOpen = () => setOpenChangePassword(true);
  const handleChangePasswordClose = () => setOpenChangePassword(false);

  const [openEditProfile, setOpenEditProfilOpen] = useState(false);
  const handleEditProfileOpen = () => setOpenEditProfilOpen(true);
  const handleEditProfileClose = () => setOpenEditProfilOpen(false);

  const onFileChange = (event: any) => {
    event.preventDefault();
    setLocalAvatar(event.target.files[0]);
  };

  function getBackgroundImage() {
    if (imageUrl && localAvatar) {
      return `url(${imageUrl})`;
    }
    if (data.fileUrl) {
      return `url(${data.fileUrl})`;
    }
    return `url(${avatarBlank})`;
  }

  const fetchUser = () => {
    (async () => {
      try {
        isLoading(true);
        if (userId) {
          const response = await Service.getUserById(userId);
          if (response && !response.hasErrors) {
            setData(response.data);
          }
        }
      } catch (err) {
        toast.error(t('crud.read.errorMessage'));
      } finally {
        isLoading(false);
        setAvatar(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  useEffect(() => {
    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      if (localAvatar) {
        setImageUrl(URL.createObjectURL(localAvatar));
        setAvatar(true);
        const data = new FormData();
        data.append("formFile", localAvatar, localAvatar.name);
        await Service.postUserAvatar(data);
        fetchUser();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localAvatar]);

  return (
    <>
      <MasterLayout>
        <ToolbarLayout>
          <ToolbarLayoutLeft />
          <ToolbarLayoutRight></ToolbarLayoutRight>
        </ToolbarLayout>
        <MainLayout>
          <div className="card mb-5 mb-xl-10">
            <div className="card-body pt-9 pb-0">
              <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
                <div className="me-7 mb-4">
                  <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
                    <div
                      className="image-input image-input-empty image-input-outline mb-3  "
                      style={{ backgroundImage: getBackgroundImage() }}
                      data-kt-image-input="true"
                    >
                      <div className="image-input-wrapper w-150px h-150px"></div>
                      <label
                        className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                        data-kt-image-input-action="change"
                        data-bs-toggle="tooltip"
                        title=""
                        data-bs-original-title="Change avatar"
                      >
                        <i className="bi bi-pencil-fill fs-7"></i>
                        <input
                          id="formFile"
                          name="formFile"
                          type="file"
                          accept="image/*"
                          onChange={(event) => onFileChange(event)}
                        />
                      </label>
                      <span
                        className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                        data-kt-image-input-action="cancel"
                        data-bs-toggle="tooltip"
                        title=""
                        data-bs-original-title="Cancel avatar"
                      >
                        <i className="bi bi-x fs-2"></i>
                      </span>
                      <span
                        className="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow"
                        data-kt-image-input-action="remove"
                        data-bs-toggle="tooltip"
                        title=""
                        data-bs-original-title="Remove avatar"
                      >
                        <i className="bi bi-x fs-2"></i>
                      </span>
                    </div>
                  </div>
                </div>

                <div className="flex-grow-1">
                  <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                    <div className="d-flex flex-column">
                      <div className="d-flex align-items-center mb-2">
                        <span className="text-gray-800 text-hover-primary fs-2 fw-bolder me-1">
                          {data.name}
                        </span>
                      </div>

                      <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                        <span className="d-flex align-items-center text-gray-400 text-hover-primary mb-2">
                          <ITSVG path={letterIcon} className={'svg-icon-4 me-1'} />
                          {data.email}
                        </span>
                      </div>
                    </div>

                    <div className="d-flex my-4"></div>
                  </div>

                  <div className="d-flex flex-wrap flex-stack">
                    <div className="d-flex flex-column flex-grow-1 pe-8">
                      <div className="d-flex flex-wrap"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
            <div className="card-header cursor-pointer">
              <div className="card-title m-0">
                <h3 className="fw-bolder m-0">{t('userProfile.title')}</h3>
              </div>
              <div className="d-flex my-4">
                <button className="btn btn-primary" onClick={handleEditProfileOpen}>
                  {t('userProfile.editProfileButton')}
                </button>
                <EditProfile
                  open={openEditProfile}
                  onClose={handleEditProfileClose}
                  onSuccess={fetchUser}
                />
              </div>
            </div>
            <div className="card-body p-9">
              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">{t('userProfile.fullName')}</label>
                <div className="col-lg-8">
                  <span className="fw-bolder fs-6 text-dark">{data.name}</span>
                </div>
              </div>
              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">{t('userProfile.email')}</label>
                <div className="col-lg-8 fv-row">
                  <span className="fw-bold fs-6">{data.email}</span>
                </div>
              </div>
              <div className="row mb-7">
                <label className="col-lg-4 fw-bold text-muted">{t('userProfile.language')}</label>
                <div className="col-lg-8 fv-row">
                  <span className="fw-bold fs-6">{data.language === 'us' ? 'English' : 'Português'}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="card mb-5 mb-xl-10">
            <div
              className="card-header border-0 cursor-pointer"
              role="button"
              data-bs-toggle="collapse"
              data-bs-target="#kt_account_signin_method"
            >
              <div className="card-title m-0">
                <h3 className="fw-bolder m-0">{t('userProfile.authentication')}</h3>
              </div>
            </div>
            <div className="card-body border-top p-9">
              <div className="d-flex flex-wrap align-items-center">
                <div id="kt_signin_email" className=" false">
                  <div className="fs-6 fw-bolder mb-1">{t('userProfile.email')}</div>
                  <div className="fw-bold text-gray-600">{data.email}</div>
                </div>
              </div>
              <div className="separator separator-dashed my-6"></div>
              <div className="d-flex flex-wrap align-items-center ">
                <div id="kt_signin_password" className=" false">
                  <div className="fs-6 fw-bolder mb-1">{t('userProfile.password')}</div>
                  <div className="fw-bold text-gray-600">************</div>
                </div>

                <div id="kt_signin_password_button" className="ms-auto false">
                  <button className="btn btn-light btn-active-light-primary" onClick={handleChangePasswordOpen}>
                    {t('userProfile.changePassword')}
                  </button>
                  <ChangePassword
                    open={openChangePassword}
                    userName={data.userName ?? ""}
                    name={data.name ?? ""}
                    id={data.id}
                    onClose={handleChangePasswordClose}
                  />
                </div>
              </div>
            </div>
          </div>
        </MainLayout>
      </MasterLayout >
    </>
  );
}
