import DataTable, { TableColumn } from "react-data-table-component";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { tableHelper } from "common/helpers/table/table-helper";
import {
  MasterLayout,
  MainLayout,
  ToolbarLayout,
  ToolbarLayoutLeft,
  ToolbarLayoutRight,
} from "common/imports/content-layout";
import { itlabMediumTableStyle } from "common/helpers/table/table-style";
import { RelatoriosService as Service } from "../services/relatorios.service";
import { IRelatorioCarteiraContratosMutuo } from "../model/relatorioCarteiraContratosMutuo.model";
import moment from 'moment';
import 'moment/locale/pt-br';
import { currencyMask } from 'common/helpers/masks/CurrencyMask';
import { useFormik } from "formik";
import { IFilterRelatorioCarteiraContratosMutuo } from "../model/filterRelatorioCarteiraContratosMutuo.model";
import * as Yup from "yup";
import { DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/pt_BR';
import dayjs from 'dayjs';
import SaveButton from "common/partials/SaveButton";
import { useDownloadFile } from "common/helpers/download-file/useDownloadFile";
import { Autocomplete, TextField } from "@mui/material";
import { ConvenioViewModel } from "app/convenios/model/convenio.model";
import { ConvenioService } from "app/convenios/services/convenio.service";
import { StatusViewModel } from "../model/status.model";

export function RelatorioCarteiraContratosMutuo() {
  const { t } = useTranslation();
  const [tableData, setTableData] = useState<IRelatorioCarteiraContratosMutuo[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page] = useState(0);
  const { RangePicker } = DatePicker;
  const dateFormat = "DD/MM/YYYY";
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalRows, setTotalRows] = useState(0);
  const [isGerarRelatorio, setIsGerarRelatorio] = useState(false);
  const [convenios, setConvenios] = useState<ConvenioViewModel[]>([]);
  const [selectedConvenioId, setSelectedConvenioId] = useState<any>(undefined);
  const [status, setStatus] = useState<StatusViewModel[]>([]);
  const [selectedStatusId, setSelectedStatusId] = useState<any>(undefined);
  const [dataInicial, setDataInicial] = useState("");
  const [dataFinal, setDataFinal] = useState("");
  
  let initialData: IFilterRelatorioCarteiraContratosMutuo = {
    lote: "",
    safraMutuoInicial: "",
    safraMutuoFinal: "",
    contratoMutuo: "",
    idLoja: "",
    operador: "",
    convenio: undefined,
    percentualMutuoMin: undefined,
    percentualMutuoMax: undefined,
    numeroMesesMin: undefined,
    numeroMesesMax: undefined,
    statusId: undefined,
    codigoRefCurvaLiquidacao: "",
    page: page,
    limit: rowsPerPage,
  };
  const [filter, setFilter] = useState<IFilterRelatorioCarteiraContratosMutuo>(initialData);

  const handlePageChange = (page: number) => {
    filter.page = page - 1
    fetchData();
  };

  const handlePerRowsChange = async (newPerPage: number) => {
    setRowsPerPage(newPerPage);
  };

  const tableColumns: TableColumn<IRelatorioCarteiraContratosMutuo>[] = [
    {
      name: `${t("Lote")}`,
      selector: (row) => row?.lote || "",
      sortable: true,
      width: "140px",
    },
    {
      name: `${t("Contrato Mutuo")}`,
      selector: (row) => row?.contratoMutuo || "",
      sortable: true,
      width: "145px",
    },
    {
      name: `${t("Status Mutuo")}`,
      selector: (row) => row?.statusMutuo || "",
      sortable: true,
      width: "330px",
    },
    {
      name: `${t("ID_Loja")}`,
      selector: (row) => row.idLoja || "",
      sortable: true,
      width: "80px",
    },
    {
      name: `${t("Operador")}`,
      selector: (row) => row.operador || "",
      sortable: true,    
      minWidth: "300px" 
    },
    {
      name: `${t("Grupo")}`,
      selector: (row) => row.grupo || "",
      sortable: true,     
    },
    {
      name: `${t("Convênio")}`,
      selector: (row) => row.convenio || "",
      sortable: true,     
      width: "100px"
    },
    {
      name: `${t("% Diferido Médio")}`,
      selector: (row) => (row.percentualDiferido ?? 0).toFixed(8).replace('.', ','),
      sortable: true,
      right: true,
      minWidth: "200px"
    },
    {
      name: `${t("Valor Antecipado")}`,
      selector: (row) => currencyMask(row.valorAntecipado) || "",
      sortable: true,
      right: true,
      minWidth: "180px"
    },
    {
      name: `${t("Valor Parcela Consignado")}`,
      selector: (row) => currencyMask(row.valorParcelaMutuo) || "",
      sortable: true,
      right: true,
      minWidth: "180px"
    },
    {
      name: `${t("% Antecipado")}`,
      selector: (row) => (row.percentualAntecipado ?? 0).toFixed(4).replace('.', ','),
      sortable: true,
      right: true,
      minWidth: "160px"
    },
    {
      name: `${t("Prazo")}`,
      selector: (row) => row.prazo || "",
      sortable: true,
      center: true,
      minWidth: "160px"
    },
    {
      name: `${t('Consignado')}`,
      selector: (row) =>
        moment(row.safraMutuo)
          .locale(`${t('dateFormat.locale')}`)
          .format('L'),
      sortable: true,
      center: true,
      width: "120px",
    },
    {
      name: `${t('Data Pagamento')}`,
      selector: (row) =>
        moment(row.dataPagamento)
          .locale(`${t('dateFormat.locale')}`)
          .format('L'),
      sortable: true,
      center: true,
      width: "140px",
    },
    {
      name: `${t('Data 1º Vencimento')}`,
      selector: (row) =>
        moment(row.dataVencimentoPrimeiraParcela)
          .locale(`${t('dateFormat.locale')}`)
          .format('L'),
      sortable: true,
      center: true,
      width: "160px",
    },   
    {
      name: `${t("Dia Vencimento")}`,
      selector: (row) => row.diaVencimento,
      sortable: true,
      right: true,
      minWidth: "80px"
    },
    {
      name: `${t("VOP")}`,
      selector: (row) => currencyMask(row.valorOperacional) || "",
      sortable: true,
      right: true,
      minWidth: "120px"
    },
    {
      name: `${t("Qtde.Operações")}`,
      selector: (row) => row.qtdeContratos || "",
      sortable: true,
      right: true,
      minWidth: "120px"
    },
    {
      name: `${t("Prazo Médio")}`,
      selector: (row) => currencyMask(row.prazoMedio) || "",
      sortable: true,
      right: true,
      minWidth: "80px"
    },
    {
      name: `${t("Diferido Total")}`,
      selector: (row) => currencyMask(row.valorDiferidoTotal) || "",
      sortable: true,
      right: true,
      minWidth: "180px"
    },
    {
      name: `${t("Liquidação")}`,
      selector: (row) => (row.percentualQA ?? 0).toFixed(4).replace('.', ','),
      sortable: true,
      right: true,
      minWidth: "250px"
    },
    {
      name: `${t("Diferido Teórico")}`,
      selector: (row) => currencyMask(row.valorDiferidoTeorico) || "",
      sortable: true,
      right: true,
      minWidth: "130px"
    },
    {
      name: `${t("Parcela Diferido")}`,
      selector: (row) => currencyMask(row.valorDiferidoMensal) || "",
      sortable: true,
      right: true,
      minWidth: "130px"
    },
    {
      name: `${t("Rel. Parc. Consignado e Diferido")}`,
      selector: (row) => (row.valorParcelaMutuoSobDif ?? 0).toFixed(2).replace('.', ','),
      sortable: true,
      right: true,
      minWidth: "220px"
    },
    {
      name: `${t("Rel. Parc. Consignado e Diferido líq. Q.A.")}`,
      selector: (row) =>  (row.valorParcelaMutuoSobDifQA ?? 0).toFixed(2).replace('.', ','),
      sortable: true,
      right: true,
      minWidth: "270px"
    },
    {
      name: `${t("Provisão")}`,
      selector: (row) => (row.valorProvisao ?? 0).toFixed(2).replace('.', ','),
      sortable: true,
      center: true,
      minWidth: "80px"
    },
    {
      name: `${t("Status")}`,
      selector: (row) => row.status || "",
      sortable: true,
      center: true,
      minWidth: "70px"
    },
    {
      name: `${t("VOP Liquidado")}`,
      selector: (row) => row.valorOperacaolLiquidado ? currencyMask(row.valorOperacaolLiquidado) : "",
      sortable: true,
      right: true,
      minWidth: "130px"
    },
    {
      name: `${t("Diferido Liquidado")}`,
      selector: (row) => row.valorDiferidoLiquidado ? currencyMask(row.valorDiferidoLiquidado) : "",
      sortable: true,
      right: true,
      minWidth: "130px"
    },
    {
      name: `${t("Soma Diferido A Pagar")}`,
      selector: (row) => row.valorDiferidoAPagar ? currencyMask(row.valorDiferidoAPagar) : "",
      sortable: true,
      right: true,
      minWidth: "200px"
    },
    {
      name: `${t("Soma Diferido A Pagar com curva de liquidação")}`,
      selector: (row) => row.valorDiferidoAPagarCurva ? currencyMask(row.valorDiferidoAPagarCurva) : "",
      sortable: true,
      right: true,
      minWidth: "370px"
    },
    {
      name: `${t("Soma de parcelas em aberto")}`,
      selector: (row) => row.valorParcelasEmAberto ? currencyMask(row.valorParcelasEmAberto) : "",
      sortable: true,
      right: true,
      minWidth: "230px"
    },
    {
      name: `${t("GAP")}`,
      selector: (row) => row.gap ? currencyMask(row.gap) : "",
      sortable: true,
      right: true,
      minWidth: "80px"
    },
    {
      name: `${t("Dias em atraso")}`,
      selector: (row) => row.diasEmAtraso ? currencyMask(row.diasEmAtraso) : "",
      sortable: true,
      right: true,
      minWidth: "240px"
    },    
    {
      name: `${t("Codigo de referência da curva de liquidação")}`,
      selector: (row) => row.codigoRefCurvaLiquidacao || "",
      sortable: true,
      minWidth: "370px"
    },    
  ];

  const handleDates = (dates: any) => {
    setDataInicial("");
    setDataFinal("");

    if (dates === null) {
      setDataInicial("");
      setDataFinal("");
      formik.setFieldValue("safraMutuoInicial", undefined);
      formik.setFieldValue("safraMutuoFinal", undefined);
    }

    if (dates) {
      const inicio = dayjs(dates[0]).format("YYYY-MM-DD");
      const fim = dayjs(dates[1]).format("YYYY-MM-DD");

      setDataInicial(inicio);
      setDataFinal(fim);
      formik.setFieldValue("safraMutuoInicial", inicio);
      formik.setFieldValue("safraMutuoFinal", fim);
    }
  };

  const formSchema = Yup.object().shape({
    // safraMutuoInicial: Yup
    //   .string()
    //   .required(`${t("crud.validators.requiredField")}`),
    // safraMutuoFinal: Yup
    //   .string()
    //   .required(`${t("crud.validators.requiredField")}`),
  });
  const formik = useFormik<IFilterRelatorioCarteiraContratosMutuo>({
    enableReinitialize: true,
    initialValues: filter,
    validationSchema: formSchema,
    onSubmit: async (values) => {
      filter.lote = values.lote;
      filter.safraMutuoInicial = dataInicial;
      filter.safraMutuoFinal = dataFinal;
      filter.contratoMutuo = values.contratoMutuo;
      filter.idLoja = values.idLoja;
      filter.operador = values.operador;
      filter.grupo = values.grupo;
      filter.convenio = selectedConvenioId;
      filter.percentualMutuoMin = values.percentualMutuoMin;
      filter.percentualMutuoMax = values.percentualMutuoMax;
      filter.numeroMesesMin = values.numeroMesesMin;
      filter.numeroMesesMax = values.numeroMesesMax;
      filter.statusId = values.statusId;
      filter.codigoRefCurvaLiquidacao = values.codigoRefCurvaLiquidacao;

      fetchData();
    }
  });

  const fetchData = async () => {
    setFilter(filter);
    // if (filter.safraMutuoInicial === "" || filter.safraMutuoFinal === "") return;
    formik.setTouched({ safraMutuoInicial: true })
    setIsLoading(true);
    try {
      const response = await Service.getRelatorioCarteiraContratosMutuo(filter);
      if (response && !response.hasErrors) {
        setTableData(response.data);
        setTotalRows(response.totalItems);
      }
    } catch (err) {
      toast.error(t("crud.read.errorMessage"));
    } finally {
      setIsLoading(false);
    }
  };
  const fetchConvenios = async () => {
    if (convenios && convenios.length) return;
    const response = await ConvenioService.getAllConvenio();
    if (response && !response.hasErrors) {
      setConvenios(response.data);
    }
  };
  const fetchStatus = async () => {
    const items: StatusViewModel[] = [
      { id: 1, nome: 'PAGO ou Abaixo do Ticket Minimo' },
      { id: 2, nome: 'Em Atraso ou vazio' },
    ];
    setStatus(items);
  };
  // INICIO: metodos para Exportação
  const preDownloading = () => setIsGerarRelatorio(true);
  const postDownloading = () => setIsGerarRelatorio(false);
  const onErrorDownloadFile = () => { setIsGerarRelatorio(false); };
  const downloadSampleCsvFile_2 = Service.getExportarRelatorioCarteiraContratosMutuo;
  const gerarRelatorio = async() => {
    const filterExport: IFilterRelatorioCarteiraContratosMutuo = {
      lote: formik.values.lote,
      safraMutuoInicial: dataInicial,
      safraMutuoFinal: dataFinal,
      contratoMutuo: formik.values.contratoMutuo,
      idLoja: formik.values.idLoja,
      operador: formik.values.operador,
      grupo: formik.values.grupo,
      convenio: selectedConvenioId,
      percentualMutuoMin: formik.values.percentualMutuoMin,
      percentualMutuoMax: formik.values.percentualMutuoMax,
      numeroMesesMin: formik.values.numeroMesesMin,
      numeroMesesMax: formik.values.numeroMesesMax,
      statusId: selectedStatusId,
      codigoRefCurvaLiquidacao: formik.values.codigoRefCurvaLiquidacao,
      page: 0, 
      limit: 1048576 - 1
    };
    var date = moment(new Date()).format("YYYYMMDD_HHmmss");
    var fileName = `Carteria_Contratos_Mutuo_${date}.xlsx`;
    await useDownloadFile.download({
      apiDefinition: downloadSampleCsvFile_2,
      preDownloading,
      postDownloading,
      onError: onErrorDownloadFile,
      apiParameters: filterExport,
      getFileName: fileName,
      typeFile: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"
    });
  
  };
  // FIM: metodos para Exportação
  useEffect(() => {
    fetchConvenios();
    fetchStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    filter.limit = rowsPerPage
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage]);

  return (
    <MasterLayout>
      <ToolbarLayout>
        <ToolbarLayoutLeft />
        <ToolbarLayoutRight />
      </ToolbarLayout>
      <MainLayout>
        <div className="card">
          <div className="card-body">
            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-sm-6 col-md-3 col-xxl-2 mb-10">
                  <label className="form-label">{t("Lote")}</label>
                  <input
                    type="text"
                    name="lote"
                    value={formik.values.lote}
                    className="form-control form-control-solid" 
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur} />
                </div>
                <div className="col-8 col-sm-6 col-md-4 col-xxl-3 mb-10">
                  <label className="form-label">Data Consignado</label>
                  <RangePicker
                    locale={locale}
                    format={dateFormat}
                    className="form-control form-control-lg form-control-solid d-flex"
                    onChange={(dates: any) => {
                      if (dates === null) {
                        formik.setTouched({ safraMutuoInicial: true })
                      }
                      handleDates(dates);
                    }}
                    onBlur={() => formik.setTouched({ safraMutuoInicial: true })}
                  />
                  {formik.touched.safraMutuoInicial && formik.errors.safraMutuoInicial && (
                    <div className="mt-3 text-danger fw-bold">{formik.errors.safraMutuoInicial}</div>
                  )}
                </div>
                <div className="col-sm-6 col-md-3 col-xxl-2 mb-10">
                  <label className="form-label">{t("Contrato Mutuo")}</label>
                  <input
                    type="text"
                    name="contratoMutuo"
                    value={formik.values.contratoMutuo}
                    className="form-control form-control-solid" 
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur} />
                </div>
                <div className="col-sm-6 col-md-3 col-xxl-2 mb-10">
                  <label className="form-label">{t("ID Loja")}</label>
                  <input
                    type="text"
                    name="idLoja"
                    value={formik.values.idLoja}
                    className="form-control form-control-solid" 
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur} 
                    />
                </div>
                <div className="col-sm-6 col-md-3 col-xxl-2 mb-10">
                  <label className="form-label">{t("Operador")}</label>
                  <input
                    type="text"
                    name="operador"
                    value={formik.values.operador}
                    className="form-control form-control-solid" 
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur} 
                    />
                </div>
                <div className="col-sm-6 col-md-3 col-xxl-2 mb-10">
                  <label className="form-label">{t("Grupo")}</label>
                  <input
                    type="text"
                    name="grupo"
                    value={formik.values.grupo}
                    className="form-control form-control-solid" 
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur} 
                    />
                </div>
                <div className="col-sm-6 col-md-3 col-xxl-2 mb-10">
                  <label className="form-label">{t("Convênio")}</label>
                  <Autocomplete
                    className="autocomplete-md"
                    options={convenios}
                    getOptionLabel={option => option.nome ? option.nome : ""}
                    onChange={(_, value) => { setSelectedConvenioId(value?.id!); }}
                    onInputChange={(_, value) => {
                      if (!value) {
                        setSelectedConvenioId(undefined)
                      }
                    }}                    
                    renderInput={(params) =>
                      <TextField
                        {...params}
                        placeholder={`Selecione o convênio`}
                      />
                    }
                  />
                </div>
                <div className="col-8 col-sm-6 col-md-4 col-xxl-3 mb-10">
                  <label className="form-label">{t("Taxa Consignado")}</label>
                  <div className="d-flex justify-content">
                    <input
                      type="number"
                      name="percentualMutuoMin"
                      min={0.0001}
                      step={0.0001}
                      className="form-control form-control-solid" 
                      value={formik.values.percentualMutuoMin}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur} 
                    />
                    <label className="form-label p-2">até</label>
                    <input
                      type="number"
                      name="percentualMutuoMax"
                      step={0.0001}
                      className="form-control form-control-solid" 
                      value={formik.values.percentualMutuoMax}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur} 
                    />
                  </div>
                </div>
                <div className="col-8 col-sm-6 col-md-4 col-xxl-3 mb-10">
                  <label className="form-label">{t("Prazo")}</label>
                  <div className="d-flex justify-content">
                    <input
                      type="number"
                      name="numeroMesesMin"
                      min={1}
                      className="form-control form-control-solid" 
                      value={formik.values.numeroMesesMin}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur} 
                    />
                    <label className="form-label p-2">até</label>
                    <input
                      type="number"
                      name="numeroMesesMax"
                      className="form-control form-control-solid" 
                      value={formik.values.numeroMesesMax}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur} 
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-3 mb-10">
                  <label className="form-label">{t("Status")}</label>
                  <Autocomplete
                    className="autocomplete-md"
                    options={status}
                    getOptionLabel={option => option.nome ? option.nome : ""}
                    onChange={(_, value) => { setSelectedStatusId(value?.id!); }}
                    onInputChange={(_, value) => {
                      if (!value) {
                        setSelectedStatusId(undefined)
                      }
                    }}                    
                    renderInput={(params) =>
                      <TextField
                        {...params}
                        placeholder={`Selecione o Status`}
                      />
                    }
                  />
                </div>
                <div className="col-sm-6 col-md-3 mb-10">
                  <label className="form-label">{t("Cód.Ref. (Curva de Liquidação)")}</label>
                  <input
                    type="text"
                    name="codigoRefCurvaLiquidacao"
                    value={formik.values.codigoRefCurvaLiquidacao}
                    className="form-control form-control-solid" 
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur} 
                    />
                </div>
              </div>
              <div className="card-footer d-flex justify-content-end py-6 px-9">
                <div className="col-xl-1 mb-5 mx-5">
                  <SaveButton
                    type="submit"
                    label={`Filtrar`}
                    loading={isLoading}
                    disabled={!formik.isValid || !formik.dirty}
                  />
                </div>
                <div className="col-xl-1 mb-5">
                  <SaveButton
                    type="button"
                    label={`Exportar`}
                    loading={isGerarRelatorio}
                    disabled={!formik.isValid || !formik.dirty}
                    onClick={gerarRelatorio}
                  />
                </div>
              </div>
            </form>
            <DataTable
              persistTableHead
              columns={tableColumns}
              data={tableData}
              progressPending={isLoading}
              progressComponent={tableHelper.getLoader()}
              noDataComponent={tableHelper.getNoDataComponent()}
              dense
              customStyles={itlabMediumTableStyle}
              striped 
              highlightOnHover
              contextMessage={tableHelper.getContextMessage()}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePerRowsChange}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationPerPage={rowsPerPage}
              paginationRowsPerPageOptions={[10, 25, 50, 100]}
              paginationComponentOptions={tableHelper.getPaginationComponentOptions()}
            />
          </div>
        </div>
      </MainLayout>
    </MasterLayout>
  );
}
