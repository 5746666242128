import { http } from 'common/helpers/axios/axios-helper';
import { ChangePasswordModel, UserManagementModel, UserInsertResponseViewModel } from '../model/user-management.model';

const basePath = '/user';

async function getSimpleList() {
  return await http.get<UserManagementModel>(`${basePath}`);
}

async function getUserById(userId: any) {
  return await http.get<UserManagementModel>(`${basePath}/${userId}`);
}

async function getUserByName(name: any) {
  return await http.get<UserManagementModel>(`${basePath}/?name=${name}`);
}

async function postUser(user: UserManagementModel) {
  return await http.post<UserInsertResponseViewModel>(`${basePath}`, user);
}

async function changePasswordUser(user: ChangePasswordModel) {
  return await http.post<ChangePasswordModel>(`${basePath}/changepassword`, user);
}

async function putUser(user: UserManagementModel) {
  return await http.put<UserManagementModel>(`${basePath}/${user.id}`, user);
}

async function deleteUserById(id: any) {
  return await http.del(`${basePath}/${id}`);
}

async function getSimpleListFiltering(page: number = 0, limit: number = 10, query: string) {
  var queryFilter = `_page=${page}&_limit=${limit}`;
  if (query !== '') {
    queryFilter += `&${query}`;
  }

  return await http.get<UserManagementModel[]>(`${basePath}?${queryFilter}`);
}

export const UserManagementService = {
  getSimpleList,
  getSimpleListFiltering,
  getUserById,
  deleteUserById,
  postUser,
  putUser,
  getUserByName,
  changePasswordUser,
};
