import i18next from "i18next";
import moment from "moment";
import "moment/locale/pt-br";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import en from "../../calculo/i18n/en-us.json";
import pt from "../../calculo/i18n/pt-br.json";
import ParcelasMutuoTable from "./childs/ParcelasMutuoTable";
import ContratosConsignadosTable from "./childs/ContratosAntecipadosTable";
import {
  MasterLayout,
  MainLayout,
  ToolbarLayout,
  ToolbarLayoutLeft,
  ToolbarLayoutRight,
} from "common/imports/content-layout";
import { currencyMask } from "common/helpers/masks/CurrencyMask";
import { LotesContratacaoCorbanParcelaDetailViewModel } from "app/lotes-contratacao/model/lotes-contratacao.model";
import { LotesContratacaoService } from "app/lotes-contratacao/services/lotes-contratacao.service";

function ContratosLotesContratacao() {
  i18next.addResourceBundle("us", "translation", en);
  i18next.addResourceBundle("br", "translation", pt);
  const { t } = useTranslation();
  const { loteId } = useParams();
  const { corbanId } = useParams();
  const [data, setData] = useState<LotesContratacaoCorbanParcelaDetailViewModel>();
  const [, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("loteCorbanPagamentos");

  const fetchData = async () => {
    try {
      setIsLoading(true);
      if (loteId && corbanId) {
        const response = await LotesContratacaoService.getCorbanLoteContratacaoById(loteId, corbanId);
        if (response && !response.hasErrors) {
          setData(response.data);
        }
      }
    } catch (err) {
      toast.error(t("crud.read.errorMessage"));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MasterLayout>
      <ToolbarLayout>
        <ToolbarLayoutLeft />
        <ToolbarLayoutRight />
      </ToolbarLayout>
      <MainLayout>
        <div className="card mb-5 mb-xl-10">
          <div className="card-body">
            <div className="row">
              <div className="col-sm-6 col-md-4 col-xl-3 col-xxl-2 mb-5">
                <label className="col-md-6 fw-bold text-muted">Lote</label>
                <div className="fw-bolder fs-6 text-dark">{data?.lote ?? ""}</div>
              </div>

              <div className="col-sm-6 col-md-4 col-xl-3 col-xxl-2 mb-5">
                <label className="col-md-6 fw-bold text-muted">CNPJ</label>
                <div className="fw-bolder fs-6 text-dark">{data?.cnpj?.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5") ?? ""}</div>
              </div>

              <div className="col-sm-6 col-md-4 col-xl-3 col-xxl-4 mb-5">
                <label className="col-md-8 fw-bold text-muted">Razão Social</label>
                <div className="fw-bolder fs-6 text-dark">{data?.razaoSocial ?? ""}</div>
              </div>

              <div className="col-sm-6 col-md-4 col-xl-3 col-xxl-2 mb-5">
                <label className="col-md-8 fw-bold text-muted">Qtd. Contratos</label>
                <div className="fw-bolder fs-6 text-dark">{data?.quantidadeContratos ?? ""}</div>
              </div>

              <div className="col-sm-6 col-md-4 col-xl-3 col-xxl-2 mb-5">
                <label className="col-md-8 fw-bold text-muted">1ª Parcela</label>
                <div className="fw-bolder fs-6 text-dark">
                  {
                    moment(data?.primeiraParcela)
                      .locale(`${t("dateFormat.locale")}`)
                      .format("L")
                  }
                </div>
              </div>

              <div className="col-sm-6 col-md-4 col-xl-3 col-xxl-2 mb-5">
                <label className="col-md-8 fw-bold text-muted text-nowrap">Valor Operacional</label>
                <div className="fw-bolder fs-6 text-dark">{currencyMask(data?.valorOperacional ?? 0)}</div>
              </div>

              <div className="col-sm-6 col-md-4 col-xl-3 col-xxl-2 mb-5">
                <label className="col-md-8 fw-bold text-muted">Valor Antecipado</label>
                <div className="fw-bolder fs-6 text-dark">{currencyMask(data?.valorAntecipado ?? 0)}</div>
              </div>

              <div className="col-sm-6 col-md-4 col-xl-3 col-xxl-2 mb-5">
                <label className="col-md-8 fw-bold text-muted">Valor Diferido</label>
                <div className="fw-bolder fs-6 text-dark">{currencyMask(data?.valorDiferido ?? 0)}</div>
              </div>

              <div className="col-sm-6 col-md-4 col-xl-3 col-xxl-2 mb-5">
                <label className="col-md-9 fw-bold text-muted">Taxa Mutuo</label>
                <div className="fw-bolder fs-6 text-dark">{(data?.percentualMutuo ?? 0).toFixed(4).replace('.', ',')}</div>
              </div>

              <div className="col-sm-6 col-md-4 col-xl-3 col-xxl-4 mb-5">
                <label className="col-md-9 fw-bold text-muted">Status</label>
                <div className="fw-bolder fs-6 text-dark">{data?.statusContratacaoDescricao ?? ""}</div>
              </div>

            </div>

          </div>

        </div>

        <div className="card">
          <div className="card-body">

            <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
              <li className="nav-item cursor-pointer">
                <div
                  className={`nav-link text-active-primary ${activeTab === "loteCorbanPagamentos" ? "active" : ""
                    }`}
                  onClick={() => setActiveTab("loteCorbanPagamentos")}
                >
                  Parcelas do Consignado
                </div>
              </li>

              <li className="nav-item cursor-pointer">
                <div
                  className={`nav-link text-active-primary ${activeTab === "contratosConsignados" ? "active" : ""
                    }`}
                  onClick={() => setActiveTab("contratosConsignados")}
                >
                  Contratos Antecipados
                </div>
              </li>
            </ul>

            {activeTab === "loteCorbanPagamentos" && (
              <ParcelasMutuoTable />
            )}

            {activeTab === "contratosConsignados" && (
              <ContratosConsignadosTable />
            )}

          </div>
        </div>
      </MainLayout >
    </MasterLayout >
  )
}

export default ContratosLotesContratacao