import { useEffect, useState } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Modal } from 'react-bootstrap';
import { useFormik } from 'formik';
import { Autocomplete, TextField } from '@mui/material';
import { TokenHelper } from 'common/helpers/auth/token-helper';
import en from 'app/user-management/i18n/en-us.json';
import pt from 'app/user-management/i18n/pt-br.json';
import { ITSVG } from 'common/helpers/ITSVG';
import SaveButton from 'common/partials/SaveButton';
import closeModalIcon from 'assets/images/icons/close.svg'
import { UserProfileModel as Model } from '../../model/user-profile.model';
import { UserProfileService as Service } from '../../service/user-profile.service';

interface EditProfileProps {
  open: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

interface LanguageOptionsModel {
  name: string;
  value: string;
}

export function EditProfile(props: EditProfileProps) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  i18next.addResourceBundle('us', 'translation', en);
  i18next.addResourceBundle('br', 'translation', pt);
  const helper = new TokenHelper();
  const userId = helper.GetId();
  const [languageDefaultValue, setLanguageDefaultValue] = useState<LanguageOptionsModel | undefined | null>(null);

  const formSchema = Yup.object().shape({
    name: Yup.string()
      .required(t('crud.validators.requiredField').toString())
      .max(70, t('crud.validators.maxLength_70').toString())
      .min(5, t('crud.validators.minLength_5').toString())
      .matches(/\w+(?:[, ]\w+)+/, t('crud.validators.minTwoWords').toString()),
    language: Yup.string()
      .required(`${t('crud.validators.requiredField')}`),
  });

  const languageOptions: LanguageOptionsModel[] = [
    { name: 'Português', value: 'br' },
    { name: 'English', value: 'us' }
  ];

  let initialData: Model = {
    id: 0,
    name: '',
    email: '',
    language: '',
  };

  const [data, setData] = useState(initialData);

  const formik = useFormik<Model>({
    enableReinitialize: true,
    initialValues: data,
    validationSchema: formSchema,
    onSubmit: async (values: any) => {
      try {
        setIsLoading(true);

        const name = values.name.trim();

        const editProfileBody: Model = {
          id: data.id,
          language: values.language,
          name: name,
          isActive: true
        };

        const response = await Service.putUser(editProfileBody);
        if (response) {
          if (response.hasErrors) {
            toast.error(t(`error.${response.errorCode}`));
            const errors = response.message.split('/');
            errors.forEach((err) => {
              const regex: RegExp = /(?<=\bCode:\s)(\w+)/gi;
              const result = regex.exec(err);
              if (result && result?.length > 0) {
                toast.error(t(`error.${result[0]}`));
              }
            });
          } else {
            toast.success(t('crud.update.successMessage'));
            setData(response.data);
            props.onClose();
            props.onSuccess();
          }
        }
      } catch (error) {
        toast.error(t('crud.update.errorMessage'));
      } finally {
        setIsLoading(false);
      }
    },
  });

  const fetchUser = () => {
    (async () => {
      try {
        setIsLoading(true);
        if (userId && props.open) {
          const response = await Service.getUserById(userId);
          if (response && !response.hasErrors) {
            setData(response.data);
            setLanguageDefaultValue(languageOptions.find(value => value.value === response.data.language));
          }
        }
      } catch (err) {
        toast.error(t('crud.read.errorMessage'));
      } finally {
        setIsLoading(false);
      }
    })();
  };

  useEffect(() => {
    fetchUser();

    return (() => {
      setData(initialData);
      formik.resetForm();
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  return (
    <Modal
      show={props.open}
      onHide={props.onClose}
      dialogClassName='modal-dialog-centered mw-600px h-auto'
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h2>{t('userProfile.editProfileTitle')}</h2>
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={props.onClose}>
            <ITSVG path={closeModalIcon} className='svg-icon-1' />
          </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className='modal-body py-6 px-9'>
            <div className='row'>
              <div className='col-12 mb-10'>
                <label className='required form-label'>{t('form.name')}</label>
                <input
                  type='text'
                  name='name'
                  className='form-control form-control-lg form-control-solid'
                  placeholder={t('form.placeholder.name').toString()}
                  value={formik.values.name ?? ''}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.name && formik.errors.name && (
                  <div className='mt-3 text-danger fw-bold'>{formik.errors.name}</div>
                )}
                <div className='mt-3 text-danger fw-bold invalid-feedback'></div>
              </div>
              <div className='col-12 mb-10'>
                <label className='form-label'>{t('form.email')}</label>
                <label className='form-control form-control-lg form-control-solid'>{data.email}</label>
                <div className='mt-3 text-danger fw-bold invalid-feedback'></div>
              </div>
              <div className='col-6 mb-10'>
                <label className='required form-label'>{t('form.language.label')}</label>
                <Autocomplete
                  id='language'
                  options={languageOptions}
                  value={languageDefaultValue ?? null}
                  getOptionLabel={option => option.name ? option.name : ''}
                  onChange={(_, value) => {
                    setLanguageDefaultValue(value)
                    formik.setFieldValue('language', value?.value);
                  }}
                  onInputChange={(_, value) => {
                    if (!value) {
                      setLanguageDefaultValue(null)
                      formik.setFieldValue('language', undefined)
                    }
                  }}
                  isOptionEqualToValue={(option, value) => option.value === value.value}
                  onBlur={formik.handleBlur}
                  renderInput={(params) =>
                    <TextField
                      {...params}
                      placeholder={`${t('generalMessages.select')}`}
                    />
                  }
                />
                {formik.errors.language && (
                  <div className='mt-3 text-danger fw-bold'>{formik.errors.language}</div>
                )}
              </div>
            </div>
          </div>
          <div className='modal-footer d-flex justify-content-end py-6 px-9'>
            <button className='btn btn-light btn-active-light-primary me-2' onClick={props.onClose}>
              {t('crud.update.buttonCancel')}
            </button>
            <SaveButton type='submit' loading={isLoading} />
          </div>
        </form>
      </div>
    </Modal>
  );
}