import { ReactElement, useEffect, useState } from 'react';
import { UploadConfigModel } from '../../model/health-check.model';
import { HealthCheckService } from '../../services/health-check.service';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import en from '../../i18n/en-us.json';
import pt from '../../i18n/pt-br.json';
import { CardItemModel } from 'common/layout/components/card/model/card.model';
import { Card } from 'common/layout/components/card/Card';

let uploadArray: CardItemModel[] = [];

export function UploadHealthCheck() {
  i18next.addResourceBundle('us', 'translation', en);
  i18next.addResourceBundle('br', 'translation', pt);

  const { t, i18n } = useTranslation();
  const [uploadState, setUploadState] = useState<CardItemModel[]>([]);
  const [uploadData, setUploadData] = useState<UploadConfigModel>();
  const [fragment, setFragment] = useState<ReactElement>(<></>);

  function buildUploadItems(data: UploadConfigModel | undefined): void {
    if (!data) {
      return;
    }

    uploadArray = [];

    uploadArray.push({ title: t('healthCheck.upload.directory'), value: data.directory });
    uploadArray.push({ title: t('healthCheck.upload.directoryExists'), value: data.directoryExists });
    uploadArray.push({ title: t('healthCheck.upload.access'), value: data.hasAccess });
    uploadArray.push({ title: t('healthCheck.upload.fileCount'), value: data.filesCount });
    uploadArray.push({ title: `${t('healthCheck.upload.total')} (Mb)`, value: data.filesSize });
    uploadArray.push({ title: `${t('healthCheck.upload.maxSize')} (Mb)`, value: data.uploadMaxSize });

    setUploadState(uploadArray);
  }

  useEffect(() => {
    (async () => {
      buildUploadItems(uploadData);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, uploadData]);

  useEffect(() => {
    (async () => {
      try {
        const uploadResponse = await HealthCheckService.getUploadConfig();
        if (uploadResponse && !uploadResponse.hasErrors) {
          setUploadData(uploadResponse.data);
        }

      } catch (err) {
        toast.error(t('crud.read.errorMessage'));
      } finally {
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response = await HealthCheckService.testUploadConfig();
        if (response) {
          setFragment(response.hasErrors
            ? <p className="card-text text-danger">Test failed</p>
            : <p className="card-text text-success">Test success</p>)
        }
      } catch (err) {
        toast.error(t('crud.read.errorMessage'));
      } finally {
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Card title={t('healthCheck.upload.title')} items={uploadState} headerTopRightElement={fragment}></Card>
    </>
  );
}
