import { http } from 'common/helpers/axios/axios-helper';
import { MotivoBloqueioViewModel as Model } from '../model/motivos-bloqueio.model';

const basePath = '/motivoBloqueio';

async function getDataList() {
  return await http.get<Model[]>(`${basePath}`);
}

async function getDataById(id: string) {
  return await http.get<Model>(`${basePath}/${id}`);
}

async function postData(data: Model) {
  return await http.post<Model>(`${basePath}`, data);
}

async function putData(data: Model) {
  return await http.put<Model>(`${basePath}`, data);
}

async function deleteDataById(id: any) {
  return await http.del(`${basePath}/${id}`);
}

export const MotivosBloqueioService = {
  getDataList,
  getDataById,
  postData,
  putData,
  deleteDataById,
};
