import i18next from "i18next";
import moment from "moment";
import "moment/locale/pt-br";
import DataTable, { TableColumn } from "react-data-table-component";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { tableHelper } from "common/helpers/table/table-helper";
import {
  MasterLayout,
  MainLayout,
  ToolbarLayout,
  ToolbarLayoutLeft,
  ToolbarLayoutRight,
} from "common/imports/content-layout";
import { itlabMediumTableStyle } from "common/helpers/table/table-style";
import en from "../i18n/en-us.json";
import pt from "../i18n/pt-br.json";
import { CurvasPercentuaisTable } from "./CurvasPercentuaisTable";
import { Autocomplete, TextField } from "@mui/material";
import { ConvenioService } from "app/convenios/services/convenio.service";
import { ConvenioViewModel } from "app/convenios/model/convenio.model";
import { TiposCurvaViewModel } from "../model/tipo-curva.model";

export function TipoCurva() {
  i18next.addResourceBundle("us", "translation", en);
  i18next.addResourceBundle("br", "translation", pt);
  const { t } = useTranslation();
  const [tiposCurva, setTiposCurva] = useState<TiposCurvaViewModel[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [convenios, setConvenios] = useState<ConvenioViewModel[]>([]);
  const [selectedConvenioId, setSelectedConvenioId] = useState<number | null>(null);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const handlePerRowsChange = async (newPerPage: number) => {
    setRowsPerPage(newPerPage);
  };

  const columns: TableColumn<TiposCurvaViewModel>[] = [
    {
      name: "Id",
      selector: (row) => row.id || "",
      sortable: true,
    },
    {
      name: `${t("convenio.list.table.headers.convenio")}`,
      selector: (row) => row.convenio?.nome || "",
    },
    {
      name: `${t("convenio.list.table.headers.inicioVigencia")}`,
      selector: (row) =>
        moment(row.inicioVigencia)
          .locale(`${t("dateFormat.locale")}`)
          .format("L"),
      sortable: true,
      center: true
    },
    {
      name: `${t("convenio.list.table.headers.finalVigencia")}`,
      selector: (row) =>
        moment(row.finalVigencia)
          .locale(`${t("dateFormat.locale")}`)
          .format("L"),
      sortable: true,
      center: true
    },
  ];

  const fetchTiposCurva = async () => {
    setIsLoading(true);
    try {
      const response = await ConvenioService.getTiposCurvasById(selectedConvenioId!);
      if (response && !response.hasErrors) {
        setTiposCurva(response.data);
      }
    } catch (err) {
      toast.error(t("crud.read.errorMessage"));
    } finally {
      setIsLoading(false);
    }
  };

  const curvasPercentuaisExpandedTable = (row: any) => {
    return (
      <CurvasPercentuaisTable tipoCurvaId={row.data.id} />
    );
  };

  useEffect(() => {
    const fetchConvenios = async () => {
      const response = await ConvenioService.getAllConvenio();
      if (response && !response.hasErrors) {
        setConvenios(response.data);
      }
    };
    fetchConvenios();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchTiposCurva();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedConvenioId])


  return (
    <MasterLayout>
      <ToolbarLayout>
        <ToolbarLayoutLeft />
        <ToolbarLayoutRight />
      </ToolbarLayout>
      <MainLayout>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-sm-6 col-md-4 col-xl-3 mb-10">
                <Autocomplete
                  className="autocomplete-md"
                  options={convenios}
                  getOptionLabel={option => option.nome ? option.nome : ""}
                  onChange={(_, value) => setSelectedConvenioId(value?.id!)}
                  onInputChange={(_, value) => {
                    if (!value) {
                      setSelectedConvenioId(null)
                    }
                  }}
                  renderInput={(params) =>
                    <TextField
                      {...params}
                      placeholder={`Selecione o convênio`}
                    />
                  }
                />
              </div>
            </div>
            <DataTable
              persistTableHead
              columns={columns}
              data={tiposCurva}
              noDataComponent={tableHelper.getNoDataComponent()}
              progressPending={isLoading}
              progressComponent={tableHelper.getLoader()}
              contextMessage={tableHelper.getContextMessage()}
              onChangeRowsPerPage={handlePerRowsChange}
              dense
              striped
              highlightOnHover
              customStyles={itlabMediumTableStyle}
              expandableRows
              expandableRowsComponent={curvasPercentuaisExpandedTable}
              pagination
              paginationPerPage={rowsPerPage}
              paginationRowsPerPageOptions={[10, 25, 50, 100]}
              paginationComponentOptions={tableHelper.getPaginationComponentOptions()}
            />
          </div>
        </div>
      </MainLayout>
    </MasterLayout>
  );
}
