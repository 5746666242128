import { ReactElement, useEffect, useState } from 'react';
import { DataBaseConfigModel } from '../../model/health-check.model';
import { HealthCheckService } from '../../services/health-check.service';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import en from '../../i18n/en-us.json';
import pt from '../../i18n/pt-br.json';
import { CardItemModel } from 'common/layout/components/card/model/card.model';
import { Card } from 'common/layout/components/card/Card';

let databaseArray: CardItemModel[] = [];

export function DatabaseHealthCheck() {
  i18next.addResourceBundle('us', 'translation', en);
  i18next.addResourceBundle('br', 'translation', pt);

  const { t, i18n } = useTranslation();

  const [databaseState, setDatabaseState] = useState<CardItemModel[]>([]);
  const [dataBaseData, setDataBaseData] = useState<DataBaseConfigModel>();
  const [fragment, setFragment] = useState<ReactElement>(<></>);

  function buildDatabaseItems(data: DataBaseConfigModel | undefined): void {
    if (!data) {
      return;
    }

    databaseArray = [];
    databaseArray.push({ title: t('healthCheck.database.name'), value: data.dataBaseName });
    databaseArray.push({ title: t('healthCheck.database.instance'), value: data.serverInstance });

    setDatabaseState(databaseArray);
  }

  useEffect(() => {
    (async () => {
      buildDatabaseItems(dataBaseData);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, dataBaseData]);

  useEffect(() => {
    (async () => {
      try {
        const databaseResponse = await HealthCheckService.getDatabaseConfig();
        if (databaseResponse && !databaseResponse.hasErrors) {
          setDataBaseData(databaseResponse.data);
        }
      } catch (err) {
        toast.error(t('crud.read.errorMessage'));
      } finally {
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response = await HealthCheckService.testDataBaseConfig();
        if (response) {
          setFragment(response.hasErrors
            ? <p className="card-text text-danger">Test failed</p>
            : <p className="card-text text-success">Test success</p>)
        }
      } catch (err) {
        toast.error(t('crud.read.errorMessage'));
      } finally {
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Card title={t('healthCheck.database.title')} items={databaseState} headerTopRightElement={fragment}></Card>
    </>
  );
}
