import { LotesContratacaoDetailViewModel } from "app/lotes-contratacao/model/lotes-contratacao.model";
import { LotesContratacaoService } from "app/lotes-contratacao/services/lotes-contratacao.service";
import { currencyMask } from "common/helpers/masks/CurrencyMask";
import {
  MasterLayout,
  MainLayout,
  ToolbarLayout,
  ToolbarLayoutLeft,
  ToolbarLayoutRight,
} from 'common/imports/content-layout';
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import EvolucaoExecucoesTable from "./childs/EvolucaoExecucoesTable";
import InconsistenciasTable from "./childs/InconsistenciasTable";

function Execucoes() {
  const { loteId } = useParams();
  const { t } = useTranslation();
  const [dataHeader, setDataHeader] = useState<LotesContratacaoDetailViewModel>();
  const [, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("inconsistencias");

  const fetchData = async () => {
    setIsLoading(true);
    try {
      if (loteId) {
        const response = await LotesContratacaoService.getLoteContratacaoById(loteId);
        if (response && !response.hasErrors) {
          setDataHeader(response.data);
        }
      }
    } catch (err) {
      toast.error(t("crud.read.errorMessage"));
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MasterLayout>
      <ToolbarLayout>
        <ToolbarLayoutLeft />
        <ToolbarLayoutRight />
      </ToolbarLayout>
      <MainLayout>
        <div className="card mb-5 mb-xl-10">
          <div className="card-body">
            <div className="row">

              <div className="col-sm-6 col-md-4 col-xl-3 col-xxl-2 mb-5">
                <label className="fw-bold text-muted">Lote</label>
                <div className="fw-bolder fs-6 text-dark">{dataHeader?.id ?? ""}</div>
              </div>

              <div className="col-sm-6 col-md-4 col-xl-3 col-xxl-2 mb-5">
                <label className="fw-bold text-muted">Data Consignado</label>
                <div className="fw-bolder fs-6 text-dark">
                  {
                    moment(dataHeader?.dataMutuo)
                      .locale(`${t("dateFormat.locale")}`)
                      .format("L") ?? ""
                  }
                </div>
              </div>

              <div className="col-sm-6 col-md-4 col-xl-3 col-xxl-3 mb-5">
                <label className="fw-bold text-muted">Período</label>
                <div className="fw-bolder fs-6 text-dark">
                  <span className="me-2">
                    {
                      moment(dataHeader?.periodoInicial)
                        .locale(`${t("dateFormat.locale")}`)
                        .format("L") ?? ""
                    }
                  </span>
                  até
                  <span className="ms-2">
                    {
                      moment(dataHeader?.periodoFinal)
                        .locale(`${t("dateFormat.locale")}`)
                        .format("L") ?? ""
                    }
                  </span>
                </div>
              </div>

              <div className="col-sm-6 col-md-4 col-xl-3 col-xxl-2 mb-5">
                <label className="fw-bold text-muted">Dia Vencimento</label>
                <div className="fw-bolder fs-6 text-dark">{dataHeader?.diaVencimento ?? ""}</div>
              </div>

              <div className="col-sm-6 col-md-4 col-xl-3 col-xxl-2 mb-5">
                <label className="fw-bold text-muted">Qtd. Corbans</label>
                <div className="fw-bolder fs-6 text-dark">{dataHeader?.quantidadeCorbans ?? ""}</div>
              </div>

              <div className="col-sm-6 col-md-4 col-xl-3 col-xxl-2 mb-5">
                <label className="fw-bold text-muted">Qtd. Contratos</label>
                <div className="fw-bolder fs-6 text-dark">{dataHeader?.quantidadeContratos ?? ""}</div>
              </div>

              <div className="col-sm-6 col-md-4 col-xl-3 col-xxl-2 mb-5">
                <label className="fw-bold text-muted">Total Antecipado</label>
                <div className="fw-bolder fs-6 text-dark">{currencyMask(dataHeader?.totalAntecipado) ?? ""}</div>
              </div>

              <div className="col-sm-6 col-md-4 col-xl-3 col-xxl-3 mb-5">
                <label className="fw-bold text-muted">Convênio</label>
                <div className="fw-bolder fs-6 text-dark">{dataHeader?.convenio ?? ""}</div>
              </div>

            </div>
          </div>
        </div >

        <div className="card">
          <div className="card-body">

            <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
              <li className="nav-item cursor-pointer">
                <div
                  className={`nav-link text-active-primary ${activeTab === "inconsistencias" ? "active" : ""
                    }`}
                  onClick={() => setActiveTab("inconsistencias")}
                >
                  Inconsistências
                </div>
              </li>

              <li className="nav-item cursor-pointer">
                <div
                  className={`nav-link text-active-primary ${activeTab === "evolucaoExecucoes" ? "active" : ""
                    }`}
                  onClick={() => setActiveTab("evolucaoExecucoes")}
                >
                  Histórico das Execuções
                </div>
              </li>
            </ul>

            {activeTab === "inconsistencias" && (
              <InconsistenciasTable />
            )}

            {activeTab === "evolucaoExecucoes" && (
              <EvolucaoExecucoesTable />
            )}

          </div>
        </div>
      </MainLayout>
    </MasterLayout>
  )
}

export default Execucoes;