import i18next from 'i18next';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { tableHelper } from 'common/helpers/table/table-helper';
import { itLabTableStyle } from 'common/helpers/table/table-style';
import en from '../../i18n/en-us.json';
import pt from '../../i18n/pt-br.json';
import { CorbansService as Service } from '../../services/corbans.service';
import { useNavigate, useParams } from 'react-router-dom';
import { CorbanViewModel } from 'app/lotes-contratacao/model/lotes-contratacao.model';
import { Tooltip } from 'antd';

export function Subs() {
  const { id } = useParams();
  i18next.addResourceBundle('us', 'translation', en);
  i18next.addResourceBundle('br', 'translation', pt);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const [tableData, setTableData] = useState<CorbanViewModel[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const columns: TableColumn<CorbanViewModel>[] = [
    {
      name: `${t('corban.form.sessions.subsVinculadas.table.headers.cnpj')}`,
      selector: (row) => row?.cnpj?.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5') || '',
      sortable: true,
      width: '180px',
    },
    {
      name: `${t('corban.form.sessions.subsVinculadas.table.headers.razaoSocial')}`,
      selector: (row) => row.razaoSocial || '',
      sortable: true,
    },
    {
      name: `${t('corban.form.sessions.subsVinculadas.table.headers.nomeFantasia')}`,
      selector: (row) => row.nomeFantasia || '',
      sortable: true,
    },
    {
      name: `${t('corban.form.sessions.subsVinculadas.table.headers.situacao')}`,
      cell: (row) => {
        if (row.situacao === 1) {
          return <span className="badge badge-light-success ">{t('Ativo')}</span>;
        }
        if (row.situacao! === 2) {
          return <span className="badge badge-primary ">{t('Inativo')}</span>;
        }
        if (row.situacao! === 3) {
          return <span className="badge badge-light-danger ">{t('Suspenso')}</span>;
        }
      },
      center: true,
      width: '100px',
    },
    {
      name: `${t('corban.form.sessions.subsVinculadas.table.headers.acoes')}`,
      cell: (row) => (
        <Tooltip title="Ver detalhes">
          <button
            type="button"
            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
            onClick={() => navigate(`/cadastros/corban/${row.id}`)}
          >
            <i className="fa-solid fa-eye"></i>
          </button>
        </Tooltip>
      ),
      center: true,
      width: '100px',
    },
  ];

  const fetchData = async () => {
    setIsLoading(true);
    if (id) {
      const response = await Service.getSubsByCorbans(Number(id));
      if (response && !response.hasErrors) {
        setTableData(response.data);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DataTable
      title={''}
      persistTableHead
      columns={columns}
      data={tableData}
      noDataComponent={tableHelper.getNoDataComponent()}
      pagination
      dense
      paginationComponentOptions={tableHelper.getPaginationComponentOptions()}
      highlightOnHover
      progressPending={isLoading}
      progressComponent={tableHelper.getLoader()}
      contextMessage={tableHelper.getContextMessage()}
      selectableRowsVisibleOnly={true}
      customStyles={itLabTableStyle}
    />
  );
}
