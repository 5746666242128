import { SetStateAction } from "react";
import { Modal } from "react-bootstrap";
import DataTable, { TableColumn } from "react-data-table-component";
import { CorbanViewModel } from "app/lotes-contratacao/model/lotes-contratacao.model";
import closeModalIcon from "assets/images/icons/close.svg"
import { ITSVG } from "common/helpers/ITSVG";
import { tableHelper } from "common/helpers/table/table-helper";
import { itlabMediumTableStyle } from "common/helpers/table/table-style";

interface CorbansSelecionadosModalProps {
  corbansSelecionados: CorbanViewModel[];
  setCorbansSelecionados: React.Dispatch<SetStateAction<CorbanViewModel[]>>;
  open: boolean;
  onClose: () => void;
}

export default function CorbansSelecionadosModal(props: CorbansSelecionadosModalProps) {

  const tableColumns: TableColumn<CorbanViewModel>[] = [
    {
      name: "IDLoja",
      selector: (row) => row.codigoOrigem || "",
      width: "90px"
    },
    {
      name: "CNPJ",
      selector: (row) => row?.cnpj?.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5") || "",
      sortable: true,
      width: "180px",
    },
    {
      name: "Razão Social",
      selector: (row) => row.razaoSocial || "",
      maxWidth: "200px",
    },
    {
      name: "Grupo",
      selector: (row) => row.grupoMutuo || "",
      maxWidth: "200px",
    },
  ];

  return (
    <Modal
      show={props.open}
      onHide={props.onClose}
      dialogClassName="modal-dialog-centered mw-750px h-auto"
    >
      <div className="modal-content">
        <div className="modal-header">
          <h3>Corbans removidos do cálculo</h3>
          <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={props.onClose}>
            <ITSVG path={closeModalIcon} className="svg-icon-1" />
          </div>
        </div>

        <div className="modal-body mh-550px overflow-auto">
          <DataTable
            columns={tableColumns}
            data={props.corbansSelecionados}
            progressComponent={tableHelper.getLoader()}
            noDataComponent={tableHelper.getNoDataComponent()}
            dense
            customStyles={itlabMediumTableStyle}
          />
        </div>
      </div>
    </Modal>
  )
}
