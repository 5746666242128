import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface DeleteModalProps {
  type: "delete" | "deactivate";
  open: boolean;
  onClose: () => void;
  onConfirm: (event: React.MouseEvent<HTMLButtonElement>) => void;
  loading: boolean;
}

function DeleteModal({
  type,
  open,
  onClose,
  onConfirm,
  loading
}: DeleteModalProps) {
  const { t } = useTranslation();

  const handleCancel = () => {
    onClose();
  };

  const handleConfirm = (event: React.MouseEvent<HTMLButtonElement>) => {
    onConfirm(event);
  };

  return (
    <Modal show={open} onHide={onClose} dialogClassName="modal-dialog-centered mw-400px h-auto">
      <div className="card">
        <div className="card-header">
          <div className="card-title">
            {type === "delete" ? t("crud.common.delete") : t("crud.common.deactivate")}
          </div>
        </div>
        <div className="card-body">
          <div className="mb-3">
            <span>
              {type === "delete" ? t("crud.delete.confirmMessage") : t("crud.deactivate.confirmMessage")}
            </span>
          </div>
        </div>
        <div className="card-footer d-flex justify-content-end py-6 px-9">
          <button type="button" className="btn btn-active-light-primary me-2" onClick={handleCancel}>
            {t("crud.common.cancel")}
          </button>
          <button type="button" className="btn btn-danger" disabled={loading} onClick={handleConfirm}>
            {t("crud.common.confirm")}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;
