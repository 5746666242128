import { Navigate, Outlet } from 'react-router-dom';
import { APP_ROUTES } from '../routes';
import { TokenHelper } from 'common/helpers/auth/token-helper';
import { Content } from 'common/layout/components/Content';

function SecureRoute() {

  const content = (
    <Content>
      <Outlet />
    </Content>
  );

  const routeGuard = () => {
    if (new TokenHelper().HasToken()) {
      return content;
    }
    return <Navigate to={'/' + APP_ROUTES.login} />;
  };

  return routeGuard();
};

export default SecureRoute;
