import jwt_decode from 'jwt-decode';

const tokenKey = 'token';
const refreshTokenKey = 'refreshToken';

function getToken() {
  return sessionStorage.getItem(tokenKey);
}

function getDecodedToken() {
  const token = sessionStorage.getItem(tokenKey);
  if (token) {
    const decoded = jwt_decode(token);
    return decoded;
  }
  return undefined;
}

function getRefreshToken() {
  return sessionStorage.getItem(refreshTokenKey);
}

function clearToken() {
  sessionStorage.removeItem(tokenKey);
}

function clearRefreshToken() {
  sessionStorage.removeItem(refreshTokenKey);
}

function setToken(value: string) {
  sessionStorage.setItem(tokenKey, value);
}

function setRefreshToken(value: string) {
  sessionStorage.setItem(refreshTokenKey, value);
}

function setAuthData(token: string, refreshToken: string) {
  setToken(token);
  setRefreshToken(refreshToken);
}

export const AuthToken = {
  getToken,
  getDecodedToken,
  getRefreshToken,
  clearToken,
  clearRefreshToken,
  setToken,
  setRefreshToken,
  setAuthData,
};
