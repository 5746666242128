import { ReactElement, useEffect, useState } from 'react';
import { LogConfigModel } from '../../model/health-check.model';
import { HealthCheckService } from '../../services/health-check.service';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import en from '../../i18n/en-us.json';
import pt from '../../i18n/pt-br.json';
import { Card } from 'common/layout/components/card/Card';
import { CardItemModel } from 'common/layout/components/card/model/card.model';

let logArray: CardItemModel[] = [];

export function LogHealthCheck() {
  i18next.addResourceBundle('us', 'translation', en);
  i18next.addResourceBundle('br', 'translation', pt);

  const { t, i18n } = useTranslation();

  const [logState, setLogState] = useState<CardItemModel[]>([]);
  const [logData, setLogData] = useState<LogConfigModel>();
  const [fragment, setFragment] = useState<ReactElement>(<></>);

  function buildLogItems(data: LogConfigModel | undefined): void {
    if (!data) {
      return;
    }

    logArray = [];
    logArray.push({ title: t('healthCheck.log.minLevel'), value: data.minLevel.default });

    data.writers.forEach((w) => {
      logArray.push({ title: t('healthCheck.log.writerName'), value: w.name });
      logArray.push({ title: t('healthCheck.log.writerArgsPath'), value: w.args.path });
      logArray.push({ title: t('healthCheck.log.writerArgsInterval'), value: w.args.rollingInterval });
    });

    setLogState(logArray);
  }

  useEffect(() => {
    (async () => {
      buildLogItems(logData);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, logData]);

  useEffect(() => {
    (async () => {
      try {
        const logResponse = await HealthCheckService.getLogConfig();
        if (logResponse && !logResponse.hasErrors) {
          setLogData(logResponse.data);
        }
      } catch (err) {
        toast.error(t('crud.read.errorMessage'));
      } finally {
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const response = await HealthCheckService.testLogConfig();
        if (response) {
          setFragment(response.hasErrors
            ? <p className="card-text text-danger">Test failed</p>
            : <p className="card-text text-success">Test success</p>)
        }
      } catch (err) {
        toast.error(t('crud.read.errorMessage'));
      } finally {
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Card title={t('healthCheck.log.title')} items={logState} headerTopRightElement={fragment}></Card>
    </>
  );
}
