import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export function TokenExpirationCountdown() {
  const { t } = useTranslation();
  
  const calculateTimeLeft = () => {
    const tokenExpirationDate = sessionStorage.getItem('tokenExpirationDate');
    const tokenExpirationDateToDate = Date.parse(tokenExpirationDate!)
    const difference = tokenExpirationDateToDate - new Date().getTime();
    let timeLeft = {};
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState<any>(calculateTimeLeft());

  useEffect(() => {
    setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
  });

  return (
    <div className="d-flex align-items-center">
      {timeLeft.hours || timeLeft.minutes || timeLeft.seconds ? (
        <span className={`fw-bolder fs-8 ${timeLeft.minutes < 2 && timeLeft.seconds < 60 && timeLeft.hours === 0 ? 'blinking text-warning' : 'text-muted'}`}>
          <span className="me-2">{t('generalMessages.sessionExpiresIn')}</span>
          <span>{String(timeLeft.hours).padStart(2, '0')}</span>
          <span>:</span>
          <span>{String(timeLeft.minutes).padStart(2, '0')}</span>
          <span>:</span>
          <span>{String(timeLeft.seconds).padStart(2, '0')}</span>
        </span>
      ) : (
        <span className="text-muted fw-bolder fs-8">{t('generalMessages.sessionExpired')}</span>
      )}
    </div>
  );
}