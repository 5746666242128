import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { toast } from "react-toastify";
import DataTable, { TableColumn } from "react-data-table-component";
import { tableHelper } from "common/helpers/table/table-helper";
import { itlabMediumTableStyle } from "common/helpers/table/table-style";
import en from "../i18n/en-us.json";
import pt from "../i18n/pt-br.json";
import { ICurvaPercentual as Model } from "../model/curva-percentual.model";
import { TipoCurvaService as Service } from "../services/tipo-curva.service";

interface CurvasPercentuaisProps {
  tipoCurvaId: number;
}

export function CurvasPercentuaisTable(props: CurvasPercentuaisProps) {
  i18next.addResourceBundle("us", "translation", en);
  i18next.addResourceBundle("br", "translation", pt);
  const { t } = useTranslation();

  const [curvasPercentuais, setCurvasPercentuais] = useState<Model[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const columns: TableColumn<Model>[] = [
    {
      name: `${t("Parcela")}`,
      selector: (row) => row.parcela || "",
      sortable: true,
      center: true,
      width: "100px",
    },
    {
      name: `${t("Valor")}`,
      selector: (row) => (row.valor ?? 0).toFixed(17).replace('.', ','),
      sortable: true,
      right: true,
      width: "200px",
    },
  ];

  useEffect(() => {
    const fetchCurvasPercentuais = async () => {
      try {
        setIsLoading(true);
        const response = await Service.getGetCurvasPercentuaisTipoCurva(props.tipoCurvaId);
        if (response && !response.hasErrors) {
          setCurvasPercentuais(response.data);
        }
      } catch (err) {
        toast.error(t("crud.read.errorMessage"));
      } finally {
        setIsLoading(false);
      }
    };
    fetchCurvasPercentuais();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DataTable
      persistTableHead
      columns={columns}
      data={curvasPercentuais}
      progressPending={isLoading}
      progressComponent={tableHelper.getLoader()}
      noDataComponent={tableHelper.getNoDataComponent()}
      contextMessage={tableHelper.getContextMessage()}
      dense
      highlightOnHover
      customStyles={itlabMediumTableStyle}
    />
  );
}
