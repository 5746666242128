import { useEffect } from 'react';
import { toast } from 'react-toastify';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { AuthenticationType } from './childs/AuthTypeSettings';
import { SecuritySettings } from './childs/SecuritySettings';
import { ActiveDirectorySettings } from './childs/ActiveDirectorySettings';
import { SMTPSettings } from './childs/SMTPSettings';
import { UploadSettings } from './childs/UploadSettings';
import { useLoading } from '../../../common/loading/loading.hook';
import en from '../i18n/en-us.json';
import pt from '../i18n/pt-br.json';
import { MasterLayout, MainLayout, ToolbarLayout, ToolbarLayoutLeft, ToolbarLayoutRight } from 'common/imports/content-layout';
import { useLocation } from 'react-router-dom';
import { RoboSettings } from './childs/RoboSettings';
import { CalculoSettings } from './childs/CalculoSettings';

export function GeneralSettings() {
  i18next.addResourceBundle('us', 'translation', en);
  i18next.addResourceBundle('br', 'translation', pt);

  const { isLoading } = useLoading();
  const { t } = useTranslation();
  const location = useLocation();
  const isParametros = (location.pathname === "/cadastros/parametros");
  const isGeneralsettings = (location.pathname === "/settings/generalsettings");
  console.log(location.pathname);
  // const [loading, setLoading] = useState(false)

  useEffect(() => {
    (async () => {
      try {
        isLoading(true);
      } catch (err) {
        toast.error(t('crud.read.errorMessage'));
      } finally {
        isLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MasterLayout>
        <ToolbarLayout>
          <ToolbarLayoutLeft />
          <ToolbarLayoutRight></ToolbarLayoutRight>
        </ToolbarLayout>
        <MainLayout>
          { isGeneralsettings ? <SecuritySettings></SecuritySettings> : null }
          { isGeneralsettings ? <AuthenticationType></AuthenticationType> : null }
          { isGeneralsettings ? <ActiveDirectorySettings></ActiveDirectorySettings> : null }
          { isGeneralsettings ? <SMTPSettings></SMTPSettings> : null }
          { isGeneralsettings ? <UploadSettings></UploadSettings> : null }
          { isParametros ? <RoboSettings></RoboSettings> : null }
          { isParametros ? <CalculoSettings></CalculoSettings> : null }
        </MainLayout>
      </MasterLayout>
    </>
  );
}
