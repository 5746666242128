import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { SMTPSettingsModel as Model } from '../../model/general-settings.model';
import { GeneralSettingsService as Service } from '../../services/general-settings.service';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import SaveButton from 'common/partials/SaveButton';

export function SMTPSettings() {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const formSchema = Yup.object().shape({
    // smtpPickupDirectoryLocation: Yup.string().required(t('validation.requiredField')),
    // smtpServerHost: Yup.string().required(t('validation.requiredField')),
    // smtpServerPort: Yup.number()
    //   .required(t('validation.requiredField'))
    //   .positive(t('validation.positiveNumber'))
    //   .integer(),
    // smtpEnableSsl: Yup.boolean().required(t('validation.requiredField')),
    // smtpCredentialsUsername: Yup.string().required(t('validation.requiredField')),
    // smtpDefaultFromAddress: Yup.string().required(t('validation.requiredField')).email(t('validation.email')),
  });

  let initialData: Model = {
    smtpDeliveryMethod: 0,
    smtpPickupDirectoryLocation: '',
    smtpServerHost: '',
    smtpServerPort: 0,
    smtpEnableSsl: false,
    smtpCredentialsUsername: '',
    smtpCredentialsPassword: '**********',
    smtpDefaultFromAddress: '',
    smtpDefaultSenderName: '',
    smtpSenderTool: 1,
    smtP3rdPartyKey: ''
  };

  const [data, setData] = useState<Model>(initialData);

  const formik = useFormik<Model>({
    enableReinitialize: true,
    initialValues: data,
    validationSchema: formSchema,
    onSubmit: (values) => {
      setIsLoading(true);

      if (values.smtpDeliveryMethod === 0 && (values.smtpSenderTool !== 1 && values.smtpSenderTool !== 3)) {
        toast.error(t('generalSettings.message.errorSelection'));
        return;
      }
      else if (values.smtpDeliveryMethod === 2 && values.smtpSenderTool !== 4) {
        toast.error(t('generalSettings.message.errorSelection'));
        return;
      }
      else if (values.smtpDeliveryMethod === 1) {
        values.smtpSenderTool = 1;
      }

      if (values.smtpDeliveryMethod === 2) {
        values.smtpDeliveryMethod = 0
      }

      // return

      const body: Model = {
        smtpDeliveryMethod: values.smtpDeliveryMethod,
        smtpPickupDirectoryLocation: values.smtpPickupDirectoryLocation,
        smtpServerHost: values.smtpServerHost,
        smtpServerPort: values.smtpServerPort,
        smtpEnableSsl: values.smtpEnableSsl,
        smtpCredentialsUsername: values.smtpCredentialsUsername,
        smtpCredentialsPassword: values.smtpCredentialsPassword,
        smtpDefaultFromAddress: values.smtpDefaultFromAddress,
        smtpDefaultSenderName: values.smtpDefaultSenderName,
        smtpSenderTool: values.smtpSenderTool,
        smtP3rdPartyKey: values.smtP3rdPartyKey
      };

      if (body.smtpCredentialsPassword === '**********') {
        delete body.smtpCredentialsPassword;
      }

      Service.putSMTPSettings(body);
      toast.success(t('crud.update.successMessage'));
      setIsLoading(false);
    },
  });

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const response = await Service.getSMTPSettings();
        if (response && !response.hasErrors) {
          if (response.data.smtpSenderTool === 4) {
            response.data.smtpDeliveryMethod = 2;
          }
          setData(response.data);
        }
      } catch (err) {
        toast.error(t('crud.read.errorMessage'));
      } finally {
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <form onSubmit={formik.handleSubmit} className="form" autoComplete="off">
        <div className="card mb-5 mb-xl-10">
          <div
            className="card-header border-0 cursor-pointer"
            role="button"
            data-bs-toggle="collapse"
            data-bs-target="#kt_smtp_settings"
            aria-expanded="true"
            aria-controls="kt_smtp_settings"
          >
            <div className="card-title m-0">
              <h3 className="fw-bolder m-0">{t('generalSettings.smtp.title')}</h3>
            </div>
          </div>

          <div id="kt_smtp_settings" className="collapse show">
            <div className="card-body border-top p-9">
              <div className="row mb-6">
                <div className="col">
                  <label className={`btn btn-outline btn-outline-dashed btn-outline-default d-flex text-start p-6 `}>
                    <span className="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="smtpDeliveryMethod_0"
                        value={0}
                        checked={formik.values.smtpDeliveryMethod === 0}
                        onChange={() => formik.setFieldValue('smtpDeliveryMethod', 0)}
                      />
                    </span>
                    <span className="ms-5">
                      <span className="fs-4 fw-bolder text-gray-800 d-block">
                        {t('generalSettings.smtp.smtpServer')}
                      </span>
                    </span>
                  </label>
                </div>
                <div className="col">
                  <label className={`btn btn-outline btn-outline-dashed btn-outline-default d-flex text-start p-6 `}>
                    <span className="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="smtpDeliveryMethod_2"
                        value={2}
                        checked={formik.values.smtpDeliveryMethod === 2}
                        onChange={() => formik.setFieldValue('smtpDeliveryMethod', 2)}
                      />
                    </span>
                    <span className="ms-5">
                      <span className="fs-4 fw-bolder text-gray-800 d-block">
                        {t('generalSettings.smtp.smtp3party')}
                      </span>
                    </span>
                  </label>
                </div>
                <div className="col">
                  <label className={`btn btn-outline btn-outline-dashed btn-outline-default d-flex text-start p-6 $`}>
                    <span className="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="smtpDeliveryMethod_1"
                        value={1}
                        checked={formik.values.smtpDeliveryMethod === 1}
                        onChange={() => formik.setFieldValue('smtpDeliveryMethod', 1)}
                      />
                    </span>
                    <span className="ms-5">
                      <span className="fs-4 fw-bolder text-gray-800 d-block">
                        {t('generalSettings.smtp.smtpLocal')}
                      </span>
                    </span>
                  </label>
                </div>
              </div>

              {(formik.values.smtpDeliveryMethod === 0 || formik.values.smtpDeliveryMethod === 2) && (
                <div className="row mb-6 d-flex align-items-center">
                  <label className="col-lg-4 col-form-label required fw-bold fs-6">
                    {t('generalSettings.smtp.toolSender')}
                  </label>
                  <div className="col-lg-8">
                    <div className="fv-row fv-plugins-icon-container">
                      <div className="fv-row">
                        <div className="row row-cols-1 row-cols-md-3 row-cols-lg-1 row-cols-xl-3 g-9">

                          {formik.values.smtpDeliveryMethod === 0 && (
                            <>
                              <div className="col">
                                <label
                                  className={`btn btn-outline btn-outline-dashed btn-outline-default d-flex text-start p-6 `}
                                >
                                  <span className="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="smtpSenderTool_1"
                                      value={1}
                                      checked={formik.values.smtpSenderTool === 1}
                                      onChange={() => formik.setFieldValue('smtpSenderTool', 1)}
                                    />
                                    {formik.touched.smtpSenderTool && formik.errors.smtpSenderTool && (
                                      <div className="mt-3 text-danger fw-bold">
                                        <div className="fv-help-block">{formik.errors.smtpSenderTool}</div>
                                        <div className="mt-3 text-danger fw-bold invalid-feedback"></div>
                                      </div>
                                    )}
                                  </span>
                                  <span className="ms-5">
                                    <span className="fs-4 fw-bolder text-gray-800 d-block">.NET Mail</span>
                                  </span>
                                </label>
                              </div>
                              <div className="col">
                                <label
                                  className={`btn btn-outline btn-outline-dashed btn-outline-default d-flex text-start p-6 `}
                                >
                                  <span className="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="smtpSenderTool_3"
                                      value={3}
                                      checked={formik.values.smtpSenderTool === 3}
                                      onChange={() => formik.setFieldValue('smtpSenderTool', 3)}
                                    />
                                    {formik.touched.smtpSenderTool && formik.errors.smtpSenderTool && (
                                      <div className="mt-3 text-danger fw-bold">
                                        <div className="fv-help-block">{formik.errors.smtpSenderTool}</div>
                                        <div className="mt-3 text-danger fw-bold invalid-feedback"></div>
                                      </div>
                                    )}
                                  </span>
                                  <span className="ms-5">
                                    <span className="fs-4 fw-bolder text-gray-800 d-block">MailKit</span>
                                  </span>
                                </label>
                              </div>
                            </>
                          )}

                          {formik.values.smtpDeliveryMethod === 2 && (
                            <div className="col">
                              <label
                                className={`btn btn-outline btn-outline-dashed btn-outline-default d-flex text-start p-6 `}
                              >
                                <span className="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="smtpSenderTool_4"
                                    value={4}
                                    checked={formik.values.smtpSenderTool === 4}
                                    onChange={() => formik.setFieldValue('smtpSenderTool', 4)}
                                  />
                                  {formik.touched.smtpSenderTool && formik.errors.smtpSenderTool && (
                                    <div className="mt-3 text-danger fw-bold">
                                      <div className="fv-help-block">{formik.errors.smtpSenderTool}</div>
                                      <div className="mt-3 text-danger fw-bold invalid-feedback"></div>
                                    </div>
                                  )}
                                </span>
                                <span className="ms-5">
                                  <span className="fs-4 fw-bolder text-gray-800 d-block">SendGrid</span>
                                </span>
                              </label>
                            </div>
                          )}

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="row mb-6">
                <label className="col-lg-4 col-form-label required fw-bold fs-6">
                  {t('generalSettings.smtp.defaultEmail')}
                </label>

                <div className="col-lg-8">
                  <div className="row">
                    <div className="col-lg-12 fv-row">
                      <input
                        type="text"
                        className="form-control form-control-lg form-control-solid"
                        placeholder={t('generalSettings.smtp.defaultEmail').toString()}
                        name="smtpDefaultFromAddress"
                        value={formik.values.smtpDefaultFromAddress ?? ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.smtpDefaultFromAddress && formik.errors.smtpDefaultFromAddress && (
                        <div className="mt-3 text-danger fw-bold">
                          <div className="fv-help-block">{formik.errors.smtpDefaultFromAddress}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mb-6">
                <label className="col-lg-4 col-form-label required fw-bold fs-6">
                  {t('generalSettings.smtp.defaultSender')}
                </label>

                <div className="col-lg-8">
                  <div className="row">
                    <div className="col-lg-12 fv-row">
                      <input
                        type="text"
                        name="smtpDefaultSenderName"
                        className="form-control form-control-lg form-control-solid"
                        placeholder={t('generalSettings.smtp.defaultSender').toString()}
                        value={formik.values.smtpDefaultSenderName ?? ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.smtpDefaultSenderName && formik.errors.smtpDefaultSenderName && (
                        <div className="mt-3 text-danger fw-bold">
                          <div className="fv-help-block">{formik.errors.smtpDefaultSenderName}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {(formik.values.smtpDeliveryMethod === 0 || formik.values.smtpDeliveryMethod === 2) ? (
                <>

                  {formik.values.smtpDeliveryMethod === 0 && (
                    <>
                      <div className="row mb-6">
                        <label className="col-lg-4 col-form-label required fw-bold fs-6">
                          {t('generalSettings.smtp.host')}
                        </label>

                        <div className="col-lg-8">
                          <div className="row">
                            <div className="col-lg-12 fv-row">
                              <input
                                type="text"
                                className="form-control form-control-lg form-control-solid"
                                placeholder={t('generalSettings.smtp.host').toString()}
                                name="smtpServerHost"
                                value={formik.values.smtpServerHost ?? ''}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                              {formik.touched.smtpServerHost && formik.errors.smtpServerHost && (
                                <div className="mt-3 text-danger fw-bold">
                                  <div className="fv-help-block">{formik.errors.smtpServerHost}</div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row mb-6">
                        <label className="col-lg-4 col-form-label required fw-bold fs-6">
                          {t('generalSettings.smtp.port')}
                        </label>

                        <div className="col-lg-8">
                          <div className="row">
                            <div className="col-lg-12 fv-row">
                              <input
                                type="number"
                                className="form-control form-control-lg form-control-solid"
                                placeholder={t('generalSettings.smtp.port').toString()}
                                name="smtpServerPort"
                                value={formik.values.smtpServerPort ?? ''}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                              {formik.touched.smtpServerPort && formik.errors.smtpServerPort && (
                                <div className="mt-3 text-danger fw-bold">
                                  <div className="fv-help-block">{formik.errors.smtpServerPort}</div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row mb-6">
                        <label className="col-lg-4 col-form-label required fw-bold fs-6">
                          {t('generalSettings.smtp.ssl')}
                        </label>

                        <div className="col-lg-8">
                          <div className="row">
                            <div className="col-lg-12 fv-row ">
                              <div className="form-check form-check-solid form-switch">
                                <input
                                  className="form-check-input w-45px h-30px "
                                  type="checkbox"
                                  id="smtpEnableSsl"
                                  name="smtpEnableSsl"
                                  defaultChecked={formik.values.smtpEnableSsl ?? false}
                                  onChange={(e) => { formik.values.smtpEnableSsl = e.target.checked; }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row mb-6">
                        <label className="col-lg-4 col-form-label required fw-bold fs-6">
                          {t('generalSettings.smtp.smtpUsername')}
                        </label>

                        <div className="col-lg-8">
                          <div className="row">
                            <div className="col-lg-12 fv-row">
                              <input
                                type="text"
                                name="smtpCredentialsUsername"
                                className="form-control form-control-lg form-control-solid"
                                placeholder={t('generalSettings.smtp.smtpUsername').toString()}
                                value={formik.values.smtpCredentialsUsername ?? ''}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              // {...formik.getFieldProps('smtpCredentialsUsername')}
                              />
                              {formik.touched.smtpCredentialsUsername && formik.errors.smtpCredentialsUsername && (
                                <div className="mt-3 text-danger fw-bold">
                                  <div className="fv-help-block">{formik.errors.smtpCredentialsUsername}</div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row mb-6">
                        <label className="col-lg-4 col-form-label fw-bold fs-6">
                          {t('generalSettings.smtp.smtpPassword')}
                        </label>

                        <div className="col-lg-8">
                          <div className="row">
                            <div className="col-lg-12 fv-row">
                              <input
                                type="password"
                                name="smtpCredentialsPassword"
                                className="form-control form-control-lg form-control-solid"
                                value={formik.values.smtpCredentialsPassword ?? '**********'}
                                placeholder={t('generalSettings.smtp.smtpPassword').toString()}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                              {formik.touched.smtpCredentialsPassword && formik.errors.smtpCredentialsPassword && (
                                <div className="mt-3 text-danger fw-bold">
                                  <div className="fv-help-block">{formik.errors.smtpCredentialsPassword}</div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {formik.values.smtpDeliveryMethod === 2 && (
                    <div className="row mb-6">
                      <label className="col-lg-4 col-form-label fw-bold fs-6">
                        {t('generalSettings.smtp.smtP3rdPartyKey')}
                      </label>

                      <div className="col-lg-8">
                        <div className="row">
                          <div className="col-lg-12 fv-row">
                            <input
                              type="text"
                              name="smtP3rdPartyKey"
                              value={formik.values.smtP3rdPartyKey ?? ''}
                              className="form-control form-control-lg form-control-solid"
                              placeholder={t('generalSettings.smtp.smtP3rdPartyKey').toString()}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.touched.smtP3rdPartyKey && formik.errors.smtP3rdPartyKey && (
                              <div className="mt-3 text-danger fw-bold">
                                <div className="fv-help-block">{formik.errors.smtP3rdPartyKey}</div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                </>
              ) : (
                <>
                  <div className="row mb-6">
                    <label className="col-lg-4 col-form-label required fw-bold fs-6">
                      {t('generalSettings.smtp.localDirectoryLabel')}
                    </label>

                    <div className="col-lg-8">
                      <div className="row">
                        <div className="col-lg-12 fv-row">
                          <input
                            type="text"
                            name="smtpPickupDirectoryLocation"
                            className="form-control form-control-lg form-control-solid"
                            value={formik.values.smtpPickupDirectoryLocation ?? ''}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.smtpPickupDirectoryLocation && formik.errors.smtpPickupDirectoryLocation && (
                            <div className="mt-3 text-danger fw-bold">
                              <div className="fv-help-block">{formik.errors.smtpPickupDirectoryLocation}</div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="card-footer d-flex justify-content-end py-6 px-9">
              <button type="reset" className="btn btn-white btn-active-light-primary me-2">{t('crud.create.buttonCancel')}</button>
              <SaveButton type="submit" loading={isLoading} />
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
