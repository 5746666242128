import i18next from "i18next";
import * as Yup from "yup";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ProfileSelectorComponent } from "app/profile-selector/components/ProfileSelector";
import { ChangePassword } from "./child/ChangePassword";
import { Autocomplete, TextField } from "@mui/material";
import {
  MasterLayout,
  MainLayout,
  ToolbarLayout,
  ToolbarLayoutLeft,
  ToolbarLayoutRight,
} from "common/imports/content-layout";
import { AuthenticationMethodSettingsModel } from "app/general-settings/model/general-settings.model";
import { GeneralSettingsService } from "app/general-settings/services/general-settings.service";
import en from "../i18n/en-us.json";
import pt from "../i18n/pt-br.json";
import { UserManagementModel as Model } from "../model/user-management.model";
import { UserManagementService as Service } from "../services/user-management.service";
import { TokenHelper } from "common/helpers/auth/token-helper";
import SaveButton from "common/partials/SaveButton";

interface IsActiveOptionsModel {
  name: string;
  value: boolean;
}

interface LanguageOptionsModel {
  name: string;
  value: string;
}

export function CreateEditUser() {
  i18next.addResourceBundle("us", "translation", en);
  i18next.addResourceBundle("br", "translation", pt);

  const { t } = useTranslation();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("basicInfoTab");
  const navigate = useNavigate();
  const tokenHelper = new TokenHelper();

  const isActiveOptions: IsActiveOptionsModel[] = [
    { name: `${t("generalMessages.active")}`, value: true },
    { name: `${t("generalMessages.inactive")}`, value: false }
  ];

  const languageOptions: LanguageOptionsModel[] = [
    { name: "Português", value: "br" },
    { name: "English", value: "us" }
  ];

  const formSchema = Yup.object().shape({
    name: Yup.string()
      .required(`${t("crud.validators.requiredField")}`)
      .max(256, `${t("crud.validators.maxLength_70")}`)
      .min(5, `${t("crud.validators.minLength_5")}`)
      .matches(/\w+(?:[, ]\w+)+/, `${t("crud.validators.minTwoWords")}`),
    email: Yup.string()
      .required(`${t("crud.validators.requiredField")}`)
      .email(`${t("crud.validators.email")}`)
      .max(256, `${t("crud.validators.maxLength_70")}`),
    userName: Yup.string()
      .required(`${t("crud.validators.requiredField")}`)
      .max(256, `${t("crud.validators.maxLength_25")}`),
    password: Yup.string()
      .when("authenticationType", {
        is: 1,
        then: Yup.string().required(`${t("crud.validators.requiredField")}`)
      }),
    confirmPassword: Yup.string()
      .when("authenticationType", {
        is: 1,
        then: Yup.string()
          .required(`${t("crud.validators.requiredField")}`)
          .oneOf([Yup.ref("password"), null], `${t("crud.validators.passwordMatch")}`)
      }),
    isActive: Yup.boolean()
      .required(`${t("crud.validators.requiredField")}`),
    language: Yup.string()
      .required(`${t("crud.validators.requiredField")}`),
    authenticationType: Yup.number()
      .required(`${t("crud.validators.requiredField")}`),
  });

  let initialData: Model = {
    id: parseInt(id ?? "0"),
    name: "",
    email: "",
    userName: "",
    password: "",
    confirmPassword: "",
    isActive: true,
    authenticationType: undefined,
    language: tokenHelper.GetLanguage(),
  };

  let initialAuthtypeData: AuthenticationMethodSettingsModel = {
    authenticateActiveDirectory: false,
    authenticateDataBase: false,
  };

  const [authTypeData, setAuthTypeData] = useState<AuthenticationMethodSettingsModel>(initialAuthtypeData);
  const [data, setData] = useState<Model>(initialData);
  const [isActiveDefaultValue, setIsActiveDefaultValue] = useState<IsActiveOptionsModel | undefined | null>(null);
  const [languageDefaultValue, setLanguageDefaultValue] = useState<LanguageOptionsModel | undefined | null>(null);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const formik = useFormik<Model>({
    enableReinitialize: true,
    initialValues: data,
    validationSchema: formSchema,
    onSubmit: async (values: any) => {
      try {
        setIsLoading(true);
        const name = values.name.trim();
        const body: Model = {
          id: parseInt(id ?? "0"),
          name: name,
          userName: values.userName,
          email: values.email,
          password: values.password,
          confirmPassword: values.confirmPassword,
          isActive: values.isActive,
          authenticationType: values.authenticationType,
          language: values.language,
        };

        if (values.authenticationType === 0) {
          delete body.password;
          delete body.confirmPassword;
        }

        if (id) {
          const response = await Service.putUser(body);
          if (response && !response?.hasErrors) {
            toast.success(`${t("crud.update.successMessage")}`);
          } else {
            toast.error(`${t("crud.update.errorMessage")}`);
          }
        } else {
          const response = await Service.postUser(body);
          if (response && !response?.hasErrors) {
            toast.success(`${t("crud.create.successMessage")}`);
            navigate(`/security/usermanagement/${response.data.id}`);
          } else {
            toast.error(`${t(`error.${response?.errorCode}`)}`);
            const errors = response?.message.split("/");
            errors?.forEach((err) => {
              const regex: RegExp = /(?<=\bCode:\s)(\w+)/gi;
              const result = regex.exec(err);
              if (result && result?.length > 0) {
                toast.error(`${t(`error.${result[0]}`)}`);
              }
            });
          }
        }
      } catch (err) {
        console.log(err)
      } finally {
        fetchUsers();
        setIsLoading(false);
      }
    },
  });

  const fetchUsers = async () => {
    try {
      setIsLoading(true);
      if (id) {
        const response = await Service.getUserById(id);
        if (response && !response.hasErrors) {
          setData(response.data);
          setIsActiveDefaultValue(isActiveOptions.find(value => value.value === response.data.isActive));
          setLanguageDefaultValue(languageOptions.find(value => value.value === response.data.language));
        }
      } else {
        setIsActiveDefaultValue(isActiveOptions.find(value => value.value === formik.values.isActive));
        setLanguageDefaultValue(languageOptions.find(value => value.value === formik.values.language));
      }
    } catch (err) {
      toast.error(`${t("crud.read.errorMessage")}`);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAuthType = async () => {
    try {
      setIsLoading(true);
      const response = await GeneralSettingsService.getAuthTypeSettings();
      if (response && !response.hasErrors) {
        setAuthTypeData(response.data);
      }
    } catch (err) {
      toast.error(`${t("crud.read.errorMessage")}`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
    fetchAuthType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MasterLayout>
      <ToolbarLayout>
        <ToolbarLayoutLeft />
        <ToolbarLayoutRight />
      </ToolbarLayout>
      <MainLayout>
        <div className="card">
          <div className="card-body d-flex justify-content-between pb-0">
            <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
              <li className={`nav-item ${id ? "cursor-pointer" : ""}`}>
                <div
                  className={`nav-link text-active-primary ${activeTab === "basicInfoTab" ? "active" : ""} ${!id ? "disabled" : ""}`}
                  onClick={() => setActiveTab("basicInfoTab")}
                >
                  {t("basicInfoTab")}
                </div>
              </li>
              <li className={`nav-item ${id ? "cursor-pointer" : ""}`}>
                <div
                  className={`nav-link text-active-primary ${activeTab === "groupsTab" ? "active" : ""} ${!id ? "disabled" : ""}`}
                  onClick={() => setActiveTab("groupsTab")}
                >
                  {t("groupsTab")}
                </div>
              </li>
            </ul>
            {activeTab === "basicInfoTab" && data.authenticationType === 1 && (
              <>
                <button
                  type="button"
                  className="btn btn-sm btn-primary fw-bolder"
                  onClick={handleOpen}
                  disabled={!data.isActive}
                >
                  {t("form.passwordComponent.title")}
                </button>
                <ChangePassword
                  open={open}
                  userName={data.userName ?? ""}
                  name={data.name ?? ""}
                  id={data.id}
                  onClose={handleClose}
                />
              </>
            )}
          </div>
          {activeTab === "basicInfoTab" && (
            <form onSubmit={formik.handleSubmit} autoComplete="off" className="form w-100">
              <div className="card-body pt-0">
                <div className="row">
                  <div className="col-12 mb-10">
                    <label className="required form-label mt-10">{t("form.name")}</label>
                    <input
                      type="text"
                      name="name"
                      maxLength={70}
                      className="form-control form-control-lg form-control-solid"
                      placeholder={`${t("form.placeholder.name")}`}
                      autoComplete="name"
                      value={formik.values.name ?? ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div className="mt-3 text-danger fw-bold">
                        <div className="fv-help-block">{formik.errors.name}</div>
                      </div>
                    )}
                  </div>
                  <div className="col-md-6 mb-10">
                    <label className="required form-label">{t("form.email")}</label>
                    <input
                      type="text"
                      name="email"
                      maxLength={70}
                      className="form-control form-control-lg form-control-solid"
                      placeholder={`${t("form.placeholder.email")}`}
                      value={formik.values.email ?? ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.email && formik.errors.email && (
                      <div className="mt-3 text-danger fw-bold">
                        <div className="fv-help-block">{formik.errors.email}</div>
                      </div>
                    )}
                  </div>
                  <div className="col-md-6 mb-10">
                    <label className="required form-label">{t("form.username")}</label>
                    <input
                      type="text"
                      name="userName"
                      autoComplete="new-username"
                      maxLength={25}
                      className="form-control form-control-lg form-control-solid"
                      placeholder={t("form.placeholder.username").toString()}
                      value={formik.values.userName ?? ""}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={id?.length! > 0}
                    />
                    {formik.touched.userName && formik.errors.userName && (
                      <div className="mt-3 text-danger fw-bold">
                        <div className="fv-help-block">{formik.errors.userName}</div>
                      </div>
                    )}
                  </div>
                  {(!id && formik.values.authenticationType === 1 && authTypeData.authenticateDataBase) &&
                    <>
                      <div className="col-md-6 mb-10">
                        <label className="required form-label">{t("form.password")}</label>
                        <input
                          type="password"
                          name="password"
                          autoComplete="new-password"
                          className="form-control form-control-lg form-control-solid"
                          placeholder={t("form.placeholder.password").toString()}
                          value={formik.values.password ?? ""}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          disabled={id?.length! > 0}
                        />
                        {formik.touched.password && formik.errors.password && (
                          <div className="mt-3 text-danger fw-bold">
                            <div className="fv-help-block">{formik.errors.password}</div>
                          </div>
                        )}
                      </div>
                      <div className="col-md-6 mb-10">
                        <label className="required form-label">{t("form.confirmPassword")}</label>
                        <input
                          type="password"
                          name="confirmPassword"
                          className="form-control form-control-lg form-control-solid w-200"
                          placeholder={t("form.placeholder.confirmPassword").toString()}
                          value={formik.values.confirmPassword ?? ""}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          disabled={id?.length! > 0}
                        />
                        {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                          <div className="mt-3 text-danger fw-bold">
                            <div className="fv-help-block">{formik.errors.confirmPassword}</div>
                          </div>
                        )}
                      </div>
                    </>
                  }
                  <div className="col-sm-6 col-lg-3 mb-10">
                    <label className="required form-label">{t("table.status")}</label>
                    <Autocomplete
                      id="isActive"
                      options={isActiveOptions}
                      value={isActiveDefaultValue ?? null}
                      getOptionLabel={option => option.name ? option.name : ""}
                      onChange={(_, value) => {
                        setIsActiveDefaultValue(value)
                        formik.setFieldValue("isActive", value?.value);
                      }}
                      onInputChange={(_, value) => {
                        if (!value) {
                          setIsActiveDefaultValue(null)
                          formik.setFieldValue("isActive", undefined)
                        }
                      }}
                      isOptionEqualToValue={(option, value) => option.value === value.value}
                      onBlur={formik.handleBlur}
                      renderInput={(params) =>
                        <TextField
                          {...params}
                          placeholder={`${t("generalMessages.select")}`}
                        />
                      }
                    />
                    {formik.errors.isActive && (
                      <div className="mt-3 text-danger fw-bold">
                        <div className="fv-help-block">{formik.errors.isActive}</div>
                      </div>
                    )}
                  </div>
                  <div className="col-sm-6 col-lg-3 mb-10">
                    <label className="required form-label">{t("form.language.label")}</label>
                    <Autocomplete
                      id="language"
                      options={languageOptions}
                      value={languageDefaultValue ?? null}
                      getOptionLabel={option => option.name ? option.name : ""}
                      onChange={(_, value) => {
                        setLanguageDefaultValue(value)
                        formik.setFieldValue("language", value?.value);
                      }}
                      onInputChange={(_, value) => {
                        if (!value) {
                          setLanguageDefaultValue(null)
                          formik.setFieldValue("language", undefined)
                        }
                      }}
                      isOptionEqualToValue={(option, value) => option.value === value.value}
                      onBlur={formik.handleBlur}
                      renderInput={(params) =>
                        <TextField
                          {...params}
                          placeholder={`${t("generalMessages.select")}`}
                        />
                      }
                    />
                    {formik.errors.language && (
                      <div className="mt-3 text-danger fw-bold">{formik.errors.language}</div>
                    )}
                  </div>
                </div>
                <div className="mb-10 fv-row fv-plugins-icon-container">
                  <label className="required form-label">{t("form.authType.title")}</label>
                  <div className="fv-row mb-10 mt-5">
                    <div className="row row-cols-1 row-cols-md-3 row-cols-lg-1 row-cols-xl-3 g-9">
                      {authTypeData.authenticateActiveDirectory && (
                        <>
                          <div className="col">
                            <label
                              className={`btn btn-outline btn-outline-dashed btn-outline-default d-flex text-start p-6 `}
                            >
                              <span className="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="authenticationType_0"
                                  value={0}
                                  checked={formik.values.authenticationType === 0}
                                  onChange={() => formik.setFieldValue("authenticationType", 0)}
                                  disabled={id ? true : false}
                                />
                              </span>
                              <span className="ms-5">
                                <span className="fs-4 fw-bolder text-gray-800 d-block">{t("form.authType.ad")}</span>
                              </span>
                            </label>
                          </div>
                        </>
                      )}
                      {authTypeData.authenticateDataBase && (
                        <>
                          <div className="col">
                            <label
                              className={`btn btn-outline btn-outline-dashed btn-outline-default d-flex text-start p-6 `}
                            >
                              <span className="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="authenticationType_1"
                                  value={1}
                                  checked={formik.values.authenticationType === 1}
                                  onChange={() => formik.setFieldValue("authenticationType", 1)}
                                  disabled={id ? true : false}
                                />
                              </span>
                              <span className="ms-5">
                                <span className="fs-4 fw-bolder text-gray-800 d-block">
                                  {t("form.authType.database")}
                                </span>
                              </span>
                            </label>
                          </div>
                        </>
                      )}
                    </div>
                    {formik.touched.authenticationType && formik.errors.authenticationType && (
                      <div className="row">
                        <div className="mt-3 text-danger fw-bold">
                          <div className="fv-help-block">{formik.errors.authenticationType}</div>
                          <div className="mt-3 text-danger fw-bold invalid-feedback"></div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="card-footer d-flex justify-content-end py-6 px-9">
                <Link className="btn btn-light btn-active-light-primary me-2" to="/security/usermanagement">
                  {t("crud.update.buttonCancel")}
                </Link>
                <SaveButton type="submit" loading={isLoading} />
              </div>
            </form>
          )}
          {activeTab === "groupsTab" && id && (
            <ProfileSelectorComponent userId={parseInt(id)} />
          )}
        </div>
      </MainLayout>
    </MasterLayout>
  );
}