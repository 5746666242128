import { http } from "common/helpers/axios/axios-helper";
import { ParecerViewModel } from "../models/parecer.model";

const basePath = "/parecer";

async function getParecerFiltered(page: number, limit: number, corbanQuery: string) {
  let paginationFilter = `_page=${page}&_limit=${limit}`;
  let queryFilter = "";
  if (corbanQuery) {
    queryFilter += `&Query=${corbanQuery}`;
  }

  return await http.get<ParecerViewModel[]>(`${basePath}?${paginationFilter}${queryFilter}`);
}


export const ParecerService = { getParecerFiltered };
