import * as Yup from 'yup';
import { Autocomplete, TextField } from '@mui/material';
import { ExecucaoAcaoViewModel, ExecucaoRoboViewModel, InconsistenciaAcaoJustificativaViewModel } from 'app/execucoes/model/execucoes.model';
import { InconsistenciasService } from 'app/execucoes/services/inconsistencias.service';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { ExecucoesService } from 'app/execucoes/services/execucoes.service';
import SaveButton from 'common/partials/SaveButton';

interface DeleteModalProps {
  acaoId: number | null;
  inconsistenciasSelecionadas: ExecucaoRoboViewModel[];
  open: boolean;
  onClose: () => void;
  fetchInconsistencias: () => void;
  handleDelete?: (event: any) => void;
}

export function ConfirmActionExecucoes(props: DeleteModalProps) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [inconsistencias, setInconsistencias] = useState<InconsistenciaAcaoJustificativaViewModel[]>([]);
  const [selectedInconsistencia, setSelectedInconsistencia] = useState<InconsistenciaAcaoJustificativaViewModel | null>(null);

  const formSchema = Yup.object().shape({
    justificativa: Yup
      .string()
      .when("isJustificativaObrigatoria",
        {
          is: true,
          then: Yup
            .string()
            .required(`${t('crud.validators.requiredField')}`)
            .min(50, "A justificativa deve ter no mínimo 50 caracteres.")
        }),
    inconsistenciaAcaoJustificativaId: Yup
      .number()
      .required(`${t('crud.validators.requiredField')}`),
  });

  let initialData: ExecucaoAcaoViewModel = {
    inconsistenciaAcaoJustificativaId: undefined,
    justificativa: undefined,
  };

  const formik = useFormik<ExecucaoAcaoViewModel>({
    enableReinitialize: true,
    initialValues: initialData,
    validationSchema: formSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        const body: ExecucaoAcaoViewModel = {
          inconsistenciaAcaoJustificativaId: values.inconsistenciaAcaoJustificativaId,
          justificativa: values.justificativa,
          execucoesRobos: props.inconsistenciasSelecionadas
        };

        if (props.acaoId === 1) {
          const response = await ExecucoesService.ReprocessarInconsistencia(body)
          if (response && !response.hasErrors) {
            toast.success("Inconsistência(s) reprocessada(s) com sucesso!");
          } else {
            toast.error("Erro ao reprocessar inconsistência(s). Tente novamente.");
          }
        } else if (props.acaoId === 2) {
          const response = await ExecucoesService.PularInconsistencia(body)
          if (response && !response.hasErrors) {
            toast.success("Inconsistência(s) pulada(s) com sucesso!");
          } else {
            toast.error("Erro ao pular inconsistência(s). Tente novamente.");
          }
        } else if (props.acaoId === 3) {
          const response = await ExecucoesService.CancelarInconsistencia(body)
          if (response && !response.hasErrors) {
            toast.success("Inconsistência(s) cancelada(s) com sucesso!");
          } else {
            toast.error("Erro ao cancelar inconsistência(s). Tente novamente.");
          }
        }
      } catch (error) {
        toast.error(t('crud.update.errorMessage'));
      } finally {
        formik.resetForm();
        props.onClose();
        props.fetchInconsistencias();
        setIsLoading(false);
      }
    },
  });

  const fetchJustificativas = async () => {
    setIsLoading(true);
    if (props.acaoId) {
      try {
        const response = await InconsistenciasService.getAllInconsistenciasAcaoJustificativa(props.acaoId);
        if (response && !response.hasErrors) {
          setInconsistencias(response.data);
        }
      } catch (err) {
        toast.error(t("crud.read.errorMessage"));
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (props.open && props.acaoId! > 0) {
      fetchJustificativas();
    }

    return () => {
      setSelectedInconsistencia(null);
      formik.resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  return (
    <Modal
      show={props.open}
      onHide={props.onClose}
      dialogClassName='modal-dialog-centered mw-400px h-auto'
    >
      <form onSubmit={formik.handleSubmit}>
        <div className="card">
          <div className="card-body">
            <div className="fs-4 fw-bold text-center mb-10">
              Tem certeza que deseja <span className="fw-bolder">{props.acaoId === 1 ? "REPROCESSAR" : props.acaoId === 2 ? "PULAR" : "CANCELAR"}</span> as execuções selecionadas?
            </div>
            <div className="row">
              <div className="col-12 mb-10">
                <label className="form-label">Justificativa</label>
                <Autocomplete
                  className="autocomplete-md"
                  options={inconsistencias}
                  getOptionLabel={option => option.descricao ? option.descricao : ""}
                  onChange={(_, value) => {
                    formik.setFieldValue("inconsistenciaAcaoJustificativaId", value?.id)
                    setSelectedInconsistencia(value)
                    if (value?.justificativaObrigatoria) {
                      formik.setFieldValue("isJustificativaObrigatoria", true);
                    } else {
                      formik.setFieldValue("isJustificativaObrigatoria", false);
                    }
                  }}
                  onInputChange={(_, value) => {
                    if (!value) {
                      formik.setFieldValue("inconsistenciaAcaoJustificativaId", undefined);
                      setSelectedInconsistencia(null);
                    }
                  }}
                  renderInput={(params) =>
                    <TextField
                      {...params}
                      placeholder="Selecione uma justificativa"
                    />
                  }
                />
                {formik.touched.inconsistenciaAcaoJustificativaId && formik.errors.inconsistenciaAcaoJustificativaId && (
                  <div className="mt-3 text-danger fw-bold">{formik.errors.inconsistenciaAcaoJustificativaId}</div>
                )}
              </div>
              <div className="col-12 mb-10">
                <label className={`form-label ${selectedInconsistencia?.justificativaObrigatoria ? "required" : ""}`}>Observação</label>
                <textarea
                  name="justificativa"
                  className="form-control form-control-solid"
                  rows={5}
                  required={selectedInconsistencia?.justificativaObrigatoria}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.justificativa && formik.errors.justificativa && (
                  <div className="mt-3 text-danger fw-bold">{formik.errors.justificativa}</div>
                )}
              </div>
              <div className="col-12">
                <div className="d-grid">
                  <SaveButton type="submit" label="Confirmar" loading={isLoading} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  )
}
