import { http } from 'common/helpers/axios/axios-helper';
import { AmortizacaoViewModel } from '../model/amortizacao.model';

const basePath = '/amortizacao';

async function getAllAmortizacao() {
  return await http.get<AmortizacaoViewModel[]>(`${basePath}`);
}

export const AmortizacaoService = { getAllAmortizacao };
