import i18next from "i18next";
import DataTable, { TableColumn } from "react-data-table-component";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { tableHelper } from "common/helpers/table/table-helper";
import {
  MasterLayout,
  MainLayout,
  ToolbarLayout,
  ToolbarLayoutLeft,
  ToolbarLayoutRight,
} from "common/imports/content-layout";
import { itlabMediumTableStyle } from "common/helpers/table/table-style";
import en from "../i18n/en-us.json";
import pt from "../i18n/pt-br.json";
import { CorbansService as Service } from "../services/corbans.service";
import { CorbanViewModel } from "app/lotes-contratacao/model/lotes-contratacao.model";
import ListActionsButtons from "common/partials/ListActionsButtons";
import { useNavigate } from "react-router-dom";
import { Autocomplete, TextField, debounce } from "@mui/material";
import { ITSVG } from "common/helpers/ITSVG";
import searchIcon from "assets/images/icons/search.svg";

interface SituacaoModel {
  name: string;
  value: number;
}

interface ElegivelModel {
  name: string;
  value: string;
}

const situacaoOptions: SituacaoModel[] = [
  {
    name: "Ativo",
    value: 1
  },
  {
    name: "Inativo",
    value: 2
  },
  {
    name: "Suspenso",
    value: 3
  }
]

const elegivelOptions: ElegivelModel[] = [
  {
    name: "Todos",
    value: ""
  },
  {
    name: "Elegível",
    value: "true"
  },
  {
    name: "Não Elegível",
    value: "false"
  }
]

export function Corbans() {
  i18next.addResourceBundle("us", "translation", en);
  i18next.addResourceBundle("br", "translation", pt);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [tableData, setTableData] = useState<CorbanViewModel[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalRows, setTotalRows] = useState(0);
  const [corbanSearchValue, setCorbanSearchValue] = useState("");
  const [cnpjSearchValue, setCnpjSearchValue] = useState("");
  const [situacaoSearchValue, setSituacaoSearchValue] = useState<number | null>(null);
  const [elegivelSearchValue, setElegivelSearchValue] = useState<string | null>(null);

  const handleCorbanSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCorbanSearchValue(e.target.value);
  };

  const handleDebouncedCorbanSearch = debounce(handleCorbanSearchChange, 800);

  const handleCnpjSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCnpjSearchValue(e.target.value);
  };

  const handleDebouncedCnpjSearch = debounce(handleCnpjSearchChange, 800);

  const handlePageChange = (page: number) => {
    fetchData(page - 1);
  };

  const handlePerRowsChange = async (newPerPage: number) => {
    setRowsPerPage(newPerPage);
  };

  const tableColumns: TableColumn<CorbanViewModel>[] = [
    {
      name: "IdLoja",
      selector: (row) => row?.codigoOrigem || "",
      sortable: true,
      width: "100px",
    },
    {
      name: `${t("corban.list.table.headers.cnpj")}`,
      selector: (row) => row?.cnpj?.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5") || "",
      width: "180px",
    },
    {
      name: `${t("corban.list.table.headers.razaoSocial")}`,
      selector: (row) => row.razaoSocial || "",
      sortable: true,
    },
    {
      name: `${t("Grupo")}`,
      selector: (row) => row.grupoMutuo || "",
      sortable: true,     
    },
    {
      name: `${t("corban.list.table.headers.situacao")}`,
      cell: (row) => {
        if (row.situacao === 1) {
          return <span className="badge badge-light-primary ">{t("Ativo")}</span>;
        }
        if (row.situacao! === 2) {
          return <span className="badge badge-light-warning">{t("Inativo")}</span>;
        }
        if (row.situacao! === 3) {
          return <span className="badge badge-light-danger ">{t("Suspenso")}</span>;
        }
      },
      center: true,
      width: "150px",
    },
    {
      name: `${t("corban.list.table.headers.acoes")}`,
      cell: (row) => (
        <ListActionsButtons
          editButton
          editAction={() => navigate(`/cadastros/corban/${row.id}`)}
        />
      ),
      center: true,
      width: "100px",
    },
  ];

  const fetchData = async (page: number) => {
    setIsLoading(true);
    try {
      const response = await Service.getDataList(page, rowsPerPage, corbanSearchValue, cnpjSearchValue, elegivelSearchValue!, situacaoSearchValue!);
      if (response && !response.hasErrors) {
        setTableData(response.data);
        setTotalRows(response.totalItems);
      }
    } catch (err) {
      toast.error(t("crud.read.errorMessage"));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [corbanSearchValue, cnpjSearchValue, elegivelSearchValue, situacaoSearchValue, rowsPerPage]);

  return (
    <MasterLayout>
      <ToolbarLayout>
        <ToolbarLayoutLeft />
        <ToolbarLayoutRight />
      </ToolbarLayout>
      <MainLayout>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-12 col-sm-6 col-lg-3 col-xxl-3 mb-8">
                <div className="d-flex align-items-center position-relative">
                  <ITSVG path={searchIcon} className="svg-icon-1 position-absolute ms-6" />
                  <input
                    type="text"
                    className="form-control form-control-solid ps-14"
                    placeholder="Buscar"
                    onChange={handleDebouncedCorbanSearch}
                  />
                </div>
              </div>

              <div className="col-12 col-sm-6 col-lg-3 col-xxl-3 mb-8">
                <input
                  type="text"
                  className="form-control form-control-solid"
                  placeholder="CNPJ"
                  onChange={handleDebouncedCnpjSearch}
                />
              </div>

              <div className="col-12 col-sm-6 col-lg-3 col-xxl-2 mb-8">
                <Autocomplete
                  className="autocomplete-md"
                  options={situacaoOptions}
                  getOptionLabel={option => option.name ? option.name : ""}
                  onChange={(_, value) => setSituacaoSearchValue(value?.value!)}
                  onInputChange={(_, value) => {
                    if (!value) {
                      setSituacaoSearchValue(null)
                    }
                  }}
                  renderInput={(params) =>
                    <TextField
                      {...params}
                      placeholder="Situação"
                    />
                  }
                />
              </div>

              <div className="col-12 col-sm-6 col-lg-3 col-xxl-2 mb-8">
                <Autocomplete
                  className="autocomplete-md"
                  options={elegivelOptions}
                  getOptionLabel={option => option.name ? option.name : ""}
                  onChange={(_, value) => setElegivelSearchValue(value?.value!)}
                  onInputChange={(_, value) => {
                    if (!value) {
                      setElegivelSearchValue(null)
                    }
                  }}
                  renderInput={(params) =>
                    <TextField
                      {...params}
                      placeholder="Elegível"
                    />
                  }
                />
              </div>
            </div>

            <DataTable
              persistTableHead
              columns={tableColumns}
              data={tableData}
              progressPending={isLoading}
              progressComponent={tableHelper.getLoader()}
              noDataComponent={tableHelper.getNoDataComponent()}
              dense
              customStyles={itlabMediumTableStyle}
              striped
              highlightOnHover
              contextMessage={tableHelper.getContextMessage()}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePerRowsChange}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationPerPage={rowsPerPage}
              paginationRowsPerPageOptions={[10, 25, 50, 100]}
              paginationComponentOptions={tableHelper.getPaginationComponentOptions()}
            />
          </div>
        </div>
      </MainLayout>
    </MasterLayout>
  );
}
