import { http } from 'common/helpers/axios/axios-helper';
import { IFilterRelatorioBaseAnalitica } from '../model/filterRelatorioBaseAnalitica.model';
import { IRelatorioBaseAnalitica } from '../model/relatorioBaseAnalitica.model';
import { axiosInstance } from 'common/helpers/axios/axios-instance';
import { IFilterRelatorioCarteiraContratosMutuo } from '../model/filterRelatorioCarteiraContratosMutuo.model';
import { IRelatorioCarteiraContratosMutuo } from '../model/relatorioCarteiraContratosMutuo.model';
import { IFilterRelatorioAnaliticoCalculoApurado } from '../model/filterRelatorioAnaliticoCalculoApurado.model';
import { IRelatorioAnaliticoCalculoApurado } from '../model/relatorioAnaliticoCalculoApurado.model';
import { StatusContratacaoViewModel } from '../model/status.model';
import { IFilterRelatorioContratosMutuoCorban } from '../model/filterRelatorioContratosMutuoCorban.model';
import { IRelatorioContratosMutuoCorban } from '../model/relatorioContratosMutuoCorban.model';
import { IFilterRelatorioContratosConsignados } from '../model/filterRelatorioContratosConsignados.model';
import { IRelatorioContratosConsignados } from '../model/relatorioContratosConsignados.model';

const basePath = '/relatorio';

const formatQueryStringRelatorioBaseAnalitica = (request: IFilterRelatorioBaseAnalitica) => {
  let queryString = `_page=${request.page}&_limit=${request.limit}`;
  if (request.lotesContratacaoId) {
    queryString += `&lotesContratacaoId=${request.lotesContratacaoId}`;
  }
  if (request.safraMutuoInicial && request.safraMutuoFinal) {
    queryString += `&SafraMutuoInicial=${request.safraMutuoInicial}&SafraMutuoFinal=${request.safraMutuoFinal}`;
  }
  if (request.contratoMutuo) {
    queryString += `&contratoMutuo=${request.contratoMutuo}`;
  }
  if (request.contratoINSS) {
    queryString += `&contratoINSS=${request.contratoINSS}`;
  }
  if (request.idLoja) {
    queryString += `&idLoja=${request.idLoja}`;
  }
  if (request.operador) {
    queryString += `&operador=${request.operador}`;
  }
  if (request.grupo) {
    queryString += `&grupo=${request.grupo}`;
  }
  if (request.sub) {
    queryString += `&sub=${request.sub}`;
  }
  if (request.convenio) {
    queryString += `&convenio=${request.convenio}`;
  }
  if (request.valorTaxaMin) {
    queryString += `&valorTaxaMin=${request.valorTaxaMin}`;
  }
  if (request.valorTaxaMax) {
    queryString += `&valorTaxaMax=${request.valorTaxaMax}`;
  }
  if (request.numeroMesesMin) {
    queryString += `&numeroMesesMin=${request.numeroMesesMin}`;
  }
  if (request.numeroMesesMax) {
    queryString += `&numeroMesesMax=${request.numeroMesesMax}`;
  }
  if (request.nomeProduto) {
    queryString += `&nomeProduto=${request.nomeProduto}`;
  }
  return queryString;
};

const formatQueryStringRelatorioCarteiraContratosMutuo = (request: IFilterRelatorioCarteiraContratosMutuo) => {
  let queryString = `_page=${request.page}&_limit=${request.limit}`;
  if (request.lote) {
    queryString += `&lote=${request.lote}`;
  }
  if (request.safraMutuoInicial && request.safraMutuoFinal) {
    queryString += `&SafraMutuoInicial=${request.safraMutuoInicial}&SafraMutuoFinal=${request.safraMutuoFinal}`;
  }
  if (request.contratoMutuo) {
    queryString += `&contratoMutuo=${request.contratoMutuo}`;
  }
  if (request.idLoja) {
    queryString += `&idLoja=${request.idLoja}`;
  }
  if (request.operador) {
    queryString += `&operador=${request.operador}`;
  }
  if (request.grupo) {
    queryString += `&grupo=${request.grupo}`;
  }
  if (request.convenio) {
    queryString += `&convenio=${request.convenio}`;
  }
  if (request.percentualMutuoMin) {
    queryString += `&percentualMutuoMin=${request.percentualMutuoMin}`;
  }
  if (request.percentualMutuoMax) {
    queryString += `&percentualMutuoMax=${request.percentualMutuoMax}`;
  }
  if (request.numeroMesesMin) {
    queryString += `&numeroMesesMin=${request.numeroMesesMin}`;
  }
  if (request.numeroMesesMax) {
    queryString += `&numeroMesesMax=${request.numeroMesesMax}`;
  }
  if (request.statusId) {
    queryString += `&statusId=${request.statusId}`;
  }
  if (request.codigoRefCurvaLiquidacao) {
    queryString += `&codigoRefCurvaLiquidacao=${request.codigoRefCurvaLiquidacao}`;
  }
  return queryString;
};

const formatQueryStringRelatorioAnaliticoCalculoApurado = (request: IFilterRelatorioAnaliticoCalculoApurado) => {
  let queryString = `_page=${request.page}&_limit=${request.limit}`;
  if (request.lote) {
    queryString += `&lote=${request.lote}`;
  }
  if (request.contratoMutuo) {
    queryString += `&contratoMutuo=${request.contratoMutuo}`;
  }
  if (request.idLoja) {
    queryString += `&idLoja=${request.idLoja}`;
  }
  return queryString;
};

const formatQueryStringRelatorioContratosMutuoCorban = (request: IFilterRelatorioContratosMutuoCorban) => {
  let queryString = `_page=${request.page}&_limit=${request.limit}`;
  if (request.mesRef) {
    queryString += `&mesRef=${request.mesRef}`;
  }
  if (request.idLoja) {
    queryString += `&idLoja=${request.idLoja}`;
  }
  return queryString;
};

const formatQueryStringRelatorioContratosConsignados = (request: IFilterRelatorioContratosConsignados) => {
  let queryString = `_page=${request.page}&_limit=${request.limit}`;
  if (request.dataProducaoInicial && request.dataProducaoFinal) {
    queryString += `&DataProducaoInicial=${request.dataProducaoInicial}&DataProducaoFinal=${request.dataProducaoFinal}`;
  }
  if (request.contratoINSS) {
    queryString += `&contratoINSS=${request.contratoINSS}`;
  }
  if (request.idLoja) {
    queryString += `&idLoja=${request.idLoja}`;
  }
  if (request.operador) {
    queryString += `&operador=${request.operador}`;
  }
  if (request.sub) {
    queryString += `&sub=${request.sub}`;
  }
  if (request.convenio) {
    queryString += `&convenio=${request.convenio}`;
  }
  if (request.numeroMesesMin) {
    queryString += `&numeroMesesMin=${request.numeroMesesMin}`;
  }
  if (request.numeroMesesMax) {
    queryString += `&numeroMesesMax=${request.numeroMesesMax}`;
  }
  if (request.nomeProduto) {
    queryString += `&nomeProduto=${request.nomeProduto}`;
  }
  if (request.nomeTabela) {
    queryString += `&nomeTabela=${request.nomeTabela}`;
  }
  if (request.antecipado != null) {
    queryString += `&antecipado=${request.antecipado}`;
  }
  return queryString;
};

async function getRelatorioBaseAnalitica(request: IFilterRelatorioBaseAnalitica) {
  const queryString = formatQueryStringRelatorioBaseAnalitica(request);
  return await http.get<IRelatorioBaseAnalitica[]>(`${basePath}/base-analitica?${queryString}`);
};

const getUrlExportarRelatorioBaseAnalitica = async (request: IFilterRelatorioBaseAnalitica) => {
  const queryString = formatQueryStringRelatorioBaseAnalitica(request);
  return await axiosInstance.get<any>(`${basePath}/export/base-analitica?${queryString}`,  { responseType: "blob" });
};

async function getRelatorioCarteiraContratosMutuo(request: IFilterRelatorioCarteiraContratosMutuo) {
  const queryString = formatQueryStringRelatorioCarteiraContratosMutuo(request);
  return await http.get<IRelatorioCarteiraContratosMutuo[]>(`${basePath}/carteira-contratos-mutuo?${queryString}`);
};

const getExportarRelatorioCarteiraContratosMutuo = async (request: IFilterRelatorioCarteiraContratosMutuo) => {
  const queryString = formatQueryStringRelatorioCarteiraContratosMutuo(request);
  return await axiosInstance.get<any>(`${basePath}/export/carteira-contratos-mutuo?${queryString}`,  { responseType: "blob" });
};

async function getRelatorioAnaliticoCalculoApurado(request: IFilterRelatorioAnaliticoCalculoApurado) {
  const queryString = formatQueryStringRelatorioAnaliticoCalculoApurado(request);
  return await http.get<IRelatorioAnaliticoCalculoApurado[]>(`${basePath}/analitico-calculo-apurado?${queryString}`);
};

const getExportarRelatorioAnaliticoCalculoApurado = async (request: IFilterRelatorioCarteiraContratosMutuo) => {
  const queryString = formatQueryStringRelatorioAnaliticoCalculoApurado(request);
  return await axiosInstance.get<any>(`${basePath}/export/analitico-calculo-apurado?${queryString}`,  { responseType: "blob" });
};

async function getRelatorioContratosMutuoCorban(request: IFilterRelatorioContratosMutuoCorban) {
  const queryString = formatQueryStringRelatorioContratosMutuoCorban(request);
  return await http.get<IRelatorioContratosMutuoCorban>(`${basePath}/contratos-mutuo-corban?${queryString}`);
};

const getExportarRelatorioContratosMutuoCorban = async (request: IFilterRelatorioContratosMutuoCorban) => {
  const queryString = formatQueryStringRelatorioContratosMutuoCorban(request);
  return await axiosInstance.get<any>(`${basePath}/export/contratos-mutuo-corban?${queryString}`,  { responseType: "blob" });
};

async function getRelatorioContratosConsignados(request: IFilterRelatorioContratosConsignados) {
  const queryString = formatQueryStringRelatorioContratosConsignados(request);
  return await http.get<IRelatorioContratosConsignados[]>(`${basePath}/contratos-consignados?${queryString}`);
};

const getUrlExportarRelatorioContratosConsignados = async (request: IFilterRelatorioContratosConsignados) => {
  const queryString = formatQueryStringRelatorioContratosConsignados(request);
  return await axiosInstance.get<any>(`${basePath}/export/contratos-consignados?${queryString}`,  { responseType: "blob" });
};
const getStatusContratacao = async () => {
  return await http.get<StatusContratacaoViewModel[]>(`${basePath}/status-contratacao`);
};

export const RelatoriosService = {
  getRelatorioBaseAnalitica,
  getUrlExportarRelatorioBaseAnalitica,
  getRelatorioCarteiraContratosMutuo,
  getExportarRelatorioCarteiraContratosMutuo,
  getRelatorioAnaliticoCalculoApurado,
  getExportarRelatorioAnaliticoCalculoApurado,
  getStatusContratacao,
  getRelatorioContratosMutuoCorban,
  getExportarRelatorioContratosMutuoCorban,
  getRelatorioContratosConsignados,
  getUrlExportarRelatorioContratosConsignados,
};
