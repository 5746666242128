import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { ActiveDirectorySettingsModel as Model } from '../../model/general-settings.model';
import { GeneralSettingsService as Service } from '../../services/general-settings.service';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import SaveButton from 'common/partials/SaveButton';

export function ActiveDirectorySettings() {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const formSchema = Yup.object().shape({
    activeDirectoryDomain: Yup.string().required(t('validation.requiredField').toString()),
    activeDirectoryDN: Yup.string().required(t('validation.requiredField').toString()),
    activeDirectoryUsername: Yup.string().required(t('validation.requiredField').toString()),
    activeDirectoryPassword: Yup.string(),
  });

  let initialData: Model = {
    activeDirectoryType: 1,
    activeDirectoryDomain: '',
    activeDirectoryDN: '',
    activeDirectoryUsername: '',
    activeDirectoryPassword: '',
  };

  const [data, setData] = useState<Model>(initialData);

  const formik = useFormik<Model>({
    enableReinitialize: true,

    initialValues: data,
    validationSchema: formSchema,
    onSubmit: (values) => {
      setIsLoading(true);

      const postBody: Model = {
        activeDirectoryType: values.activeDirectoryType,
        activeDirectoryDomain: values.activeDirectoryDomain,
        activeDirectoryDN: values.activeDirectoryDN,
        activeDirectoryUsername: values.activeDirectoryUsername,
        activeDirectoryPassword: values.activeDirectoryPassword,
      };
      Service.putADSettings(postBody);
      toast.success(t('crud.update.successMessage'));
      setIsLoading(false);
    },
  });

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const settingsResponse = await Service.getADSettings();
        if (settingsResponse && !settingsResponse.hasErrors) {
          setData(settingsResponse.data);
        }
      } catch (err) {
        toast.error(t('crud.read.errorMessage'));
      } finally {
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <form onSubmit={formik.handleSubmit} className="form" autoComplete="off">
        <div className="card mb-5 mb-xl-10">
          <div
            className="card-header border-0 cursor-pointer"
            role="button"
            data-bs-toggle="collapse"
            data-bs-target="#kt_ad_settings"
            aria-expanded="true"
            aria-controls="kt_ad_settings"
          >
            <div className="card-title m-0">
              <h3 className="fw-bolder m-0">{t('generalSettings.ad.title')}</h3>
            </div>
          </div>

          <div id="kt_ad_settings" className="collapse show">
            <div className="card-body border-top p-9">
              <div className="row mb-6 d-flex align-items-center">
                <label className="col-lg-4 col-form-label required fw-bold fs-6">{t('generalSettings.ad.title')}</label>
                <div className="col-lg-8">
                  <div className="fv-row fv-plugins-icon-container">
                    <div className="fv-row">
                      <div className="row row-cols-1 row-cols-md-3 row-cols-lg-1 row-cols-xl-3 g-9">
                        <div className="col">
                          <label
                            className={`btn btn-outline btn-outline-dashed btn-outline-default d-flex text-start p-6 `}
                          >
                            <span className="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="activeDirectoryType_1"
                                value={1}
                                checked={formik.values.activeDirectoryType === 1}
                                onChange={() => formik.setFieldValue('activeDirectoryType', 1)}
                              />
                              {formik.touched.activeDirectoryType && formik.errors.activeDirectoryType && (
                                <div className="mt-3 text-danger fw-bold">
                                  <div className="fv-help-block">{formik.errors.activeDirectoryType}</div>
                                  <div className="mt-3 text-danger fw-bold invalid-feedback"></div>
                                </div>
                              )}
                            </span>
                            <span className="ms-5">
                              <span className="fs-4 fw-bolder text-gray-800 d-block">
                                {t('generalSettings.ad.localOpt')}
                              </span>
                            </span>
                          </label>
                        </div>
                        <div className="col">
                          <label
                            className={`btn btn-outline btn-outline-dashed btn-outline-default d-flex text-start p-6 $`}
                          >
                            <span className="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="activeDirectoryType_2"
                                value={2}
                                checked={formik.values.activeDirectoryType === 2}
                                onChange={() => formik.setFieldValue('activeDirectoryType', 2)}
                              />
                              {formik.touched.activeDirectoryType && formik.errors.activeDirectoryType && (
                                <div className="mt-3 text-danger fw-bold">
                                  <div className="fv-help-block">{formik.errors.activeDirectoryType}</div>
                                  <div className="mt-3 text-danger fw-bold invalid-feedback"></div>
                                </div>
                              )}
                            </span>
                            <span className="ms-5">
                              <span className="fs-4 fw-bolder text-gray-800 d-block">
                                {t('generalSettings.ad.serverOpt')}
                              </span>
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mb-6">
                <label className="col-lg-4 col-form-label required fw-bold fs-6">
                  {t('generalSettings.ad.adDomain')}
                </label>

                <div className="col-lg-8">
                  <div className="row">
                    <div className="col-lg-12 fv-row">
                      <input
                        type="text"
                        name="activeDirectoryDomain"
                        className="form-control form-control-lg form-control-solid"
                        placeholder={t('generalSettings.ad.adDomain').toString()}
                        value={formik.values.activeDirectoryDomain ?? ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      // {...formik.getFieldProps('activeDirectoryDomain')}
                      />
                      {formik.touched.activeDirectoryDomain && formik.errors.activeDirectoryDomain && (
                        <div className="mt-3 text-danger fw-bold">
                          <div className="fv-help-block">{formik.errors.activeDirectoryDomain}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mb-6">
                <label className="col-lg-4 col-form-label required fw-bold fs-6">{t('generalSettings.ad.adDN')}</label>

                <div className="col-lg-8">
                  <div className="row">
                    <div className="col-lg-12 fv-row">
                      <input
                        type="text"
                        name="activeDirectoryDN"
                        className="form-control form-control-lg form-control-solid"
                        placeholder={t('generalSettings.ad.adDN').toString()}
                        value={formik.values.activeDirectoryDN ?? ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      // {...formik.getFieldProps('activeDirectoryDN')}
                      />
                      {formik.touched.activeDirectoryDN && formik.errors.activeDirectoryDN && (
                        <div className="mt-3 text-danger fw-bold">
                          <div className="fv-help-block">{formik.errors.activeDirectoryDN}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mb-6">
                <label className="col-lg-4 col-form-label required fw-bold fs-6">
                  {t('generalSettings.ad.adUsername')}
                </label>

                <div className="col-lg-8">
                  <div className="row">
                    <div className="col-lg-12 fv-row">
                      <input
                        type="text"
                        name="activeDirectoryUsername"
                        className="form-control form-control-lg form-control-solid"
                        placeholder={t('generalSettings.ad.adUsername').toString()}
                        value={formik.values.activeDirectoryUsername ?? ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      // {...formik.getFieldProps('activeDirectoryUsername')}
                      />
                      {formik.touched.activeDirectoryUsername && formik.errors.activeDirectoryUsername && (
                        <div className="mt-3 text-danger fw-bold">
                          <div className="fv-help-block">{formik.errors.activeDirectoryUsername}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mb-6">
                <label className="col-lg-4 col-form-label required fw-bold fs-6">
                  {t('generalSettings.ad.adPassword')}
                </label>

                <div className="col-lg-8">
                  <div className="row">
                    <div className="col-lg-12 fv-row">
                      <input
                        type="password"
                        name="activeDirectoryPassword"
                        className="form-control form-control-lg form-control-solid"
                        placeholder={t('generalSettings.ad.adPassword').toString()}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        autoComplete="new-password"
                      // {...formik.getFieldProps('activeDirectoryPassword')}
                      />
                      {formik.touched.activeDirectoryPassword && formik.errors.activeDirectoryPassword && (
                        <div className="mt-3 text-danger fw-bold">
                          <div className="fv-help-block">{formik.errors.activeDirectoryPassword}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card-footer d-flex justify-content-end py-6 px-9">
              <button type="reset" className="btn btn-white btn-active-light-primary me-2">{t('crud.create.buttonCancel')}</button>
              <SaveButton type="submit" loading={isLoading} />
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
