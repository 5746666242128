import { ExecucaoControleFluxoViewModel, ExecucaoListViewModel, ExecucaoLogListViewModel } from "app/execucoes/model/execucoes.model";
import { http } from "common/helpers/axios/axios-helper";
import {
  LotesContratacaoCorbanContratoAntecipadoViewModel,
  LotesContratacaoCorbanListViewModel,
  LotesContratacaoCorbanParcelaDetailViewModel,
  LotesContratacaoDetailViewModel,
  LotesContratacaoViewModel
} from "../model/lotes-contratacao.model";

const basePath = "/loteContratacao";

async function getLotesContratacaoFiltered(page: number, limit: number, dataInicial: string, dataFinal: string) {
  let paginationFilter = `_page=${page}&_limit=${limit}`;
  let dateFilter = "";

  if (dataInicial !== "" && dataFinal !== "") {
    dateFilter = `&DataInicial=${dataInicial}&DataFinal=${dataFinal}`;
  }

  return await http.get<LotesContratacaoViewModel[]>(`${basePath}?${paginationFilter}${dateFilter}`);
}

async function getLoteContratacaoById(id: string) {
  return await http.get<LotesContratacaoDetailViewModel>(`${basePath}/${id}`);
}

async function getCorbanLoteContratacaoById(loteId: string, corbanId: string) {
  return await http.get<LotesContratacaoCorbanParcelaDetailViewModel>(`${basePath}/${loteId}/corbans/${corbanId}`);
}

async function getDetalhesLoteContratacaoFiltered(id: string, page: number, limit: number, query: string) {
  let queryFilter = `_page=${page}&_limit=${limit}`;
  if (query !== "") {
    queryFilter += `&Query=${query}`;
  }

  return await http.get<LotesContratacaoCorbanListViewModel[]>(`${basePath}/${id}/corbans?${queryFilter}`);
}

async function getContratosLoteContratacao(page: number, limit: number, loteId: string, corbanId: string) {
  let paginationFilter = `_page=${page}&_limit=${limit}`;

  return await http.get<LotesContratacaoCorbanContratoAntecipadoViewModel[]>(`${basePath}/${loteId}/corbans/${corbanId}/contratos?${paginationFilter}`);
}

async function getParcelasLoteContratacao(page: number, limit: number, loteId: string, corbanId: string) {
  let paginationFilter = `_page=${page}&_limit=${limit}`;

  return await http.get<LotesContratacaoCorbanParcelaDetailViewModel[]>(`${basePath}/${loteId}/corbans/${corbanId}/parcelas?${paginationFilter}`);
}

async function getLoteContratacaoExecucoes(page: number, limit: number, id: string, query?: string, roboId?: number, roboFluxoId?: number) {
  let paginationFilter = `_page=${page}&_limit=${limit}`;

  let queryFilter = "";
  let roboIdFilter = "";
  let roboFluxoIdFilter = "";

  if (query !== "") queryFilter = `&Query=${query}`;
  if (roboId && roboId > 0) roboIdFilter = `&RoboId=${roboId}`;
  if (roboFluxoId && roboFluxoId > 0) roboFluxoIdFilter = `&RoboFluxoId=${roboFluxoId}`;

  return await http.get<ExecucaoListViewModel[]>(`${basePath}/${id}/inconsistencias?${paginationFilter}${queryFilter}${roboIdFilter}${roboFluxoIdFilter}`);
}

async function getLoteContratacaoExecucoesControleFluxo(page: number, limit: number, id: string, query: string, status: number[], roboId?: number, roboFluxoId?: number) {
  let paginationFilter = `_page=${page}&_limit=${limit}`;

  let queryFilter = "";
  let statusFilter = "";
  let roboIdFilter = "";
  let roboFluxoIdFilter = "";

  if (query !== "") queryFilter = `&Query=${query}`;
  if (roboId && roboId > 0) roboIdFilter = `&RoboId=${roboId}`;
  if (roboFluxoId && roboFluxoId > 0) roboFluxoIdFilter = `&RoboFluxoId=${roboFluxoId}`;

  if (status.length !== 0) {
    let statusFilterConcatenated = "&";
    status.forEach((value, index) => {
      if (index > 0) {
        statusFilterConcatenated += "&";
      }
      statusFilterConcatenated += `Status=${value}`;
    });

    if (statusFilterConcatenated !== "") {
      statusFilter = statusFilterConcatenated;
    }
  }

  return await http.get<ExecucaoLogListViewModel[]>(`${basePath}/${id}/execucoesLog?${paginationFilter}${queryFilter}${statusFilter}${roboIdFilter}${roboFluxoIdFilter}`);
}

async function getLoteContratacaoDetalhesExecucoesControleFluxo(id: string, execucaoId: string) {
  return await http.get<ExecucaoControleFluxoViewModel[]>(`${basePath}/${id}/execucoes/${execucaoId}/execucoesControleFluxo`);
}

async function deleteLoteContratacaoById(id: number) {
  return await http.del(`${basePath}/${id}`);
}

export const LotesContratacaoService = {
  getLoteContratacaoById,
  getCorbanLoteContratacaoById,
  getLotesContratacaoFiltered,
  getDetalhesLoteContratacaoFiltered,
  getContratosLoteContratacao,
  getParcelasLoteContratacao,
  getLoteContratacaoExecucoes,
  getLoteContratacaoExecucoesControleFluxo,
  getLoteContratacaoDetalhesExecucoesControleFluxo,
  deleteLoteContratacaoById
};
