import { http } from 'common/helpers/axios/axios-helper';
import { RoboViewModel } from '../model/execucoes.model';

const basePath = '/robo';

async function getAllRobos() {
  return await http.get<RoboViewModel[]>(`${basePath}`);
}

export const RobosService = { getAllRobos };
