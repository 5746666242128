import i18next from "i18next";
import moment from "moment";
import DataTable, { TableColumn } from "react-data-table-component";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { tableHelper } from "common/helpers/table/table-helper";
import {
  MasterLayout,
  MainLayout,
  ToolbarLayout,
  ToolbarLayoutLeft,
  ToolbarLayoutRight,
} from "common/imports/content-layout";
import { itlabMediumTableStyle } from "common/helpers/table/table-style";
import en from "../i18n/en-us.json";
import pt from "../i18n/pt-br.json";
import { ServicosPercentuaisService } from "../services/servicos-percentuais.service";
import { ConvenioService } from "app/convenios/services/convenio.service";
import { ServicoPercentualViewModel } from "../model/servicos-percentuais.model";
import { Autocomplete, TextField } from "@mui/material";
import { ConvenioViewModel } from "app/convenios/model/convenio.model";

export function Percentages() {
  i18next.addResourceBundle("us", "translation", en);
  i18next.addResourceBundle("br", "translation", pt);
  const { t } = useTranslation();
  const [tableData, setTableData] = useState<ServicoPercentualViewModel[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalRows, setTotalRows] = useState(0);
  const [convenios, setConvenios] = useState<ConvenioViewModel[]>([]);
  const [selectedConvenioId, setSelectedConvenioId] = useState<number | null>(null);

  const handlePageChange = (page: number) => {
    fetchData(page - 1);
  };

  const handlePerRowsChange = async (newPerPage: number) => {
    setRowsPerPage(newPerPage);
  };

  const tableColumns: TableColumn<ServicoPercentualViewModel>[] = [
    {
      name: `${t("Convênio")}`,
      selector: (row) => row.convenio?.nome || "",
      sortable: true,
      width: "100px",
    },
    {
      name: `${t("Código Condição")}`,
      selector: (row) => row.codigoCondicao || "",
      sortable: true,
      center: true,
      width: "150px",
    },
    {
      name: `${t("Serviço")}`,
      selector: (row) => row.servicoId || "",
      sortable: true,
      center: true,
    },
    {
      name: `${t("Número Parcelas")}`,
      selector: (row) => row.numeroParcelas || "",
      sortable: true,
      width: "150px",
      center: true,
    },
    {
      name: `${t("Prazo Mínimo")}`,
      selector: (row) => row.prazoMinimo || "",
      sortable: true,
      width: "130px",
      center: true,
    },
    {
      name: `${t("Prazo Máximo")}`,
      selector: (row) => row.prazoMaximo || "",
      sortable: true,
      width: "130px",
      center: true,
    },
    {
      name: `${t("% Diferido")}`,
      selector: (row) => row.percentualDiferido.toFixed(4).replace(".", ",") || "",
      sortable: true,
      right: true,
      width: "100px"
    },
    {
      name: `${t("% Antecipado")}`,
      selector: (row) => row.percentualAntecipado.toFixed(4).replace(".", ",") || "",
      sortable: true,
      right: true,
      width: "120px",
    },
    {
      name: `${t("Prazo")}`,
      selector: (row) => row.prazo || "",
      sortable: true,
      center: true,
      width: "100px",
    },
    {
      name: `${t("Taxa")}`,
      selector: (row) => (row.taxa!).toFixed(4).replace(".", ",") || "",
      sortable: true,
      right: true,
      width: "70px",
    },
    {
      name: `${t("Dia Pagamento")}`,
      selector: (row) => row.diaPagamento || "",
      sortable: true,
      center: true,
      width: "160px",
    },
    {
      name: `${t("Início Vigência")}`,
      selector: (row) =>
        moment(row.inicioVigencia)
          .locale(`${t("dateFormat.locale")}`)
          .format("L"),
      sortable: true,
      center: true,
      width: "150px",
    },
    {
      name: `${t("Final Vigência")}`,
      selector: (row) =>
        moment(row.finalVigencia)
          .locale(`${t("dateFormat.locale")}`)
          .format("L"),
      sortable: true,
      center: true,
      width: "130px",
    },
    {
      name: `${t("% QA")}`,
      selector: (row) => row.percentualQA.toFixed(4).replace(".", ",") || "",
      right: true,
      sortable: true,
      width: "70px"
    },
    {
      name: `${t("% Provisão")}`,
      selector: (row) => row.percentualProvisao.toFixed(4).replace(".", ",") || "",
      right: true,
      sortable: true,
      width: "100px"
    },
    {
      name: `${t("Taxa IOF Flat")}`,
      selector: (row) => (row.taxaIOFFlat).toFixed(4).replace(".", ",") || "",
      sortable: true,
      right: true,
      width: "130px",
    },
    {
      name: `${t("Taxa IOF Ano")}`,
      selector: (row) => (row.taxaIOFAno).toFixed(4).replace(".", ",") || "",
      sortable: true,
      right: true,
      width: "130px",
    },
    {
      name: `${t("Status Serviço")}`,
      selector: (row) => row.statusServico || "",
      sortable: true,
      width: "150px",
      center: true,
    },
    {
      name: `${t("Conta")}`,
      selector: (row) => row.conta || "",
      sortable: true,
      width: "80px"
    },
    {
      name: `${t("Serviço Tipo")}`,
      selector: (row) => row.servicoTipo || "",
      sortable: true,
      width: "130px"
    },
  ];

  const fetchData = async (page: number) => {
    try {
      setIsLoading(true);
      const response = await ServicosPercentuaisService.getDataList(page, rowsPerPage, selectedConvenioId);
      if (response && !response.hasErrors) {
        setTableData(response.data);
        setTotalRows(response.totalItems);
      }
    } catch (err) {
      toast.error(t("crud.read.errorMessage"));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchConvenios = async () => {
      const response = await ConvenioService.getAllConvenio();
      if (response && !response.hasErrors) {
        setConvenios(response.data);
      }
    };
    fetchConvenios();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    fetchData(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedConvenioId, rowsPerPage]);

  return (
    <MasterLayout>
      <ToolbarLayout>
        <ToolbarLayoutLeft />
        <ToolbarLayoutRight />
      </ToolbarLayout>
      <MainLayout>
        <div className="card card-flush">
          <div className="card-body">
            <div className="dataTables_wrapper dt-bootstrap4 no-footer">
              <div className="row">
                <div className="col-sm-6 col-md-4 col-xl-3 mb-10">
                  <Autocomplete
                    className="autocomplete-md"
                    options={convenios}
                    getOptionLabel={option => option.nome ? option.nome : ""}
                    onChange={(_, value) => setSelectedConvenioId(value?.id!)}
                    onInputChange={(_, value) => {
                      if (!value) {
                        setSelectedConvenioId(null)
                      }
                    }}
                    renderInput={(params) =>
                      <TextField
                        {...params}
                        placeholder={`Selecione o convênio`}
                      />
                    }
                  />
                </div>
              </div>
              <DataTable
                persistTableHead
                columns={tableColumns}
                data={tableData}
                progressPending={isLoading}
                progressComponent={tableHelper.getLoader()}
                noDataComponent={tableHelper.getNoDataComponent()}
                dense
                customStyles={itlabMediumTableStyle}
                striped
                highlightOnHover
                contextMessage={tableHelper.getContextMessage()}
                pagination
                paginationServer
                paginationPerPage={rowsPerPage}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}  
                paginationTotalRows={totalRows}
                paginationComponentOptions={tableHelper.getPaginationComponentOptions()}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
              />
            </div>
          </div>
        </div>
      </MainLayout>
    </MasterLayout>
  );
}
