import Logo from "assets/images/logos/app-logo.png";
import clsx from "clsx";

interface AppLogoProps {
  className?: string;
  height?: number;
}

export default function AppLogo({ height, className }: AppLogoProps) {
  return (
    <img
      className={clsx(className)}
      src={Logo}
      height={height}
      alt="App Logo"
    />
  )
}