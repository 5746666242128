// import { useTranslation } from "react-i18next";

export const currencyMask = (value: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  // const { t } = useTranslation();
  
  return (
    new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value)
  )
}


