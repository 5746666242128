import DataTable, { TableColumn } from "react-data-table-component";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { tableHelper } from "common/helpers/table/table-helper";
import {
  MasterLayout,
  MainLayout,
  ToolbarLayout,
  ToolbarLayoutLeft,
  ToolbarLayoutRight,
} from "common/imports/content-layout";
import { itlabMediumTableStyle } from "common/helpers/table/table-style";
import { RelatoriosService as Service } from "../services/relatorios.service";
import moment from 'moment';
import 'moment/locale/pt-br';
import { currencyMask } from 'common/helpers/masks/CurrencyMask';
import { useFormik } from "formik";
import * as Yup from "yup";
import SaveButton from "common/partials/SaveButton";
import { useDownloadFile } from "common/helpers/download-file/useDownloadFile";
import { IFilterRelatorioContratosMutuoCorban } from "../model/filterRelatorioContratosMutuoCorban.model";
import { IRelatorioContratosMutuoCorbanDetail, IRelatorioContratosMutuoCorbanHeader } from "../model/relatorioContratosMutuoCorban.model";
import { DatePicker } from "antd";
import locale from "antd/es/date-picker/locale/pt_BR";
import dayjs from "dayjs";

export function RelatorioContratosMutuoCorban() {
  const { t } = useTranslation();
  const [headerData, setHeaderData] = useState<IRelatorioContratosMutuoCorbanHeader>({});
  const [tableData, setTableData] = useState<IRelatorioContratosMutuoCorbanDetail[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalRows, setTotalRows] = useState(0);
  const [isGerarRelatorio, setIsGerarRelatorio] = useState(false);
  const dateFormat = "YYYY-MM";
  const { MonthPicker } = DatePicker;
  const [mesRef, setMesRef] = useState(moment().locale(`${t('dateFormat.locale')}`).format('YYYY-MM'));
  let initialData: IFilterRelatorioContratosMutuoCorban = {
    mesRef: moment().locale(`${t('dateFormat.locale')}`).format('YYYY-MM'),
    idLoja: "",
    page: page,
    limit: rowsPerPage,
  };
  const [filter, setFilter] = useState<IFilterRelatorioContratosMutuoCorban>(initialData);

  const handlePageChange = (page: number) => {
    filter.page = page - 1
    fetchData();
  };

  const handlePerRowsChange = async (newPerPage: number) => {
    setRowsPerPage(newPerPage);
  };

  const handleDate = (date: any) => {
    setMesRef("");

    if (date === null) {
      setMesRef("");
      formik.setFieldValue("mesRef", undefined);
    }

    if (date) {
      const data = dayjs(date).format("YYYY-MM");

      setMesRef(data);
      formik.setFieldValue("mesRef", data);
    }
  };
  const tableColumns: TableColumn<IRelatorioContratosMutuoCorbanDetail>[] = [
    {
      name: `${t("Contrato Mutuo")}`,
      selector: (row) => row?.contratoMutuo || "",
      width: "130px",
    },
    {
      name: `${t("Taxa")}`,
      selector: (row) => (row.percentualMutuo ?? 0).toFixed(4).replace('.', ','),
      right: true,
      minWidth: "120px"
    },
    {
      name: `${t("Prazo")}`,
      selector: (row) => row.prazo ?? "",
      right: true,
      minWidth: "120px"
    },
    {
      name: `${t("Saldo em Aberto")}`,
      selector: (row) => row.saldoDevedor ? currencyMask(row.saldoDevedor) : "",
      right: true,
      minWidth: "120px"
    },
    {
      name: `${t("Vlr Antecip.")}`,
      selector: (row) => row.valorMutuo ? currencyMask(row.valorMutuo) : "",
      right: true,
      minWidth: "160px"
    },
    {
      name: `${t("% Antecip.")}`,
      selector: (row) => (row.percentualAntecipado ?? 0).toFixed(4).replace('.', ','),
      right: true,
      minWidth: "120px"
    },
    {
      name: `${t("Vlr INSS")}`,
      selector: (row) => row.valorOperacional ? currencyMask(row.valorOperacional) : "",
      right: true,
      minWidth: "120px"
    },
    {
      name: `${t("Garantia Total")}`,
      selector: (row) => row.valorDiferido? currencyMask(row.valorDiferido) : "",
      right: true,
      minWidth: "160px"
    },
    {
      name: `${t("% Diferido")}`,
      selector: (row) => (row.percentualDiferido ?? 0).toFixed(4).replace('.', ','),
      right: true,
      minWidth: "180px"
    },
  ];

  const formSchema = Yup.object().shape({
    mesRef: Yup
      .string()
      .required(`${t("crud.validators.requiredField")}`),
    idLoja: Yup
      .string()
      .required(`${t("crud.validators.requiredField")}`),
  });

  const formik = useFormik<IFilterRelatorioContratosMutuoCorban>({
    enableReinitialize: true,
    initialValues: filter,
    validationSchema: formSchema,
    onSubmit: async (values) => {
      console.log(mesRef, moment(mesRef).locale(`${t('dateFormat.locale')}`).format('YYYY-MM'))
      filter.mesRef = moment(mesRef).locale(`${t('dateFormat.locale')}`).format('YYYY-MM');
      filter.idLoja = values.idLoja;

      fetchData();
    }
  });

  const fetchData = async () => {
    setFilter(filter);
    setIsLoading(true);
    try {
      const response = await Service.getRelatorioContratosMutuoCorban(filter);
      if (response && !response.hasErrors) {
        setHeaderData(response.data.header);
        setTableData(response.data.contratos);
        setTotalRows(response.totalItems);
      }
    } catch (err) {
      toast.error(t("crud.read.errorMessage"));
    } finally {
      setIsLoading(false);
    }
  };
  
  // INICIO: metodos para Exportação
  const preDownloading = () => setIsGerarRelatorio(true);
  const postDownloading = () => setIsGerarRelatorio(false);
  const onErrorDownloadFile = () => { setIsGerarRelatorio(false); };
  const downloadSampleCsvFile_2 = Service.getExportarRelatorioContratosMutuoCorban;
  const gerarRelatorio = async() => {
    const filterExport: IFilterRelatorioContratosMutuoCorban = {
      mesRef: formik.values.mesRef,
      idLoja: formik.values.idLoja,
      page: 0, 
      limit: 1048576 - 1
    };
    var date = moment(new Date()).format("YYYYMMDD_HHmmss");
    var fileName = `ContratosMutuoCorban_${formik.values.idLoja}_${formik.values.mesRef}_${date}.xlsx`;
    await useDownloadFile.download({
      apiDefinition: downloadSampleCsvFile_2,
      preDownloading,
      postDownloading,
      onError: onErrorDownloadFile,
      apiParameters: filterExport,
      getFileName: fileName,
      typeFile: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"
    });
  
  };
  // FIM: metodos para Exportação

  useEffect(() => {
    filter.limit = rowsPerPage
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage]);

  const renderHeader = () => {
    if (totalRows === 0) return (<div></div>);
    return (
      <div>
        <div className="border-0 mb-3">
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">{`MUTUO EG INSS - ${headerData?.mesRef ?? ""} - ${headerData?.loja ?? ""}`}</h3>
          </div>
        </div>
        <div className="border-top pt-5 mb-7">
          <div className="row">
            <div className="col-sm-3 mb-5">
              <label className="fw-bold text-muted">Saldo Total Consignado</label>
              <div className="fw-bolder fs-6 text-dark">{currencyMask(headerData?.saldoTotalMutuo) ?? ""}</div>
            </div>
            <div className="col-sm-3 mb-5">
              <label className="fw-bold text-muted">Total Antecipado</label>
              <div className="fw-bolder fs-6 text-dark">{headerData?.totalAntecipado ? currencyMask(headerData?.totalAntecipado) : ""}</div>
            </div>
            <div className="col-sm-3 mb-5">
              <label className="fw-bold text-muted">Parcelas Mutuo</label>
              <div className="fw-bolder fs-6 text-dark">{headerData?.parcelasMutuo ? currencyMask(headerData?.parcelasMutuo) : ""}</div>
            </div>
            <div className="col-sm-3 mb-5">
              <label className="fw-bold text-muted">Quantidade de contratos</label>
              <div className="fw-bolder fs-6 text-dark">{headerData?.qtdeContratos ?? ""}</div>
            </div>
            <div className="col-sm-3 mb-5">
              <label className="fw-bold text-muted">Saldo Conta Vinculada</label>
              <div className="fw-bolder fs-6 text-dark">{currencyMask(headerData?.saldoContaVinculada) ?? ""}</div>
            </div>
            <div className="col-sm-3 mb-5">
              <label className="fw-bold text-muted">Garantia</label>
              <div className="fw-bolder fs-6 text-dark">{currencyMask(headerData?.garantia) ?? ""}</div>
            </div>
            <div className="col-sm-3 mb-5">
              <label className="fw-bold text-muted">Garantia Receber</label>
              <div className="fw-bolder fs-6 text-dark">{currencyMask(headerData?.garantiaReceber) ?? ""}</div>
            </div>
            <div className="col-sm-3 mb-5">
              <label className="fw-bold text-muted">Garantia Liquidada</label>
              <div className="fw-bolder fs-6 text-dark">{currencyMask(headerData?.garantiaLiquidada) ?? ""}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <MasterLayout>
      <ToolbarLayout>
        <ToolbarLayoutLeft />
        <ToolbarLayoutRight />
      </ToolbarLayout>
      <MainLayout>
        <div className="card">
          <div className="card-body">
            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-sm-6 col-md-3 col-xxl-2 mb-10">
                  <label className="form-label required">{t("Mês Ref.")}</label>
                  <MonthPicker
                      className="form-control form-control-lg form-control-solid"
                      name="mesRef"
                      locale={locale}
                      format={dateFormat}
                      defaultValue={dayjs(formik.values.mesRef?.toString())}
                      onChange={(e) => {
                        if (e === null) {
                          formik.setTouched({ mesRef: true })
                        }
                        // formik.setFieldValue("mesRef", e) 
                        handleDate(e);
                      }}
                      onBlur={formik.handleBlur}
                    />
                  {formik.touched.mesRef && formik.errors.mesRef && (
                      <div className="mt-3 text-danger fw-bold">{formik.errors.mesRef}</div>
                    )}
                </div>
                <div className="col-sm-6 col-md-3 col-xxl-2 mb-10">
                  <label className="form-label required">{t("ID Loja")}</label>
                  <input
                    type="text"
                    name="idLoja"
                    value={formik.values.idLoja}
                    className="form-control form-control-solid" 
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur} 
                    />
                  {formik.touched.idLoja && formik.errors.idLoja && (
                      <div className="mt-3 text-danger fw-bold">{formik.errors.idLoja}</div>
                    )}
                </div>
              </div>
              <div className="card-footer d-flex justify-content-end py-6 px-9">
                <div className="col-xl-1 mb-5 mx-5">
                  <SaveButton
                    type="submit"
                    label={`Filtrar`}
                    loading={isLoading}
                    disabled={!formik.isValid || !formik.dirty}
                  />
                </div>
                <div className="col-xl-1 mb-5">
                  <SaveButton
                    type="button"
                    label={`Exportar`}
                    loading={isGerarRelatorio}
                    disabled={!formik.isValid || !formik.dirty}
                    onClick={gerarRelatorio}
                  />
                </div>
              </div>
            </form>

            {renderHeader()}
            <DataTable
              persistTableHead
              columns={tableColumns}
              data={tableData}
              progressPending={isLoading}
              progressComponent={tableHelper.getLoader()}
              noDataComponent={tableHelper.getNoDataComponent()}
              dense
              customStyles={itlabMediumTableStyle}
              striped 
              highlightOnHover
              contextMessage={tableHelper.getContextMessage()}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePerRowsChange}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationPerPage={rowsPerPage}
              paginationRowsPerPageOptions={[10, 25, 50, 100]}
              paginationComponentOptions={tableHelper.getPaginationComponentOptions()}
            />
        </div>
      </div>
      </MainLayout>
    </MasterLayout>
  );
}
