import { http } from 'common/helpers/axios/axios-helper';
import { InconsistenciaAcaoJustificativaViewModel, InconsistenciaAcaoViewModel } from '../model/execucoes.model';

const basePath = '/InconsistenciaAcao';

async function getAllInconsistenciasAcao() {
  return await http.get<InconsistenciaAcaoViewModel[]>(`${basePath}`);
}

async function getAllInconsistenciasAcaoJustificativa(acaoId: number) {
  return await http.get<InconsistenciaAcaoJustificativaViewModel[]>(`${basePath}/${acaoId}/Justificativas`);
}

export const InconsistenciasService = {
  getAllInconsistenciasAcao,
  getAllInconsistenciasAcaoJustificativa,
};