import { TiposCurvaViewModel } from 'app/tipo-curva/model/tipo-curva.model';
import { http } from 'common/helpers/axios/axios-helper';
import { ConvenioViewModel } from '../model/convenio.model';

const basePath = '/convenio';

async function getAllConvenio() {
  return await http.get<ConvenioViewModel[]>(`${basePath}`);
}

async function getConvenioById(id: string) {
  return await http.get<ConvenioViewModel>(`${basePath}/${id}`);
}

async function getTiposCurvasById(id: number) {
  return await http.get<TiposCurvaViewModel[]>(`${basePath}/${id}/tiposCurvas`);
}

export const ConvenioService = {
  getAllConvenio,
  getConvenioById,
  getTiposCurvasById
};
