import i18next from "i18next";
import DataTable, { TableColumn } from "react-data-table-component";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { tableHelper } from "common/helpers/table/table-helper";
import { itLabTableStyle } from "common/helpers/table/table-style";
import en from "../../i18n/en-us.json";
import pt from "../../i18n/pt-br.json";
import { IConta as Model } from "../../model/linked-accounts.model";
import { CorbansService as Service } from "../../services/corbans.service";
import { useParams } from "react-router-dom";

export function LinkedAccounts() {
  const { id } = useParams();
  i18next.addResourceBundle("us", "translation", en);
  i18next.addResourceBundle("br", "translation", pt);
  const { t } = useTranslation();
  const [tableData, setTableData] = useState<Model[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const columns: TableColumn<Model>[] = [
    {
      name: `${t("corban.form.sessions.contaVinculada.table.headers.codigo")}`,
      selector: (row) => row?.id || "",
      sortable: true,
      width: "80px"
    },
    {
      name: `${t("corban.form.sessions.contaVinculada.table.headers.agencia")}`,
      selector: (row) => row?.agencia || "",
      sortable: true,
      center: true,
    },
    {
      name: `${t("Conta Corrente")}`,
      selector: (row) => row?.contaCorrente || "",
      sortable: true,
      center: true,
    },
    {
      name: `${t("corban.form.sessions.contaVinculada.table.headers.tipoConta")}`,
      selector: (row) => row?.tipoConta?.descricao || "",
      sortable: true,
      maxWidth: "300px"
    },
  ];

  const fetchData = async () => {
    setIsLoading(true);
    if (id) {
      const response = await Service.getAccountsByCorbans(Number(id));
      if (response && !response.hasErrors) {
        setTableData(response.data);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DataTable
      title={""}
      persistTableHead
      columns={columns}
      data={tableData}
      noDataComponent={tableHelper.getNoDataComponent()}
      pagination
      dense
      paginationComponentOptions={tableHelper.getPaginationComponentOptions()}
      highlightOnHover
      progressPending={isLoading}
      progressComponent={tableHelper.getLoader()}
      contextMessage={tableHelper.getContextMessage()}
      selectableRowsVisibleOnly={true}
      customStyles={itLabTableStyle}
    />
  );
}