import { useEffect, useState } from "react"
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import moment from "moment";
import { Autocomplete, TextField, debounce } from "@mui/material";
import DataTable, { TableColumn } from "react-data-table-component"
import { LotesContratacaoService } from "app/lotes-contratacao/services/lotes-contratacao.service";
import { RobosService } from "app/execucoes/services/robos.service";
import { ExecucaoLogListViewModel, RoboFluxoViewModel, RoboViewModel } from "app/execucoes/model/execucoes.model";
import { ITSVG } from "common/helpers/ITSVG";
import { tableHelper } from "common/helpers/table/table-helper";
import { itlabMediumTableStyle } from "common/helpers/table/table-style";
import searchIcon from "assets/images/icons/search.svg";

interface StatusOptions {
  id: number;
  nome: string;
}

const statusOptions: StatusOptions[] = [
  {
    id: 1,
    nome: "Reprocessado",
  },
  {
    id: 2,
    nome: "Ignorado",
  },
  {
    id: 3,
    nome: "Cancelado",
  }
]

function EvolucaoExecucoesTable() {
  const { loteId } = useParams();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState<ExecucaoLogListViewModel[]>([]);
  const [robos, setRobos] = useState<RoboViewModel[]>([]);
  const [robosFluxos, setRobosFluxos] = useState<RoboFluxoViewModel[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const [page] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [corbanSearchValue, setCorbanSearchValue] = useState("");
  const [statusSearchValue, setStatusSearchValue] = useState<number[]>([]);
  const [roboSearchValue, setRoboSearchValue] = useState<number | null>(null);
  const [roboFluxoSearchValue, setRoboFluxoSearchValue] = useState<number | null>(null);

  const handleCorbanSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCorbanSearchValue(e.target.value);
  };

  const handleDebouncedCorbanSearch = debounce(handleCorbanSearchChange, 800);

  const handlePageChange = (page: number) => {
    fetchData(page - 1);
  };

  const handlePerRowsChange = async (newPerPage: number) => {
    setRowsPerPage(newPerPage);
  };

  const tableColumns: TableColumn<ExecucaoLogListViewModel>[] = [
    {
      name: "Corban",
      selector: (row) => row?.nomeFantasia ?? "",
      sortable: true,
      width: "170px",
    },
    {
      name: `${t("Robô (Fluxo)")}`,
      cell: (row) => <>{row.nomeRobo ?? ""} ({row.nomeFluxo ?? ""})</>,
      wrap: false,
      compact: false,
      width: "450px",
    },
    {
      name: "Execução",
      selector: (row) =>
        moment(row.primeiraParcela)
          .locale(`${t("dateFormat.locale")}`)
          .format("L"),
      sortable: true,
      center: true,
      width: "130px"
    },
    {
      name: "Status",
      cell: (row) => row.status === 1 ? <span className="badge badge-light-primary ">Reprocessado</span> :
        row.status === 2 ? <span className="badge badge-light-warning ">Ignorado</span> :
          row.status === 3 && <span className="badge badge-light-danger ">Cancelado</span>
      ,
      center: true,
      width: "150px"
    },
    {
      name: "Mensagem",
      selector: (row) => row.mensagem || "",
      width: "300px"
    },
    {
      name: "Justificativa",
      selector: (row) => row.justificativa || "",
      width: "300px",
    },
    {
      name: "Usuário",
      selector: (row) => row.usuario || "",
      width: "300px"
    },
  ];

  const fetchData = async (page: number) => {
    setIsLoading(true);
    try {
      if (loteId) {
        const response = await LotesContratacaoService.getLoteContratacaoExecucoesControleFluxo(page, rowsPerPage, loteId, corbanSearchValue, statusSearchValue, roboSearchValue!, roboFluxoSearchValue!);
        if (response && !response.hasErrors) {
          setTableData(response.data);
          setTotalRows(response.totalItems);
        }
      }
    } catch (err) {
      toast.error(t("crud.read.errorMessage"));
    } finally {
      setIsLoading(false);
    }
  };

  const fetchRobos = async () => {
    setIsLoading(true);
    try {
      if (loteId) {
        const response = await RobosService.getAllRobos();
        if (response && !response.hasErrors) {
          setRobos(response.data);
        }
      }
    } catch (err) {
      toast.error(t("crud.read.errorMessage"));
    } finally {
      setIsLoading(false);
    }
  };

  const fetchRobosFluxos = async () => {
    setIsLoading(true);
    try {
      if (loteId) {
        const response = await RobosService.getAllRobos();
        if (response && !response.hasErrors) {
          setRobosFluxos(response.data);
        }
      }
    } catch (err) {
      toast.error(t("crud.read.errorMessage"));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchRobos();
    fetchRobosFluxos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchData(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, corbanSearchValue, statusSearchValue, roboSearchValue, roboFluxoSearchValue]);

  return (
    <>
      <div className="row mt-8">
        <div className="col-sm-6 col-md-3 mb-8">
          <div className="d-flex align-items-center position-relative">
            <ITSVG path={searchIcon} className={"svg-icon-1 position-absolute ms-6"} />
            <input
              type="text"
              className="form-control form-control-solid ps-14"
              placeholder="Buscar"
              name="searchInput"
              onChange={handleDebouncedCorbanSearch}
            />
          </div>
        </div>

        <div className="col-sm-6 col-md-3 mb-8">
          <Autocomplete
            className="autocomplete-md"
            options={robos}
            getOptionLabel={option => option.nome ? option.nome : ""}
            onChange={(_, value) => setRoboSearchValue(value?.id!)}
            onInputChange={(_, value) => {
              if (!value) {
                setRoboSearchValue(null)
              }
            }}
            renderInput={(params) =>
              <TextField
                {...params}
                placeholder="Filtrar por robô"
              />
            }
          />
        </div>

        <div className="col-sm-6 col-md-3 mb-8">
          <Autocomplete
            className="autocomplete-md"
            options={robosFluxos}
            getOptionLabel={option => option.nome ? option.nome : ""}
            onChange={(_, value) => setRoboFluxoSearchValue(value?.id!)}
            onInputChange={(_, value) => {
              if (!value) {
                setRoboFluxoSearchValue(null)
              }
            }}
            renderInput={(params) =>
              <TextField
                {...params}
                placeholder="Filtrar por fluxo do robô"
              />
            }
          />
        </div>

        <div className="col-sm-6 col-md-3 mb-8">
          <Autocomplete
            className="autocomplete-md"
            multiple
            options={statusOptions}
            getOptionLabel={option => option.nome ? option.nome : ""}
            onChange={(_, value) => {
              if (value) {
                const selectedIds = value.map(option => option.id);
                setStatusSearchValue(selectedIds);
              } else {
                setStatusSearchValue([]);
              }
            }}
            onInputChange={(_, value) => {
              if (!value) {
                setStatusSearchValue([])
              }
            }}
            renderInput={(params) =>
              <TextField
                {...params}
                placeholder="Filtrar por status"
              />
            }
          />
        </div>
      </div>

      <DataTable
        persistTableHead
        columns={tableColumns}
        data={tableData}
        progressPending={isLoading}
        progressComponent={tableHelper.getLoader()}
        noDataComponent={tableHelper.getNoDataComponent()}
        dense
        customStyles={itlabMediumTableStyle}
        striped
        highlightOnHover
        contextMessage={tableHelper.getContextMessage()}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerRowsChange}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        paginationPerPage={rowsPerPage}
        paginationRowsPerPageOptions={[5, 10, 25, 50, 100]}
        paginationComponentOptions={tableHelper.getPaginationComponentOptions()}
      />
    </>
  )
}

export default EvolucaoExecucoesTable;