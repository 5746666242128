import i18next from 'i18next';
import 'moment/locale/pt-br';
import DataTable, { TableColumn } from 'react-data-table-component';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { tableHelper } from 'common/helpers/table/table-helper';
import {
  MasterLayout,
  MainLayout,
  ToolbarLayout,
  ToolbarLayoutLeft,
  ToolbarLayoutRight,
} from 'common/imports/content-layout';
import { itlabMediumTableStyle } from 'common/helpers/table/table-style';
import en from '../i18n/en-us.json';
import pt from '../i18n/pt-br.json';
import { ConvenioService } from '../services/convenio.service';
import { ConvenioViewModel } from '../model/convenio.model';

export function Convenios() {
  i18next.addResourceBundle('us', 'translation', en);
  i18next.addResourceBundle('br', 'translation', pt);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState<ConvenioViewModel[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  const handlePerRowsChange = async (newPerPage: number) => {
    setRowsPerPage(newPerPage);
  };

  const columns: TableColumn<ConvenioViewModel>[] = [
    {
      name: `${t('convenio.list.table.headers.nome')}`,
      selector: (row) => row.nome || '',
      sortable: true,
    },
  ];

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await ConvenioService.getAllConvenio();
      if (response && !response.hasErrors) {
        setTableData(response.data);
      }
    } catch (err) {
      toast.error(t('crud.read.errorMessage'));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MasterLayout>
      <ToolbarLayout>
        <ToolbarLayoutLeft />
        <ToolbarLayoutRight />
      </ToolbarLayout>
      <MainLayout>
        <div className="card">
          <div className="card-body">
            <DataTable
              persistTableHead
              columns={columns}
              data={tableData}
              progressPending={isLoading}
              progressComponent={tableHelper.getLoader()}
              contextMessage={tableHelper.getContextMessage()}
              onChangeRowsPerPage={handlePerRowsChange}
              noDataComponent={tableHelper.getNoDataComponent()}
              dense
              striped
              highlightOnHover
              customStyles={itlabMediumTableStyle}
              pagination
              paginationPerPage={rowsPerPage}
              paginationRowsPerPageOptions={[10, 25, 50, 100]}
              paginationComponentOptions={tableHelper.getPaginationComponentOptions()}
            />
          </div>
        </div>
      </MainLayout>
    </MasterLayout>
  );
}
