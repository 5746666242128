import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { useNavigate } from 'react-router-dom';
import { APP_ROUTES } from 'routes/routes';
import { useEffect } from "react";
import { loginRequest } from "../sso/authConfig";
import { AuthService as Service } from 'common/helpers/auth/auth.service';

export function Auth() {
  const { instance } = useMsal();
  const navigate = useNavigate();

  const ProfileContent = () => {
    const { instance, accounts } = useMsal();

    useEffect(() => {
      RequestProfileData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function RequestProfileData() {
      // Silently acquires an access token which is then attached to a request for MS Graph data
      instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0]
      }).then((tokenResponse) => {
        logIn(tokenResponse.accessToken);
      });
    }

    const logIn = async (token: string) => {
      try {
        const response = await Service.authenticateSSO(token);
        if (response && !response.hasErrors) {
          Service.login(response.data);
          navigate('/' + APP_ROUTES.dashboard);
        } else {
          navigate('/' + APP_ROUTES.unauthorizedpage);
        }
      } catch (err) {
        navigate('/' + APP_ROUTES.unauthorizedpage);
      }
    };

    return (
      <>
        <h5 className="card-title">Welcome {accounts[0].name}</h5>
      </>
    );
  };

  const LoginContent = () => {

    useEffect(() => {
      instance.loginRedirect(loginRequest).catch(e => { console.log(e); });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
      <>
      </>
    );
  };

  return (
    <>
      <AuthenticatedTemplate>
        <ProfileContent />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LoginContent />
      </UnauthenticatedTemplate>
    </>
  );
}


