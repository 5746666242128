import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface Props {
  hideBreadcrumbs?: boolean;
  hideTitle?: boolean;
}

export function BreadcrumbsComponent(props: Props) {
  const { t } = useTranslation();

  const routes = [
    { path: '/', breadcrumb: `${t('menu.home')}` },
    { path: '/security', breadcrumb: `${t('menu.security')}` },
    { path: '/security/usermanagement', breadcrumb: `${t('menu.userManagement.title')}` },
    { path: '/security/usermanagement/add', breadcrumb: `${t('menu.userManagement.add')}` },
    { path: '/security/usermanagement/:id', breadcrumb: `${t('menu.userManagement.edit')}` },
    { path: '/security/groupmanagement', breadcrumb: `${t('menu.groupManagement.title')}` },
    { path: '/security/groupmanagement/add', breadcrumb: `${t('menu.groupManagement.add')}` },
    { path: '/security/groupmanagement/:id', breadcrumb: `${t('menu.groupManagement.edit')}` },
    { path: '/security/userprofile', breadcrumb: `${t('menu.userProfile')}` },
    { path: '/security/apiclients', breadcrumb: `${t('menu.apiClients')}` },
    { path: '/settings', breadcrumb: `${t('menu.settings')}` },
    { path: '/settings/healthcheck', breadcrumb: `${t('menu.healthCheck')}` },
    { path: '/settings/generalsettings', breadcrumb: `${t('menu.generalSettings')}` },
    { path: '/settings/apiClients', breadcrumb: `${t('menu.apiClients')}` },
    { path: '/samples', breadcrumb: `${t('menu.samples')}` },
    { path: '/samples/basic', breadcrumb: `${t('menu.basic.title')}` },
    { path: '/samples/basic/add', breadcrumb: `${t('menu.basic.add')}` },
    { path: '/samples/basic/:id', breadcrumb: `${t('menu.basic.edit')}` },
    { path: '/samples/complexdatabase', breadcrumb: `${t('menu.complexdatabase.title')}` },
    { path: '/samples/complexdatabase/add', breadcrumb: `${t('menu.complexdatabase.add')}` },
    { path: '/samples/complexdatabase/:id', breadcrumb: `${t('menu.complexdatabase.edit')}` },
    { path: '/samples/complexfrontend', breadcrumb: `${t('menu.complexfrontend.title')}` },
    { path: '/samples/complexfrontend/add', breadcrumb: `${t('menu.complexfrontend.add')}` },
    { path: '/samples/complexfrontend/:id', breadcrumb: `${t('menu.complexfrontend.edit')}` },
    { path: '/samples/detailscreen/:id', breadcrumb: `${t('menu.detailScreen')}` },
    { path: '/samples/detailscreen', breadcrumb: `${t('menu.detailScreen')}` },
    { path: '/samples/calendar', breadcrumb: `${t('menu.calendar')}` },
    { path: '/samples/categories', breadcrumb: `${t('menu.categories.title')}` },
    { path: '/samples/clients', breadcrumb: `${t('menu.clients.title')}` },
    { path: '/samples/clients/add', breadcrumb: `${t('menu.clients.add')}` },
    { path: '/samples/clients/:id', breadcrumb: `${t('menu.clients.edit')}` },
    { path: '/cadastros', breadcrumb: `${t('Configuração')}` },
    { path: '/cadastros', breadcrumb: `${t('Configuração')}` },
    { path: '/cadastros/lojas/:id', breadcrumb: `${t('Editar Lojas')}` },
    { path: '/cadastros/lojas/add', breadcrumb: `${t('Adicionar Lojas')}` },
    { path: '/cadastros/lojas', breadcrumb: `${t('Lojas')}` },
    { path: '/cadastros/corban/:id', breadcrumb: `${t('Editar Corban')}` },
    { path: '/cadastros/corban/add', breadcrumb: `${t('Adicionar Corban')}` },
    { path: '/cadastros/corban', breadcrumb: `${t('Corbans')}` },
    { path: '/cadastros/corban', breadcrumb: `${t('Corbans')}` },
    { path: '/cadastros/motivosBloqueio/:id', breadcrumb: `${t('Editar Motivos de Bloqueio Corban')}` },
    { path: '/cadastros/motivosBloqueio/add', breadcrumb: `${t('Adicionar Motivos de Bloqueio Corban')}` },
    { path: '/cadastros/motivosBloqueio', breadcrumb: `${t('Motivos de Bloqueio Corban')}` },
    { path: '/cadastros/percentuais', breadcrumb: `${t('Serviços Percentuais')}` },
    { path: '/cadastros/tipoCurva', breadcrumb: `${t('Tipos de Curva')}` },
    { path: '/cadastros/convenio', breadcrumb: `${t('Convênios')}` },
    { path: '/cadastros/convenio/:id', breadcrumb: `${t('Editar Convênio')}` },
    { path: '/cadastros/convenio/add', breadcrumb: `${t('Adicionar Convênio')}` },
    { path: '/cadastros/parametros', breadcrumb: "Parâmetros" },
    { path: '/cadastros/importacao', breadcrumb: "Importação" },
    { path: '/cadastros/importcontratos', breadcrumb: "Importação de Contratos" },
    { path: '/operacao', breadcrumb: `${t('Contratação')}` },
    { path: '/operacao/calculo', breadcrumb: `${t('Cálculo de Contratação')}` },
    { path: '/operacao/lotes', breadcrumb: `${t('Lotes')}` },
    { path: '/operacao/lotes/:id', breadcrumb: `${t('Detalhes do Lote')}` },
    { path: '/operacao/lotes/:id/corban/:id/contratos', breadcrumb: `${t('Contratos Consignados')}` },
    { path: '/operacao/lotes/:id/execucoes', breadcrumb: `${t('Execuções do Lote')}` },
    { path: '/operacao/lotes/:id/execucoes/:execucaoId', breadcrumb: `${t('Detalhes de Execução')}` },
    { path: '/relatorio', breadcrumb: `${t('Relatórios')}` },
    { path: '/relatorio/relatorio-base-analitica', breadcrumb: `${t('Base Analítica - Composição do Consignado')}` },
    { path: '/relatorio/relatorio-carteira-contratos-mutuo', breadcrumb: `${t('Base Analítica - Carteira Contratos Consignados')}` },
    { path: '/relatorio/relatorio-analitico-calculo-apurado', breadcrumb: `${t('Base Analítica - Analítico de Cálculo Apurado')}` },
    { path: '/relatorio/relatorio-contratos-mutuo-corban', breadcrumb: `${t('Relatório Unificado - Contratos Consignados Corban')}` },
  ];

  const breadcrumbs = useBreadcrumbs(routes, {
    excludePaths: [
      '/cadastros',
      '/operacao',
      '/operacao/lotes/:id/corban',
      '/operacao/lotes/:id/corban/:id',
    ]
  });

  return (
    <>
      <div className="d-flex align-items-center flex-wrap mb-lg-0">
        {!props.hideTitle && (
          <h1 className="d-flex align-items-center text-dark fw-boldest my-1 fs-3">
            {breadcrumbs[breadcrumbs.length - 1].breadcrumb}
          </h1>
        )}
        {!props.hideBreadcrumbs && (
          <>
            <span className="h-20px border-gray-200 border-start mx-3"></span>
            <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1">
              {breadcrumbs
                .map(({ match, index, breadcrumb }: any) => (
                  <li key={Math.random()} className="breadcrumb-item text-muted">
                    <Link className="text-muted text-hover-primary me-2" key={Math.random()} to={match.pathname}>
                      {breadcrumb}
                    </Link>
                    <span className="bullet bg-gray-400 w-5px h-2px me-1"></span>
                  </li>
                ))
                .slice(0, breadcrumbs.length - 1)}
              <li className="breadcrumb-item text-dark">{breadcrumbs[breadcrumbs.length - 1].breadcrumb}</li>
            </ul>
          </>
        )}
      </div>
    </>
  );
}
