import { useCallback, useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Autocomplete, TextField, debounce } from "@mui/material";
import DataTable, { TableColumn } from "react-data-table-component"
import { LotesContratacaoService } from "app/lotes-contratacao/services/lotes-contratacao.service";
import { RobosService } from "app/execucoes/services/robos.service";
import { ExecucaoListViewModel, ExecucaoRoboViewModel, RoboFluxoViewModel, RoboViewModel } from "app/execucoes/model/execucoes.model";
import { ITSVG } from "common/helpers/ITSVG";
import { tableHelper } from "common/helpers/table/table-helper";
import { itlabMediumTableStyle } from "common/helpers/table/table-style";
import { ConfirmActionExecucoes } from "app/execucoes/components/execucoes-lote/childs/ConfirmActionExecucoes";
import searchIcon from "assets/images/icons/search.svg";

function InconsistenciasTable() {
  const { loteId } = useParams();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState<ExecucaoListViewModel[]>([]);
  const [robosFluxos, setRobosFluxos] = useState<RoboFluxoViewModel[]>([]);
  const [robos, setRobos] = useState<RoboViewModel[]>([]);
  const [acaoInconsistenciaId, setAcaoInconsistenciaId] = useState<number | null>(null);
  const [inconsistenciasSelecionadas, setInconsistenciasSelecionadas] = useState<ExecucaoRoboViewModel[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const [page] = useState(0);
  const [selectedRows, setSelectedRows] = useState<ExecucaoListViewModel[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [isTableActionsModalOpen, setIsTableActionsModalOpen] = useState(false);
  const [corbanSearchValue, setCorbanSearchValue] = useState("");
  const [roboSearchValue, setRoboSearchValue] = useState<number | null>(null);
  const [roboFluxoSearchValue, setRoboFluxoSearchValue] = useState<number | null>(null);

  const rowDisabledCriteria = (row: ExecucaoListViewModel) => row.cancelado;

  const handleOpenTableActionsModal = () => {
    setIsTableActionsModalOpen(true)
  };
  const handleCloseTableActionsModal = () => {
    setAcaoInconsistenciaId(null)
    setIsTableActionsModalOpen(false)
    setToggleCleared(!toggleCleared);
    setSelectedRows([]);
  };

  const handleCorbanSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCorbanSearchValue(e.target.value);
  };

  const handleDebouncedCorbanSearch = debounce(handleCorbanSearchChange, 800);

  const handlePageChange = (page: number) => {
    fetchData(page - 1);
  };

  const handlePerRowsChange = async (newPerPage: number) => {
    setRowsPerPage(newPerPage);
  };

  const handleRowSelected = useCallback((state: any) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const tableColumns: TableColumn<ExecucaoListViewModel>[] = [
    {
      name: "Corban",
      selector: (row) => row?.nomeFantasia || "",
      width: "170px",
    },
    {
      name: `${t("Robô (Fluxo)")}`,
      cell: (row) => <>{row.nomeRobo ?? ""} ({row.nomeFluxo ?? ""})</>,
      width: "450px",
    },
    {
      name: `${t("Bloqueado")}`,
      cell: (row) => row.bloqueado ?
        <span className="badge badge-light-primary ">{t("Sim")}</span>
        :
        <span className="badge badge-light-warning">{t("Não")}</span>,
      center: true,
      width: "130px",
    },
    {
      name: `${t("Cancelado")}`,
      cell: (row) => row.cancelado ?
        <span className="badge badge-light-primary ">{t("Sim")}</span>
        :
        <span className="badge badge-light-warning">{t("Não")}</span>,
      center: true,
      width: "130px",
    },
    {
      name: `${t("Mensagem")}`,
      selector: (row) => row.mensagem || "",
      maxWidth: "600px",
    },
  ];

  const contextActions = useMemo(() => {
    const handleSelectedRows = (acaoId: number) => {
      setAcaoInconsistenciaId(acaoId)
      let selecionados: ExecucaoRoboViewModel[] = [];
      selectedRows.forEach((row: ExecucaoListViewModel) => {
        const inconsistenciaSelecionada: ExecucaoRoboViewModel = {
          execucaoId: row.id,
          roboId: row.roboId,
        }
        selecionados.push(inconsistenciaSelecionada);
      })
      setInconsistenciasSelecionadas(selecionados)
      handleOpenTableActionsModal()
    }

    return (
      <>
        <button type="button" className="btn btn-sm btn-primary me-2" onClick={() => handleSelectedRows(1)}>
          Reprocessar
        </button>
        <button type="button" className="btn btn-sm btn-warning me-2" onClick={() => handleSelectedRows(2)}>
          Pular
        </button>
        <button type="button" className="btn btn-sm btn-danger" onClick={() => handleSelectedRows(3)}>
          Cancelar
        </button>
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows, toggleCleared]);

  const fetchData = async (page: number) => {
    setIsLoading(true);
    try {
      if (loteId) {
        const response = await LotesContratacaoService.getLoteContratacaoExecucoes(page, rowsPerPage, loteId, corbanSearchValue, roboSearchValue!, roboFluxoSearchValue!);
        if (response && !response.hasErrors) {
          setTableData(response.data);
          setTotalRows(response.totalItems);
        }
      }
    } catch (err) {
      toast.error(t("crud.read.errorMessage"));
    } finally {
      setIsLoading(false);
    }
  };

  const fetchInconsistencias = () => {
    fetchData(page);
  }

  const fetchRobos = async () => {
    setIsLoading(true);
    try {
      if (loteId) {
        const response = await RobosService.getAllRobos();
        if (response && !response.hasErrors) {
          setRobos(response.data);
        }
      }
    } catch (err) {
      toast.error(t("crud.read.errorMessage"));
    } finally {
      setIsLoading(false);
    }
  };

  const fetchRobosFluxos = async () => {
    setIsLoading(true);
    try {
      if (loteId) {
        const response = await RobosService.getAllRobos();
        if (response && !response.hasErrors) {
          setRobosFluxos(response.data);
        }
      }
    } catch (err) {
      toast.error(t("crud.read.errorMessage"));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchRobos();
    fetchRobosFluxos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchData(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, corbanSearchValue, roboSearchValue, roboFluxoSearchValue]);

  return (
    <>
      <div className="row mt-8">
        <div className="col-sm-6 col-md-4 col-xxl-3 mb-8">
          <div className="d-flex align-items-center position-relative">
            <ITSVG path={searchIcon} className="svg-icon-1 position-absolute ms-6" />
            <input
              type="text"
              className="form-control form-control-solid ps-14"
              placeholder="Buscar"
              name="searchInput"
              onChange={handleDebouncedCorbanSearch}
            />
          </div>
        </div>

        <div className="col-sm-6 col-md-4 col-xxl-3 mb-8">
          <Autocomplete
            className="autocomplete-md"
            options={robos}
            getOptionLabel={option => option.nome ? option.nome : ""}
            onChange={(_, value) => setRoboSearchValue(value?.id!)}
            onInputChange={(_, value) => {
              if (!value) {
                setRoboSearchValue(null)
              }
            }}
            renderInput={(params) =>
              <TextField
                {...params}
                placeholder="Filtrar por robô"
              />
            }
          />
        </div>

        <div className="col-sm-6 col-md-4 col-xxl-3 mb-8">
          <Autocomplete
            className="autocomplete-md"
            options={robosFluxos}
            getOptionLabel={option => option.nome ? option.nome : ""}
            onChange={(_, value) => setRoboFluxoSearchValue(value?.id!)}
            onInputChange={(_, value) => {
              if (!value) {
                setRoboFluxoSearchValue(null)
              }
            }}
            renderInput={(params) =>
              <TextField
                {...params}
                placeholder="Filtrar por fluxo do robô"
              />
            }
          />
        </div>
      </div>

      <DataTable
        title={selectedRows.length === 0 ? false : <></>}
        persistTableHead
        columns={tableColumns}
        data={tableData}
        progressPending={isLoading}
        progressComponent={tableHelper.getLoader()}
        noDataComponent={tableHelper.getNoDataComponent()}
        contextActions={contextActions}
        contextMessage={tableHelper.getContextMessage()}
        dense
        striped
        highlightOnHover
        customStyles={itlabMediumTableStyle}
        selectableRows
        selectableRowsVisibleOnly={true}
        selectableRowDisabled={rowDisabledCriteria}
        onSelectedRowsChange={handleRowSelected}
        clearSelectedRows={toggleCleared}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        paginationPerPage={rowsPerPage}
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
        paginationComponentOptions={tableHelper.getPaginationComponentOptions()}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerRowsChange}
      />

      <ConfirmActionExecucoes
        acaoId={acaoInconsistenciaId}
        inconsistenciasSelecionadas={inconsistenciasSelecionadas}
        fetchInconsistencias={fetchInconsistencias}
        open={isTableActionsModalOpen}
        onClose={handleCloseTableActionsModal}
      />
    </>
  )
}

export default InconsistenciasTable;