import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import clsx from "clsx";
import { toast } from "react-toastify";
import appsettings from "appsettings.json";
import { AuthService as Service } from "common/helpers/auth/auth.service";
import { TopbarService } from "common/layout/components/header/topbar/services/Topbar.service";
import AppLogo from "common/partials/AppLogo";
import { AuthModel as Model } from "../model/login.model";

interface Props {
  onSucessfulLogin(): any;
  logo?: React.ReactElement;
}
export function Login({ onSucessfulLogin }: Props) {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const addMinutes = (date: Date, minutes: number) => {
    return new Date(date.getTime() + minutes * 60000);
  };

  const fetchTimeToTokenExpires = () => {
    (async () => {
      try {
        const response: any = await TopbarService.getUserInfo();
        if (response && !response.hasErrors) {
          let timeToTokenExpiresFormated = addMinutes(
            new Date(),
            response.data.data.accessTokenExpireTimeSpanInMinutes,
          );
          let string = timeToTokenExpiresFormated.toString();
          sessionStorage.setItem("tokenExpirationDate", string);
        }
      } catch (err) {
        console.log(err);
      }
    })();
  };

  const logIn = async (values: Model) => {
    setLoading(true);
    try {
      const response = await Service.authenticate(values.username, values.password);
      if (response && !response.hasErrors) {
        Service.login(response.data);
        toast.success("Login realizado com sucesso!");
        fetchTimeToTokenExpires();
        onSucessfulLogin();
      } else {
        const errors = response?.errorCode;
        toast.error(t(`error.${errors}`));
        setLoading(false);
      }
    } catch (err) {
      toast.error("Usuário ou senha inválidos.");
      setLoading(false);
    }
  };

  const formSchema = Yup.object().shape({
    username: Yup.string().required("Usuário é obrigatório."),
    password: Yup.string().required("Senha é obrigatória."),
  });

  const formik = useFormik<Model>({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: formSchema,
    onSubmit: logIn,
  });

  return (
    <form className="form w-100" onSubmit={formik.handleSubmit} noValidate id="kt_login_signin_form">
      <div className="text-center mb-10">
        <div className="mb-12">
          <AppLogo className="h-60px" />
        </div>
        <h1 className="text-dark mb-3">Bem-vindo ao {appsettings["application-name"]}</h1>
      </div>

      <div className="fv-row mb-10">
        <label className="form-label fs-6 fw-bolder text-dark">Usuário</label>
        <input
          placeholder="Nome de usuário"
          {...formik.getFieldProps("username")}
          className={clsx(
            "form-control form-control-lg form-control-solid",
            { "is-invalid": formik.touched.username && formik.errors.username },
            {
              "is-valid": formik.touched.username && !formik.errors.username,
            },
          )}
          type="text"
          name="username"
          autoComplete="off"
        />
        {formik.touched.username && formik.errors.username && (
          <div className="mt-3 text-danger fw-bold">
            <span role="alert">{formik.errors.username}</span>
          </div>
        )}
      </div>

      <div className="fv-row mb-10">
        <div className="d-flex justify-content-between mt-n5">
          <div className="d-flex flex-stack mb-2">
            <label className="form-label fw-bolder text-dark fs-6 mb-0">Senha</label>
          </div>
        </div>
        <input
          placeholder="Senha"
          type="password"
          autoComplete="off"
          {...formik.getFieldProps("password")}
          className={clsx(
            "form-control form-control-lg form-control-solid",
            {
              "is-invalid": formik.touched.password && formik.errors.password,
            },
            {
              "is-valid": formik.touched.password && !formik.errors.password,
            },
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className="mt-3 text-danger fw-bold">
            <div className="fv-help-block">
              <span role="alert">{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>

      <div className="text-center">
        <button
          type="submit"
          id="kt_sign_in_submit"
          className="btn btn-lg btn-primary w-100 mb-5"
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className="indicator-label">Continuar</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: "block" }}>
              Por favor, aguarde...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
    </form>
  );
}
