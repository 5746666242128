import { useTranslation } from 'react-i18next';
import filterIcon from 'assets/images/icons/filter.svg';
import { ITSVG } from 'common/helpers/ITSVG';

interface Props {
  handleStatusClick?: (event: any) => void;
  handleStatusChange?: (event: any) => void;
  handleCnpjChange?: (event: any) => void;
  handleBloqueioChange?: (event: any) => void;
  handleElegivelChange?: (event: any) => void;
  handleRazaoSocialChange?: (event: any) => void;
  handleCnpjCorbanChange?: (event: any) => void;
  handleCodLojaChange?: (event: any) => void;
  handleRazaoSocialCorbanChange?: (event: any) => void;
  filterStatus?: string;
  razaoSocial?: string;
  cnpj?: string;
  elegivel?: string;
  bloqueio?: string;
  codLoja?: string;
  razaoSocialCorban?: string;
  cnpjCorban?: string;
}

export function CorbansFilterDropdown(props: Props) {
  const { t } = useTranslation();

  return (
    <div className="me-4">
      <button
        type="button"
        className="btn btn-sm btn-flex btn-light btn-active-primary fw-bolder"
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end"
        data-kt-menu-flip="top-end"
      >
        <ITSVG path={filterIcon} className="svg-icon-5 svg-icon-gray-500 me-1" />
        {t('toolbar.filter')}
      </button>
      <div className="menu menu-sub menu-sub-dropdown w-250px w-md-300px" data-kt-menu="true">
        <div className="px-7 py-5">
          <div className="fs-5 text-dark fw-bolder">{t('toolbar.filterOptions')}</div>
        </div>
        <div className="separator border-gray-200"></div>
        <div className="px-7 py-5">
          <div className="">
            <label className=" form-label">{t('CNPJ')}</label>
            <input
              type="number"
              name="cnpj"
              className="form-control form-control-lg form-control-solid"
              placeholder={t('Filtre por CNPJ').toString()}
              value={props.cnpj}
              onChange={props.handleCnpjChange}
            />
          </div>
          <div className="mt-10">
            <label className=" form-label">{t('Razão Social / Nome Fantasia')}</label>
            <input
              type="text"
              name="razaoSocial"
              className="form-control form-control-lg form-control-solid"
              placeholder={t('Filtre por razão social').toString()}
              value={props.razaoSocial}
              onChange={props.handleRazaoSocialChange}
            />
          </div>
          <div className="mt-10">
            <label className="form-label fw-bold">{t('Elegivel')}</label>
            <div>
              <select
                className="form-select form-select-solid"
                data-kt-select2="true"
                data-placeholder="Select option"
                data-allow-clear="true"
                defaultValue={props.elegivel}
                onChange={props.handleElegivelChange}
              >
                <option value="">{t('Todos')}</option>
                <option value="true">{t('Sim')}</option>
                <option value="false">{t('Não')}</option>
              </select>
            </div>
          </div>
          <div className="mt-10">
            <label className="form-label fw-bold">{t('Bloqueio')}</label>
            <div>
              <select
                className="form-select form-select-solid"
                data-kt-select2="true"
                data-placeholder="Select option"
                data-allow-clear="true"
                defaultValue={props.bloqueio}
                onChange={props.handleBloqueioChange}
              >
                <option value="">{t('Todos')}</option>
                <option value="true">{t('Sim')}</option>
                <option value="false">{t('Não')}</option>
              </select>
            </div>
          </div>
          <div className="d-flex justify-content-end mt-10">
            <button
              type="reset"
              className="btn btn-sm btn-white btn-active-light-primary me-2"
              data-kt-menu-dismiss="true"
            >
              {t('toolbar.buttonCancel')}
            </button>
            <button
              type="submit"
              onClick={props.handleStatusClick}
              className="btn btn-sm btn-primary"
              data-kt-menu-dismiss="true"
            >
              {t('toolbar.buttonApply')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export function StatusFilterDropdown(props: Props) {
  const { t } = useTranslation();

  return (
    <div className="me-4">
      <button
        type="button"
        className="btn btn-sm btn-flex btn-light btn-active-primary fw-bolder"
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end"
        data-kt-menu-flip="top-end"
      >
        <ITSVG path={filterIcon} className="svg-icon-5 svg-icon-gray-500 me-1" />
        {t('crud.common.filter')}
      </button>
      <div className="menu menu-sub menu-sub-dropdown w-250px w-md-300px" data-kt-menu="true">
        <div className="px-7 py-5">
          <div className="fs-5 text-dark fw-bolder">{t('crud.common.filterOptions')}</div>
        </div>
        <div className="separator border-gray-200"></div>
        <div className="px-7 py-5">
          <div className="mb-10">
            <label className="form-label fw-bold">{t('crud.common.status')}</label>
            <div>
              <select
                className="form-select form-select-solid"
                defaultValue={props.filterStatus}
                onChange={props.handleStatusChange}
              >
                <option value="">{t('generalMessages.all')}</option>
                <option value="true">{t('generalMessages.active')}</option>
                <option value="false">{t('generalMessages.inactive')}</option>
              </select>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <button
              type="reset"
              className="btn btn-sm btn-white btn-active-light-primary me-2"
              data-kt-menu-dismiss="true"
            >
              {t('crud.common.cancel')}
            </button>
            <button
              type="submit"
              onClick={props.handleStatusClick}
              className="btn btn-sm btn-primary"
              data-kt-menu-dismiss="true"
            >
              {t('crud.common.apply')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

