import { http } from 'common/helpers/axios/axios-helper';
import { ServicoPercentualViewModel } from '../model/servicos-percentuais.model';

const basePath = '/servicoPercentual';

async function getDataList(page: number, limit: number, convenioId: number | null) {
  let queryString = `_page=${page}&_limit=${limit}`;
  if (convenioId) {
    queryString += `&convenioId=${convenioId}`;
  }

  return await http.get<ServicoPercentualViewModel[]>(`${basePath}?${queryString}`);
}

async function getDataById(id: string) {
  return await http.get<ServicoPercentualViewModel>(`${basePath}/${id}`);
}

export const ServicosPercentuaisService = { getDataList, getDataById };
