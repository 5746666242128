import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';
import { TokenHelper } from 'common/helpers/auth/token-helper';

interface Props {
  type: "submit" | "reset" | "button";
  loading: boolean;
  onClick?: () => void;
  disabled?: boolean;
  label?: string;
  roles?: number[];
}

function SaveButton(props: Props) {
  const { t } = useTranslation();
  const tokenHelper = new TokenHelper();

  return (
    <Tooltip
      color="orange"
      title={(props.roles && !tokenHelper.HasAccess(props.roles)) ? "Você não tem permissão para realizar esta ação." : ""}
    >
      <div>
        <button
          type={props.type}
          className="btn btn-primary"
          disabled={props.loading || props.disabled || (props.roles && !tokenHelper.HasAccess(props.roles))}
          onClick={(e) => props.onClick ? props.onClick() : e.stopPropagation()}
        >
          {!props.loading && (props.label ? props.label : t('crud.update.buttonSave'))}
          {props.loading && (
            <span className="indicator-progress" style={{ display: 'block' }}>
              {t('crud.common.wait')}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
          {props.roles && !tokenHelper.HasAccess(props.roles) && (
            <span className="ms-2">
              <i className="fa-solid fa-lock"></i>
            </span>
          )}
        </button>
      </div>
    </Tooltip>
  )
}

export default SaveButton;