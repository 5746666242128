import DataTable, { TableColumn } from "react-data-table-component";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { tableHelper } from "common/helpers/table/table-helper";
import {
  MasterLayout,
  MainLayout,
  ToolbarLayout,
  ToolbarLayoutLeft,
  ToolbarLayoutRight,
} from "common/imports/content-layout";
import { itlabMediumTableStyle } from "common/helpers/table/table-style";
import { RelatoriosService as Service } from "../services/relatorios.service";
import { IRelatorioBaseAnalitica } from "../model/relatorioBaseAnalitica.model";
import moment from 'moment';
import 'moment/locale/pt-br';
import { currencyMask } from 'common/helpers/masks/CurrencyMask';
import { DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/pt_BR';
import dayjs from 'dayjs';
import { useFormik } from "formik";
import { IFilterRelatorioBaseAnalitica } from "../model/filterRelatorioBaseAnalitica.model";
import * as Yup from "yup";
import SaveButton from "common/partials/SaveButton";
import { useDownloadFile } from "common/helpers/download-file/useDownloadFile";
import { Autocomplete, TextField } from "@mui/material";
import { ConvenioViewModel } from "app/convenios/model/convenio.model";
import { ConvenioService } from "app/convenios/services/convenio.service";
import { StatusContratacaoViewModel } from "../model/status.model";

export function RelatorioBaseAnalitica() {
  const { t } = useTranslation();
  const [tableData, setTableData] = useState<IRelatorioBaseAnalitica[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { RangePicker } = DatePicker;
  const [dataInicial, setDataInicial] = useState("");
  const [dataFinal, setDataFinal] = useState("");
  const dateFormat = "DD/MM/YYYY";
  const [page] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalRows, setTotalRows] = useState(0);
  const [isGerarRelatorio, setIsGerarRelatorio] = useState(false);
  const [convenios, setConvenios] = useState<ConvenioViewModel[]>([]);
  const [selectedConvenioId, setSelectedConvenioId] = useState<any>(undefined);
  const [status, setStatus] = useState<StatusContratacaoViewModel[]>([]);
  const [selectedStatusMutuoId, setSelectedStatusMutuoId] = useState<any>(undefined);

  let initialData: IFilterRelatorioBaseAnalitica = {
    contratoMutuo: "",
    safraMutuoInicial: "",
    safraMutuoFinal: "",
    contratoINSS: "",
    idLoja: "",
    operador: "",
    sub: "",
    convenio: undefined,
    valorTaxaMin: undefined,
    valorTaxaMax: undefined,
    numeroMesesMin: undefined,
    numeroMesesMax: undefined,
    nomeProduto: "",
    page: page,
    limit: rowsPerPage,
  };
  const [filter, setFilter] = useState<IFilterRelatorioBaseAnalitica>(initialData);

  const handlePageChange = (page: number) => {
    filter.page = page - 1
    fetchData();
  };

  const handlePerRowsChange = async (newPerPage: number) => {
    setRowsPerPage(newPerPage);
  };

  const tableColumns: TableColumn<IRelatorioBaseAnalitica>[] = [
    {
      name: `${t("Contrato Consignado")}`,
      selector: (row) => row?.contratoMutuo || "",
      sortable: true,
      width: "145px",
    },
    {
      name: `${t("Status Consignado")}`,
      selector: (row) => row?.statusMutuo || "",
      sortable: true,
      width: "300px",
    },
    {
      name: `${t('Consignado')}`,
      selector: (row) =>
        moment(row.safraMutuo)
          .locale(`${t('dateFormat.locale')}`)
          .format('L'),
      sortable: true,
      center: true,
      width: "120px",
    },
    {
      name: `${t("Contrato INSS")}`,
      selector: (row) => row?.contratoINSS || "",
      sortable: true,
      width: "145px",
    },
    {
      name: `${t('Data Liberação')}`,
      selector: (row) =>
        moment(row.dataLiberacao)
          .locale(`${t('dateFormat.locale')}`)
          .format('L'),
      sortable: true,
      center: true,
      width: "130px",
    },
    {
      name: `${t('Data Cancelamento')}`,
      selector: (row) => row.dataCancelamento ?
        moment(row.dataCancelamento)
          .locale(`${t('dateFormat.locale')}`)
          .format('L') : "",
      sortable: true,
      center: true,
      width: "170px",
    },
    {
      name: `${t('Data Liquidação')}`,
      selector: (row) => row.dataLiquidacao ? 
        moment(row.dataLiquidacao)
          .locale(`${t('dateFormat.locale')}`)
          .format('L') : "",
      sortable: true,
      center: true,
      width: "140px",
    },
    {
      name: `${t("ID_Loja")}`,
      selector: (row) => row.idLoja || "",
      sortable: true,
      width: "80px",
    },
    {
      name: `${t("Operador")}`,
      selector: (row) => row.operador || "",
      sortable: true,    
      minWidth: "300px" 
    },
    {
      name: `${t("Grupo")}`,
      selector: (row) => row.grupo || "",
      sortable: true,     
      minWidth: "180px" 
    },
    {
      name: `${t("Sub")}`,
      selector: (row) => row.sub || "",
      sortable: true,     
    },
    {
      name: `${t("Convênio")}`,
      selector: (row) => row.convenio || "",
      sortable: true,     
      width: "100px"
    },
    {
      name: `${t("Valor INSS")}`,
      selector: (row) => currencyMask(row.valorOperacao) || "",
      sortable: true,
      right: true,
      minWidth: "120px"
    },
    {
      name: `${t("Valor Comissionavel")}`,
      selector: (row) => currencyMask(row.valorComissionavel) || "",
      sortable: true,
      right: true,
      minWidth: "180px"
    },
    {
      name: `${t("IOF")}`,
      selector: (row) => (row.valorIOF ?? 0).toFixed(4).replace('.', ','),
      sortable: true,
      right: true,
      minWidth: "80px"
    },
    {
      name: `${t("TAXA")}`,
      selector: (row) => (row.valorTaxa ?? 0).toFixed(4).replace('.', ','),
      sortable: true,
      center: true,
      minWidth: "80px"
    },
    {
      name: `${t("Numero de Meses")}`,
      selector: (row) => row.numeroMeses || "",
      sortable: true,
      center: true,
      minWidth: "160px"
    },
    {
      name: `${t("Produto")}`,
      selector: (row) => row.nomeProduto || "",
      sortable: true,     
    },
    {
      name: `${t("Tabela Digitada")}`,
      selector: (row) => row.idTabela || "",
      center: true,
      sortable: true,     
      minWidth: "160px"
    },
    {
      name: `${t("Nome da Tabela")}`,
      selector: (row) => row.nomeTabela || "",
      sortable: true,  
      minWidth: "200px"   
    },
    {
      name: `${t("Servico Digitado")}`,
      selector: (row) => row.servicoDigitado || "",
      center: true,
      sortable: true,   
      minWidth: "160px"  
    },
    {
      name: `${t("% Diferido Mensal")}`,
      selector: (row) => (row.percentualDiferidoMensal ?? 0).toFixed(4).replace('.', ','),
      sortable: true,
      right: true,
      minWidth: "160px"
    },
    {
      name: `${t("Diferido Mes R$")}`,
      selector: (row) => currencyMask(row.valorDiferidoMensal) || "",
      sortable: true,
      right: true,
      minWidth: "180px"
    },
    {
      name: `${t("% Diferido Total")}`,
      selector: (row) => (row.percentualDiferido ?? 0).toFixed(4).replace('.', ','),
      sortable: true,
      right: true,
      minWidth: "160px"
    },
    {
      name: `${t("Total Diferido R$")}`,
      selector: (row) => currencyMask(row.valorDiferido) || "",
      sortable: true,
      right: true,
      minWidth: "180px"
    },
    {
      name: `${t("% Antecipado")}`,
      selector: (row) => (row.percentualAntecipado ?? 0).toFixed(4).replace('.', ','),
      sortable: true,
      right: true,
      minWidth: "160px"
    },
    {
      name: `${t("Total Antecipado R$")}`,
      selector: (row) => currencyMask(row.valorAntecipado) || "",
      sortable: true,
      right: true,
      minWidth: "180px"
    },
    {
      name: `${t("PRAZO")}`,
      selector: (row) => row.prazo || "",
      sortable: true,
      center: true,
      minWidth: "70px"
    },
    {
      name: `${t("TAXA SERVIÇO")}`,
      selector: (row) => (row.valorTaxaServico ?? 0).toFixed(4).replace('.', ','),
      sortable: true,
      right: true,
      minWidth: "120px"
    },
    {
      name: `${t('DATA PRI DIF')}`,
      selector: (row) =>
        moment(row.dataVencPrimeiraParcela)
          .locale(`${t('dateFormat.locale')}`)
          .format('L'),
      sortable: true,
      center: true,
      width: "120px",
    },
  ];

  const handleDates = (dates: any) => {
    setDataInicial("");
    setDataFinal("");

    if (dates === null) {
      setDataInicial("");
      setDataFinal("");
      formik.setFieldValue("safraMutuoInicial", undefined);
      formik.setFieldValue("safraMutuoFinal", undefined);
    }

    if (dates) {
      const inicio = dayjs(dates[0]).format("YYYY-MM-DD");
      const fim = dayjs(dates[1]).format("YYYY-MM-DD");

      setDataInicial(inicio);
      setDataFinal(fim);
      formik.setFieldValue("safraMutuoInicial", inicio);
      formik.setFieldValue("safraMutuoFinal", fim);
    }
  };

  const formSchema = Yup.object().shape({
    safraMutuoInicial: Yup
      .string()
      .required(`${t("crud.validators.requiredField")}`),
    safraMutuoFinal: Yup
      .string()
      .required(`${t("crud.validators.requiredField")}`),
  });
  const formik = useFormik<IFilterRelatorioBaseAnalitica>({
    enableReinitialize: true,
    initialValues: filter,
    validationSchema: formSchema,
    onSubmit: async (values) => {
      filter.contratoMutuo = values.contratoMutuo;
      filter.safraMutuoInicial = dataInicial;
      filter.safraMutuoFinal = dataFinal;
      filter.contratoINSS = values.contratoINSS;
      filter.idLoja = values.idLoja;
      filter.operador = values.operador;
      filter.grupo = values.grupo;
      filter.sub = values.sub;
      filter.convenio = selectedConvenioId;
      filter.valorTaxaMin = values.valorTaxaMin;
      filter.valorTaxaMax = values.valorTaxaMax;
      filter.numeroMesesMin = values.numeroMesesMin;
      filter.numeroMesesMax = values.numeroMesesMax;
      filter.nomeProduto = values.nomeProduto;
      filter.statusContratacaoId = selectedStatusMutuoId;
      fetchData();
    }
  });

  const fetchData = async () => {
    setFilter(filter);
    if (filter.safraMutuoInicial === "" || filter.safraMutuoFinal === "") return;
    formik.setTouched({ safraMutuoInicial: true })
    setIsLoading(true);
    try {
      const response = await Service.getRelatorioBaseAnalitica(filter);
      if (response && !response.hasErrors) {
        setTableData(response.data);
        setTotalRows(response.totalItems);
      }
    } catch (err) {
      toast.error(t("crud.read.errorMessage"));
    } finally {
      setIsLoading(false);
    }
  };
  const fetchConvenios = async () => {
    if (convenios && convenios.length) return;
    const response = await ConvenioService.getAllConvenio();
    if (response && !response.hasErrors) {
      setConvenios(response.data);
    }
  };
  const fetchStatus = async () => {
    if (status && status.length) return;
    const response = await Service.getStatusContratacao();
    if (response && !response.hasErrors) {
      setStatus(response.data);
    }
  };
  // INICIO: metodos para Exportação
  const preDownloading = () => setIsGerarRelatorio(true);
  const postDownloading = () => setIsGerarRelatorio(false);
  const onErrorDownloadFile = () => { setIsGerarRelatorio(false); };
  const downloadSampleCsvFile_2 = Service.getUrlExportarRelatorioBaseAnalitica;
  const gerarRelatorio = async() => {
    const filterExport = {
      contratoMutuo: formik.values.contratoMutuo,
      safraMutuoInicial: dataInicial,
      safraMutuoFinal: dataFinal,
      contratoINSS: formik.values.contratoINSS,
      idLoja: formik.values.idLoja,
      operador: formik.values.operador,
      grupo: formik.values.grupo,
      sub: formik.values.sub,
      convenio: selectedConvenioId,
      valorTaxaMin: formik.values.valorTaxaMin,
      valorTaxaMax: formik.values.valorTaxaMax,
      numeroMesesMin: formik.values.numeroMesesMin,
      numeroMesesMax: formik.values.numeroMesesMax,
      nomeProduto: formik.values.nomeProduto,
      statusContratacaoId: selectedStatusMutuoId,
      page: 0, 
      limit: 1048576 - 1
    };
    var date = moment(new Date()).format("YYYYMMDD_HHmmss");
    var fileName = `Base_Analitiva_${date}.xlsx`;
    await useDownloadFile.download({
      apiDefinition: downloadSampleCsvFile_2,
      preDownloading,
      postDownloading,
      onError: onErrorDownloadFile,
      apiParameters: filterExport,
      getFileName: fileName,
      typeFile: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"
    });
  
  };
  // FIM: metodos para Exportação
  useEffect(() => {
    fetchConvenios();
    fetchStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    filter.limit = rowsPerPage
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage]);

  return (
    <MasterLayout>
      <ToolbarLayout>
        <ToolbarLayoutLeft />
        <ToolbarLayoutRight />
      </ToolbarLayout>
      <MainLayout>
        <div className="card">
          <div className="card-body">
            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-sm-6 col-md-3 col-xxl-2 mb-10">
                  <label className="form-label">{t("Contrato Mutuo")}</label>
                  <input
                    type="text"
                    name="contratoMutuo"
                    value={formik.values.contratoMutuo}
                    className="form-control form-control-solid" 
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur} />
                </div>
                <div className="col-8 col-sm-6 col-md-4 col-xxl-3 mb-10">
                  <label className="form-label required">Data Consignado</label>
                  <RangePicker
                    locale={locale}
                    format={dateFormat}
                    className="form-control form-control-lg form-control-solid d-flex"
                    onChange={(dates: any) => {
                      if (dates === null) {
                        formik.setTouched({ safraMutuoInicial: true })
                      }
                      handleDates(dates);
                    }}
                    onBlur={() => formik.setTouched({ safraMutuoInicial: true })}
                  />
                  {formik.touched.safraMutuoInicial && formik.errors.safraMutuoInicial && (
                    <div className="mt-3 text-danger fw-bold">{formik.errors.safraMutuoInicial}</div>
                  )}
                </div>
                <div className="col-sm-6 col-md-3 col-xxl-2 mb-10">
                  <label className="form-label">{t("Contrato INSS")}</label>
                  <input
                    type="text"
                    name="contratoINSS"
                    value={formik.values.contratoINSS}
                    className="form-control form-control-solid" 
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur} 
                    />
                </div>
                <div className="col-sm-6 col-md-3 col-xxl-2 mb-10">
                  <label className="form-label">{t("ID Loja")}</label>
                  <input
                    type="text"
                    name="idLoja"
                    value={formik.values.idLoja}
                    className="form-control form-control-solid" 
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur} 
                    />
                </div>
                <div className="col-sm-6 col-md-3 col-xxl-2 mb-10">
                  <label className="form-label">{t("Operador")}</label>
                  <input
                    type="text"
                    name="operador"
                    value={formik.values.operador}
                    className="form-control form-control-solid" 
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur} 
                    />
                </div>
                <div className="col-sm-6 col-md-3 col-xxl-2 mb-10">
                  <label className="form-label">{t("Grupo")}</label>
                  <input
                    type="text"
                    name="grupo"
                    value={formik.values.grupo}
                    className="form-control form-control-solid" 
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur} 
                    />
                </div>
                <div className="col-sm-6 col-md-3 col-xxl-2 mb-10">
                  <label className="form-label">{t("Sub")}</label>
                  <input
                    type="text"
                    name="sub"
                    value={formik.values.sub}
                    className="form-control form-control-solid" 
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur} 
                    />
                </div>
                <div className="col-sm-6 col-md-4 col-xl-3 mb-10">
                  <label className="form-label">{t("Convênio")}</label>
                  <Autocomplete
                    className="autocomplete-md"
                    options={convenios}
                    getOptionLabel={option => option.nome ? option.nome : ""}
                    onChange={(_, value) => { setSelectedConvenioId(value?.id!); }}
                    onInputChange={(_, value) => {
                      if (!value) {
                        setSelectedConvenioId(undefined)
                      }
                    }}                    
                    renderInput={(params) =>
                      <TextField
                        {...params}
                        placeholder={`Selecione o convênio`}
                      />
                    }
                  />
                </div>
                <div className="col-8 col-sm-6 col-md-4 col-xxl-3 mb-10">
                  <label className="form-label">{t("Taxa")}</label>
                  <div className="d-flex justify-content">
                    <input
                      type="number"
                      name="valorTaxaMin"
                      min={0.0001}
                      step={0.0001}
                      className="form-control form-control-solid" 
                      value={formik.values.valorTaxaMin}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur} 
                    />
                    <label className="form-label p-2">até</label>
                    <input
                      type="number"
                      name="valorTaxaMax"
                      step={0.0001}
                      className="form-control form-control-solid" 
                      value={formik.values.valorTaxaMax}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur} 
                    />
                  </div>
                </div>
                <div className="col-8 col-sm-6 col-md-4 col-xxl-3 mb-10">
                  <label className="form-label">{t("Número de Meses")}</label>
                  <div className="d-flex justify-content">
                    <input
                      type="number"
                      name="numeroMesesMin"
                      min={1}
                      className="form-control form-control-solid" 
                      value={formik.values.numeroMesesMin}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur} 
                    />
                    <label className="form-label p-2">até</label>
                    <input
                      type="number"
                      name="numeroMesesMax"
                      className="form-control form-control-solid" 
                      value={formik.values.numeroMesesMax}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur} 
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-md-3 col-xxl-2 mb-10">
                  <label className="form-label">{t("Produto")}</label>
                  <input
                    type="text"
                    name="nomeProduto"
                    value={formik.values.nomeProduto}
                    className="form-control form-control-solid" 
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur} 
                    />
                </div>
                <div className="col-sm-6 col-md-5 mb-10">
                  <label className="form-label">{t("Status Consignado")}</label>
                  <Autocomplete
                    className="autocomplete-md"
                    options={status}
                    getOptionLabel={option => option.descricao ? option.descricao : ""}
                    onChange={(_, value) => { setSelectedStatusMutuoId(value?.id!); }}
                    onInputChange={(_, value) => {
                      if (!value) {
                        setSelectedStatusMutuoId(undefined)
                      }
                    }}                    
                    renderInput={(params) =>
                      <TextField
                        {...params}
                        placeholder={`Selecione um status`}
                      />
                    }
                  />
                </div>
              </div>
              <div className="card-footer d-flex justify-content-end py-6 px-9">
                <div className="col-xl-1 mb-5 mx-5">
                  <SaveButton
                    type="submit"
                    label={`Filtrar`}
                    loading={isLoading}
                    disabled={!formik.isValid || !formik.dirty}
                  />
                </div>
                <div className="col-xl-1 mb-5">
                  <SaveButton
                    type="button"
                    label={`Exportar`}
                    loading={isGerarRelatorio}
                    disabled={!formik.isValid || !formik.dirty}
                    onClick={gerarRelatorio}
                  />
                </div>
              </div>
            </form>
            <DataTable
              persistTableHead
              columns={tableColumns}
              data={tableData}
              progressPending={isLoading}
              progressComponent={tableHelper.getLoader()}
              noDataComponent={tableHelper.getNoDataComponent()}
              dense
              customStyles={itlabMediumTableStyle}
              striped 
              highlightOnHover
              contextMessage={tableHelper.getContextMessage()}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePerRowsChange}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationPerPage={rowsPerPage}
              paginationRowsPerPageOptions={[10, 25, 50, 100]}
              paginationComponentOptions={tableHelper.getPaginationComponentOptions()}
            />
          </div>
        </div>
      </MainLayout>
    </MasterLayout>
  );
}
