import i18next from 'i18next';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { RoleSelectorComponent } from 'app/role-selector/components/RoleSelector';
import { MasterLayout, MainLayout, ToolbarLayout, ToolbarLayoutLeft, ToolbarLayoutRight } from 'common/imports/content-layout';
import en from '../i18n/en-us.json';
import pt from '../i18n/pt-br.json';
import { GroupManagementModel as Model } from '../model/GroupManagement.model';
import { GroupManagementService as Service } from '../services/GroupManagement.service';
import SaveButton from 'common/partials/SaveButton';

export function CreateEditGroup() {
  i18next.addResourceBundle('us', 'translation', en);
  i18next.addResourceBundle('br', 'translation', pt);

  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('basicInfoTab');
  const { t } = useTranslation();
  const navigate = useNavigate();

  const formSchema = Yup.object().shape({
    name: Yup.string().required(t('crud.validators.requiredField').toString()),
  });

  let initialData: Model = {
    id: 0,
    name: '',
  };

  const [data, setData] = useState<Model>(initialData);

  const formik = useFormik<Model>({
    enableReinitialize: true,
    initialValues: data,
    validationSchema: formSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const name = values.name.trim();
        const body: Model = {
          id: values.id,
          name: name,
        };
        if (id) {
          const response = await Service.putGroup(body);
          if (response && !response?.hasErrors) {
            toast.success(`${t('crud.update.successMessage')}`);
          } else {
            toast.error(`${t('crud.update.errorMessage')}`);
          }
        } else {
          const response = await Service.postGroup(body);
          if (response && !response?.hasErrors) {
            toast.success(`${t('crud.create.successMessage')}`);
            navigate(`/security/groupmanagement/${response.data.id}`);
          } else {
            toast.error(`${t('crud.create.errorMessage')}`);
          }
        }
      } catch (err) {
        console.log(err)
      } finally {
        fetchGroup();
        setIsLoading(false);
      }
    },
  });

  const fetchGroup = async () => {
    try {
      setIsLoading(true);
      if (id) {
        const response = await Service.getGroupById(id);
        if (response && !response.hasErrors) {
          setData(response.data);
        }
      }
    } catch (err) {
      toast.error(`${t('crud.read.errorMessage')}`);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    fetchGroup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MasterLayout>
      <ToolbarLayout>
        <ToolbarLayoutLeft />
        <ToolbarLayoutRight></ToolbarLayoutRight>
      </ToolbarLayout>
      <MainLayout>
        <div className="card">
          <div className="card-body d-flex justify-content-between pb-0">
            <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap">
              <li className={`nav-item ${id ? 'cursor-pointer' : ''}`}>
                <div
                  className={`nav-link text-active-primary ${activeTab === 'basicInfoTab' ? 'active' : ''} ${!id ? 'disabled' : ''}`}
                  onClick={() => setActiveTab('basicInfoTab')}
                >
                  {t('groupManagement.basicInfoTab')}
                </div>
              </li>
              <li className={`nav-item ${id ? 'cursor-pointer' : ''}`}>
                <div
                  className={`nav-link text-active-primary ${activeTab === 'RolePermissionTab' ? 'active' : ''} ${!id ? 'disabled' : ''}`}
                  onClick={() => setActiveTab('RolePermissionTab')}
                >
                  {t('groupManagement.RolePermissionTab')}
                </div>
              </li>
            </ul>
          </div>
          {activeTab === 'basicInfoTab' && (
            <form onSubmit={formik.handleSubmit}>
              <div className="card-body">
                <div className="row">
                  <div className="mb-10">
                    <label className="required form-label">{t('groupManagement.form.label.name')}</label>
                    <input
                      type="text"
                      name="name"
                      className="form-control form-control-lg form-control-solid"
                      placeholder={t('groupManagement.form.placeholder.name').toString()}
                      value={formik.values.name ?? ''}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.name && formik.errors.name && (
                      <div className="mt-3 text-danger fw-bold">
                        <div className="fv-help-block">{formik.errors.name}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="card-footer d-flex justify-content-end py-6 px-9">
                <Link className="btn btn-light btn-active-light-primary me-2" to="/security/groupmanagement">
                  {t('crud.create.buttonCancel')}
                </Link>
                <SaveButton type="submit" loading={isLoading} />
              </div>
            </form>
          )}
        </div>
        {activeTab === 'RolePermissionTab' && id && (
          <RoleSelectorComponent groupId={parseInt(id)} />
        )}
      </MainLayout>
    </MasterLayout>
  );
}