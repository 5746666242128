import { AxiosResponse } from "axios";

interface DownloadFileProps {
  readonly apiDefinition: (filter?: any) => Promise<AxiosResponse<Blob>>;
  readonly preDownloading: () => void;
  readonly postDownloading: () => void;
  readonly onError: () => void;
  readonly apiParameters?: any;
  readonly getFileName: string;
  readonly typeFile: string;
}

export const download = async ({
  apiDefinition,
  preDownloading,
  postDownloading,
  onError,
  apiParameters,
  getFileName,
  typeFile,
}: DownloadFileProps) => {
  let ref = document.createElement("a");
  try {
    
    preDownloading();
    const { data } = await apiDefinition(apiParameters);
    const url = URL.createObjectURL(new Blob([data], {type: typeFile}));
    ref.setAttribute("href", url);
    ref.setAttribute("download", getFileName);
    ref.click();
    postDownloading();
    URL.revokeObjectURL(url); // não pode remover senão dá erro no download
  } catch (error) {
    onError();
  }
};

export const useDownloadFile = {
  download
}