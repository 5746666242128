import i18next from 'i18next';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  MasterLayout,
  MainLayout,
  ToolbarLayout,
  ToolbarLayoutLeft,
  ToolbarLayoutRight,
} from 'common/imports/content-layout';
import SaveButton from 'common/partials/SaveButton';
import { RolePermission } from 'common/helpers/dictionaries/roles';
import en from '../i18n/en-us.json';
import pt from '../i18n/pt-br.json';
import { MotivoBloqueioViewModel as Model } from '../model/motivos-bloqueio.model';
import { MotivosBloqueioService as Service } from '../services/motivos-bloqueio.service';

export function CreateEditMotivosBloqueio() {
  i18next.addResourceBundle('us', 'translation', en);
  i18next.addResourceBundle('br', 'translation', pt);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const formSchema = Yup.object().shape({
    descricao: Yup
      .string()
      .required(`${t('crud.validators.requiredField')}`)
  });

  let initialData: Model = {
    id: parseInt(id ?? '0'),
    descricao: '',
    isActive: true
  };

  const [data, setData] = useState<Model>(initialData);
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik<Model>({
    enableReinitialize: true,
    initialValues: data,
    validationSchema: formSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        const body: Model = {
          id: data.id,
          descricao: values.descricao,
          isActive: data.isActive
        };

        if (id) {
          const result = await Service.putData(body);
          if (result && !result?.hasErrors) {
            toast.success(t('crud.update.successMessage'));
          }
        } else {
          const result = await Service.postData(body);
          if (result && !result?.hasErrors) {
            toast.success(t('crud.create.successMessage'));
            navigate(`/cadastros/motivosBloqueio`);
          }
        }
      } catch (error) {
        toast.error(t('crud.update.errorMessage'));
      } finally {
        setIsLoading(false);
      }
    },
  });

  const fetchData = async () => {
    setIsLoading(true);
    try {
      if (id) {
        const response = await Service.getDataById(id);
        if (response && !response.hasErrors) {
          setData(response.data);
        }
      }
    } catch (err) {
      toast.error(t('crud.read.errorMessage'));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MasterLayout>
      <ToolbarLayout>
        <ToolbarLayoutLeft />
        <ToolbarLayoutRight></ToolbarLayoutRight>
      </ToolbarLayout>
      <MainLayout>
        <form onSubmit={formik.handleSubmit}>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-6 mb-10">
                  <label className="required form-label">{t('Descrição')}</label>
                  <input
                    type="text"
                    name="descricao"
                    className="form-control form-control-lg form-control-solid"
                    placeholder={t('Digite o motivo do bloqueio').toString()}
                    value={formik.values.descricao ?? ''}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.descricao && formik.errors.descricao && (
                    <div className="mt-3 text-danger fw-bold">{formik.errors.descricao}</div>
                  )}
                </div>
              </div>
            </div>
            <div className="card-footer d-flex justify-content-end py-6 px-9">
              <Link className="btn btn-light btn-active-light-primary me-2" to="/cadastros/motivosBloqueio">
                {t('crud.create.buttonCancel')}
              </Link>
              <SaveButton
                type="submit"
                loading={isLoading}
                disabled={!formik.isValid || !formik.dirty}
                roles={[RolePermission.Update_MotivoBloqueio]}
              />
            </div>
          </div>
        </form>
      </MainLayout>
    </MasterLayout>
  );
}
