import LoginRoute from './components/LoginRoute';
import SecureRoute from './components/SecureRoute';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { APP_ROUTES } from './routes';
import { RolePermission } from 'common/helpers/dictionaries/roles';
import { TokenHelper } from 'common/helpers/auth/token-helper';
import { UserManagement } from 'app/user-management/components/UserManagement';
import { GroupManagement } from 'app/group-management/components/GroupManagement';
import { UserProfile } from 'app/user-profile/components/UserProfile';
import { Unauthorized } from 'app/errors-page/components/Unauthorized';
import { NotFound } from 'app/errors-page/components/Not-Found';
import { HealthCheck } from 'app/health-check/components/HealthCheck';
import { GeneralSettings } from 'app/general-settings/components/GeneralSettings';
import { DashboardWrapper } from 'app/dashboard/components/DashboardWrapper';
import { Auth } from 'app/login/components/Auth';
import { APIClients } from 'app/api-clients/components/APIClients';
// import { CreateUpdatePIClient } from 'app/api-clients/components/CreateUpdatePIClient';
import { CreateEditGroup } from 'app/group-management/components/CreateEditGroup';
import { CreateEditUser } from 'app/user-management/components/CreateEditUser';
import { Corbans } from 'app/corbans/components/Corbans';
import { CreateEditCorbans } from 'app/corbans/components/CreateEditCorbans';
import { MotivosBloqueio } from 'app/motivos-bloqueio/components/MotivosBloqueio';
import { CreateEditMotivosBloqueio } from 'app/motivos-bloqueio/components/CreateEditMotivosBloqueio';
import { Percentages } from 'app/servicos-percentuais/components/ServicosPercentuais';
import { Convenios } from 'app/convenios/components/Convenios';
import { TipoCurva } from 'app/tipo-curva/components/TipoCurva';
import { CreateEditTipoCurva } from 'app/tipo-curva/components/CreateEditTipoCurva';
import CalculoContratacao from 'app/calculo/components/CalculoContratacao';
import LotesContratacao from 'app/lotes-contratacao/components/LotesContratacao';
import DetalhesLotesContratacao from 'app/lotes-contratacao/components/DetalhesLoteContratacao';
import ContratosLotesContratacao from 'app/contratos/components/ContratosConsignadosCorban';
import Execucoes from 'app/execucoes/components/execucoes-lote/Execucoes';
import InputDados from 'app/importacao/components/Importacao';
import { ImportacaoContratos } from 'app/importacao/components/ImportacaoContratos';
import Parecer from 'app/parecer/components/Parecer';
import { RelatorioBaseAnalitica } from 'app/relatorios/components/BaseAnalitica';
import { RelatorioCarteiraContratosMutuo } from 'app/relatorios/components/CarteiraContratosMutuo';
import { RelatorioAnaliticoCalculoApurado } from 'app/relatorios/components/AnaliticoCalculoApurado';
import { RelatorioContratosMutuoCorban } from 'app/relatorios/components/ContratosMutuoCorban';
import { RelatorioContratosConsignados } from 'app/relatorios/components/ContratosConsignados';

interface ProtectedRouteProps {
  role: number[];
  children: JSX.Element;
}

const ProtectedRoute = (props: ProtectedRouteProps) => {
  const helper = new TokenHelper();
  if (!helper.HasAccess(props.role)) {
    return <Navigate to={'/unauthorized'} replace />;
  }
  return props.children ? props.children : <Outlet />;
};

export default function RootRouter() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path={APP_ROUTES.login} element={<LoginRoute />} />
          <Route path={APP_ROUTES.auth} element={<Auth />} />
          <Route path={APP_ROUTES.dashboard} element={<SecureRoute />}>
            <Route
              path={APP_ROUTES.blank}
              element={
                <ProtectedRoute role={[RolePermission.Login]}>
                  <DashboardWrapper />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path={APP_ROUTES.operacao} element={<SecureRoute />}>
            <Route
              path={APP_ROUTES.calculo.list}
              element={
                <ProtectedRoute role={[RolePermission.Read_CalculoContratacao, RolePermission.Update_CalculoContratacao]}>
                  <CalculoContratacao />
                </ProtectedRoute>
              }
            />
            <Route
              path={APP_ROUTES.lotes.list}
              element={
                <ProtectedRoute role={[RolePermission.Read_LoteContratacao, RolePermission.Update_LoteContratacao]}>
                  <LotesContratacao />
                </ProtectedRoute>
              }
            />
            <Route
              path={APP_ROUTES.lotes.detalhes}
              element={
                <ProtectedRoute role={[RolePermission.Read_LoteContratacao, RolePermission.Update_LoteContratacao]}>
                  <DetalhesLotesContratacao />
                </ProtectedRoute>
              }
            />
            <Route
              path={APP_ROUTES.lotes.contratos}
              element={
                <ProtectedRoute role={[RolePermission.Read_LoteContratacao, RolePermission.Update_LoteContratacao]}>
                  <ContratosLotesContratacao />
                </ProtectedRoute>
              }
            />
            <Route
              path={APP_ROUTES.lotes.execucoes}
              element={
                <ProtectedRoute role={[RolePermission.Read_LoteContratacao, RolePermission.Update_LoteContratacao]}>
                  <Execucoes />
                </ProtectedRoute>
              }
            />
          </Route>

          <Route path={APP_ROUTES.cadastros} element={<SecureRoute />}>
            <Route
              path={APP_ROUTES.corban.list}
              element={
                <ProtectedRoute role={[RolePermission.Read_Corban, RolePermission.Update_Corban]}>
                  <Corbans />
                </ProtectedRoute>
              }
            />
            <Route
              path={APP_ROUTES.corban.add}
              element={
                <ProtectedRoute role={[RolePermission.Read_Corban, RolePermission.Update_Corban]}>
                  <CreateEditCorbans />
                </ProtectedRoute>
              }
            />
            <Route
              path={APP_ROUTES.corban.edit}
              element={
                <ProtectedRoute role={[RolePermission.Read_Corban, RolePermission.Update_Corban]}>
                  <CreateEditCorbans />
                </ProtectedRoute>
              }
            />
            <Route
              path={APP_ROUTES.convenio.list}
              element={
                <ProtectedRoute role={[RolePermission.Read_Convenio, RolePermission.Update_Convenio]}>
                  <Convenios />
                </ProtectedRoute>
              }
            />
            <Route
              path={APP_ROUTES.tipoCurva.list}
              element={
                <ProtectedRoute role={[RolePermission.Read_TipoCurva, RolePermission.Update_TipoCurva]}>
                  <TipoCurva />
                </ProtectedRoute>
              }
            />
            <Route
              path={APP_ROUTES.tipoCurva.add}
              element={
                <ProtectedRoute role={[RolePermission.Read_TipoCurva, RolePermission.Update_TipoCurva]}>
                  <CreateEditTipoCurva />
                </ProtectedRoute>
              }
            />
            <Route
              path={APP_ROUTES.tipoCurva.edit}
              element={
                <ProtectedRoute role={[RolePermission.Read_TipoCurva, RolePermission.Update_TipoCurva]}>
                  <CreateEditTipoCurva />
                </ProtectedRoute>
              }
            />
            <Route
              path={APP_ROUTES.motivosBloqueio.list}
              element={
                <ProtectedRoute role={[RolePermission.Read_MotivoBloqueio, RolePermission.Update_MotivoBloqueio]}>
                  <MotivosBloqueio />
                </ProtectedRoute>
              }
            />
            <Route
              path={APP_ROUTES.motivosBloqueio.add}
              element={
                <ProtectedRoute role={[RolePermission.Read_MotivoBloqueio, RolePermission.Update_MotivoBloqueio]}>
                  <CreateEditMotivosBloqueio />
                </ProtectedRoute>
              }
            />
            <Route
              path={APP_ROUTES.motivosBloqueio.edit}
              element={
                <ProtectedRoute role={[RolePermission.Read_MotivoBloqueio, RolePermission.Update_MotivoBloqueio]}>
                  <CreateEditMotivosBloqueio />
                </ProtectedRoute>
              }
            />
            <Route
              path={APP_ROUTES.percentuais.list}
              element={
                <ProtectedRoute role={[RolePermission.Read_ServicoPercentual, RolePermission.Update_ServicoPercentual]}>
                  <Percentages />
                </ProtectedRoute>
              }
            />
            <Route
              path={APP_ROUTES.parecer}
              element={
                <ProtectedRoute role={[RolePermission.Read_Parecer, RolePermission.Update_Parecer]}>
                  <Parecer />
                </ProtectedRoute>
              }
            />
            <Route
              path={APP_ROUTES.parametros}
              element={
                <ProtectedRoute role={[RolePermission.Settings, RolePermission.Settings]}>
                  <GeneralSettings />
                </ProtectedRoute>
              }
            />
            <Route
              path={APP_ROUTES.importacao}
              element={
                <ProtectedRoute role={[RolePermission.Read_Importacao, RolePermission.Update_Importacao]}>
                  <InputDados />
                </ProtectedRoute>
              }
            />
            <Route
              path={APP_ROUTES.importacaoContratos}
              element={
                <ProtectedRoute role={[RolePermission.Read_Importacao, RolePermission.Update_Importacao]}>
                  <ImportacaoContratos />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path={APP_ROUTES.relatorio} element={<SecureRoute />}>
          <Route
              path={APP_ROUTES.relatorios.relatorioContratosConsignados}
              element={
                <ProtectedRoute role={[RolePermission.Read_Relatorio]}>
                  <RelatorioContratosConsignados />
                </ProtectedRoute>
              }
            />
            <Route
              path={APP_ROUTES.relatorios.relatorioBaseAnalitica}
              element={
                <ProtectedRoute role={[RolePermission.Read_Relatorio]}>
                  <RelatorioBaseAnalitica />
                </ProtectedRoute>
              }
            />
            <Route
              path={APP_ROUTES.relatorios.relatorioCarteiraContratosMutuo}
              element={
                <ProtectedRoute role={[RolePermission.Read_Relatorio]}>
                  <RelatorioCarteiraContratosMutuo />
                </ProtectedRoute>
              }
            />
            <Route
              path={APP_ROUTES.relatorios.relatorioAnaliticoCalculoApurado}
              element={
                <ProtectedRoute role={[RolePermission.Read_Relatorio]}>
                  <RelatorioAnaliticoCalculoApurado />
                </ProtectedRoute>
              }
            />
            <Route
              path={APP_ROUTES.relatorios.relatorioContratosMutuoCorban}
              element={
                <ProtectedRoute role={[RolePermission.Read_Relatorio]}>
                  <RelatorioContratosMutuoCorban />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path={APP_ROUTES.security} element={<SecureRoute />}>
            <Route path={APP_ROUTES.userprofile} element={<UserProfile />} />
            <Route
              path={APP_ROUTES.usermanagement.list}
              element={
                <ProtectedRoute role={[RolePermission.UserManagement]}>
                  <UserManagement />
                </ProtectedRoute>
              }
            />
            <Route
              path={APP_ROUTES.usermanagement.add}
              element={
                <ProtectedRoute role={[RolePermission.UserManagement]}>
                  <CreateEditUser />
                </ProtectedRoute>
              }
            />
            <Route
              path={APP_ROUTES.usermanagement.edit}
              element={
                <ProtectedRoute role={[RolePermission.UserManagement]}>
                  <CreateEditUser />
                </ProtectedRoute>
              }
            />
            <Route
              path={APP_ROUTES.groupmanagement.list}
              element={
                <ProtectedRoute role={[RolePermission.GroupManagement]}>
                  <GroupManagement />
                </ProtectedRoute>
              }
            />
            <Route
              path={APP_ROUTES.groupmanagement.add}
              element={
                <ProtectedRoute role={[RolePermission.GroupManagement]}>
                  <CreateEditGroup />
                </ProtectedRoute>
              }
            />
            <Route
              path={APP_ROUTES.groupmanagement.edit}
              element={
                <ProtectedRoute role={[RolePermission.GroupManagement]}>
                  <CreateEditGroup />
                </ProtectedRoute>
              }
            />
            <Route
              path={APP_ROUTES.apiClients.list}
              element={
                <ProtectedRoute role={[RolePermission.APIClients]}>
                  <APIClients />
                </ProtectedRoute>
              }
            />
            {/* <Route
              path={APP_ROUTES.apiClients.add}
              element={
                <ProtectedRoute role={[Security.APIClients]}>
                  <CreateUpdatePIClient />
                </ProtectedRoute>
              }
            />
            <Route
              path={APP_ROUTES.apiClients.edit}
              element={
                <ProtectedRoute role={[Security.APIClients]}>
                  <CreateUpdatePIClient />
                </ProtectedRoute>
              }
            /> */}
          </Route>
          <Route path={APP_ROUTES.settings} element={<SecureRoute />}>
            <Route
              path={APP_ROUTES.healthcheck}
              element={
                <ProtectedRoute role={[RolePermission.Settings]}>
                  <HealthCheck />
                </ProtectedRoute>
              }
            />
            <Route
              path={APP_ROUTES.generalsettings}
              element={
                <ProtectedRoute role={[RolePermission.Settings]}>
                  <GeneralSettings />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path={APP_ROUTES.notfoundpage} element={<NotFound />} />
          <Route path={APP_ROUTES.unauthorizedpage} element={<Unauthorized />} />
          <Route path="" element={<Navigate to={APP_ROUTES.login} />} />
          <Route path="*" element={<Navigate to={APP_ROUTES.notfoundpage} />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}
