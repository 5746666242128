export enum RolePermission {
  Administrator = 1,
  Login = 100,
  UserManagement = 350,
  GroupManagement = 400,
  RoleManagement = 450,
  Settings = 500,
  APIClients = 550,
  Read_Corban = 560,
  Update_Corban = 570,
  Read_MotivoBloqueio = 580,
  Update_MotivoBloqueio = 590,
  Read_ServicoPercentual = 600,
  Update_ServicoPercentual = 610,
  Read_Convenio = 620,
  Update_Convenio = 630,
  Read_TipoCurva = 640,
  Update_TipoCurva = 650,
  Read_LoteContratacao = 660,
  Update_LoteContratacao = 670,
  Read_CorbanElegivel = 680,
  Update_CorbanElegivel = 690,
  Read_Importacao = 700,
  Update_Importacao = 710,
  Read_Execucao = 720,
  Update_Execucao = 730,
  Read_ExecucaoControleFluxo = 740,
  Update_ExecucaoControleFluxo = 750,
  Read_ExecucaoLog = 760,
  Update_ExecucaoLog = 770,
  Read_Robo = 780,
  Update_Robo = 790,
  Read_RoboFluxo = 800,
  Update_RoboFluxo = 810,
  Read_InconsistenciaAcao = 820,
  Update_InconsistenciaAcao = 830,
  Read_InconsistenciaAcaoJustificativa = 840,
  Update_InconsistenciaAcaoJustificativa = 850,
  Read_IntegracaoRobo = 860,
  Update_IntegracaoRobo = 870,
  Read_Amortizacao = 880,
  Update_Amortizacao = 890,
  Read_Relatorio = 900,
  Read_Parecer = 910,
  Update_Parecer = 920,
  Read_CalculoContratacao = 930,
  Update_CalculoContratacao = 940
}