import React, { ReactElement, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { CardItem } from './CardItem';
import { useTranslation } from 'react-i18next';
import { useLoading } from 'common/loading/loading.hook';
import { CardItemModel } from './model/card.model';

interface Props {
  items: CardItemModel[];
  title: string;
  headerTopRightElement?: ReactElement;
  footerElement?: ReactElement;
}

export function Card(props: Props) {
  const { isLoading } = useLoading();
  const { t } = useTranslation();

  const [cardItems, setCardItems] = useState<CardItemModel[]>([]);
  useEffect(() => {
    (async () => {
      try {
        isLoading(true);
        setCardItems(props.items);
      } catch (err) {
        toast(t('crud.read.errorMessage'));
      } finally {
        isLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return (
    <>
      <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
        <div className="card-header">
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">{props.title}</h3>
          </div>
          <div className="card-title m-0 float-right">
            {props.headerTopRightElement}
          </div>
        </div>
        <div className="card-body p-9">
          {cardItems.map((item, ix) => (
            <CardItem key={ix} model={item}></CardItem>
          ))}
        </div>
        {props.footerElement && (
        <div className="card-footer text-muted">
          {props.footerElement}
        </div>)}

      </div>
    </>
  );
}
