import { CorbanViewModel } from 'app/lotes-contratacao/model/lotes-contratacao.model';
import { http } from 'common/helpers/axios/axios-helper';

const basePath = '/corban';

async function getDataList(page: number, limit: number, query: string, cnpj: string, elegivel: string, situacao: number) {
  let paginationFilter = `_page=${page}&_limit=${limit}`;
  let querySearch = "";
  let cnpjFilter = "";
  let elegivelFilter = "";
  let situacaoFilter = "";

  if (query !== "") querySearch = `&Query=${query}`;
  if (cnpj !== "") cnpjFilter = `&CNPJ=${cnpj}`;
  if (elegivel !== "" && elegivel !== undefined) elegivelFilter = `&Elegivel=${elegivel}`;
  if (situacao && situacao > 0) situacaoFilter = `&Situacao=${situacao}`;

  return await http.get<CorbanViewModel[]>(`${basePath}?${paginationFilter}${querySearch}${cnpjFilter}${elegivelFilter}${situacaoFilter}`);
}

async function getCorbansElegiveis() {
  return await http.get<CorbanViewModel[]>(`${basePath}/elegiveis`);
}

async function getCorbansElegiveisFiltered(page: number, limit: number, query?: string) {
  let paginationFilter = `_page=${page}&_limit=${limit}`;

  let queryFilter = "";

  if (query !== "") {
    queryFilter = `&Query=${query}`;
  }

  return await http.get<CorbanViewModel[]>(`${basePath}/elegiveis?${paginationFilter}${queryFilter}`);
}

async function getCorbanById(id: string) {
  return await http.get<CorbanViewModel>(`${basePath}/${id}`);
}

async function getFilteredDataList(query: any) {
  return await http.get<CorbanViewModel[]>(`${basePath}${query}`);
}

async function getCorbanByCnpj(cnpj: string) {
  return await http.get<CorbanViewModel>(`${basePath}/${cnpj}`);
}

async function getDataById(id: string) {
  return await http.get<any>(`${basePath}?cnpj=${id}`);
}

async function getAccountsByCorbans(id: number) {
  return await http.get<any>(`${basePath}/${id}/contas`);
}
async function getPocByCorbans(id?: string) {
  return await http.get<any>(`corbanspoc`);
}

async function getPocByCorbansId(id: number) {
  return await http.get<any>(`${basePath}/${id}/pocs`);
}

async function getPocInfoByCorbans(id: any) {
  return await http.get<any>(`corbanspoc?id=${id}`);
}

async function getSubsByCorbans(id: number) {
  return await http.get<any>(`${basePath}/${id}/subs`);
}

async function getEnderecosByCorban(id: number) {
  return await http.get<any>(`${basePath}/${id}/enderecos`);
}

async function postData(data: CorbanViewModel) {
  return await http.post<CorbanViewModel>(`${basePath}`, data);
}

async function putIsCorbanElegivel(id: string) {
  return await http.put<CorbanViewModel>(`${basePath}/${id}/elegivel`);
}

async function putData(data: CorbanViewModel) {
  return await http.put<CorbanViewModel>(`${basePath}/${data.id}`, data);
}

async function deleteDataById(id: any) {
  return await http.del(`${basePath}/${id}`);
}

export const CorbansService = {
  getDataList,
  getCorbansElegiveis,
  getCorbansElegiveisFiltered,
  getCorbanById,
  getDataById,
  getCorbanByCnpj,
  putIsCorbanElegivel,
  postData,
  putData,
  deleteDataById,
  getFilteredDataList,
  getAccountsByCorbans,
  getSubsByCorbans,
  getPocByCorbans,
  getPocInfoByCorbans,
  getPocByCorbansId,
  getEnderecosByCorban
};
