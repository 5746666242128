import RootRouter from 'routes/RootRouter';
import { LoadingContext } from 'common/loading/loading.context';
import { useLoadingState } from 'common/loading/loading.hook';
import { LayoutProvider } from 'common/layout/core';
import { MasterInit } from 'common/layout/MasterInit';
import { AvatarContext } from 'common/helpers/avatar/context/avatar.context';
import { useAvatarState } from 'common/helpers/avatar/context/avatar.hook';

function App() {
  const { loading, isLoading } = useLoadingState();
  const { avatar, setAvatar } = useAvatarState();

  return (
    <>
      <LoadingContext.Provider value={{ loading, isLoading }}>
        <AvatarContext.Provider value={{ avatar, setAvatar }}>
          <LayoutProvider>
            <RootRouter />
          </LayoutProvider>
        </AvatarContext.Provider>
      </LoadingContext.Provider>
      <MasterInit />
    </>
  );
}

export default App;
