/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  MasterLayout,
  MainLayout,
  ToolbarLayout,
  ToolbarLayoutLeft,
  ToolbarLayoutRight
} from 'common/imports/content-layout';

export function DashboardWrapper() {
  return (
    <>
      <MasterLayout>
        <ToolbarLayout>
          <ToolbarLayoutLeft />
          <ToolbarLayoutRight />
        </ToolbarLayout>
        <MainLayout>
        </MainLayout>
      </MasterLayout>
    </>
  )
}
