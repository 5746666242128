import i18next from 'i18next';
import moment from 'moment';
import 'moment/locale/pt-br';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { tableHelper } from 'common/helpers/table/table-helper';
import { itLabTableStyle } from 'common/helpers/table/table-style';
import en from '../../i18n/en-us.json';
import pt from '../../i18n/pt-br.json';
import { IPoc as Model } from '../../model/poc.model';
import { CorbansService } from 'app/corbans/services/corbans.service';
import { currencyMask } from 'common/helpers/masks/CurrencyMask';
import { useParams } from 'react-router-dom';

export function PocTable() {
  const { id } = useParams();
  i18next.addResourceBundle('us', 'translation', en);
  i18next.addResourceBundle('br', 'translation', pt);

  const { t } = useTranslation();
  const [tableData, setTableData] = useState<Model[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const columns: TableColumn<Model>[] = [
    {
      name: `${t("Convênio")}`,
      selector: (row) => row.convenio?.nome || '',
      sortable: true,
      minWidth: "80px"
    },
    {
      name: `${t('corban.form.sessions.poc.table.headers.limiteVigente')}`,
      selector: (row) => currencyMask(row.limiteVigente) || '',
      sortable: true,
      right: true,
      minWidth: "140px"
    },
    {
      name: `${t('corban.form.sessions.poc.table.headers.limiteTomado')}`,
      selector: (row) => currencyMask(row.limiteTomado) || '',
      sortable: true,
      right: true,
      minWidth: "140px"
    },
    {
      name: `${t('corban.form.sessions.poc.table.headers.limiteReservado')}`,
      selector: (row) => currencyMask(row.limiteReservado) || '',
      sortable: true,
      right: true,
      minWidth: "140px"
    },
    {
      name: `${t('corban.form.sessions.poc.table.headers.limiteDisponivel')}`,
      selector: (row) => currencyMask(row.limiteDisponivel) || '',
      sortable: true,
      right: true,
      minWidth: "140px"
    },
    {
      name: `${t('corban.form.sessions.poc.table.headers.percentualTomado')}`,
      selector: (row) => (row.percentualTomado ?? 0).toFixed(4).replace('.', ','),
      sortable: true,
      right: true,
      minWidth: "150px"
    },
    {
      name: `${t('corban.form.sessions.poc.table.headers.percentualDisponivel')}`,
      selector: (row) => (row.percentualDisponivel ?? 0).toFixed(4).replace('.', ','),
      sortable: true,
      right: true,
      minWidth: "140px"
    },
    {
      name: `${t('corban.form.sessions.poc.table.headers.inicioVigencia')}`,
      selector: (row) =>
        moment(row.inicioVigencia)
          .locale(`${t('dateFormat.locale')}`)
          .format('L'),
      sortable: true,
      center: true,
      minWidth: "150px"
    },
    {
      name: `${t('corban.form.sessions.poc.table.headers.finalVigencia')}`,
      selector: (row) =>
        moment(row.finalVigencia)
          .locale(`${t('dateFormat.locale')}`)
          .format('L'),
      sortable: true,
      center: true,
      minWidth: "140px"
    },
    {
      name: `${t('corban.form.sessions.poc.table.headers.numero')}`,
      selector: (row) => row.numero || '',
      sortable: true,
      width: '110px',
      center: true
    },
  ];

  const fetchData = async () => {
    setIsLoading(true);
    if (id) {
      const response = await CorbansService.getPocByCorbansId(Number(id));
      if (response && !response.hasErrors) {
        setTableData(response.data);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DataTable
      persistTableHead
      columns={columns}
      data={tableData}
      noDataComponent={tableHelper.getNoDataComponent()}
      pagination
      dense
      paginationComponentOptions={tableHelper.getPaginationComponentOptions()}
      highlightOnHover
      progressPending={isLoading}
      progressComponent={tableHelper.getLoader()}
      contextMessage={tableHelper.getContextMessage()}
      selectableRowsVisibleOnly={true}
      customStyles={itLabTableStyle}
    />
  );
}
