import React from 'react'
import { AsideDefault } from './aside/AsideDefault'
import { Footer } from './Footer'
import { Header } from './header/Header'
import { ScrollTop } from './ScrollTop'

interface Props {
    children?: React.ReactNode
}

export function Content(props: Props) {
    return (
        <div className='page d-flex flex-row flex-column-fluid'>
            <AsideDefault />
            <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
                <Header />
                <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
                    <div className='post d-flex flex-column-fluid' id='kt_post'>
                        {props.children}
                    </div>
                </div>
                <ScrollTop />
                <Footer />
            </div>
        </div>
    )
}