import { FrontendHealthCheck } from './child/FrontendHealthCheck';
import { DatabaseHealthCheck } from './child/DatabaseHealthCheck';
import { LogHealthCheck } from './child/LogHealthCheck';
import { SMTPHealthCheck } from './child/SMTPHealthCheck';
import { UploadHealthCheck } from './child/UploadHealthCheck';
import { MasterLayout, MainLayout, ToolbarLayout, ToolbarLayoutLeft, ToolbarLayoutRight } from 'common/imports/content-layout';

export function HealthCheck() {
  
  return (
    <>
      <MasterLayout>
        <ToolbarLayout>
          <ToolbarLayoutLeft />
          <ToolbarLayoutRight>
          </ToolbarLayoutRight>
        </ToolbarLayout>
        <MainLayout>
          <FrontendHealthCheck />
          <DatabaseHealthCheck />
          <LogHealthCheck />
          <SMTPHealthCheck />
          <UploadHealthCheck />
        </MainLayout>
      </MasterLayout>
    </>
  );
}
