import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import AppLogo from "common/partials/AppLogo";
import { APP_ROUTES } from "routes/routes";
import en from "../i18n/en-us.json";
import pt from "../i18n/pt-br.json";

export function Unauthorized() {
  i18next.addResourceBundle("us", "translation", en);
  i18next.addResourceBundle("br", "translation", pt);
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="d-flex flex-column flex-column-fluid text-center p-10 py-lg-20">
      <div className="mb-10 pt-lg-20" onClick={() => navigate(APP_ROUTES.dashboard)}>
        <AppLogo className="w-300px mb-5 img-fluid" />
      </div>
      <div className="pt-lg-10 mb-10">
        <h1 className="fw-bolder fs-2x text-gray-700 mb-10">{t("unauthorizedPage.title")}</h1>
        <div className="fw-bold fs-3 text-gray-400 mb-15">{t("unauthorizedPage.subtitle")}</div>
        <div className="d-flex justify-content-center">
          <button className="btn btn-lg btn-primary fw-bolder me-2" onClick={() => navigate(-2)}>
            {t("crud.common.back")}
          </button>
          <Link className="btn btn-lg btn-primary fw-bolder" to="/dashboard">
            {t("unauthorizedPage.homepageButton")}
          </Link>
        </div>
      </div>
    </div>
  );
}
