import DataTable, { TableColumn } from "react-data-table-component";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { tableHelper } from "common/helpers/table/table-helper";
import {
  MasterLayout,
  MainLayout,
  ToolbarLayout,
  ToolbarLayoutLeft,
  ToolbarLayoutRight,
} from "common/imports/content-layout";
import { itlabMediumTableStyle } from "common/helpers/table/table-style";
import { RelatoriosService as Service } from "../services/relatorios.service";
import moment from 'moment';
import 'moment/locale/pt-br';
import { currencyMask } from 'common/helpers/masks/CurrencyMask';
import { useFormik } from "formik";
import * as Yup from "yup";
import SaveButton from "common/partials/SaveButton";
import { useDownloadFile } from "common/helpers/download-file/useDownloadFile";
import { IFilterRelatorioAnaliticoCalculoApurado } from "../model/filterRelatorioAnaliticoCalculoApurado.model";
import { IRelatorioAnaliticoCalculoApurado } from "../model/relatorioAnaliticoCalculoApurado.model";

export function RelatorioAnaliticoCalculoApurado() {
  const { t } = useTranslation();
  const [tableData, setTableData] = useState<IRelatorioAnaliticoCalculoApurado[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalRows, setTotalRows] = useState(0);
  const [isGerarRelatorio, setIsGerarRelatorio] = useState(false);
  
  let initialData: IFilterRelatorioAnaliticoCalculoApurado = {
    lote: "",
    contratoMutuo: "",
    idLoja: "",
    page: page,
    limit: rowsPerPage,
  };
  const [filter, setFilter] = useState<IFilterRelatorioAnaliticoCalculoApurado>(initialData);

  const handlePageChange = (page: number) => {
    filter.page = page - 1
    fetchData();
  };

  const handlePerRowsChange = async (newPerPage: number) => {
    setRowsPerPage(newPerPage);
  };

  const tableColumns: TableColumn<IRelatorioAnaliticoCalculoApurado>[] = [
    {
      name: `${t("Lote")}`,
      selector: (row) => row?.lote || "",
      width: "140px",
    },
    {
      name: `${t("Contrato Mutuo")}`,
      selector: (row) => row?.contratoMutuo || "",
      width: "145px",
    },
    {
      name: `${t("ID_Loja")}`,
      selector: (row) => row.idLoja || "",
      minWidth: "80px",
    },
    {
      name: `${t("Parcela")}`,
      selector: (row) => row.numeroParcela,
      sortable: true,
      right: true,
      width: "100px"
    },
    {
      name: `${t("Data da Parcela")}`,
      selector: (row) =>
        moment(row.dataVencimento)
          .locale(`${t('dateFormat.locale')}`)
          .format('L'),
      sortable: true,
      center: true,
      minWidth: "160px",
    },
    {
      name: `${t("Saldo Devedor")}`,
      selector: (row) => currencyMask(row.saldoDevedor) || "",
      right: true,
      minWidth: "120px"
    },
    {
      name: `${t("Valor Amortizado")}`,
      selector: (row) => row.valorAmortizado ? currencyMask(row.valorAmortizado) : "",
      right: true,
      minWidth: "150px"
    },
    {
      name: `${t("Juros")}`,
      selector: (row) => row.valorJuros ? currencyMask(row.valorJuros) : "",
      right: true,
      minWidth: "120px"
    },
    {
      name: `${t("Valor da Parcela")}`,
      selector: (row) => row.valorParcelaMutuo ? currencyMask(row.valorParcelaMutuo) : "",
      right: true,
      minWidth: "160px"
    },
    {
      name: `${t("Provisão")}`,
      selector: (row) => currencyMask(row.valorProvisao) || "",
      right: true,
      minWidth: "120px"
    },
    {
      name: `${t("Diferido Total")}`,
      selector: (row) => currencyMask(row.valorDiferidoTotal) || "",
      right: true,
      minWidth: "120px"
    },
    {
      name: `${t("Diferido Teórico")}`,
      selector: (row) => currencyMask(row.valorDiferidoTeorico) || "",
      right: true,
      minWidth: "140px"
    },
    {
      name: `${t("Colchão Teórico")}`,
      selector: (row) => currencyMask(row.colchaoTeorico) || "",
      right: true,
      minWidth: "140px"
    },
    {
      name: `${t("Saldo Conta Vinculada Teórico")}`,
      selector: (row) => currencyMask(row.saldoContaVinculadaTeorico) || "",
      right: true,
      minWidth: "240px"
    },
    {
      name: `${t("Curva Diferido Total")}`,
      selector: (row) => row.curvaDiferidoTotal? currencyMask(row.curvaDiferidoTotal) : "",
      right: true,
      minWidth: "160px"
    },
    {
      name: `${t("Curva Diferido Teórico")}`,
      selector: (row) => row.curvaDiferidoTeorico ? currencyMask(row.curvaDiferidoTeorico) : "",
      right: true,
      minWidth: "180px"
    },
    {
      name: `${t("Curva Colchão Real")}`,
      selector: (row) => currencyMask(row.curvaColchaoReal) || "",
      right: true,
      minWidth: "180px"
    },
    {
      name: `${t("Curva Saldo Conta Vinculada Teórico")}`,
      selector: (row) => currencyMask(row.curvaSaldoContaVinculadaTeorico) || "",
      right: true,
      minWidth: "320px"
    },
    {
      name: `${t("Diferido Real")}`,
      selector: (row) => row.diferidoReal ? currencyMask(row.diferidoReal) : "",
      right: true,
      minWidth: "160px"
    },
    {
      name: `${t("Colchão Real")}`,
      selector: (row) => row.colchaoReal ? currencyMask(row.colchaoReal) : "",
      right: true,
      minWidth: "160px"
    },
    {
      name: `${t("Curva Saldo Conta Vinculada Real")}`,
      selector: (row) => row.curvaSaldoContaVinculadaReal ? currencyMask(row.curvaSaldoContaVinculadaReal) : "",
      right: true,
      minWidth: "300px"
    },
    {
      name: `${t("Curva Líquidação (Real)")}`,
      selector: (row) => (row.curvaLiquidacaoReal ?? 0).toFixed(2).replace('.', ','),
      right: true,
      minWidth: "220px"
    },
    {
      name: `${t("Curva Líquidação (Apuração)")}`,
      selector: (row) => (row.curvaLiquidacaoApuracao ?? 0).toFixed(2).replace('.', ','),
      right: true,
      minWidth: "240px"
    },
    {
      name: `${t("Parcela Baixa (S/N)")}`,
      selector: (row) => row.parcelBaixada || "",
      center: true,
      minWidth: "160px"
    },
    {
      name: `${t("Data da Baixa")}`,
      selector: (row) => row.dataDaBaixa ?
        moment(row.dataDaBaixa)
          .locale(`${t('dateFormat.locale')}`)
          .format('L') : "",
      center: true,
      minWidth: "160px"
    },
    
  ];

  const formSchema = Yup.object().shape({});
  const formik = useFormik<IFilterRelatorioAnaliticoCalculoApurado>({
    enableReinitialize: true,
    initialValues: filter,
    validationSchema: formSchema,
    onSubmit: async (values) => {
      filter.lote = values.lote;
      filter.contratoMutuo = values.contratoMutuo;
      filter.idLoja = values.idLoja;

      fetchData();
    }
  });

  const fetchData = async () => {
    setFilter(filter);
    setIsLoading(true);
    try {
      const response = await Service.getRelatorioAnaliticoCalculoApurado(filter);
      if (response && !response.hasErrors) {
        setTableData(response.data);
        setTotalRows(response.totalItems);
      }
    } catch (err) {
      toast.error(t("crud.read.errorMessage"));
    } finally {
      setIsLoading(false);
    }
  };
  
  // INICIO: metodos para Exportação
  const preDownloading = () => setIsGerarRelatorio(true);
  const postDownloading = () => setIsGerarRelatorio(false);
  const onErrorDownloadFile = () => { setIsGerarRelatorio(false); };
  const downloadSampleCsvFile_2 = Service.getExportarRelatorioAnaliticoCalculoApurado;
  const gerarRelatorio = async() => {
    const filterExport: IFilterRelatorioAnaliticoCalculoApurado = {
      lote: formik.values.lote,
      contratoMutuo: formik.values.contratoMutuo,
      idLoja: formik.values.idLoja,
      page: 0, 
      limit: 1048576 - 1
    };
    var date = moment(new Date()).format("YYYYMMDD_HHmmss");
    var fileName = `AnaliticoCalculoApurado_${date}.xlsx`;
    await useDownloadFile.download({
      apiDefinition: downloadSampleCsvFile_2,
      preDownloading,
      postDownloading,
      onError: onErrorDownloadFile,
      apiParameters: filterExport,
      getFileName: fileName,
      typeFile: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"
    });
  
  };
  // FIM: metodos para Exportação

  useEffect(() => {
    filter.limit = rowsPerPage
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage]);

  return (
    <MasterLayout>
      <ToolbarLayout>
        <ToolbarLayoutLeft />
        <ToolbarLayoutRight />
      </ToolbarLayout>
      <MainLayout>
        <div className="card">
          <div className="card-body">
            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-sm-6 col-md-3 col-xxl-2 mb-10">
                  <label className="form-label">{t("Lote")}</label>
                  <input
                    type="text"
                    name="lote"
                    value={formik.values.lote}
                    className="form-control form-control-solid" 
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur} />
                </div>
                <div className="col-sm-6 col-md-3 col-xxl-2 mb-10">
                  <label className="form-label">{t("Contrato Mutuo")}</label>
                  <input
                    type="text"
                    name="contratoMutuo"
                    value={formik.values.contratoMutuo}
                    className="form-control form-control-solid" 
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur} />
                </div>
                <div className="col-sm-6 col-md-3 col-xxl-2 mb-10">
                  <label className="form-label">{t("ID Loja")}</label>
                  <input
                    type="text"
                    name="idLoja"
                    value={formik.values.idLoja}
                    className="form-control form-control-solid" 
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur} 
                    />
                </div>
              </div>
              <div className="card-footer d-flex justify-content-end py-6 px-9">
                <div className="col-xl-1 mb-5 mx-5">
                  <SaveButton
                    type="submit"
                    label={`Filtrar`}
                    loading={isLoading}
                    disabled={!formik.isValid || !formik.dirty}
                  />
                </div>
                <div className="col-xl-1 mb-5">
                  <SaveButton
                    type="button"
                    label={`Exportar`}
                    loading={isGerarRelatorio}
                    disabled={!formik.isValid || !formik.dirty}
                    onClick={gerarRelatorio}
                  />
                </div>
              </div>
            </form>
            <DataTable
              persistTableHead
              columns={tableColumns}
              data={tableData}
              progressPending={isLoading}
              progressComponent={tableHelper.getLoader()}
              noDataComponent={tableHelper.getNoDataComponent()}
              dense
              customStyles={itlabMediumTableStyle}
              striped 
              highlightOnHover
              contextMessage={tableHelper.getContextMessage()}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePerRowsChange}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationPerPage={rowsPerPage}
              paginationRowsPerPageOptions={[10, 25, 50, 100]}
              paginationComponentOptions={tableHelper.getPaginationComponentOptions()}
            />
          </div>
        </div>
      </MainLayout>
    </MasterLayout>
  );
}
