import i18n from 'i18next';
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import en from './i18n/en-us.json';
import pt from './i18n/pt-br.json';

// TODO: Export languages file to split different languages and create type safety when accessing language props

i18n
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    interpolation: {
      escapeValue: false,
    },
    fallbackLng: 'br',
    resources: {
      us: {
        translation: {
          menu: {
            home: 'Home',
            dashboard: 'Dashboard',
            charts: 'Charts',
            projectStatus: 'Project Status',
            security: 'Security',
            samples: 'Samples',
            calendar: 'Calendar',
            apiClients: 'API Clients',
            clients: {
              menu: 'Clients',
              title: 'Clients',
              add: 'Add Clients',
              edit: 'Edit Clients',
            },
            categories: {
              menu: 'Categories',
              title: 'Categories',
              add: 'Add Categories',
              edit: 'Edit Categories',
            },
            crud: {
              title: 'CRUD',
              name: 'Name',
              decimalValue: 'Decimal Value',
              dateOfBirth: 'Date of Birth',
              age: 'Age',
            },
            complexCrud: {
              title: 'Complex CRUD',
              name: 'Name',
              decimalValue: 'Decimal value',
              dateOfBirth: 'Date of birth',
              handicapped: 'Handicapped',
              termsAcceptance: 'Terms of acceptance',
              avatar: 'Image',
            },
            userManagement: {
              menu: 'User Management',
              title: 'User Management',
              add: 'Add User',
              edit: 'Edit User',
            },
            groupManagement: {
              menu: 'Group Management',
              title: 'Group Management',
              add: 'Add Group',
              edit: 'Edit Group',
            },
            settings: 'Configuration',
            userProfile: 'User Profile',
            generalSettings: 'General Settings',
            basic: {
              title: 'Basic CRUD',
              add: 'Add Data',
              edit: 'Edit Data',
            },
            complexdatabase: {
              menu: 'Complex CRUD Database',
              title: 'Complex CRUD Database',
              add: 'Add Data',
              edit: 'Edit Data',
            },
            complexfrontend: {
              menu: 'Complex CRUD Frontend',
              title: 'Complex CRUD Frontend',
              add: 'Add Data',
              edit: 'Edit Data',
            },
            detailScreen: 'Detail Screen',
            language: 'Language',
            preferences: 'Preferences',
            general: 'General',
            signOut: 'Sign Out',
          },

          generalMessages: {
            yes: 'Yes',
            no: 'No',
            all: 'All',
            active: 'Active',
            inactive: 'Inactive',
            search: 'Search',
            select: 'Select',
            sessionExpiresIn: 'Session expires in:',
            sessionExpired: 'Your session has expired. Please refresh page.'
          },

          units: {
            prefix: '$',
            thousandSeparator: ',',
            decimalSeparator: '.',
          },

          numberFormat: {
            locale: "en",
            currency: "USD"
          },

          dateFormat: {
            locale: 'en',
          },

          topbar: {
            system: {
              version: 'Version: ',
            },
            userMenu: {
              language: 'Language',
              signOut: 'Sign Out',
              myProfile: 'My Profile',
            },
          },

          crud: {
            common: {
              loading: 'Loading...',
              wait: 'Please wait...',
              select: 'Select',
              all: 'All',
              filter: 'Filter',
              filterOptions: 'Filter Options',
              status: 'Status',
              apply: 'Apply',
              edit: 'Edit',
              delete: 'Delete',
              deactivate: 'Deactivate',  
              confirm: 'Confirm',
              cancel: 'Cancel',
              back: 'Back',
              noActionPermission: 'No permission to perform this action.'
            },
            create: {
              buttonSave: 'Save',
              buttonCancel: 'Cancel',
              successMessage: 'Entry successfully created',
              errorMessage: 'An error occurred while saving entries. Please, try again later.',
            },
            read: {
              errorMessage: 'An error occurred while trying to fetch the list. Please, try again later.',
            },
            update: {
              buttonSave: 'Save',
              buttonCancel: 'Cancel',
              successMessage: 'Entry successfully updated',
              errorMessage: 'An error occurred while uploading entries. Please, try again later.',
            },
            delete: {
              buttonSave: 'Delete',
              buttonCancel: 'Cancel',
              confirmMessage: 'Are you sure you want to delete this entry?',
              successMessage: 'Entry successfully delete',
              errorMessage: 'An error occurred while deleting entries. Please, try again later.',
            },
            deactivate: {
              buttonSave: 'Deactivate',
              confirmMessage: 'Are you sure you want to deactivate this entry?',
              successMessage: 'Entry successfully deactivated',
              errorMessage: 'An error occurred while deactivating entries. Please, try again.',
            },
            validators: {
              requiredField: 'This field is required.',
              passwordMatch: 'Passwords must match.',
              passwordContains:
                'Passwords must have at least one number, one uppercase letter and one non alphanumeric character.',
              email: 'Must be a valid email.',
              minStartDate: 'The start date cannot be less than the current date.',
              minStartTime: 'The start time cannot be less than the current date and time.',
              minEndDate: 'The end date cannot be less than the start date.',
              minEndTime: 'The end time cannot be less than the start date and time.',
              minTwoWords: 'Must have at least two words.',
              minLength_5: 'Must be at least 5 characters.',
              minLength_6: 'Must be at least 6 characters.',
              maxLength_25: 'Must be at most 25 characters.',
              maxLength_70: 'Must be at most 70 characters.',
            },
          },
          toolbar: {
            filter: 'Filter',
            filterOptions: 'Filter Options',
            status: 'Status:',
            buttonApply: 'Apply',
            buttonCancel: 'Cancel',
          },
          datatable: {
            pagination: {
              title: 'Rows per page',
              of: 'of',
            },
            selectedItems: {
              all: 'All',
              singular: 'selected item',
              plural: 'selected items',
            },
            noData: 'No data available',
          },
        },
      },
      br: {
        translation: {
          menu: {
            home: 'Início',
            dashboard: 'Dashboard',
            charts: 'Gráficos',
            projectStatus: 'Status do Projeto',
            security: 'Segurança',
            samples: 'Exemplos',
            calendar: 'Calendário',
            apiClients: 'Clientes API',
            clients: {
              menu: 'Clientes',
              title: 'Clientes',
              add: 'Adicionar Clientes',
              edit: 'Editar Clientes',
            },
            categories: {
              menu: 'Categorias',
              title: 'Categorias',
              add: 'Adicionar Categorias',
              edit: 'Editar Categorias',
            },
            crud: {
              title: 'CRUD',
              name: 'Nome',
              decimalValue: 'Valor decimal',
              dateOfBirth: 'Data de aniversário',
              age: 'Idade',
            },
            complexCrud: {
              title: 'CRUD Complexo',
              name: 'Nome',
              decimalValue: 'Valor decimal',
              dateOfBirth: 'Data de aniversário',
              handicapped: 'Deficiente',
              termsAcceptance: 'Termos de aceite',
              avatar: 'Imagem',
            },
            userManagement: {
              title: 'Gerenciamento de Usuários',
              add: 'Adicionar usuário',
              edit: 'Editar usuário',
            },
            groupManagement: {
              title: 'Gerenciamento de Grupos',
              add: 'Adicionar Grupo',
              edit: 'Editar Grupo',
            },
            settings: 'Configurações',
            userProfile: 'Perfil de Usuário',
            healthCheck: 'Health Check',
            generalSettings: 'Configurações Gerais',
            basic: {
              title: 'CRUD Básico',
              add: 'Adicionar Dados',
              edit: 'Editar Dados',
            },
            complexdatabase: {
              title: 'CRUD Complexo Banco de Dados',
              add: 'Adicionar Dados',
              edit: 'Editar Dados',
            },
            complexfrontend: {
              title: 'CRUD Complexo Frontend',
              add: 'Adicionar Dados',
              edit: 'Editar Dados',
            },
            detailScreen: 'Tela de Detalhes',
            language: 'Idiomas',
            preferences: 'Preferências',
            general: 'Geral',
            signOut: 'Sair',
          },

          generalMessages: {
            yes: 'Sim',
            no: 'Não',
            all: 'Todos',
            active: 'Ativo',
            inactive: 'Inativo',
            search: 'Buscar',
            select: 'Selecione',
            sessionExpiresIn: 'Sessão expira em:',
            sessionExpired: 'Sua sessão expirou. Por favor atualize a página.'
          },

          units: {
            prefix: 'R$',
            thousandSeparator: '.',
            decimalSeparator: ',',
          },

          numberFormat: {
            locale: "pt-br",
            currency: "BRL"
          },

          dateFormat: {
            locale: 'pt-br',
          },

          topbar: {
            system: {
              version: 'Versão: ',
            },
            userMenu: {
              language: 'Idioma',
              signOut: 'Sair',
              myProfile: 'Meu Perfil',
            },
          },

          crud: {
            common: {
              loading: 'Carregando...',
              wait: 'Aguarde...',
              select: 'Selecione',
              all: 'Todos',
              filter: 'Filtrar',
              filterOptions: 'Opções de Filtro',
              status: 'Status',
              apply: 'Aplicar',
              edit: 'Editar',
              delete: 'Excluir',
              deactivate: 'Desativar',  
              confirm: 'Confirmar',
              cancel: 'Cancelar',
              back: 'Voltar',
              noActionPermission: 'Você não tem permissão para realizar esta ação.'
            },
            create: {
              buttonSave: 'Salvar',
              buttonCancel: 'Cancelar',
              successMessage: 'Registro criado com sucesso',
              errorMessage: 'Aconteceu algum erro durante o processo. Tente novamente mais tarde.',
            },
            read: {
              errorMessage: 'Aconteceu algum erro durante o processo de carregamento. Tente novamente mais tarde.',
            },
            update: {
              buttonSave: 'Salvar',
              buttonCancel: 'Cancelar',
              successMessage: 'Registro atualizado com sucesso',
              errorMessage: 'Aconteceu algum erro durante o processo de atualização. Tente novamente mais tarde.',
            },
            delete: {
              buttonSave: 'Excluir',
              buttonCancel: 'Cancelar',
              confirmMessage: 'Você tem certeza que quer excluir este registro?',
              successMessage: 'Registro excluído com sucesso.',
              errorMessage: 'Aconteceu algum erro durante o processo de exclusão. Tente novamente mais tarde.',
            },
            deactivate: {
              buttonSave: 'Desativar',
              confirmMessage: 'Você tem certeza que quer desativar este registro?',
              successMessage: 'Registro desativado com sucesso.',
              errorMessage: 'Aconteceu algum erro durante o processo de desativação. Tente novamente.',
            },
            validators: {
              requiredField: 'Este campo é obrigatório.',
              passwordMatch: 'Senhas devem ser iguais.',
              passwordContains:
                'Senha deve conter pelo menos um número, uma letra maiúscula e um caractere não alfanumérico.',
              email: 'Preencha com um email válido.',
              minStartDate: 'A data de início não pode ser inferior a data atual.',
              minStartTime: 'A hora de início não pode ser inferior a data e hora atual.',
              minEndDate: 'A data de término não pode ser inferior a data de início.',
              minEndTime: 'A hora de término não pode ser inferior a data e hora de início.',
              minTwoWords: 'Preencha com pelo menos duas palavras.',
              minLength_5: 'O campo deve ter no mínimo 5 caracteres.',
              minLength_6: 'Deve conter pelo menos 6 caracteres.',
              maxLength_25: 'O campo deve ter no máximo 25 caracteres.',
              maxLength_70: 'O campo deve ter no máximo 70 caracteres.',
            },
          },
          toolbar: {
            filter: 'Filtrar',
            filterOptions: 'Opções de Filtro',
            status: 'Status:',
            buttonApply: 'Aplicar',
            buttonCancel: 'Cancelar',
          },
          datatable: {
            pagination: {
              title: 'Linhas por página',
              of: 'de',
            },
            selectedItems: {
              all: 'Todos',
              singular: 'item selecionado', // ex. 1 item selecionado
              plural: 'itens selecionados', // ex. 8 itens selecionados
            },
            noData: 'Nenhum dado disponível',
          },
        },
      },
    },
  });

i18n.addResourceBundle('us', 'translation', en);
i18n.addResourceBundle('br', 'translation', pt);

export default i18n;
