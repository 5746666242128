import { RolePermission } from '../dictionaries/roles';
import { AuthToken } from './auth-token';

export class TokenHelper {
  private token: string | null;
  private language?: string;
  private userId?: number;
  private userName?: string;
  private email?: string;
  private roles?: number[];
  private unique_name?: string;

  constructor() {
    this.token = AuthToken.getToken();
    const decodedToken = AuthToken.getDecodedToken() as any;
    let roleArray: string[] = [];

    if (decodedToken) {
      if (Array.isArray(decodedToken.role)) {
        roleArray = decodedToken.role as string[];
      } else {
        roleArray.push(decodedToken.role);
      }

      this.language = decodedToken.language;
      this.userId = decodedToken.userid;
      this.userName = decodedToken.username;
      this.email = decodedToken.email;
      this.roles = roleArray.map((r) => parseInt(r));
      this.unique_name = decodedToken.unique_name;
    }
  }

  GetLanguage(): string {
    const lang = localStorage.getItem('tokenLanguage');
    return lang || this.language || 'br';
  }

  SetLanguage(language: string) {
    this.language = language;
    localStorage.setItem('tokenLanguage', language);
  }

  GetName(): string {
    return this.unique_name || '';
  }

  GetId(): number {
    return this.userId || 0;
  }

  GetEmail(): string {
    return this.email || '';
  }

  private HasRoles(): boolean {
    if (!this.roles) return false;

    return this.roles.length > 0;
  }

  HasAccess(roles: number[]): boolean {
    roles.push(RolePermission.Administrator)
    if (!this.HasRoles()) {
      return false;
    }

    const found = this.roles?.find((item) => roles.indexOf(item) >= 0);
    return found ? found! > 0 : false;
  }

  HasToken(): boolean {
    return this.token != null;
  }
}
