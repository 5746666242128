import { useEffect, useRef, useState } from 'react';
import {
  MasterLayout,
  MainLayout,
  ToolbarLayout,
  ToolbarLayoutLeft,
  ToolbarLayoutRight,
} from 'common/imports/content-layout';
import { ImportacaoViewModel } from '../models/importacao.model';
import { ImportacaoService } from '../services/importacao.service';
import { toast } from 'react-toastify';
import SaveButton from 'common/partials/SaveButton';
import { ITSVG } from "common/helpers/ITSVG";
import iconCheck from "assets/images/icons/check.svg";
import iconError from "assets/images/icons/error.svg";
import DataTable, { TableColumn } from 'react-data-table-component';
import { LogImportacaoViewModel } from '../models/log-importacao.model';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { tableHelper } from 'common/helpers/table/table-helper';
import { itlabMediumTableStyle } from 'common/helpers/table/table-style';
export function ImportacaoContratos() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isContratoCSVImportado, setIsContratoCSVImportado] = useState(false);
  const [isTabelaServicosCSVImportado, setIsTabelaServicosCSVImportado] = useState(false);
  const [isContratoUploading, setIsContratoUploading] = useState(false);
  const [isTabelaServicosUploading, setIsTabelaServicosUploading] = useState(false);
  const [contratoFile, setContratoFile] = useState<File | null>(null);
  const [tabelaServicosFile, setTabelaServicosFile] = useState<File | null>(null);
  const [tableData, setTableData] = useState<LogImportacaoViewModel[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const contratoFileRef = useRef<HTMLInputElement>(null);
  const tabelaServicosFileRef = useRef<HTMLInputElement>(null);
  
  const handlePerRowsChange = async (newPerPage: number) => {
    setRowsPerPage(newPerPage);
  };

  const columns: TableColumn<LogImportacaoViewModel>[] = [
    {
      name: `${t('Tipo')}`,
      selector: (row) => row.tipo || '',
      sortable: true,
      width: '100px'
    },
    {
      name: `${t('Sucesso')}`,
      cell: (row) =>
        <>
          <ITSVG path={row.sucesso ? iconCheck : iconError} className="svg-icon-1 position-absolute ms-6" />
        </>,
      center: true,
      width: '100px'
    },
    {
      name: `${t('Data Processamento')}`,
      selector: (row) =>
        moment(row.dataCriacao)
          .locale(`${t('dateFormat.locale')}`)
          .format('DD/MM/yyyy HH:mm:ss'),
      sortable: true,
      center: true,
      width: '180px'
    },
    {
      name: `${t('Observações')}`,
      selector: (row) => row.observacoes || '',
      sortable: true,
    }
  ];

  const handleContratoInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setContratoFile(event.target.files[0]);
    }
  };
  const handleTabelaServicosInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setTabelaServicosFile(event.target.files[0]);
    }
  };
  const handleContratoUpload = async () => {
    if (contratoFile) {
      setIsContratoUploading(true);
      try {
        const body: ImportacaoViewModel = { file: contratoFile };
        const response = await ImportacaoService.postContratoCsvFile(body);
        if (response) {
          if (!response.hasErrors) {
            toast.success(response.data.mensagem);
          } else {
            toast.error(response.message);
          }
        }
        else {
          toast.error("Erro ao enviar dados. Por favor tente novamente.");
        }
      } catch (error) {
        toast.error("Erro ao enviar dados. Por favor tente novamente.");
      } finally {
        if (contratoFileRef.current) {
          contratoFileRef.current.value = "";
        }
        setContratoFile(null);
        setIsContratoUploading(false);
      }
    }
  };
  const handleTabelaServicosUpload = async () => {
    if (tabelaServicosFile) {
      setIsTabelaServicosUploading(true);
      try {
        const body: ImportacaoViewModel = { file: tabelaServicosFile };
        const response = await ImportacaoService.postTabelaServicosCsvFile(body);
        if (response) {
          if (!response.hasErrors) {
            toast.success(response.data.mensagem);
          } else {
            toast.error(response.message);
          }
        }
        else {
          toast.error("Erro ao enviar dados. Por favor tente novamente.");
        }
      } catch (error) {
        toast.error("Erro ao enviar dados. Por favor tente novamente.");
      } finally {
        if (tabelaServicosFileRef.current) {
          tabelaServicosFileRef.current.value = "";
        }
        setTabelaServicosFile(null);
        setIsTabelaServicosUploading(false);
      }
    }
  };
  const handleProcessar = async() => {
    setIsLoading(true);
    const response = await ImportacaoService.processarContratosImportados();
    setIsLoading(false);
    if (response) {
      if (!response.hasErrors) {
        toast.success("Contratos processados com sucesso");
      } else {
        toast.error(response.message);
      }
    }
    else {
      toast.error("Erro ao enviar dados. Por favor tente novamente.");
    }
  };

  const fetchLogsImportacao = async () => {
    const response = await ImportacaoService.getLogsImportacao();
    if (response && !response.hasErrors) {
      setTableData(response.data);
    }
  };
  const fetchContratoImportado = async () => {
    const response = await ImportacaoService.getContratoCSVImportado();
    if (response && !response.hasErrors) {
      setIsContratoCSVImportado(Boolean(response.data));
    }
  };
  const fetchTabelaServicosImportado = async () => {
    const response = await ImportacaoService.getTabelaServicosCSVImportado();
    if (response && !response.hasErrors) {
      setIsTabelaServicosCSVImportado(Boolean(response.data));
    }
  };

  useEffect(() => {
    fetchContratoImportado();
    fetchTabelaServicosImportado();
    fetchLogsImportacao();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isContratoUploading, isTabelaServicosUploading, isLoading]);

  return (
    <MasterLayout>
      <ToolbarLayout>
        <ToolbarLayoutLeft />
        <ToolbarLayoutRight />
      </ToolbarLayout>
      <MainLayout>
        <div className="card mb-5 mb-xl-10">
          <div className="card-body">

            <div className="row mb-8">
              <div className="col-md-12">
                <label className="card-title fw-bolder text-muted fs-4">
                  Importação de Contratos
                </label>
              </div>
            </div>

            <div className="row mb-5">
              <label className="col-lg-4 col-form-label fw-bold fs-6">
                Contratos
                {isContratoCSVImportado && <ITSVG path={iconCheck} className="svg-icon-1 position-absolute ms-6" />}
              </label>

              <div className="col-sm-6 col-lg-6 mb-6">
                <input
                  type="file"
                  ref={contratoFileRef}
                  className="form-control form-control-solid"
                  onChange={handleContratoInputChange}
                  accept=".csv,.txt"
                />
              </div>

              <div className="col-sm-6 col-lg-2">
                <div className="d-grid">
                  <SaveButton
                    type="button"
                    loading={isContratoUploading}
                    disabled={!contratoFile}
                    onClick={handleContratoUpload}
                    label="Enviar"
                  />
                </div>
              </div>

            </div>

            <div className="row mb-5">
              <label className="col-lg-4 col-form-label fw-bold fs-6">
                Tabela Serviços
                {isTabelaServicosCSVImportado && <ITSVG path={iconCheck} className="svg-icon-1 position-absolute ms-6" />}
              </label>

              <div className="col-sm-6 col-lg-6 mb-6">
                <input
                  type="file"
                  ref={tabelaServicosFileRef}
                  className="form-control form-control-solid"
                  onChange={handleTabelaServicosInputChange}
                  accept=".csv,.txt"
                />
              </div>

              <div className="col-sm-6 col-lg-2">
                <div className="d-grid">
                  <SaveButton
                    type="button"
                    loading={isTabelaServicosUploading}
                    disabled={!tabelaServicosFile}
                    onClick={handleTabelaServicosUpload}
                    label="Enviar"
                  />
                </div>
              </div>

            </div>
          </div>
          <div className="card-footer d-flex justify-content-end py-6 px-9">
                <SaveButton
                  type="button"
                  loading={isLoading}
                  disabled={!isContratoCSVImportado || !isTabelaServicosCSVImportado}
                  onClick={handleProcessar}
                  label="Processar"
                />
          </div>
        </div>
      
        <div className="card">
          <div className="card-body">
            <DataTable
              persistTableHead
              columns={columns}
              data={tableData}
              progressPending={isLoading}
              progressComponent={tableHelper.getLoader()}
              contextMessage={tableHelper.getContextMessage()}
              onChangeRowsPerPage={handlePerRowsChange}
              noDataComponent={tableHelper.getNoDataComponent()}
              dense
              striped
              highlightOnHover
              customStyles={itlabMediumTableStyle}
              pagination
              paginationPerPage={rowsPerPage}
              paginationRowsPerPageOptions={[10, 25, 50, 100]}
              paginationComponentOptions={tableHelper.getPaginationComponentOptions()}
            />
          </div>
        </div>
      </MainLayout>
    </MasterLayout>
  )
}
