import { useRef, useState } from 'react';
import {
  MasterLayout,
  MainLayout,
  ToolbarLayout,
  ToolbarLayoutLeft,
  ToolbarLayoutRight,
} from 'common/imports/content-layout';
import { ImportacaoViewModel } from '../models/importacao.model';
import { ImportacaoService } from '../services/importacao.service';
import { toast } from 'react-toastify';
import SaveButton from 'common/partials/SaveButton';
import { RolePermission } from 'common/helpers/dictionaries/roles';

function Importacao() {
  const [isCurvasUploading, setIsCurvasUploading] = useState(false);
  const [isInputsUploading, setIsInputsUploading] = useState(false);
  const [isPocsUploading, setIsPocsUploading] = useState(false);
  const [isContasUploading, setIsContasUploading] = useState(false);
  const [isParecerUploading, setIsParecerUploading] = useState(false);
  const [curvasFile, setCurvasFile] = useState<File | null>(null);
  const [inputsFile, setInputsFile] = useState<File | null>(null);
  const [pocsFile, setPocsFile] = useState<File | null>(null);
  const [contasFile, setContasFile] = useState<File | null>(null);
  const [parecerFile, setParecerFile] = useState<File | null>(null);
  const curvasFileRef = useRef<HTMLInputElement>(null);
  const inputsFileRef = useRef<HTMLInputElement>(null);
  const pocsFileRef = useRef<HTMLInputElement>(null);
  const contasFileRef = useRef<HTMLInputElement>(null);
  const parecerFileRef = useRef<HTMLInputElement>(null);

  const handleCurvasChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setCurvasFile(event.target.files[0]);
    }
  };

  const handleInputsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setInputsFile(event.target.files[0]);
    }
  };

  const handlePocsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setPocsFile(event.target.files[0]);
    }
  };

  const handleContasChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setContasFile(event.target.files[0]);
    }
  };

  const handleParecerInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setParecerFile(event.target.files[0]);
    }
  };

  const handleCurvasUpload = async () => {
    if (curvasFile) {
      setIsCurvasUploading(true);
      try {
        const body: ImportacaoViewModel = { file: curvasFile };
        const response = await ImportacaoService.postCurvasFile(body);
        if (response && !response.hasErrors) {
          toast.success(response.data.mensagem);
        } else {
          toast.error("Erro ao enviar dados. Por favor tente novamente.");
        }
      } catch (error) {
        console.log(error);
      } finally {
        if (curvasFileRef.current) {
          curvasFileRef.current.value = "";
        }
        setCurvasFile(null);
        setIsCurvasUploading(false);
      }
    }
  };

  const handleInputsUpload = async () => {
    if (inputsFile) {
      setIsInputsUploading(true);
      try {
        const body: ImportacaoViewModel = { file: inputsFile };
        const response = await ImportacaoService.postInputsFile(body);
        if (response && !response.hasErrors) {
          toast.success(response.data.mensagem);
        } else {
          toast.error("Erro ao enviar dados. Por favor tente novamente.");
        }
      } catch (error) {
        console.log(error);
      } finally {
        if (inputsFileRef.current) {
          inputsFileRef.current.value = "";
        }
        setInputsFile(null);
        setIsInputsUploading(false);
      }
    }
  };

  const handlePocsUpload = async () => {
    if (pocsFile) {
      setIsPocsUploading(true);
      try {
        const body: ImportacaoViewModel = { file: pocsFile };
        const response = await ImportacaoService.postPocsFile(body);
        if (response && !response.hasErrors) {
          toast.success(response.data.mensagem);
        } else {
          toast.error("Erro ao enviar dados. Por favor tente novamente.");
        }
      } catch (error) {
        console.log(error);
      } finally {
        if (pocsFileRef.current) {
          pocsFileRef.current.value = "";
        }
        setPocsFile(null);
        setIsPocsUploading(false);
      }
    }
  };

  const handleContasUpload = async () => {
    if (contasFile) {
      setIsContasUploading(true);
      try {
        const body: ImportacaoViewModel = { file: contasFile };
        const response = await ImportacaoService.postContasFile(body);
        if (response && !response.hasErrors) {
          toast.success(response.data.mensagem);
        } else {
          toast.error("Erro ao enviar dados. Por favor tente novamente.");
        }
      } catch (error) {
        console.log(error);
      } finally {
        if (contasFileRef.current) {
          contasFileRef.current.value = "";
        }
        setContasFile(null);
        setIsContasUploading(false);
      }
    }
  };

  const handleParecerUpload = async () => {
    if (parecerFile) {
      setIsParecerUploading(true);
      try {
        const body: ImportacaoViewModel = { file: parecerFile };
        const response = await ImportacaoService.postParecerFile(body);
        if (response && !response.hasErrors) {
          toast.success(response.data.mensagem);
        } else {
          toast.error("Erro ao enviar dados. Por favor tente novamente.");
        }
      } catch (error) {
        console.log(error);
      } finally {
        if (parecerFileRef.current) {
          parecerFileRef.current.value = "";
        }
        setParecerFile(null);
        setIsParecerUploading(false);
      }
    }
  };

  return (
    <MasterLayout>
      <ToolbarLayout>
        <ToolbarLayoutLeft />
        <ToolbarLayoutRight />
      </ToolbarLayout>
      <MainLayout>
        <div className="card">
          <div className="card-body">

            <div className="row mb-8">
              <div className="col-md-12">
                <label className="card-title fw-bolder text-muted fs-4">
                  Importação de Dados
                </label>
              </div>
            </div>

            <div className="row mb-5">
              <label className="col-lg-4 col-form-label fw-bold fs-6">
                Curvas (.csv)
              </label>

              <div className="col-sm-6 col-lg-6 mb-6">
                <input
                  type="file"
                  ref={curvasFileRef}
                  className="form-control form-control-solid"
                  onChange={handleCurvasChange}
                  accept=".csv,.txt"
                />
              </div>

              <div className="col-sm-6 col-lg-2">
                <div className="d-grid text-center">
                  <SaveButton
                    type="button"
                    loading={isCurvasUploading}
                    disabled={!curvasFile}
                    onClick={handleCurvasUpload}
                    label="Enviar"
                    roles={[RolePermission.Update_Importacao]}
                  />
                </div>
              </div>

            </div>

            <div className="row mb-5">
              <label className="col-lg-4 col-form-label fw-bold fs-6">
                Serviços Percentuais (.csv)
              </label>

              <div className="col-sm-6 col-lg-6 mb-6">
                <input
                  type="file"
                  ref={inputsFileRef}
                  className="form-control form-control-solid"
                  onChange={handleInputsChange}
                  accept=".csv,.txt"
                />
              </div>

              <div className="col-sm-6 col-lg-2">
                <div className="d-grid text-center">
                  <SaveButton
                    type="button"
                    loading={isInputsUploading}
                    disabled={!inputsFile}
                    onClick={handleInputsUpload}
                    label="Enviar"
                    roles={[RolePermission.Update_Importacao]}
                  />
                </div>
              </div>

            </div>

            <div className="row mb-5">
              <label className="col-lg-4 col-form-label fw-bold fs-6">
                Pocs (.csv)
              </label>

              <div className="col-sm-6 col-lg-6 mb-6">
                <input
                  type="file"
                  ref={pocsFileRef}
                  className="form-control form-control-solid"
                  onChange={handlePocsChange}
                  accept=".csv,.txt"
                />
              </div>

              <div className="col-sm-6 col-lg-2">
                <div className="d-grid text-center">
                  <SaveButton
                    type="button"
                    loading={isPocsUploading}
                    disabled={!pocsFile}
                    onClick={handlePocsUpload}
                    label="Enviar"
                    roles={[RolePermission.Update_Importacao]}
                  />
                </div>
              </div>

            </div>

            <div className="row mb-5">
              <label className="col-lg-4 col-form-label fw-bold fs-6">
                Contas (.csv)
              </label>

              <div className="col-sm-6 col-lg-6 mb-6">
                <input
                  type="file"
                  ref={contasFileRef}
                  className="form-control form-control-solid"
                  onChange={handleContasChange}
                  accept=".csv,.txt"
                />
              </div>

              <div className="col-sm-6 col-lg-2">
                <div className="d-grid text-center">
                  <SaveButton
                    type="button"
                    loading={isContasUploading}
                    disabled={!contasFile}
                    onClick={handleContasUpload}
                    label="Enviar"
                    roles={[RolePermission.Update_Importacao]}
                  />
                </div>
              </div>

            </div>

            <div className="row mb-5">
              <label className="col-lg-4 col-form-label fw-bold fs-6">
                Parecer (.xlsx)
              </label>

              <div className="col-sm-6 col-lg-6 mb-6">
                <input
                  type="file"
                  ref={parecerFileRef}
                  className="form-control form-control-solid"
                  onChange={handleParecerInputChange}
                />
              </div>

              <div className="col-sm-6 col-lg-2">
                <div className="d-grid text-center">
                  <SaveButton
                    type="button"
                    loading={isParecerUploading}
                    disabled={!parecerFile}
                    onClick={handleParecerUpload}
                    label="Enviar"
                    roles={[RolePermission.Update_Importacao]}
                  />
                </div>
              </div>

            </div>

          </div>

        </div>
      </MainLayout>
    </MasterLayout>
  )
}

export default Importacao;