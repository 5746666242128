import { LotesContratacaoCorbanContratoAntecipadoViewModel } from 'app/lotes-contratacao/model/lotes-contratacao.model';
import { LotesContratacaoService } from 'app/lotes-contratacao/services/lotes-contratacao.service';
import { currencyMask } from 'common/helpers/masks/CurrencyMask';
import { tableHelper } from 'common/helpers/table/table-helper';
import { itlabMediumTableStyle } from 'common/helpers/table/table-style';
import moment from 'moment';
import { useEffect, useState } from 'react'
import DataTable, { TableColumn } from 'react-data-table-component'
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

function ContratosAntecipadosTable() {
  const { loteId, corbanId } = useParams();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState<LotesContratacaoCorbanContratoAntecipadoViewModel[]>([]);
  const [page] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalRows, setTotalRows] = useState(0);

  const handlePageChange = (page: number) => {
    fetchData(page - 1);
  };

  const handlePerRowsChange = async (newPerPage: number) => {
    setRowsPerPage(newPerPage);
  };

  const tableColumns: TableColumn<LotesContratacaoCorbanContratoAntecipadoViewModel>[] = [
    {
      name: `${t("Contrato Externo")}`,
      selector: (row) => row.contratoExterno || "",
      sortable: true,
      minWidth: '160px'
    },
    {
      name: `${t("Data Operação")}`,
      selector: (row) =>
        moment(row.dataOperacao)
          .locale(`${t("dateFormat.locale")}`)
          .format("L"),
      sortable: true,
      center: true,
      minWidth: "140px"
    },
    {
      name: `${t("Qtd. Parcelas")}`,
      selector: (row) => row.quantidadeParcelas || 0,
      sortable: true,
      center: true,
      minWidth: "130px"
    },
    {
      name: `${t("1ª Parcela")}`,
      selector: (row) =>
        moment(row.primeiraParcela)
          .locale(`${t("dateFormat.locale")}`)
          .format("L"),
      sortable: true,
      center: true,
      minWidth: "110px"
    },
    {
      name: `${t("Valor Comissionável")}`,
      selector: (row) => currencyMask(row.valorComissionavel) || "",
      sortable: true,
      right: true,
      minWidth: "180px"
    },
    {
      name: `${t("Valor Antecipado")}`,
      selector: (row) => currencyMask(row.valorAntecipado) || "",
      sortable: true,
      right: true,
      minWidth: "150px"
    },
    {
      name: `${t("Taxa Antecipação")}`,
      selector: (row) => (row.taxaAntecipacao ?? 0).toFixed(4).replace('.', ','),
      sortable: true,
      right: true,
      minWidth: "160px"
    }
  ];

  const fetchData = async (page: number) => {
    setIsLoading(true);
    try {
      if (loteId && corbanId) {
        const response = await LotesContratacaoService.getContratosLoteContratacao(page, rowsPerPage, loteId, corbanId);
        if (response && !response.hasErrors) {
          setTableData(response.data);
          setTotalRows(response.totalItems);
        }
      }
    } catch (err) {
      toast.error(t("crud.read.errorMessage"));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage]);

  return (
    <div className="mt-10">
      <DataTable
        persistTableHead
        columns={tableColumns}
        data={tableData}
        progressPending={isLoading}
        progressComponent={tableHelper.getLoader()}
        noDataComponent={tableHelper.getNoDataComponent()}
        dense
        customStyles={itlabMediumTableStyle}
        striped
        highlightOnHover
        contextMessage={tableHelper.getContextMessage()}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerRowsChange}
        pagination
        paginationServer
        paginationTotalRows={totalRows}
        paginationPerPage={rowsPerPage}
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
        paginationComponentOptions={tableHelper.getPaginationComponentOptions()}
      />
    </div>
  )
}

export default ContratosAntecipadosTable