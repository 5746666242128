import i18next from "i18next";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { Link, useParams } from "react-router-dom";
import {
  MasterLayout,
  MainLayout,
  ToolbarLayout,
  ToolbarLayoutLeft,
  ToolbarLayoutRight,
} from "common/imports/content-layout";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import en from "../i18n/en-us.json";
import pt from "../i18n/pt-br.json";
import { CorbansService as Service } from "../services/corbans.service";
import { PocTable } from "./child/PocTable";
import { LinkedAccounts } from "./child/LinkedAccounts";
import { Subs } from "./child/Subs";
import { CorbanViewModel, EnderecoViewModel } from "app/lotes-contratacao/model/lotes-contratacao.model";
import { DatePicker } from "antd";
import locale from "antd/es/date-picker/locale/pt_BR";
import dayjs from "dayjs";
import moment from "moment";
import { Autocomplete, TextField } from "@mui/material";
import { MotivoBloqueioViewModel } from "app/motivos-bloqueio/model/motivos-bloqueio.model";
import { MotivosBloqueioService } from "app/motivos-bloqueio/services/motivos-bloqueio.service";
import SaveButton from "common/partials/SaveButton";
import { RolePermission } from "common/helpers/dictionaries/roles";

export function CreateEditCorbans() {
  i18next.addResourceBundle("us", "translation", en);
  i18next.addResourceBundle("br", "translation", pt);

  const { t } = useTranslation();
  const { id } = useParams();
  const dateFormat = "DD/MM/YYYY";

  const formSchema = Yup.object().shape({
    codigoOrigem: Yup
      .string()
      .required(`${t('crud.validators.requiredField')}`),
    cnpj: Yup
      .string()
      .required(`${t('crud.validators.requiredField')}`),
    razaoSocial: Yup
      .string()
      .required(`${t('crud.validators.requiredField')}`),
    nomeFantasia: Yup
      .string()
      .nullable(),
    grupoMutuo: Yup
      .string()
      .nullable(),
    dataRescisao: Yup
      .string()
      .nullable(),
    bloqueioMutuo: Yup.boolean().nullable(),
    motivoBloqueioId: Yup.string().nullable().when("bloqueioMutuo",
      {
        is: true,
        then: Yup
          .string()
          .required(`${t("crud.validators.requiredField")}`)
          .nullable()
      })
  });

  let initialData: CorbanViewModel = {
    id: parseInt(id ?? "0"),
    codigoLojaArrecadadora: 0,
    codigoOrigem: "",
    cnpj: "",
    razaoSocial: "",
    nomeFantasia: "",
    motivoBloqueioId: 0,
    elegivel: false,
    isentoIRRF: false,
    isentoISS: false,
    aliquotaISS: 0,
    valorIRRF: 0,
    valorISS: 0,
    grupoMutuo: "",
    cpfAvalista: "",
    agenciaContratante: "",
    lojaArrecadadoraAtiva: false,
    bloqueioMutuo: false,
    saldoLimite: 0,
    email: "",
    subs: [],
    contas: [],
    enderecos: [],
    dacContratante: "",
    quantidadeContratos: 0
  };

  const [data, setData] = useState<any>(initialData);
  const [enderecos, setEnderecos] = useState<EnderecoViewModel>()
  const [isLoading, setIsLoading] = useState(false);
  const [motivosBloqueio, setMotivosBloqueio] = useState<MotivoBloqueioViewModel[]>([]);
  const [motivoBloqueioDefaultValue, setMotivoBloqueioDefaultValue] = useState<MotivoBloqueioViewModel | undefined | null>(null);

  const formik = useFormik<CorbanViewModel>({
    enableReinitialize: true,
    initialValues: data,
    validationSchema: formSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading(true);
        const body: CorbanViewModel = {
          id: parseInt(id ?? "0"),
          codigoOrigem: values.codigoOrigem,
          cnpj: values.cnpj,
          razaoSocial: values.razaoSocial,
          nomeFantasia: values.nomeFantasia,
          bloqueioMutuo: values.bloqueioMutuo,
          motivoBloqueioId: values.motivoBloqueioId,
          dataElegivel: values.dataElegivel,
          dataRescisao: values.dataRescisao ? moment(values.dataRescisao).format("YYYY-MM-DD") : undefined,
          grupoMutuo: values.grupoMutuo,
          cpfAvalista: values.cpfAvalista,
          agenciaContratante: values.agenciaContratante,
          dacContratante: values.dacContratante,
          valorIRRF: values.valorIRRF,
          valorISS: values.valorISS,
          situacao: values.situacao,
          lojaArrecadadoraAtiva: values.lojaArrecadadoraAtiva,
          saldoLimite: values.saldoLimite,
          isentoIRRF: values.isentoIRRF,
          isentoISS: values.isentoISS,
          email: "",
          subs: [],
          contas: [],
          enderecos: [],
          quantidadeContratos: 0,
        };

        if (!values.bloqueioMutuo) {
          delete body.motivoBloqueioId;
        }

        if (id) {
          const result = await Service.putData(body);
          if (result && !result?.hasErrors) {
            toast.success(t("crud.update.successMessage"));
          }
        }
      } catch (error) {
        toast.error(t("crud.update.errorMessage"));
      } finally {
        fetchData();
        setIsLoading(false);
      }
    },
  });

  const handlePostCorbanElegivel = async () => {
    const response = await Service.putIsCorbanElegivel(id!)
    if (response && !response?.hasErrors) {
      if (response.data.elegivel) {
        formik.setFieldValue("dataElegivel", response.data.dataElegivel)
        toast.success(t("Corban atualizado para elegível."));
      } else {
        formik.setFieldValue("dataElegivel", response.data.dataElegivel)
        toast.success(t("Corban atualizado para não elegível."));
      }
    } else {
      toast.error("Erro ao atualizar corban.")
    }
  }

  useEffect(() => {
    const fetchEnderecosByCorbanId = async () => {
      setIsLoading(true);
      if (id) {
        const response = await Service.getEnderecosByCorban(Number(id));
        if (response && !response.hasErrors) {
          setEnderecos(response.data[0]);
        }
      }
      setIsLoading(false);
    };
    fetchEnderecosByCorbanId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  useEffect(() => {
    if (data !== initialData) {
      const fetchMotivosBloqueio = async () => {
        setIsLoading(true);
        const response = await MotivosBloqueioService.getDataList();
        if (response && !response.hasErrors) {
          setMotivosBloqueio(response.data);
          if (data.motivoBloqueioId > 0) {
            setMotivoBloqueioDefaultValue(response?.data.find(value => value.id === data.motivoBloqueioId));
          }
        }
        setIsLoading(false);
      };
      fetchMotivosBloqueio();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const fetchData = async () => {
    setIsLoading(true);
    if (id) {
      const response = await Service.getCorbanById(id);
      if (response && !response.hasErrors) {
        setData(response.data);
      } else {
        toast.error(t("crud.read.errorMessage"));
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <MasterLayout>
      <ToolbarLayout>
        <ToolbarLayoutLeft />
        <ToolbarLayoutRight />
      </ToolbarLayout>
      <MainLayout>
        <form onSubmit={formik.handleSubmit}>
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-sm-4 col-md-3 col-xxl-2 mb-10">
                  <label className="required form-label">{t("IdLoja")}</label>
                  <input
                    type="text"
                    name="codigoOrigem"
                    className="form-control form-control-lg form-control-solid"
                    value={formik?.values?.codigoOrigem ?? ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.codigoOrigem && formik.errors.codigoOrigem && (
                    <div className="mt-3 text-danger fw-bold">{formik.errors.codigoOrigem}</div>
                  )}
                </div>
                <div className="col-sm-6 col-md-4 col-xxl-3 mb-10">
                  <label className="required form-label">{t("corban.form.fields.cnpj.label")}</label>
                  <input
                    type="text"
                    name="cnpj"
                    className="form-control form-control-lg form-control-solid"
                    value={(formik?.values?.cnpj ?? "").replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5") ?? ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.cnpj && formik.errors.cnpj && (
                    <div className="mt-3 text-danger fw-bold">{formik.errors.cnpj}</div>
                  )}
                </div>

                <div className="col-sm-6 col-md-8 col-xxl-5 mb-10">
                  <label className="required form-label">{t("corban.form.fields.razaoSocial.label")}</label>
                  <input
                    type="text"
                    name="razaoSocial"
                    className="form-control form-control-lg form-control-solid"
                    placeholder={t("corban.form.fields.razaoSocial.placeholder").toString()}
                    value={formik?.values?.razaoSocial ?? ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.razaoSocial && formik.errors.razaoSocial && (
                    <div className="mt-3 text-danger fw-bold">{formik.errors.razaoSocial}</div>
                  )}
                </div>
                <div className="col-sm-6 col-md-5 col-xxl-4 mb-10">
                  <label className="form-label">{t("corban.form.fields.grupoMutuo.label")}</label>
                  <input
                    type="text"
                    name="grupoMutuo"
                    className="form-control form-control-lg form-control-solid"
                    placeholder={t("corban.form.fields.grupoMutuo.placeholder").toString()}
                    value={formik?.values?.grupoMutuo ?? ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                <div className="col-sm-6 col-md-5 col-xxl-4 mb-10">
                  <label className="form-label">{t("corban.form.fields.nomeFantasia.label")}</label>
                  <input
                    type="text"
                    name="nomeFantasia"
                    className="form-control form-control-lg form-control-solid"
                    placeholder={t("corban.form.fields.nomeFantasia.placeholder").toString()}
                    value={formik?.values?.nomeFantasia ?? ""}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>


                <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-2 mb-10">
                  <label className="form-label">{t("corban.form.fields.dataRescisao.label")}</label>
                  <DatePicker
                    locale={locale}
                    name="dataRescisao"
                    value={
                      formik.values.dataRescisao
                        ? dayjs(moment(formik.values.dataRescisao).toDate())
                        : null
                    }
                    format={dateFormat}
                    allowClear={true}
                    className="form-control form-control-lg form-control-solid"
                    onChange={(e) => { formik.setFieldValue("dataRescisao", e ? dayjs(e).format() : null) }}
                    onBlur={() => formik.setFieldTouched("dataRescisao")}
                  />
                  {formik.touched.dataRescisao && formik.errors.dataRescisao && (
                    <div className="mt-3 text-danger fw-bold">
                      <div className="fv-help-block">{formik.errors.dataRescisao}</div>
                    </div>
                  )}
                </div>

                <div className="col-6 col-sm-6 col-md-3 col-lg-3 col-xl-2 mb-10">
                  <label className="form-label">{t("corban.form.fields.dataElegivel.label")}</label>
                  <input
                    type="text"
                    name="dataElegivel"
                    className="form-control form-control-lg form-control-solid"
                    value={formik?.values?.dataElegivel ? moment(formik?.values?.dataElegivel).locale(`${t("dateFormat.locale")}`).format("L") : ""}
                    disabled
                  />
                </div>

                <div className="col-4 col-sm-2 col-md-1 mb-10">
                  <label className="form-label">{t("corban.form.fields.elegivel.label")}</label>
                  <div className="form-check form-check-solid form-switch">
                    <input
                      className="form-check-input w-45px h-30px mt-2"
                      type="checkbox"
                      name="elegivel"
                      checked={formik.values?.elegivel ? true : false}
                      onChange={(e) => {
                        formik.setFieldValue("elegivel", e.target.checked);
                        handlePostCorbanElegivel();
                      }}
                    />
                  </div>
                </div>

                <div className="col-4 col-sm-2 col-md-2 col-lg-1 mb-10">
                  <label className="form-label">{t("corban.form.fields.irrf.label")}</label>
                  <div className="form-check form-check-solid form-switch">
                    <input
                      className="form-check-input w-45px h-30px mt-2"
                      type="checkbox"
                      name="isentoIRRF"
                      checked={formik.values?.isentoIRRF ? true : false}
                      onChange={(e) => formik.setFieldValue("isentoIRRF", e.target.checked)}
                    />
                  </div>
                </div>

                <div className="col-4 col-sm-2 col-md-2 col-lg-1 mb-10">
                  <label className="form-label">{t("corban.form.fields.iss.label")}</label>
                  <div className="form-check form-check-solid form-switch">
                    <input
                      className="form-check-input w-45px h-30px mt-2"
                      type="checkbox"
                      name="isentoISS"
                      checked={formik.values?.isentoISS ? true : false}
                      onChange={(e) => formik.setFieldValue("isentoISS", e.target.checked)}
                    />
                  </div>
                </div>

                <div className="col-3 col-sm-3 col-md-2 col-lg-1 mb-10">
                  <label className="form-label">{t("Bloqueio")}</label>
                  <div className="form-check form-check-solid form-switch">
                    <input
                      className="form-check-input w-45px h-30px mt-2"
                      type="checkbox"
                      name="bloqueioMutuo"
                      checked={formik.values?.bloqueioMutuo ? true : false}
                      onChange={(e) => formik.setFieldValue("bloqueioMutuo", e.target.checked)}
                    />
                  </div>
                </div>

                <div className="col-9 col-sm-9 col-md-6 col-lg-5 col-xl-4 col-xxl-4 mb-10">
                  <label className={`form-label ${formik.values.bloqueioMutuo ? "required" : ""}`}>Motivo de Bloqueio</label>
                  <Autocomplete
                    value={motivoBloqueioDefaultValue ?? null}
                    options={motivosBloqueio}
                    getOptionLabel={option => option.descricao ? option.descricao : ""}
                    onChange={(_, value) => {
                      setMotivoBloqueioDefaultValue(value)
                      formik.setFieldValue("motivoBloqueioId", value?.id!)
                    }}
                    onInputChange={(_, value) => {
                      if (!value) {
                        setMotivoBloqueioDefaultValue(undefined)
                        formik.setFieldValue("motivoBloqueioId", undefined)
                      }
                    }}
                    onBlur={() => formik.setTouched({ motivoBloqueioId: true })}
                    renderInput={(params) =>
                      <TextField
                        {...params}
                        placeholder="Selecione"
                      />
                    }
                    noOptionsText="Nenhum dado disponível"
                    disabled={formik.values.bloqueioMutuo ? false : true}
                  />
                  {formik.touched.motivoBloqueioId && formik.errors.motivoBloqueioId && (
                    <div className="mt-3 text-danger fw-bold">{formik.errors.motivoBloqueioId}</div>
                  )}
                </div>

                <Accordion className="shadow mb-10">
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <h4>{t("corban.form.sessions.contaVinculada.title")}</h4>
                  </AccordionSummary>
                  <AccordionDetails>
                    <LinkedAccounts />
                  </AccordionDetails>
                </Accordion>

                <Accordion className="shadow mb-10">
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <h4>{t("corban.form.sessions.endereco.title")}</h4>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="row">
                      <div className="col-sm-7 col-lg-5 mb-10">
                        <label className="form-label">{t("corban.form.sessions.endereco.form.fields.logradouro.label")}</label>
                        <input
                          type="text"
                          name="endereco"
                          className="form-control form-control-lg form-control-solid"
                          value={enderecos?.logradouro ?? ""}
                          disabled
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {/* {formik.touched.endereco && formik.errors.endereco && (
                          <div className="mt-3 text-danger fw-bold">
                            <div className="fv-help-block">{formik.errors.endereco}</div>
                          </div>
                        )} */}
                      </div>
                      <div className="col-4 col-sm-2 col-lg-2 col-xxl-1 mb-10">
                        <label className="form-label">{t("corban.form.sessions.endereco.form.fields.numero.label")}</label>
                        <input
                          type="text"
                          name="numeroEndereco"
                          className="form-control form-control-lg form-control-solid"
                          value={enderecos?.numero ?? ""}
                          disabled
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {/* {formik.touched.enderecos && formik.errors.numeroEndereco && (
                          <div className="mt-3 text-danger fw-bold">
                            <div className="fv-help-block">{formik.errors.numeroEndereco}</div>
                          </div>
                        )} */}
                      </div>
                      <div className="col-8 col-sm-3 col-lg-2 col-xxl-3 mb-10">
                        <label className="form-label">{t("corban.form.sessions.endereco.form.fields.complemento.label")}</label>
                        <input
                          type="text"
                          name="complemento"
                          className="form-control form-control-lg form-control-solid"
                          value={enderecos?.complemento ?? ""}
                          disabled
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {/* {formik.touched.complemento && formik.errors.complemento && (
                          <div className="mt-3 text-danger fw-bold">
                            <div className="fv-help-block">{formik.errors.complemento}</div>
                          </div>
                        )} */}
                      </div>
                      <div className="col-6 col-sm-3 col-lg-3 mb-10">
                        <label className="form-label">{t("corban.form.sessions.endereco.form.fields.bairro.label")}</label>
                        <input
                          type="text"
                          name="bairro"
                          className="form-control form-control-lg form-control-solid"
                          value={enderecos?.bairro ?? ""}
                          disabled
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {/* {formik.touched.bairro && formik.errors.bairro && (
                          <div className="mt-3 text-danger fw-bold">
                            <div className="fv-help-block">{formik.errors.bairro}</div>
                          </div>
                        )} */}
                      </div>
                      <div className="col-6 col-sm-3 col-lg-3 mb-10">
                        <label className="form-label">{t("corban.form.sessions.endereco.form.fields.cidade.label")}</label>
                        <input
                          type="text"
                          name="cidade"
                          className="form-control form-control-lg form-control-solid"
                          value={enderecos?.cidade ?? ""}
                          disabled
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {/* {formik.touched.cidade && formik.errors.cidade && (
                          <div className="mt-3 text-danger fw-bold">
                            <div className="fv-help-block">{formik.errors.cidade}</div>
                          </div>
                        )} */}
                      </div>
                      <div className="col-4 col-sm-2 col-lg-2 col-xxl-1 mb-10">
                        <label className="form-label">{t("corban.form.sessions.endereco.form.fields.uf.label")}</label>
                        <input
                          type="text"
                          name="uf"
                          className="form-control form-control-lg form-control-solid"
                          value={enderecos?.estado ?? ""}
                          disabled
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {/* {formik.touched.uf && formik.errors.uf && (
                          <div className="mt-3 text-danger fw-bold">
                            <div className="fv-help-block">{formik.errors.uf}</div>
                          </div>
                        )} */}
                      </div>
                      <div className="col-8 col-sm-4 col-lg-3 col-xxl-2 mb-10">
                        <label className="form-label">{t("corban.form.sessions.endereco.form.fields.cep.label")}</label>
                        <input
                          type="text"
                          name="cep"
                          className="form-control form-control-lg form-control-solid"
                          value={enderecos?.cep ?? ""}
                          disabled
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {/* {formik.touched.cep && formik.errors.cep && (
                          <div className="mt-3 text-danger fw-bold">
                            <div className="fv-help-block">{formik.errors.complemento}</div>
                          </div>
                        )} */}
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>

                <Accordion className="shadow mb-10">
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <h4>{t("corban.form.sessions.poc.title")}</h4>
                  </AccordionSummary>
                  <AccordionDetails>
                    <PocTable />
                  </AccordionDetails>
                </Accordion>

                <Accordion className="shadow mb-10">
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                    <h4>{t("corban.form.sessions.subsVinculadas.title")}</h4>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Subs />
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>
            <div className="card-footer d-flex justify-content-end py-6 px-9">
              <Link className="btn btn-light btn-active-light-primary me-2" to="/cadastros/corban">
                {t("crud.create.buttonCancel")}
              </Link>
              <SaveButton
                type="submit"
                loading={isLoading}
                disabled={!formik.isValid || !formik.dirty}
                roles={[RolePermission.Update_Corban]}
              />
            </div>
          </div>
        </form>
      </MainLayout>
    </MasterLayout>
  );
}