import i18next from "i18next";
import DataTable, { TableColumn } from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { tableHelper } from "common/helpers/table/table-helper";
import { StatusFilterDropdown } from "common/partials/StatusFilterDropdown";
import { MasterLayout, MainLayout, ToolbarLayout, ToolbarLayoutLeft, ToolbarLayoutRight } from "common/imports/content-layout";
import { itlabMediumTableStyle } from "common/helpers/table/table-style";
import en from "../i18n/en-us.json";
import pt from "../i18n/pt-br.json";
import { UserManagementModel as Model } from "../model/user-management.model";
import { UserManagementService as Service } from "../services/user-management.service";
import DeleteModal from "common/partials/DeleteModal";
import ListActionsButtons from "common/partials/ListActionsButtons";

export function UserManagement() {
  i18next.addResourceBundle("us", "translation", en);
  i18next.addResourceBundle("br", "translation", pt);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [filterStatus, setFilterStatus] = useState("true");
  const [tableData, setTableData] = useState<Model[]>([]);
  const [filteredTableData, setFilteredTableData] = useState<Model[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState<Model[]>([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleShowDeleteModal = (id: number) => {
    setShowDeleteModal(true);
    setDeleteId(id);
  };

  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  const columns: TableColumn<Model>[] = [
    {
      name: "Id",
      selector: (row) => row?.id || "",
      sortable: true,
      width: "100px",
    },

    {
      name: t("table.name"),
      selector: (row) => row.name || "",
      sortable: true,
      minWidth: "100px",
    },
    {
      name: t("form.username"),
      selector: (row) => row.userName || "",
      sortable: true,
      minWidth: "100px",
    },

    {
      name: t("table.email"),
      selector: (row) => row.email || "",
      sortable: true,
      minWidth: "100px",
    },

    {
      name: t("table.status"),
      cell: (row) =>
        row.isActive ? (
          <span className="badge badge-light-primary">{t("generalMessages.active")}</span>
        ) : (
          <span className="badge badge-light-danger">{t("generalMessages.inactive")}</span>
        ),
      center: true,
    },
    {
      name: `${t("table.actions")}`,
      cell: (row) => (
        <ListActionsButtons
          editButton
          deactivateButton
          editAction={() => navigate(`/security/usermanagement/${row.id}`)}
          deactivateAction={() => handleShowDeleteModal(row.id!)}
        />
      ),
      width: "150px",
      center: true,
    },
  ];

  const handleStatusChange = (event: any) => {
    setFilterStatus(event.target.value);
  };

  const handleStatusClick = (event: any) => {
    if (filterStatus === "") {
      setFilteredTableData(tableData);
      return;
    }
    setFilteredTableData(tableData.filter((item) => item.isActive === (filterStatus === "true")));
    setToggleCleared(true);
    setSelectedRows([]);
  };

  function FilterByName(filter: string) {
    if (filter === "") {
      setFilteredTableData(tableData);
      return;
    }

    const normalizedFilter = filter.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

    setFilteredTableData(
      tableData.filter(
        (item) =>
          item.name
            ?.normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
            .includes(normalizedFilter.toLowerCase()) ||
          item.email
            ?.normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
            .includes(normalizedFilter.toLowerCase()) ||
          item.id.toString().includes(filter),
      ),
    );
  }

  const fetchData = () => {
    (async () => {
      try {
        const query = "ShowAll=true";
        const response = await Service.getSimpleListFiltering(0, 999, query);

        if (response) {
          setTableData(response.data);
          setFilteredTableData(response.data.filter((item: any) => item.isActive === true));
        }
      } catch (err) {
        toast.error(t("crud.read.errorMessage"));
      } finally {
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleRowSelected = useCallback((state: any) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const deleteDataById = async () => {
    try {
      setIsLoading(true);
      const result = await Service.deleteUserById(deleteId);
      if (result && !result.hasErrors) {
        toast.success(t("crud.deactivate.successMessage"));
      } else {
        toast.error(t("crud.deactivate.errorMessage"));
      }
    } catch (err) {
      toast.error(t("crud.read.errorMessage"));
    } finally {
      setToggleCleared(!toggleCleared);
      handleCloseDeleteModal();
      fetchData();
      setIsLoading(false);
    }
  };

  const contextActions = useMemo(() => {
    const deleteSelectedRows = () => {
      setShowDeleteModal(true);
      selectedRows.forEach((row) => {
        setDeleteId(row.id!);
      });
    }

    return (
      <button type="button" className="btn btn-sm btn-danger" onClick={deleteSelectedRows}>
        {t("crud.deactivate.buttonSave")}
      </button>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRows, toggleCleared]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <MasterLayout>
        <ToolbarLayout>
          <ToolbarLayoutLeft />
          <ToolbarLayoutRight>
            <div className="d-flex align-items-center">
              <StatusFilterDropdown
                filterStatus={filterStatus}
                handleStatusClick={handleStatusClick}
                handleStatusChange={handleStatusChange}
              />
              <Link to="/security/usermanagement/add" className="btn btn-sm btn-primary fw-bolder">
                {t("addUserButton")}
              </Link>
            </div>
          </ToolbarLayoutRight>
        </ToolbarLayout>
        <MainLayout>
          <div className="card card-flush">
            <div className="card-body">
              <DataTable
                title={tableHelper.getTableHeader((e: any) => FilterByName(e.target.value))}
                persistTableHead
                columns={columns}
                data={filteredTableData}
                progressPending={isLoading}
                progressComponent={tableHelper.getLoader()}
                contextActions={contextActions}
                contextMessage={tableHelper.getContextMessage()}
                noDataComponent={tableHelper.getNoDataComponent()}
                selectableRows
                selectableRowsHighlight
                selectableRowsVisibleOnly
                onSelectedRowsChange={handleRowSelected}
                clearSelectedRows={toggleCleared}
                dense
                striped
                highlightOnHover
                customStyles={itlabMediumTableStyle}
                pagination
                paginationComponentOptions={tableHelper.getPaginationComponentOptions()}
              />
            </div>
          </div>

          <DeleteModal
            type="deactivate"
            onConfirm={deleteDataById}
            onClose={handleCloseDeleteModal}
            open={showDeleteModal}
            loading={isLoading}
          />
        </MainLayout>
      </MasterLayout>
    </>
  );
}
