import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { CalculoSettingsModel as Model } from '../../model/general-settings.model';
import { GeneralSettingsService as Service } from '../../services/general-settings.service';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import SaveButton from 'common/partials/SaveButton';

interface Props {}

export function CalculoSettings(props: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const formSchema = Yup.object().shape({
    roboSimulacaoModalidade: Yup.string().required(t('crud.validators.requiredField').toString()),
    ticketMinimo: Yup.string().required(t('crud.validators.requiredField').toString()),
  });

  let initialData: Model = {
    roboSimulacaoModalidade: '',
    ticketMinimo: 0,
  };

  const [data, setData] = useState<Model>(initialData);

  const formik = useFormik<Model>({
    enableReinitialize: true,
    initialValues: data,
    validationSchema: formSchema,
    onSubmit: (values) => {
      setIsLoading(true);

      const body: Model = {
        roboSimulacaoModalidade: values.roboSimulacaoModalidade,
        ticketMinimo: values.ticketMinimo,
      };

      Service.putCalculoSettings(body);
      toast.success(t('crud.update.successMessage'));
      setIsLoading(false);
    },
  });

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const response = await Service.getCalculoSettings();
        if (response && !response.hasErrors) {
          setData(response.data);
        }
      } catch (err) {
        toast.error(t('crud.read.errorMessage'));
      } finally {
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <form onSubmit={formik.handleSubmit} className="form">
        <div className="card mb-5 mb-xl-10">
          <div
            className="card-header border-0 cursor-pointer"
            role="button"
            data-bs-toggle="collapse"
            data-bs-target="#kt_robo_settings"
            aria-expanded="true"
            aria-controls="kt_robo_settings"
          >
            <div className="card-title m-0">
              <h3 className="fw-bolder m-0">{t('Parâmetros do Cálculo')}</h3>
            </div>
          </div>

          <div id="kt_robo_settings" className="collapse show">
            <div className="card-body border-top p-9">
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label required fw-bold fs-6">
                  {t('Modalidade')}
                </label>

                <div className="col-lg-8">
                  <div className="row">
                    <div className="col-lg-12 fv-row">
                      <input
                        type="text"
                        name="roboSimulacaoModalidade"
                        className="form-control form-control-lg form-control-solid"
                        placeholder={t('Modalidade').toString()}
                        value={formik.values.roboSimulacaoModalidade ?? ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.roboSimulacaoModalidade && formik.errors.roboSimulacaoModalidade && (
                        <div className="mt-3 text-danger fw-bold">
                          <div className="fv-help-block">{formik.errors.roboSimulacaoModalidade}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-6">
                <label className="col-lg-4 col-form-label required fw-bold fs-6">
                  {t('Ticket Mínimo')}
                </label>

                <div className="col-lg-8">
                  <div className="row">
                    <div className="col-lg-12 fv-row">
                      <input
                        type="number"
                        name="ticketMinimo"
                        className="form-control form-control-lg form-control-solid"
                        placeholder={t('Ticket Mínimo').toString()}
                        value={formik.values.ticketMinimo ?? ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.ticketMinimo && formik.errors.ticketMinimo && (
                        <div className="mt-3 text-danger fw-bold">
                          <div className="fv-help-block">{formik.errors.ticketMinimo}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <div className="card-footer d-flex justify-content-end py-6 px-9">
              <button type="reset" className="btn btn-white btn-active-light-primary me-2">{t('crud.create.buttonCancel')}</button>
              <SaveButton type="submit" loading={isLoading} />
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
