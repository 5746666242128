import { AsideMenuItemWithSub } from './AsideMenuItemWithSub';
import { AsideMenuItem } from './AsideMenuItem';
import { APP_ROUTES } from 'routes/routes';
import { useTranslation } from 'react-i18next';
import dashboardIcon from 'assets/images/icons/dashboard.svg';
import securityIcon from 'assets/images/icons/security.svg';
import settingsIcon from 'assets/images/icons/settings.svg';
import calculatorIcon from 'assets/images/icons/calculator.svg';
import pageIcon from 'assets/images/icons/page.svg';
import abs015 from 'assets/images/icons/abs015.svg';

export function AsideMenuMain() {
  const { t } = useTranslation();

  return (
    <>
      <AsideMenuItem
        to="/dashboard"
        icon={dashboardIcon}
        title={`${t('menu.dashboard')}`}
        fontIcon="bi-app-indicator"
      />

      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">{t('menu.general')}</span>
        </div>
      </div>

      <AsideMenuItemWithSub to="/operacao" title={`${t('Operação')}`} fontIcon="bi-archive" icon={calculatorIcon}>
        <AsideMenuItem
          to={'/' + APP_ROUTES.operacao + '/' + APP_ROUTES.calculo.list}
          title={`${t('Cálculo de Contratação')}`}
          hasBullet={true}
        />
        <AsideMenuItem
          to={'/' + APP_ROUTES.operacao + '/' + APP_ROUTES.lotes.list}
          title={`${t('Lotes de Contratação')}`}
          hasBullet={true}
        />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub to="/cadastros" title={`${t('Cadastros')}`} fontIcon="bi-chat-left" icon={pageIcon}>
        <AsideMenuItem
          to={'/' + APP_ROUTES.cadastros + '/' + APP_ROUTES.corban.list}
          title={`${t('Corbans')}`}
          hasBullet={true}
        />
        <AsideMenuItem
          to={'/' + APP_ROUTES.cadastros + '/' + APP_ROUTES.convenio.list}
          title={`${t('Convênios')}`}
          hasBullet={true}
        />
        <AsideMenuItem
          to={'/' + APP_ROUTES.cadastros + '/' + APP_ROUTES.tipoCurva.list}
          title={`${t('Tipos de Curva')}`}
          hasBullet={true}
        />
        <AsideMenuItem
          to={'/' + APP_ROUTES.cadastros + '/' + APP_ROUTES.motivosBloqueio.list}
          title={`${t('Motivos de Bloqueio')}`}
          hasBullet={true}
        />
        <AsideMenuItem
          to={'/' + APP_ROUTES.cadastros + '/' + APP_ROUTES.percentuais.list}
          title={`${t('Serviços Percentuais')}`}
          hasBullet={true}
        />
        <AsideMenuItem
          to={'/' + APP_ROUTES.cadastros + '/' + APP_ROUTES.parecer}
          title={`${t('Parecer')}`}
          hasBullet={true}
        />
        <AsideMenuItem
          to={'/' + APP_ROUTES.cadastros + '/' + APP_ROUTES.parametros}
          title={`${t('Parâmetros')}`}
          hasBullet={true}
        />
        <AsideMenuItem
          to={'/' + APP_ROUTES.cadastros + '/' + APP_ROUTES.importacao}
          title="Importação de Dados"
          hasBullet={true}
        />
        <AsideMenuItem
          to={'/' + APP_ROUTES.cadastros + '/' + APP_ROUTES.importacaoContratos}
          title="Importação de Contratos"
          hasBullet={true}
        />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub to="/relatorios" title={`${t('Relatórios')}`} fontIcon="bi-archive" icon={abs015}>
      <AsideMenuItem
          to={'/' + APP_ROUTES.relatorio + '/' + APP_ROUTES.relatorios.relatorioContratosConsignados}
          title={`${t('Contratos Consignados')}`}
          hasBullet={true}
        />
        <AsideMenuItem
          to={'/' + APP_ROUTES.relatorio + '/' + APP_ROUTES.relatorios.relatorioBaseAnalitica}
          title={`${t('Base Analítica - Composição do Consignado')}`}
          hasBullet={true}
        />
        <AsideMenuItem
          to={'/' + APP_ROUTES.relatorio + '/' + APP_ROUTES.relatorios.relatorioCarteiraContratosMutuo}
          title={`${t('Base Analítica - Carteira Contratos Consignados')}`}
          hasBullet={true}
        />
        <AsideMenuItem
          to={'/' + APP_ROUTES.relatorio + '/' + APP_ROUTES.relatorios.relatorioAnaliticoCalculoApurado}
          title={`${t('Base Analítica - Analítico de Cálculo Apurado')}`}
          hasBullet={true}
        />
        <AsideMenuItem
          to={'/' + APP_ROUTES.relatorio + '/' + APP_ROUTES.relatorios.relatorioContratosMutuoCorban}
          title={`${t('Relatório Unificado - Contratos Consignados Corban')}`}
          hasBullet={true}
        />
      </AsideMenuItemWithSub>

      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">{`${t('menu.settings')}`}</span>
        </div>
      </div>

      <AsideMenuItemWithSub to="/security" title={`${t('menu.security')}`} fontIcon="bi-shield-alt" icon={securityIcon}>
        <AsideMenuItem
          to={'/' + APP_ROUTES.security + '/' + APP_ROUTES.usermanagement.list}
          title={`${t('menu.userManagement.title')}`}
          hasBullet={true}
        />
        <AsideMenuItem
          to={'/' + APP_ROUTES.security + '/' + APP_ROUTES.groupmanagement.list}
          title={`${t('menu.groupManagement.title')}`}
          hasBullet={true}
        />
        <AsideMenuItem
          to={'/' + APP_ROUTES.security + '/' + APP_ROUTES.apiClients.list}
          title={`${t('menu.apiClients')}`}
          hasBullet={true}
        />
      </AsideMenuItemWithSub>

      <AsideMenuItemWithSub to="/settings" title={`${t('menu.settings')}`} fontIcon="bi-chat-left" icon={settingsIcon}>
        <AsideMenuItem
          to={'/' + APP_ROUTES.settings + '/' + APP_ROUTES.healthcheck}
          title={`${t('menu.healthCheck')}`}
          hasBullet={true}
        />
        <AsideMenuItem
          to={'/' + APP_ROUTES.settings + '/' + APP_ROUTES.generalsettings}
          title={`${t('menu.generalSettings')}`}
          hasBullet={true}
        />
      </AsideMenuItemWithSub>

      <div className="menu-item">
        <div className="menu-content">
          <div className="separator mx-1 my-4"></div>
        </div>
      </div>
    </>
  );
}