import { tableHelper } from "common/helpers/table/table-helper";
import { itlabMediumTableStyle } from "common/helpers/table/table-style";
import {
  MasterLayout,
  MainLayout,
  ToolbarLayout,
  ToolbarLayoutLeft,
  ToolbarLayoutRight,
} from "common/imports/content-layout";
import i18next from "i18next";
import moment from "moment";
import "moment/locale/pt-br";
import { useEffect, useState } from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import en from "../../calculo/i18n/en-us.json";
import pt from "../../calculo/i18n/pt-br.json";
import { LotesContratacaoService } from "../services/lotes-contratacao.service";
import { ExecucoesService } from "../../execucoes/services/execucoes.service";
import { RelatoriosService } from "../../relatorios/services/relatorios.service";
import { LotesContratacaoCorbanListViewModel, LotesContratacaoDetailViewModel } from "../model/lotes-contratacao.model";
import { currencyMask } from "common/helpers/masks/CurrencyMask";
import { Tooltip } from "antd";
import { debounce } from "@mui/material";
import SaveButton from "common/partials/SaveButton";
import { GerarLoteContratacaoViewModel } from "app/calculo/model/calculo-contratacao.model";
import { useDownloadFile } from "common/helpers/download-file/useDownloadFile";
import { RolePermission } from "common/helpers/dictionaries/roles";

function DetalhesLotesContratacao() {
  i18next.addResourceBundle("us", "translation", en);
  i18next.addResourceBundle("br", "translation", pt);
  const { t } = useTranslation();
  const { loteId } = useParams();
  const [data, setData] = useState<LotesContratacaoDetailViewModel>();
  const [tableData, setTableData] = useState<LotesContratacaoCorbanListViewModel[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [corbanSearchValue, setCorbanSearchValue] = useState("");
  const [isRecalcular, setIsRecalcular] = useState(false);
  const [isExportar, setIsExportar] = useState(false);
  const handleCorbanSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCorbanSearchValue(e.target.value);
  };

  const handleDebouncedCorbanSearch = debounce(handleCorbanSearchChange, 800);

  const conditionalRowStyles = [
    {
      when: (row: any) => row.statusContratacaoId === 4 || row.statusContratacaoId === 6,
      style: {
        backgroundColor: '#ff4d4d',
        color: 'white',
        '&:hover': {
          cursor: 'pointer',
        },
      },
    },
  ];

  const tableColumns: TableColumn<LotesContratacaoCorbanListViewModel>[] = [
    {
      name: `${t("Ações")}`,
      cell: (row) => (
        <Tooltip title="Ver detalhes">
          <Link
            to={`/operacao/lotes/${data?.id}/corban/${row?.corbanId}`}
            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2">
            <i className="fa-solid fa-eye"></i>
          </Link>
        </Tooltip>
      ),
      center: true,
      width: "80px",
    },
    {
      name: `${t("Status")}`,
      selector: (row) => row.statusContratacaoDescricao || "",
      sortable: true,
      minWidth: "330px"
    },
    {
      name: `${t("IDLoja")}`,
      selector: (row) => row?.idLoja || 0,
      sortable: true,
      minWidth: "90px",
    },
    {
      name: `${t("CNPJ")}`,
      selector: (row) => row?.cnpj?.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5") || "",
      sortable: true,
      minWidth: "180px",
    },
    {
      name: `${t("Razão Social")}`,
      selector: (row) => row.razaoSocial || "",
      sortable: true,
      width: "250px"
    },
    {
      name: `${t("Grupo")}`,
      selector: (row) => row.grupo || "",
      sortable: true,
      minWidth: "250px"
    },
    {
      name: `${t("Qtd. Contratos")}`,
      selector: (row) => row.quantidadeContratos || 0,
      sortable: true,
      center: true,
      minWidth: "140px"
    },
    {
      name: `${t("Valor Operacional")}`,
      selector: (row) => currencyMask(row.valorOperacional) || "",
      sortable: true,
      right: true,
      minWidth: "160px"
    },
    {
      name: `${t("Valor Antecipado")}`,
      selector: (row) => currencyMask(row.valorAntecipado) || "",
      sortable: true,
      right: true,
      minWidth: "150px"
    },
    {
      name: `${t("Valor Diferido")}`,
      selector: (row) => currencyMask(row.valorDiferido) || "",
      sortable: true,
      right: true,
      minWidth: "150px"
    },
    {
      name: `${t("Taxa Mutuo")}`,
      selector: (row) => (row.percentualMutuo ?? 0).toFixed(4).replace('.', ','),
      sortable: true,
      right: true,
      minWidth: "120px"
    },
    {
      name: `${t("1ª Parcela")}`,
      selector: (row) =>
        moment(row.primeiraParcela)
          .locale(`${t("dateFormat.locale")}`)
          .format("L"),
      sortable: true,
      center: true,
      minWidth: "140px"
    },
    {
      name: `${t("Valor Parcela Mutuo")}`,
      selector: (row) => currencyMask(row.valorParcela) || "",
      sortable: true,
      right: true,
      minWidth: "180px"
    },
  ];

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    setIsLoading(true);
    if (loteId) {
      const response = await LotesContratacaoService.getDetalhesLoteContratacaoFiltered(loteId, 0, newPerPage, "");
      if (response && !response.hasErrors) {
        setTableData(response.data);
      }
    }

    setRowsPerPage(newPerPage);
  };

  const handlePageChange = (page: number) => {
    fetchFilteredData(page - 1);
  };

  const fetchHeader = async () => {
    setIsLoading(true);
    try {
      if (loteId) {
        const response = await LotesContratacaoService.getLoteContratacaoById(loteId);
        if (response && !response.hasErrors) {
          setData(response.data);
        }
      }
    } catch (err) {
      toast.error(t("crud.read.errorMessage"));
    } finally {
      setIsLoading(false);
    }
  }

  const fetchFilteredData = async (page: number) => {
    try {
      setIsLoading(true);
      if (loteId) {
        const response = await LotesContratacaoService.getDetalhesLoteContratacaoFiltered(loteId, page, rowsPerPage, corbanSearchValue);
        if (response && !response.hasErrors) {
          setTableData(response.data);
          setTotalRows(response.totalItems);
        }
      }
    } catch (err) {
      toast.error(t("crud.read.errorMessage"));
    } finally {
      setIsLoading(false);
    }
  };

  const recalcular = async () => {
    setIsRecalcular(true);
    const body: GerarLoteContratacaoViewModel = {
      loteContratacaoId: data?.id,
      dataMutuo: moment(data?.dataMutuo).format("YYYY-MM-DD"),
      periodoInicial: moment(data?.periodoInicial).format("YYYY-MM-DD"),
      periodoFinal: moment(data?.periodoFinal).format("YYYY-MM-DD"),
      dataPagamento: moment(data?.dataPagamento).format("YYYY-MM-DD"),
      diaVencimento: data?.diaVencimento ?? 1,
      amortizacaoId: data?.amortizacaoId ?? 0,
      convenioId: data?.convenioId ?? 0,
      modalidade: data?.modalidade ?? "",
      excluidos: []
    };

    const response = await ExecucoesService.GerarLoteContratacao(body);
    if (response && !response?.hasErrors) {
      toast.success("Lote registrado e em processo de Recalculo. Acesse a lista de lotes para ver o status.")
    } else {
      toast.error(t(`${response?.message}`));
    }
    setIsRecalcular(false);
  };
  // INICIO: metodos para Exportação
  const gerarRelatorio = async () => {

    const response = await LotesContratacaoService.getDetalhesLoteContratacaoFiltered(loteId ?? "", 0, 5000, "");
    const lojas = response?.data.map(m => m.idLoja);
    const preDownloading = () => { };
    const postDownloading = () => { };
    const onErrorDownloadFile = () => { };
    const downloadSampleCsvFile_2 = RelatoriosService.getUrlExportarRelatorioBaseAnalitica;
    lojas?.forEach(async (loja) => {
      const fileName = `${moment(new Date()).format("YYYYMMDD")}_${loja}.xlsx`;
      const filterExport = {
        lotesContratacaoId: loteId,
        idLoja: loja,
        page: 0,
        limit: 1048576 - 1
      };
      await useDownloadFile.download({
        apiDefinition: downloadSampleCsvFile_2,
        preDownloading,
        postDownloading,
        onError: onErrorDownloadFile,
        apiParameters: filterExport,
        getFileName: fileName,
        typeFile: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,"
      });
    });

  };
  // FIM: metodos para Exportação
  const exportar = async () => {
    setIsExportar(true);
    await gerarRelatorio();
    setIsExportar(false);
  };

  useEffect(() => {
    fetchHeader();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        await fetchFilteredData(page);
      } catch (err) {
        toast.error(t("crud.read.errorMessage"));
      } finally {
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [corbanSearchValue, rowsPerPage]);

  return (
    <MasterLayout>
      <ToolbarLayout>
        <ToolbarLayoutLeft />
        <ToolbarLayoutRight />
      </ToolbarLayout>
      <MainLayout>
        <div className="card mb-5 mb-xl-10">
          <div className="card-body">
            <div className="row">

              <div className="col-sm-6 col-md-4 col-xl-3 col-xxl-2 mb-5">
                <label className="fw-bold text-muted">Lote</label>
                <div className="fw-bolder fs-6 text-dark">{data?.id ?? ""}</div>
              </div>

              <div className="col-sm-6 col-md-4 col-xl-3 col-xxl-2 mb-5">
                <label className="fw-bold text-muted">Data Mutuo</label>
                <div className="fw-bolder fs-6 text-dark">
                  {
                    moment(data?.dataMutuo)
                      .locale(`${t("dateFormat.locale")}`)
                      .format("L") ?? ""
                  }
                </div>
              </div>

              <div className="col-sm-6 col-md-4 col-xl-3 col-xxl-3 mb-5">
                <label className="fw-bold text-muted">Período</label>
                <div className="fw-bolder fs-6 text-dark">
                  <span className="me-2">
                    {
                      moment(data?.periodoInicial)
                        .locale(`${t("dateFormat.locale")}`)
                        .format("L") ?? ""
                    }
                  </span>
                  até
                  <span className="ms-2">
                    {
                      moment(data?.periodoFinal)
                        .locale(`${t("dateFormat.locale")}`)
                        .format("L") ?? ""
                    }
                  </span>
                </div>
              </div>

              <div className="col-sm-6 col-md-4 col-xl-3 col-xxl-2 mb-5">
                <label className="fw-bold text-muted">Dia Vencimento</label>
                <div className="fw-bolder fs-6 text-dark">{data?.diaVencimento ?? ""}</div>
              </div>

              <div className="col-sm-6 col-md-4 col-xl-3 col-xxl-2 mb-5">
                <label className="fw-bold text-muted">Qtd. Corbans</label>
                <div className="fw-bolder fs-6 text-dark">{data?.quantidadeCorbans ?? ""}</div>
              </div>

              <div className="col-sm-6 col-md-4 col-xl-3 col-xxl-2 mb-5">
                <label className="fw-bold text-muted">Qtd. Contratos</label>
                <div className="fw-bolder fs-6 text-dark">{data?.quantidadeContratos ?? ""}</div>
              </div>

              <div className="col-sm-6 col-md-4 col-xl-3 col-xxl-2 mb-5">
                <label className="fw-bold text-muted">Total Antecipado</label>
                <div className="fw-bolder fs-6 text-dark">{currencyMask(data?.totalAntecipado) ?? ""}</div>
              </div>

              <div className="col-sm-6 col-md-4 col-xl-3 col-xxl-3 mb-5">
                <label className="fw-bold text-muted">Convênio</label>
                <div className="fw-bolder fs-6 text-dark">{data?.convenio ?? ""}</div>
              </div>

              <div className="col-sm-6 col-md-4 col-xl-3 col-xxl-2 mb-5">
                <label className="fw-bold text-muted">Ticket Mínimo</label>
                <div className="fw-bolder fs-6 text-dark">{currencyMask(data?.ticketMinimo) ?? ""}</div>
              </div>

              <div className="col-sm-6 mb-5">
                <label className="fw-bold text-muted">Status</label>
                <div className="fw-bolder fs-6 text-dark">{data?.statusLoteContratacao ?? ""}</div>
              </div>
            </div>
            <div className="row">
              <div className="card-footer d-flex justify-content-end py-6 px-9">
                <div className="col-xl-4 mb-5 mx-5">
                  <SaveButton
                    type="button"
                    label={`Exportar Apuração Realizada`}
                    loading={isExportar}
                    onClick={exportar}
                  />
                </div>
                <div className="col-xl-1 mb-5">
                  <SaveButton
                    type="button"
                    label={`Recalcular`}
                    loading={isRecalcular}
                    onClick={recalcular}
                    roles={[RolePermission.Update_Execucao]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div >

        <div className="card">
          <div className="card-body">
            <div className="row">

              <div className="col-md-12 mb-5">
                <label className="card-title fw-bolder text-muted fs-4">
                  Corbans do Lote
                </label>
              </div>

              <DataTable
                title={tableHelper.getTableHeader(handleDebouncedCorbanSearch)}
                persistTableHead
                columns={tableColumns}
                data={tableData}
                progressPending={isLoading}
                progressComponent={tableHelper.getLoader()}
                noDataComponent={tableHelper.getNoDataComponent()}
                dense
                customStyles={itlabMediumTableStyle}
                striped
                highlightOnHover
                contextMessage={tableHelper.getContextMessage()}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handlePerRowsChange}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                paginationPerPage={rowsPerPage}
                paginationRowsPerPageOptions={[10, 25, 50, 100]}
                paginationComponentOptions={tableHelper.getPaginationComponentOptions()}
                conditionalRowStyles={conditionalRowStyles}
              />
            </div>
          </div>
        </div>
      </MainLayout >
    </MasterLayout >
  )
}

export default DetalhesLotesContratacao