import { tableHelper } from 'common/helpers/table/table-helper';
import { itlabMediumTableStyle } from 'common/helpers/table/table-style';
import {
  MasterLayout,
  MainLayout,
  ToolbarLayout,
  ToolbarLayoutLeft,
  ToolbarLayoutRight,
} from 'common/imports/content-layout';
import i18next from 'i18next';
import moment from 'moment';
import 'moment/locale/pt-br';
import { useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import en from '../../calculo/i18n/en-us.json';
import pt from '../../calculo/i18n/pt-br.json';
import { LotesContratacaoViewModel } from '../model/lotes-contratacao.model';
import { LotesContratacaoService } from '../services/lotes-contratacao.service';
import { DatePicker, Tooltip } from 'antd';
import dayjs from 'dayjs';
import locale from 'antd/es/date-picker/locale/pt_BR';
import { currencyMask } from 'common/helpers/masks/CurrencyMask';
import DeleteModal from 'common/partials/DeleteModal';

function LotesContratacao() {
  i18next.addResourceBundle('us', 'translation', en);
  i18next.addResourceBundle('br', 'translation', pt);
  const { t } = useTranslation();
  const [tableData, setTableData] = useState<LotesContratacaoViewModel[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { RangePicker } = DatePicker;
  const [dataInicial, setDataInicial] = useState("");
  const [dataFinal, setDataFinal] = useState("");
  const dateFormat = "DD/MM/YYYY";
  const [page] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalRows, setTotalRows] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(0);

  const handleCloseDeleteModal = () => setShowDeleteModal(false);
  const handlePageChange = (page: number) => {
    fetchData(page - 1, dataInicial, dataFinal);
  };

  const handlePerRowsChange = async (newPerPage: number) => {
    setRowsPerPage(newPerPage);
  };

  const getIconStatus = (status: number) => {
    switch (status) {
      case 1: return 'exclamation-circle text-danger';
      case 2: return 'exclamation-triangle text-warning';
      case 3: return 'check-circle text-success';
      case 4: case 5: return 'exclamation-triangle text-danger';
      default: return '';
    }
  };

  const handleShowDeleteModal = (id: number) => {
    setShowDeleteModal(true);
    setDeleteId(id);
  };

  const tableColumns: TableColumn<LotesContratacaoViewModel>[] = [
    {
      name: `${t('Lote')}`,
      cell: (row) => row.id || '',
      sortable: true,
      width: '50px'
    },
    {
      name: `${t('Data Consignado')}`,
      selector: (row) =>
        moment(row.dataMutuo)
          .locale(`${t('dateFormat.locale')}`)
          .format('L'),
      sortable: true,
      center: true,
    },
    {
      name: `${t('Período')}`,
      selector: (row) =>
        `${moment(row.periodoInicial).locale(`${t('dateFormat.locale')}`).format('L')} - ${moment(row.periodoFinal).locale(`${t('dateFormat.locale')}`).format('L')}`,
      center: true,
      minWidth: '200px'
    },
    {
      name: `${t('Corbans Antecipados')}`,
      selector: (row) => row.quantidadeCorbansAntecipados || 0,
      center: true,
      sortable: true,
      minWidth: '180px'
    },
    {
      name: `${t('Corbans Não Antecipados')}`,
      selector: (row) => row.quantidadeCorbansNaoAntecipados || 0,
      center: true,
      sortable: true,
      minWidth: '210px'
    },
    {
      name: `${t('Qtde. Contratos')}`,
      selector: (row) => row.quantidadeContratos || 0,
      center: true,
      sortable: true,
      minWidth: '160px'
    },
    {
      name: `${t('Total Antecipado')}`,
      selector: (row) => currencyMask(row.totalAntecipado) || '',
      sortable: true,
      right: true,
      minWidth: '150px'
    },
    {
      name: `${t('Ações')}`,
      cell: (row) =>
        <>
          <Tooltip title={`${row.statusLoteContratacao}`}>
            <div className="btn btn-icon">
              <i className={`fa-solid fa-${getIconStatus(row.status)}`}></i>
            </div>
          </Tooltip>
          <Tooltip title={t("crud.common.delete")}>
            <button
              type="button"
              className={`btn btn-icon btn-bg-light btn-sm me-2 ${row.canDelete ? 'btn-active-color-danger' : ''}`}
              onClick={() => row.canDelete ? handleShowDeleteModal(row.id!) : null }
              >
              <span className="svg-icon svg-icon-3">
                <svg className="mh-50px" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor" />
                  <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor" />
                  <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor" />
                </svg>
              </span>
            </button>
          </Tooltip>
          <Tooltip title="Ver detalhes">
            <Link
              to={`/operacao/lotes/${row.id}`}
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2"
            >
              <i className="fa-solid fa-eye"></i>
            </Link>
          </Tooltip>
          <Tooltip title="Execuções">
            <Link
              to={`/operacao/lotes/${row.id}/execucoes`}
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
            >
              <i className="fa-solid fa-list-check"></i>
            </Link>
          </Tooltip>
        </>,
      center: true,
      width: '130px'
    },
  ];

  const handleFilterLotes = (dates: any) => {
    if (dates === null) {
      setDataInicial("");
      setDataFinal("");
    }

    if (dates) {
      const inicio = dayjs(dates[0]).format("YYYY-MM-DD");
      const final = dayjs(dates[1]).format("YYYY-MM-DD");

      setDataInicial(inicio);
      setDataFinal(final);
    }
  };

  const fetchData = async (page: number, dataInicial: string, dataFinal: string) => {
    try {
      setIsLoading(true);
      const response = await LotesContratacaoService.getLotesContratacaoFiltered(page, rowsPerPage, dataInicial, dataFinal);
      if (response && !response.hasErrors) {
        setTableData(response.data);
        setTotalRows(response.totalItems);
      }
    } catch (err) {
      toast.error(t('crud.read.errorMessage'));
    } finally {
      setIsLoading(false);
    }
  };

  const deleteDataById = async () => {
    try {
      setIsLoading(true);
      const result = await LotesContratacaoService.deleteLoteContratacaoById(deleteId);
      if (result && !result.hasErrors) {
        toast.success(`${t("crud.delete.successMessage")}`);
      } else {
        toast.error(`${t("crud.delete.errorMessage")}`);
      }
    } catch (err) {
      toast.error(`${t("crud.read.errorMessage")}`);
    } finally {
      // setToggleCleared(!toggleCleared);
      handleCloseDeleteModal();
      fetchData(page, dataInicial, dataFinal);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData(page, dataInicial, dataFinal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, dataInicial, dataFinal]);

  return (
    <MasterLayout>
      <ToolbarLayout>
        <ToolbarLayoutLeft />
        <ToolbarLayoutRight />
      </ToolbarLayout>
      <MainLayout>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-sm-6 col-lg-4 col-xxl-3 mb-10">
                <RangePicker
                  locale={locale}
                  format={dateFormat}
                  className="form-control form-control-solid d-flex"
                  onChange={(dates: any) => handleFilterLotes(dates)}
                />
              </div>
            </div>
            <DataTable
              persistTableHead
              columns={tableColumns}
              data={tableData}
              progressPending={isLoading}
              progressComponent={tableHelper.getLoader()}
              noDataComponent={tableHelper.getNoDataComponent()}
              dense
              customStyles={itlabMediumTableStyle}
              striped
              highlightOnHover
              contextMessage={tableHelper.getContextMessage()}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePerRowsChange}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationPerPage={rowsPerPage}
              paginationRowsPerPageOptions={[10, 25, 50, 100]}
              paginationComponentOptions={tableHelper.getPaginationComponentOptions()}
            />
          </div>
        </div>
        <DeleteModal
            type="delete"
            onConfirm={deleteDataById}
            onClose={handleCloseDeleteModal}
            open={showDeleteModal}
            loading={isLoading}
          />
      </MainLayout>
    </MasterLayout>
  )
}

export default LotesContratacao